//BundlesList.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Fade,
  Chip,
  Skeleton,
  Link
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BundleWidget from '../components/BundleWidget';
import { createTheme, ThemeProvider, styled, useTheme } from '@mui/material/styles';
import getSignUpTheme from '../pages/theme/theme/getSignUpTheme';

const BundlesListContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  /* padding: theme.spacing(2), */
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const RowContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(2),
  position: 'relative',
}));

const HalfContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  flex: '0 0 48%',
  borderRadius: '8px',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  left: theme.spacing(2),
  zIndex: 3,
  backgroundColor: theme.palette.info.info,
  color: theme.palette.primary.contrastText,
  fontSize: '0.75rem',
  height: 24,
}));

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

function gatherBundlePairs(bundles, language, isShowAdultContent) {
  const results = [];

  // Filter out adult if isShowAdultContent = false
  const filtered = isShowAdultContent ? bundles : bundles.filter((b) => !b.adult);

  // Shuffle them
  shuffleArray(filtered);

  // For each valid bundle, gather up to 3 examples
  for (const b of filtered) {
    if (!b.source_photo) continue;

    const examples = [];
    if (Array.isArray(b.bundle)) {
      for (const item of b.bundle) {
        if (item.example) {
          examples.push(item.example);
          if (examples.length >= 3) break;
        }
      }
    }
    if (examples.length === 0) continue;

    results.push({
      source: b.source_photo,
      examples,
      name: b.name?.[language] || b.name?.en || 'Bundle'
    });
  }
  return results;
}

const t = {
  title: {
    en: "Get full access",
    es: "Obtén acceso completo",
    pt: "Obtenha acesso total",
    de: "Vollzugriff erhalten",
    ja: "フルアクセスを取得",
    ko: "전체 액세스 권한 얻기",
    zh: "获取完整访问权限",
    ru: "Получить полный доступ",
    hi: "पूर्ण पहुंच प्राप्त करें"
  },
  orientation: {
    straight: {
      en: "👁 Your preference is straight. Change",
      es: "👁 Tu preferencia es heterosexual. Cambiar",
      pt: "👁 Sua preferência é heterossexual. Alterar",
      de: "👁 Ihre Präferenz ist heterosexuell. Ändern",
      ja: "👁 あなたの好みはストレートです。変更",
      ko: "👁 선호도가 이성애자로 설정되었습니다. 변경",
      zh: "👁 您的偏好是异性恋。更改",
      ru: "👁 Ваше предпочтение - гетеросексуал. Изменить",
      hi: "👁 आपकी प्राथमिकता सीधा है। बदलें"
    },
    gay: {
      en: "👁 You're viewing gay content. Change",
      es: "👁 Estás viendo contenido gay. Cambiar",
      pt: "👁 Você está vendo conteúdo gay. Alterar",
      de: "👁 Sie sehen sich schwules Material an. Ändern",
      ja: "👁 あなたはゲイのコンテンツを見ています。変更",
      ko: "👁 게이 콘텐츠를 보고 있습니다. 변경",
      zh: "👁 您正在观看同性恋内容。更改",
      ru: "👁 Вы просматриваете контент для геев. Изменить",
      hi: "👁 आप समलैंगिक सामग्री देख रहे हैं। बदलें"
    },
    trans: {
      en: "👁 You're viewing content with trans. Change",
      es: "👁 Estás viendo contenido con trans. Cambiar",
      pt: "👁 Você está vendo conteúdo com trans. Alterar",
      de: "👁 Sie sehen sich Inhalte mit Trans an. Ändern",
      ja: "👁 あなたはトランスのコンテンツを見ています。変更",
      ko: "👁 트랜스 콘텐츠를 보고 있습니다. 변경",
      zh: "👁 您正在观看变性内容。更改",
      ru: "👁 Вы просматриваете контент с транс. Изменить",
      hi: "👁 आप ट्रांस सामग्री देख रहे हैं। बदलें"
    }
  },  
  message: {
    en: [
      "🆕 Get access to new bundles every day by purchasing credits once",
      "⚡️ Get your media processed faster with premium priority",
      "🔓 Full access to all features, including custom instructions"
    ],
    es: [
      "🆕 Obtén acceso a nuevos paquetes todos los días comprando créditos una vez",
      "⚡️ Procesa tus medios más rápido con prioridad premium",
      "🔓 Acceso completo a todas las funciones, incluidas las instrucciones personalizadas"
    ],
    pt: [
      "🆕 Obtenha acesso a novos pacotes todos os dias comprando créditos uma vez",
      "⚡️ Processe sua mídia mais rapidamente com prioridade premium",
      "🔓 Acesso total a todos os recursos, incluindo instruções personalizadas"
    ],
    de: [
      "🆕 Erhalte täglich Zugriff auf neue Pakete durch den einmaligen Kauf von Credits",
      "⚡️ Lass deine Medien schneller mit Premium-Priorität verarbeiten",
      "🔓 Voller Zugriff auf alle Funktionen, einschließlich benutzerdefinierter Anweisungen"
    ],
    ja: [
      "🆕 クレジットを一度購入して毎日新しいバンドルにアクセス",
      "⚡️ プレミアム優先でメディアを迅速に処理",
      "🔓 カスタム指示を含むすべての機能へのフルアクセス"
    ],
    ko: [
      "🆕 크레딧을 한 번 구매하여 매일 새로운 번들에 액세스하세요",
      "⚡️ 프리미엄 우선으로 미디어를 더 빠르게 처리하세요",
      "🔓 사용자 정의 지침을 포함한 모든 기능에 대한 전체 액세스"
    ],
    zh: [
      "🆕 一次购买积分，每天获取新包",
      "⚡️ 使用高级优先权更快处理您的媒体",
      "🔓 完全访问所有功能，包括自定义说明"
    ],
    ru: [
      "🆕 Получайте доступ к новым пакетам каждый день, купив кредиты один раз",
      "⚡️ Быстрая обработка медиа с премиум-приоритетом",
      "🔓 Полный доступ ко всем функциям, включая пользовательские инструкции"
    ],
    hi: [
      "🆕 क्रेडिट को एक बार खरीदकर हर दिन नए बंडलों का एक्सेस प्राप्त करें",
      "⚡️ प्रीमियम प्राथमिकता के साथ अपने मीडिया को तेजी से प्रोसेस करें",
      "🔓 कस्टम निर्देशों सहित सभी सुविधाओं तक पूरी पहुंच"
    ]
  },  
  dismiss: {
    en: "Dismiss",
    es: "Descartar",
    pt: "Dispensar",
    de: "Schließen",
    ja: "閉じる",
    ko: "닫기",
    zh: "忽略",
    ru: "Отклонить",
    hi: "खारिज करें"
  },
  go_shop: {
    en: "Explore",
    es: "Explorar",
    pt: "Explorar",
    de: "Entdecken",
    ja: "探検",
    ko: "탐험하다",
    zh: "探索",
    ru: "Проверить",
    hi: "खोजें"
  },
  load_more: {
    en: "Load More",
    es: "Cargar Más",
    pt: "Carregar Mais",
    de: "Mehr laden",
    ja: "もっと読み込む",
    ko: "더 보기",
    zh: "加载更多",
    ru: "Загрузить ещё",
    hi: "अधिक लोड करें"
  },
  unlock_all: {
    en: "Unlock All",
    es: "Desbloquear Todo",
    pt: "Desbloquear Tudo",
    de: "Alles entsperren",
    ja: "すべてアンロック",
    ko: "모두 잠금 해제",
    zh: "解锁全部",
    ru: "Разблокировать всё",
    hi: "सब खोलें"
  },
  no_preview: {
    en: "(No preview available)",
    es: "(Vista previa no disponible)",
    pt: "(Nenhuma prévia disponível)",
    de: "(Keine Vorschau verfügbar)",
    ja: "(プレビューはありません)",
    ko: "(미리보기를 사용할 수 없음)",
    zh: "(无可用预览)",
    ru: "(Предпросмотр недоступен)",
    hi: "(पूर्वावलोकन उपलब्ध नहीं)"
  }
};

export default function BundlesList({
  bundles = [],
  language = 'en',
  isPaid = false,
  isShowAdultContent = false,
  showData = true,
  showOrientation = true,
  isEligable = false,
  camp,
  mode = 'dark'
}) {

  const customTheme = createTheme(getSignUpTheme(mode));

  // The filtered & shuffled list for normal display
  const [shuffledBundles, setShuffledBundles] = useState([]);
  // For how many bundles we display in the normal list
  const [visibleCount, setVisibleCount] = useState(5);

  // IntersectionObserver for auto-load if isPaid
  const loadMoreRef = useRef(null);
  const observerRef = useRef(null);

  // Unlock All dialog
  const [unlockDialogOpen, setUnlockDialogOpen] = useState(false);

  const navigate = useNavigate();

  // 1) Filter & Shuffle for normal display
  useEffect(() => {

    const orientation = localStorage.getItem('orientation') || 'straight';

    const safeBundles = Array.isArray(bundles) ? bundles : [];
    const cleaned = safeBundles.filter((b) => b);

    let baseFiltered = isShowAdultContent
      ? cleaned
      : cleaned.filter((b) => !b.adult);

    const orientationBundles = baseFiltered.filter(
      (b) => (b.orientation || 'straight') === orientation
    );

    let finalList = orientationBundles;
    if (orientationBundles.length === 0) {
      finalList = baseFiltered.filter(
        (b) => (b.orientation || 'straight') === 'straight'
      );
    }

    shuffleArray(finalList);
    setShuffledBundles(finalList);
    setVisibleCount(5);
  }, [bundles, isShowAdultContent]);

  // Normal "Load More" logic
  const handleLoadMore = useCallback(() => {
    setVisibleCount((prev) => Math.min(prev + 5, shuffledBundles.length));
  }, [shuffledBundles.length]);

  // If isPaid, auto-load more on Intersection
  useEffect(() => {
    if (!isPaid) return;
    if (visibleCount >= shuffledBundles.length) return;

    observerRef.current = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          handleLoadMore();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      }
    );
    const refCurrent = loadMoreRef.current;
    if (refCurrent) observerRef.current.observe(refCurrent);

    return () => {
      if (observerRef.current && refCurrent) {
        observerRef.current.unobserve(refCurrent);
      }
    };
  }, [isPaid, visibleCount, shuffledBundles.length, handleLoadMore]);

  // 2) The normal visible bundles
  const visibleBundles = shuffledBundles.slice(0, visibleCount);

  // 3) "Unlock All" logic => we gather full pairs, open the row-based slideshow
  const [pairsForDialog, setPairsForDialog] = useState([]);
  // index of the current bundle we show in the dialog
  const [dialogIndex, setDialogIndex] = useState(0);
  // index of the example being shown in the current row
  const [exampleIndex, setExampleIndex] = useState(0);

  // fade entire row in/out
  const [rowVisible, setRowVisible] = useState(false);
  // loaded states for skeleton
  const [sourceLoaded, setSourceLoaded] = useState(false);
  const [exampleLoaded, setExampleLoaded] = useState(false);

  const BUNDLE_DISPLAY_MS = 3000; // how long each example is shown
  const FADE_DURATION_MS = 500;  // fade speed
  const GAP_BETWEEN_EXAMPLES = 500; // small gap to fade out old example, fade in new

  const openUnlockDialog = () => {
    setUnlockDialogOpen(true);
    setPairsForDialog(gatherBundlePairs(shuffledBundles, language, isShowAdultContent));
    setDialogIndex(0);
    setExampleIndex(0);
    setRowVisible(false);
    setSourceLoaded(false);
    setExampleLoaded(false);
  };

  // When user closes the dialog
  const handleCloseDialog = () => {
    setUnlockDialogOpen(false);
    setPairsForDialog([]);
    setDialogIndex(0);
    setExampleIndex(0);
    setRowVisible(false);
    setSourceLoaded(false);
    setExampleLoaded(false);
  };

  // Each time we pick a new bundle => reset exampleIndex
  useEffect(() => {
    if (!pairsForDialog.length) return;
    if (dialogIndex >= pairsForDialog.length) {
      // we've displayed all bundles
      handleCloseDialog();
      return;
    }
    setExampleIndex(0);
    setRowVisible(false);
    setSourceLoaded(false);
    setExampleLoaded(false);
    // fade row in
    setTimeout(() => setRowVisible(true), 100);
  }, [dialogIndex, pairsForDialog]);

  // Called after example loads => show for X ms => fade out => next example or next bundle
  const handleExampleLoad = () => {
    setExampleLoaded(true);
    // after X ms
    setTimeout(() => {
      // fade out example
      setExampleLoaded(false);
      // after fade out
      setTimeout(() => {
        // next example
        const nextEx = exampleIndex + 1;
        const current = pairsForDialog[dialogIndex];
        if (nextEx < current.examples.length) {
          setExampleIndex(nextEx);
          setExampleLoaded(false);
        } else {
          // all examples shown => fade out the entire row => next bundle
          setRowVisible(false);
          setTimeout(() => {
            setDialogIndex((prev) => prev + 1);
          }, FADE_DURATION_MS);
        }
      }, GAP_BETWEEN_EXAMPLES);
    }, BUNDLE_DISPLAY_MS);
  };

  // For the current row
  const currentRow = pairsForDialog[dialogIndex] || null;
  const currentExampleUrl = currentRow?.examples?.[exampleIndex];

  /* useEffect(() => {
    if (!isPaid) {
      const alreadyShown = localStorage.getItem('ad_shown');
      if (!alreadyShown) {
        // Show the dialog automatically when user opens
        localStorage.setItem('ad_shown', 'true');
        openUnlockDialog();
      }
    }
  }, [isPaid]);  */

  return (
    <BundlesListContainer>
      {(isShowAdultContent && showOrientation && (localStorage.getItem('orientation') !== 'straight')) && (
        <Link
          component="button"
          onClick={() => navigate('/profile')}
          underline="hover"
          color="primary"
          sx={{marginBottom: 2}}
        >
          {t.orientation?.[localStorage.getItem('orientation') || 'straight']?.[language] || t.orientation?.[localStorage.getItem('orientation') || 'straight'].en}
        </Link>
      )}

      {visibleBundles.map((bundle, idx) => (
        <BundleWidget
          key={bundle.bundle_id || idx}
          name={bundle.name?.[language] || bundle.name.en}
          description={bundle.description?.[language] || bundle.description.en}
          source_photo={bundle.source_photo}
          bundle={bundle.bundle}
          price={bundle.price}
          bundle_id={bundle.bundle_id}
          language={language}
          showData={showData}
          isEligable={bundle?.isEligable !== null ? bundle?.isEligable : isEligable}
          openUnlockDialog={openUnlockDialog}
          camp={camp}
          tags={bundle.tags || null}
          mode={mode}
        />
      ))}

      {/* "Load More" or "Unlock All" button */}
      {!isPaid ? (
        visibleCount < shuffledBundles.length && (
          <Button
            variant="contained"
            color="secondary"
            sx={{ alignSelf: 'center', mt: 2 }}
            onClick={openUnlockDialog}
          >
            {t.unlock_all?.[language] || t.unlock_all.en}
          </Button>
        )
      ) : (
        visibleCount < shuffledBundles.length && (
          <Button
            variant="contained"
            color="primary"
            sx={{ alignSelf: 'center', mt: 2 }}
            onClick={handleLoadMore}
            ref={loadMoreRef}
          >
            {t.load_more?.[language] || t.load_more.en}
          </Button>
        )
      )}

      {/* :::::::::::::::::: DIALOG for "Unlock All" :::::::::::::::::: */}
      <Dialog
        open={unlockDialogOpen}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
        sx={{ zIndex: 9999}}
      >
        <DialogContent sx={{ minHeight: 300, backgroundColor: customTheme.palette.background.paper }}>
          {!pairsForDialog.length ? (
            <Typography variant="body2" sx={{ mt: 2 }}>
              {t.no_preview?.[language] || t.no_preview.en}
            </Typography>
          ) : (
            // Fade the entire row in/out
            <Fade in={rowVisible} timeout={FADE_DURATION_MS} key={dialogIndex}>
              <RowContainer>
                {/* LEFT: SELFIE */}
                <HalfContainer>
                  {!sourceLoaded && (
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={200}
                      sx={{ position: 'absolute', top: 0, left: 0 }}
                    />
                  )}
                  <StyledChip label="SELFIE"/>
                  <Box
                    component="img"
                    alt="Source"
                    src={pairsForDialog[dialogIndex]?.source}
                    onLoad={() => setSourceLoaded(true)}
                    sx={{
                      width: '100%',
                      height: 'auto',
                      objectFit: 'contain',
                      zIndex: 2,
                    }}
                  />
                </HalfContainer>

                {/* RIGHT: OUTPUT SLIDESHOW of up to 3 images */}
                <HalfContainer>
                  {!exampleLoaded && (
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={200}
                      sx={{ position: 'absolute', top: 0, left: 0 }}
                    />
                  )}
                  <StyledChip label="OUTPUT"/>

                  <Fade in={exampleLoaded} timeout={FADE_DURATION_MS} key={exampleIndex}>
                    <Box
                      component="img"
                      alt="Output"
                      src={pairsForDialog[dialogIndex]?.examples?.[exampleIndex] || ''}
                      onLoad={handleExampleLoad}
                      sx={{
                        width: '100%',
                        height: 'auto',
                        objectFit: 'contain',
                        zIndex: 2,
                      }}
                    />
                  </Fade>
                </HalfContainer>
              </RowContainer>
            </Fade>
          )}

          <Typography variant="h4" sx={{ mt: 2, textAlign: 'center' }} fontWeight="bold">
            {t.title?.[language] || t.title.en}
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 2, textAlign: 'left' }}>
            {t.message?.[language]?.[0] || t.message.en[0]}
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 2, textAlign: 'left' }}>
            {t.message?.[language]?.[1] || t.message.en[1]}
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 2, textAlign: 'left' }}>
            {t.message?.[language]?.[2] || t.message.en[2]}
          </Typography>
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'center', gap: 2, backgroundColor: customTheme.palette.background.paper }}>
          <Button onClick={handleCloseDialog} color="inherit">
            {t.dismiss?.[language] || t.dismiss.en}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              handleCloseDialog();
              navigate('/shop');
            }}
          >
            {t.go_shop?.[language] || t.go_shop.en} 🎁
          </Button>
        </DialogActions>
      </Dialog>
    </BundlesListContainer>
  );
}
