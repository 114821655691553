import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Chip,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { auth, database } from '../services/firebase';
import { ref, set, get, remove } from 'firebase/database';
import {
  deleteUser,
  reauthenticateWithCredential,
  EmailAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';

// Icons
import DeleteIcon from '@mui/icons-material/Delete';
import PaletteIcon from '@mui/icons-material/Palette';
import LockResetIcon from '@mui/icons-material/LockReset';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import SettingsIcon from '@mui/icons-material/Settings';

export default function SettingsSection({ userSignedInWithEmail, toggleColorMode }) {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState('');
  const [reauthenticationDialogOpen, setReauthenticationDialogOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [supportEmail, setSupportEmail] = useState('');
  const [copied, setCopied] = useState(false);

  const navigate = useNavigate();

  // Fetch the support email from the database
  React.useEffect(() => {
    const fetchSupportEmail = async () => {
      try {
        const supportEmailRef = ref(database, `settings/links/support_email`);
        const snapshot = await get(supportEmailRef);
        if (snapshot.exists()) {
          setSupportEmail(snapshot.val());
        }
      } catch (error) {
        console.error('Error fetching support email:', error);
      }
    };

    fetchSupportEmail();
  }, []);

  const handleDeleteAccount = async () => {
    const user = auth.currentUser;

    if (user) {
      try {
        // Reference to the user's data in the database
        const userRef = ref(database, `users/${user.uid}`);
        const archiveRef = ref(database, `archive/users/${user.uid}`);

        // Get the user data
        const snapshot = await get(userRef);

        if (snapshot.exists()) {
          const userData = snapshot.val();

          // Move user data to the archive before deleting the user
          await set(archiveRef, userData);
          console.log('User data moved to archive.');

          // Delete the user data from the original location
          await remove(userRef);
          console.log('User data removed from users.');

          // Re-authenticate user before attempting to delete the account
          await reauthenticateUser();

          // Delete the user's authentication account
          await deleteUser(user);
          console.log('User account deleted from Firebase Authentication.');

          // Remove user token from localStorage
          localStorage.removeItem('userToken');
          console.log('User token removed from localStorage.');

          // Navigate to the home page
          navigate('/');
        } else {
          console.error('User data does not exist in the database.');
        }
      } catch (error) {
        if (error.code === 'auth/requires-recent-login') {
          setReauthenticationDialogOpen(true);
        } else {
          console.error('Error deleting account:', error);
        }
      }
    } else {
      console.error('No authenticated user found.');
    }
  };

  const handleReauthenticate = async () => {
    try {
      await reauthenticateUser();
      setReauthenticationDialogOpen(false);
      handleDeleteAccount(); // Retry deletion after re-authentication
    } catch (error) {
      console.error('Error re-authenticating user:', error);
    }
  };

  const reauthenticateUser = async () => {
    const user = auth.currentUser;

    if (user) {
      if (userSignedInWithEmail && password) {
        // User signed in with email and password
        const credential = EmailAuthProvider.credential(user.email, password);
        await reauthenticateWithCredential(user, credential);
        console.log('User re-authenticated successfully (email)');
      } else if (!userSignedInWithEmail) {
        // User signed in with Google or other providers
        const provider = new GoogleAuthProvider();
        await signInWithPopup(auth, provider);
        console.log('User re-authenticated successfully (Google or other)');
      }
    } else {
      throw new Error('No authenticated user found.');
    }
  };

  const handleCopySupportEmail = () => {
    navigator.clipboard.writeText(supportEmail);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography component="h2" variant="h5" sx={{ fontWeight: 'bold' }}>
        <Box display="flex" alignItems="center" gap={1}>
            <SettingsIcon />
            Settings
        </Box>
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Button variant="text" startIcon={<PaletteIcon />} onClick={toggleColorMode}>
          Change Theme
        </Button>
        {userSignedInWithEmail && (
          <Button
            variant="text"
            startIcon={<LockResetIcon />}
            onClick={() => navigate('/reset-password')}
          >
            Change Password
          </Button>
        )}
        <Button
          variant="text"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={() => setDeleteDialogOpen(true)}
        >
          Delete Account
        </Button>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
          <Button variant="text" startIcon={<ShieldOutlinedIcon />} disabled>
            Face Protection
          </Button>
          <Chip label="Coming Soon" icon={<ArrowForwardIosIcon />} />
        </Box>
      </Box>

      {/* Support Email Section */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 4 }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Support Email
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          label="Support Email"
          value={supportEmail}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleCopySupportEmail}>
                  {copied ? <DoneIcon color="success" /> : <ContentCopyIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {/* Delete Account Dialog */}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Delete Account</DialogTitle>
        <DialogContent>
          {/* WebP Animation */}
          <Box
            component="img"
            src={`${process.env.PUBLIC_URL}/assets/icons/webp/bomb.webp`}
            alt="Delete Account Animation"
            sx={{
              width: '50%',
              maxWidth: 150,
              margin: '0 auto 16px',
              display: 'block',
            }}
          />
          <Typography variant="body1">
            Warning: Deleting your account will permanently erase all of your data, including purchases and created media.
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            label='Type "delete" to confirm'
            value={deleteConfirmation}
            onChange={(e) => setDeleteConfirmation(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            disabled={deleteConfirmation !== 'delete'}
            startIcon={<DeleteIcon />}
            onClick={handleDeleteAccount}
          >
            Delete
          </Button>
          <Button variant="outlined" onClick={() => setDeleteDialogOpen(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Re-authentication Dialog */}
      <Dialog open={reauthenticationDialogOpen} onClose={() => setReauthenticationDialogOpen(false)}>
        <DialogTitle>Re-authenticate to Delete Account</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            For security reasons, please re-authenticate to confirm account deletion.
          </Typography>
          {userSignedInWithEmail && (
            <TextField
              fullWidth
              variant="outlined"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{ mt: 2 }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleReauthenticate}
            startIcon={<LockResetIcon />}
          >
            Re-authenticate
          </Button>
          <Button variant="outlined" onClick={() => setReauthenticationDialogOpen(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
