import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Typography,
  Button,
  CssBaseline,
  TextField,
  InputAdornment,
  Tooltip,
  IconButton,
  Alert,
  Stepper,
  Step,
  StepLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Skeleton,
  Container,
  StepIcon,
  Divider,
} from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import TemplateFrame from '../theme/TemplateFrame';
import getSignUpTheme from '../theme/theme/getSignUpTheme';
import { auth, database } from '../../services/firebase';
import { ref, get } from 'firebase/database';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ErrorModule from '../../components/ErrorModule';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import dayjs from 'dayjs';

import { 
  TonConnectUIProvider,
  useTonConnectModal, 
  useTonWallet, 
  useTonConnectUI, 
  TonConnectButton,
} from "@tonconnect/ui-react";

const PayContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const FixedBottomButton = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  left: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 9999,
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
}));

const languageCodes = ['en', 'ru', 'es', 'pt', 'de', 'ja', 'ko', 'zh', 'hi'];
const t = {
  credits: {
      en: "Credits",
      ru: "Кредиты",
      hi: "क्रेडिट्स",
      zh: "积分",
      de: "Guthaben",
      es: "Créditos"
  },
  payment_id: {
      en: "Payment ID",
      ru: "Идентификатор платежа",
      hi: "भुगतान आईडी",
      zh: "支付编号",
      de: "Zahlungs-ID",
      es: "ID de Pago"
  },
  payment_link: {
      en: "Pay Link",
      ru: "Ссылка на оплату",
      hi: "भुगतान लिंक",
      zh: "支付链接",
      de: "Zahlungslink",
      es: "Enlace de Pago"
  },
  payment_provider: {
      en: "Provider",
      ru: "Поставщик",
      hi: "प्रदाता",
      zh: "提供商",
      de: "Anbieter",
      es: "Proveedor"
  },
  created: {
      en: "Created On",
      ru: "Создано",
      hi: "निर्मित",
      zh: "创建时间",
      de: "Erstellt am",
      es: "Creado el"
  },
  to_pay: {
      en: "Amount to Pay",
      ru: "Сумма к оплате",
      hi: "भुगतान राशि",
      zh: "支付金额",
      de: "Zu zahlender Betrag",
      es: "Monto a Pagar"
  },
  support_email: {
      en: "Support Email",
      ru: "Email поддержки",
      hi: "सहायता ईमेल",
      zh: "支持电子邮件",
      de: "Support-E-Mail",
      es: "Correo de Soporte"
  },
  stepper: {
      details: {
          en: "Payment Details",
          ru: "Детали платежа",
          hi: "भुगतान विवरण",
          zh: "支付详情",
          de: "Zahlungsdetails",
          es: "Detalles del Pago"
      },
      expired: {
          en: "Expired",
          ru: "Истекший",
          hi: "समाप्त हो गया",
          zh: "已过期",
          de: "Abgelaufen",
          es: "Expirado"
      },
      payment: {
          en: "Payment",
          ru: "Оплата",
          hi: "भुगतान",
          zh: "支付",
          de: "Zahlung",
          es: "Pago"
      }
  },
  contact_support: {
      en: "Contact Support",
      ru: "Связаться с поддержкой",
      hi: "सहायता से संपर्क करें",
      zh: "联系客服",
      de: "Support kontaktieren",
      es: "Contactar Soporte"
  },
  received: {
      en: "Credits Received",
      ru: "Кредиты получены",
      hi: "क्रेडिट्स प्राप्त हुए",
      zh: "积分已收到",
      de: "Guthaben erhalten",
      es: "Créditos Recibidos"
  },
  howTo: {
      en: "How to make a payment with",
      ru: "Как произвести оплату с помощью",
      hi: "साथ भुगतान कैसे करें",
      zh: "如何使用进行支付",
      de: "Wie man mit bezahlt",
      es: "Cómo realizar un pago con"
  },
  no_instructions: {
      en: "No Instructions available",
      ru: "Инструкции отсутствуют",
      hi: "कोई निर्देश उपलब्ध नहीं",
      zh: "无可用说明",
      de: "Keine Anweisungen verfügbar",
      es: "No hay instrucciones disponibles"
  },
  agreement: [
      {
          en: "By proceeding with the purchase, you agree to our",
          ru: "Продолжая покупку, вы соглашаетесь с нашими",
          hi: "खरीदारी जारी रखते हुए, आप हमारी सहमति देते हैं",
          zh: "继续购买即表示您同意我们的",
          de: "Mit dem Kauf stimmen Sie unseren",
          es: "Al proceder con la compra, aceptas nuestros"
      },
      {
          en: "Terms of Service",
          ru: "Условиями обслуживания",
          hi: "सेवा की शर्तें",
          zh: "服务条款",
          de: "Nutzungsbedingungen",
          es: "Términos de Servicio"
      },
      {
          en: "and",
          ru: "и",
          hi: "और",
          zh: "和",
          de: "und",
          es: "y"
      },
      {
          en: "Privacy Policy",
          ru: "Политикой конфиденциальности",
          hi: "गोपनीयता नीति",
          zh: "隐私政策",
          de: "Datenschutzrichtlinie",
          es: "Política de Privacidad"
      },
      {
          en: ". You must be at least 21 years old to use our services.",
          ru: ". Вам должно быть не менее 21 года для использования наших услуг.",
          hi: ". हमारी सेवाओं का उपयोग करने के लिए आपकी आयु कम से कम 21 वर्ष होनी चाहिए।",
          zh: ". 您必须年满21岁才能使用我们的服务。",
          de: ". Sie müssen mindestens 21 Jahre alt sein, um unsere Dienste nutzen zu können.",
          es: ". Debes tener al menos 21 años para usar nuestros servicios."
      }
  ],
  copied: {
      en: "Copied!",
      ru: "Скопировано!",
      hi: "कॉपी किया गया!",
      zh: "已复制!",
      de: "Kopiert!",
      es: "¡Copiado!"
  },
  back: {
      en: "Back to Shop",
      ru: "Вернуться в магазин",
      hi: "दुकान पर वापस जाएं",
      zh: "返回商店",
      de: "Zurück zum Shop",
      es: "Volver a la Tienda"
  },
  proceed: {
      en: "Proceed to Payment",
      ru: "Перейти к оплате",
      hi: "भुगतान के लिए आगे बढ़ें",
      zh: "继续付款",
      de: "Weiter zur Zahlung",
      es: "Proceder al Pago"
  },
  payid_save: {
      en: "This code should be saved to provide proof of payment to support if needed.",
      ru: "Этот код следует сохранить для подтверждения оплаты при обращении в поддержку.",
      hi: "इस कोड को सहेजना चाहिए ताकि समर्थन के लिए भुगतान का प्रमाण दिया जा सके।",
      zh: "应保存此代码以便在需要时向支持提供付款证明。",
      de: "Dieser Code sollte gespeichert werden, um bei Bedarf den Zahlungsnachweis an den Support zu übermitteln.",
      es: "Este código debe guardarse para proporcionar prueba de pago al soporte si es necesario."
  }
};
const countryToCurrency = {
  KR: { code: 'KRW', symbol: '₩' },
  SA: { code: 'SAR', symbol: '﷼' },
  BR: { code: 'BRL', symbol: 'R$' },
  MX: { code: 'MXN', symbol: '$' },
  CO: { code: 'COP', symbol: 'COP$' },
  US: { code: 'USD', symbol: '$' },
  WW: { code: 'USD', symbol: '$' },
  RU: { code: 'RUB', symbol: '₽' },
};

export default function PayPage() {
  const [mode, setMode] = useState('dark');
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const defaultTheme = createTheme({ palette: { mode } });
  const payTheme = createTheme(getSignUpTheme(mode));

  const [isTelegramWebApp, setIsTelegramWebApp] = useState(false);

  const [paymentData, setPaymentData] = useState(null);
  const [providers, setProviders] = useState({});
  const [rates, setRates] = useState(null);
  const [instructions, setInstructions] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [copyAlert, setCopyAlert] = useState(false);
  const [supportEmail, setSupportEmail] = useState('');
  const [supportTelegram, setSupportTelegram] = useState('');
  const [language, setLanguage] = useState('en');

  const [localPrice, setLocalPrice] = useState(null);
  const [fee, setLocalFee] = useState(null);
  const [currency, setCurrency] = useState(null);

  const [disableRefreshButton, setDisableRefreshButton] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const isMobile = useMediaQuery('(max-width:600px)');
  const autoRefreshCountRef = useRef(0);

  const [statusExpired, setStatusExpired] = useState(false);
  const [needContactSupport, setNeedContactSupport] = useState(false);

  const { open: openTonModal} = useTonConnectModal();   // to open connect wallet modal
  const wallet = useTonWallet();                         // current wallet or null
  const [tonConnectUI] = useTonConnectUI();
  const [isTonModalOpen, setIsTonModalOpen] = useState(false);
  const [isPaymentInProgress, setIsPaymentInProgress] = useState(false);

  const handleOpenTonModal = () => {
    openTonModal();          // open the actual TonConnect UI modal
    setIsTonModalOpen(true); // track local state
    setIsPaymentInProgress(true);
  };

  useEffect(() => {
    if (wallet) {
      setIsTonModalOpen(false);
      setIsPaymentInProgress(false);
    }
  }, [wallet]);

  // Fetch providers once on mount
  useEffect(() => {
    const fetchProviders = async () => {
      try {

        const searchParams = new URLSearchParams(location.search);
        let lang = searchParams.get('lang') || null;
        if (!lang && localStorage.getItem('language') && languageCodes.includes(localStorage.getItem('language'))) {
          lang = localStorage.getItem('language');
        }
        setLanguage(lang ? lang : "en");

        const currency_type = searchParams.get("currency") || null;
        if (currency_type) setCurrency(currency_type)
        /* let local_price = searchParams.get('local')
        if (!local_price) setLocalPrice(parseFloat(local_price)) */

        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/shop`, { method: 'GET' });
        const result = await response.json();

        if (response.ok) {
          setProviders(result.providers);
          if (result.rates) { 
            setRates(result.rates);
          }
        } else {
          console.error('Error fetching shop data:', result.error);
        }
      } catch (error) {
        console.error('Error fetching shop data:', error);
      }
    };

    fetchProviders();
  }, []);

  // Fetch payment data using the payment ID from the route
  useEffect(() => {
    const fetchPaymentData = async () => {
      try {
        // Detect if we are in Telegram Mini App
        if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initData) {
          setIsTelegramWebApp(true);

          // Set theme based on Telegram's colorScheme
          const telegramColorScheme = window.Telegram.WebApp.colorScheme;
          setMode(telegramColorScheme === 'dark' ? 'dark' : 'light');

          // Expand the Mini App if not expanded
          if (!window.Telegram.WebApp.isExpanded) {
            window.Telegram.WebApp.expand();
          }

          // Disable vertical swipes
          window.Telegram.WebApp.disableVerticalSwipes();
          window.Telegram.WebApp.disableClosingConfirmation();

          // Signal that the app is ready
          window.Telegram.WebApp.ready();
        } else {
          const savedMode = localStorage.getItem('themeMode');
          setMode(savedMode ? savedMode : 'light');
        }

        // Fetch payment data using the payment ID
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pay/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const result = await response.json();
        console.log(result);
        if (response.ok) {
          setPaymentData(result);
          if (!localPrice && rates) {
            let currency_local
            if (currency && countryToCurrency[currency]) {
              currency_local = countryToCurrency[currency] || null
            }
            else if (result.country && countryToCurrency[result?.country]) {
              currency_local = countryToCurrency[result?.country] || null
            }
            if (currency_local) {
              setCurrency(currency_local)
              let local_price = result.rates?.[currency_local?.code] || null;
              setLocalPrice(local_price);
            }
          }

          setLoading(false);
        } else {
          console.error('Error fetching payment data:', result.error);
          setError('unknownError');
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching payment data:', error);
        setError('unknownError');
        setLoading(false);
      }
    };

    fetchPaymentData();
  }, [id]);

  // Fetch instructions when paymentData changes
  useEffect(() => {
    const fetchInstructions = async () => {
      try {
        const providerId = paymentData.provider;
        const instructionsRef = ref(database, `/settings/payments/howto/${providerId}`);
        const snapshot = await get(instructionsRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          setInstructions(Object.values(data));
        } else {
          setInstructions([]);
        }
      } catch (error) {
        console.error('Error fetching instructions:', error);
      }
    };

    if (paymentData) {
      fetchInstructions();
    }
  }, [paymentData]);

  // Fetch support email and support Telegram once on mount
  useEffect(() => {
    const fetchSupportInfo = async () => {
      try {
        const emailRef = ref(database, '/settings/links/support_email');
        const emailSnapshot = await get(emailRef);
        if (emailSnapshot.exists()) {
          setSupportEmail(emailSnapshot.val());
        }

        const tgRef = ref(database, '/settings/links/support_tg');
        const tgSnapshot = await get(tgRef);
        if (tgSnapshot.exists()) {
          setSupportTelegram(tgSnapshot.val());
        }
      } catch (error) {
        console.error('Error fetching support info:', error);
      }
    };
    fetchSupportInfo();
  }, []);
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopyAlert(true);
    setTimeout(() => {
      setCopyAlert(false);
    }, 3000);
  };

  // Handle manual refresh
  const handleRefresh = async () => {
    if (disableRefreshButton) return;

    setDisableRefreshButton(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pay/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();

      if (response.ok) {
        setPaymentData(result);
      } else {
        console.error('Error refreshing payment data:', result.error);
        setError('unknownError');
      }
    } catch (error) {
      console.error('Error refreshing payment data:', error);
      setError('unknownError');
    }

    setDisableRefreshButton(false);
  };

  const handlePay = () => {
    if (statusExpired) {
      cleanupTelegramMainButton();
      navigate('/shop');
    } else if (needContactSupport) {
      handleContactSupport();
    } else if (paymentData.paymentLink) {
      if (isTelegramWebApp) {
        console.log(paymentData.provider)
        if (paymentData.provider === 'telegram_stars') {
          if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('rigid');
            console.log(paymentData)
            if (paymentData.stars_link) {
              console.log(paymentData.stars_link)
              window.Telegram.WebApp.openInvoice(paymentData.stars_link);
            }
            else {
              console.log(paymentData.paymentLink)
              window.Telegram.WebApp.openTelegramLink(paymentData.paymentLink);
            }
          }
        } else {
          if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('rigid');
            window.Telegram.WebApp.showAlert(
              'You will be redirected to an external payment page. Please do not close this window.',
              () => {
                window.Telegram.WebApp.openLink(paymentData.paymentLink);
              }
            );
          }
        }
      } else {
        window.open(paymentData.paymentLink, '_blank');
      }
    }
    else if (paymentData.provider === 'tonconnect') {
      if (!wallet) {
        handleOpenTonModal();
      } else {
        // 2) If user is connected, proceed with transaction
        handleTonPayment(paymentData.price);
      }
    }
  };

  function getNanotonForCredit(creditPriceUsd) {
    let tonPrice = paymentData.tonPrice;
    if (!tonPrice) tonPrice = 8; 
    const tonAmount = creditPriceUsd / tonPrice;     // e.g. 2.5
    const nanoton = Math.floor(tonAmount * 1e9);     // 2500000000
    return String(nanoton);
  }

  const handleTonPayment = async (priceUsd) => {
    try {
      setIsPaymentInProgress(true);

      // Convert USD => nanoton
      const nanoAmount = getNanotonForCredit(priceUsd);

      console.log(paymentData);
      console.log(nanoAmount)
      // Build the transaction
      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 300,  // 5 min
        messages: [
          {
            address: paymentData.address, // your receiving wallet
            amount: nanoAmount,
          }
        ]
      };

      // request wallet to pay
      await tonConnectUI.sendTransaction(transaction);
      console.log("TON transaction requested for $", priceUsd);
      // TODO: after success, show receipt or redirect
    } catch (error) {
      console.error("TON transaction error:", error);
    } finally {
      setIsPaymentInProgress(false);
    }
  };

  // Handle contact support
  const handleContactSupport = () => {
    if (isTelegramWebApp) {
      if (supportTelegram) {
        const tgUsername = supportTelegram.replace('@', '');
        const tgLink = `https://t.me/${tgUsername}`;
        if (window.Telegram && window.Telegram.WebApp) {
          window.Telegram.WebApp.openTelegramLink(tgLink);
        } else {
          window.open(tgLink, '_blank');
        }
      } else {
        window.Telegram.WebApp.showAlert('Support is currently unavailable.');
      }
    } else {
      if (supportEmail) {
        window.location.href = `mailto:${supportEmail}`;
      } else {
        alert('Support is currently unavailable.');
      }
    }
  };

  // Confirmation before leaving the page
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (paymentData && paymentData.status === 0 && !statusExpired && !needContactSupport) {
        e.preventDefault();
        e.returnValue =
          'You have an unpaid order. Leaving this page may result in losing access to your payment.';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [paymentData, statusExpired, needContactSupport]);

  // Cleanup function for Telegram MainButton
  const cleanupTelegramMainButton = () => {
    if (isTelegramWebApp && window.Telegram && window.Telegram.WebApp) {
      const MainButton = window.Telegram.WebApp.MainButton;
      MainButton.hide();
      MainButton.offClick();
    }
  };

  // Handle Telegram MainButton
  useEffect(() => {
    if (isTelegramWebApp && paymentData) {
      const MainButton = window.Telegram.WebApp.MainButton;
      MainButton.offClick(); // Remove any existing handlers

      // Click handler
      const onClickHandler = () => {
        if (statusExpired) {
          cleanupTelegramMainButton();
          navigate('/shop');
        } else if (needContactSupport) {
          handleContactSupport();
        } else if (paymentData.status === 0) {
          if (paymentData.provider === 'tonconnect' && !wallet) {
            handleOpenTonModal();
          }
          else {
            handlePay();
          }
        } else {
          cleanupTelegramMainButton();
          navigate('/create');
        }
      };

      // Attach click handler
      MainButton.onClick(onClickHandler);

      // Set button text and parameters
      let buttonText = '';
      let params = {
        is_visible: true,
        is_active: true,
        text_color: '#FFFFFF',
        position: 'bottom',
      };

      MainButton.hideProgress();

      if (statusExpired) {
        buttonText = 'Back to Shop';
        params.color = '#f44336'; // Red color
      } else if (needContactSupport) {
        buttonText = 'Contact Support';
        params.color = '#FFC107'; // Yellow color
      } else if (paymentData.status === 0) {
        buttonText = 'Proceed to Payment';
        params.color = '#5288c1'; // Default color
        params.has_shine_effect = true;
      } else {
        buttonText = 'Back to App';
        params.color = '#4CAF50'; // Green color when payment is completed
      }

      // Update button text and parameters
      MainButton.setText(buttonText);
      MainButton.setParams(params);

      // Enable or disable the button
      MainButton.enable();

      return () => {
        MainButton.offClick(onClickHandler);
        MainButton.hide();
      };
    }
  }, [isTelegramWebApp, paymentData, navigate, statusExpired, needContactSupport, wallet]);

  // Calculate time elapsed and set statusExpired and needContactSupport
  useEffect(() => {
    if (paymentData) {
      const currentTime = Math.floor(Date.now() / 1000);
      const timeElapsed = currentTime - paymentData.timestamp;

      if (paymentData.status === 0) {
        if (timeElapsed > 3600) {
          setStatusExpired(true);
          setNeedContactSupport(false);
        } else if (timeElapsed > 1800) {
          setStatusExpired(false);
          setNeedContactSupport(true);
        } else {
          setStatusExpired(false);
          setNeedContactSupport(false);
        }
      } else {
        setStatusExpired(false);
        setNeedContactSupport(false);
      }

      console.log(statusExpired, needContactSupport)
    }
  }, [paymentData]);

  if (error) {
    return <ErrorModule type={error} />;
  }

  if (loading || !paymentData) {
    return (
      <TemplateFrame
        toggleCustomTheme={() => setShowCustomTheme((prev) => !prev)}
        showCustomTheme={showCustomTheme}
        mode={mode}
        toggleColorMode={() => {
          const newMode = mode === 'dark' ? 'light' : 'dark';
          setMode(newMode);
          if (!isTelegramWebApp) {
            localStorage.setItem('themeMode', newMode);
          }
        }}
      >
        <ThemeProvider theme={showCustomTheme ? payTheme : defaultTheme}>
          <CssBaseline enableColorScheme />
          <Container maxWidth="md">
            <Skeleton variant="rectangular" width="100%" height="100vh" />
          </Container>
        </ThemeProvider>
      </TemplateFrame>
    );
  }

  const LeftColumn = () => (
    <Box
      sx={{
        flex: 1,
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >

      {/* Amount of credits purchased */}
      <Box
        sx={{
          width: isMobile ? '90%' : 'auto',
          mb: 2,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h3" sx={{ textAlign: 'center' }}>
          💎 {paymentData.amount} {t.credits?.[language] || t.credits.en}
        </Typography>
      </Box>

      {/* Amount to pay */}
      <Box sx={{ mb: 2, width: '100%' }}>
        <Typography variant="h6" sx={{ mb: 1 }}>
        {t.to_pay?.[language] || t.to_pay.en}
        </Typography>
        {paymentData.local_price && paymentData.local_currency ? (
          <>
            <Typography variant="h4">
              {paymentData.local_price} {paymentData.local_currency}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              (~${paymentData.price.toFixed(2)} USD)
            </Typography>
          </>
        ) : (
          <Typography variant="h4">${paymentData.price.toFixed(2)} USD</Typography>
        )}
      </Box>
      
      {/* Provider */}
      <Box sx={{ mb: 2, width: '100%' }}>
        <Typography variant="h6" sx={{ mb: 1 }}>
        {t.payment_provider?.[language] || t.payment_provider.en}
        </Typography>
        <Typography variant="body1">
          {providers[paymentData.provider]?.name || paymentData.provider}
        </Typography>
      </Box>

      {/* Creation Date */}
      <Box sx={{ mb: 2, width: '100%' }}>
        <Typography variant="h6" sx={{ mb: 1 }}>
        {t.created?.[language] || t.created.en}
        </Typography>
        <Typography variant="body1">
          {dayjs.unix(paymentData.timestamp).format('DD.MM.YYYY HH:mm')}
        </Typography>
      </Box>

      {/* Payment ID with tooltip and copy functionality */}
      <Box sx={{ mb: 2, width: '100%' }}>
        <Typography variant="h6" sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
          {t.payment_id?.[language] || t.payment_id.en}
          <Tooltip
            title={t.payid_save?.[language] || t.payid_save.en}
            arrow
          >
            <IconButton size="small" sx={{ backgroundColor: "transparent", borderColor: "transparent"}}>
              <HelpOutlineIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Typography>
        <TextField
          fullWidth
          value={paymentData.paymentId}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton sx={{ borderColor: "transparent", backgroundColor: "transparent" }} onClick={() => handleCopy(paymentData.paymentId)}>
                  <ContentCopyIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {/* Payment Link */}
      {paymentData.provider !== 'tonconnect' && (
        <Box sx={{ mb: 2, width: '100%' }}>
          <Typography variant="h6" sx={{ mb: 1 }}>
          {t.payment_link?.[language] || t.payment_link.en}
          </Typography>
          <TextField
            fullWidth
            value={paymentData.paymentLink}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton sx={{ borderColor: "transparent", backgroundColor: "transparent" }} onClick={() => handleCopy(paymentData.paymentLink)}>
                    <ContentCopyIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}

      {/* Support Email */}
      <Box sx={{ mb: 2, width: '100%' }}>
        <Typography variant="body1" sx={{ mb: 1 }}>
        {t.support_email?.[language] || t.support_email.en}
        </Typography>
        <TextField
          fullWidth
          value={supportEmail}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton sx={{ borderColor: "transparent", backgroundColor: "transparent" }} onClick={() => handleCopy(supportEmail)}>
                  <ContentCopyIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      
      {paymentData.provider === 'tonconnect' && (
        <>
        <TonConnectButton style={{ width: "100%"}} />
        </>
      )}
    </Box>
  );

  const RightColumn = () => (
    <Box sx={{ flex: 1, p: 2, marginBottom: 20, marginTop: 5 }}>
      {/* Stepper */}
      <Stepper
        activeStep={paymentData.status > 0 || statusExpired ? 2 : 1}
        alternativeLabel
        sx={{ mb: 4 }}
      >
        <Step completed>
          <StepLabel>{t.stepper.details?.[language] || t.stepper.details.en}</StepLabel>
        </Step>
        <Step completed={paymentData.status > 0} error={paymentData.status <= 0 && (needContactSupport || statusExpired)}>
          <StepLabel>
            { needContactSupport ? (t.contact_support?.[language] || t.contact_support.en) : (t.stepper.payment?.[language] || t.stepper.payment.en)}
          </StepLabel>
        </Step>
        <Step completed={paymentData.status > 0} error={statusExpired && paymentData.status <= 0}>
          <StepLabel>{statusExpired ? (t.stepper.expired?.[language] || t.stepper.expired.en) : (t.received?.[language] || t.received.en)}</StepLabel>
        </Step>
      </Stepper>

      {/* Instructions */}
      <Typography variant="h6" sx={{ mb: 2 }}>
        {t.howTo?.[language] || t.howTo.en}{' '}{providers[paymentData.provider]?.name || paymentData.provider}
      </Typography>

      {/* Instructions Accordion */}
      {instructions.length > 0 ? (
        instructions.map((instruction, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{instruction.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{instruction.description}</Typography>
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <Typography variant="body2" color="textSecondary">
          {t.no_instructions?.[language] || t.no_instructions.en}
        </Typography>
      )}

      <Box sx={{ mt: 4 }}>
        <Typography variant="body2" color="textSecondary" align="center">
        {t.agreement[0]?.[language] || t.agreement[0].en}{' '}
          <a href="/terms-of-service.pdf" target="_blank" rel="noopener noreferrer">
          {t.agreement[1]?.[language] || t.agreement[1].en}
          </a>{' '}
          {t.agreement[2]?.[language] || t.agreement[2].en}{' '}
          <a href="/privacy-policy.pdf" target="_blank" rel="noopener noreferrer">
          {t.agreement[3]?.[language] || t.agreement[3].en}
          </a>
          {t.agreement[4]?.[language] || t.agreement[4].en}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <TemplateFrame
      toggleCustomTheme={() => setShowCustomTheme((prev) => !prev)}
      showCustomTheme={showCustomTheme}
      mode={mode}
      toggleColorMode={() => {
        const newMode = mode === 'dark' ? 'light' : 'dark';
        setMode(newMode);
        if (!isTelegramWebApp) {
          localStorage.setItem('themeMode', newMode);
        }
      }}
    >
      <ThemeProvider theme={showCustomTheme ? payTheme : defaultTheme}>
        <CssBaseline enableColorScheme />
        {copyAlert && (
          <Alert
            onClose={() => setCopyAlert(false)}
            severity="success"
            sx={{
              position: 'fixed',
              top: 10,
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 9999,
            }}
          >
            {t.copied?.[language] || t.copied.en}
          </Alert>
        )}
        <Container sx={{marginTop: isMobile ? 0 : 10,}}>
          {/* Animation Image */}
          {isMobile && (
            <Box sx={{ 
              width: "30%",
              display: 'flex',
              alignItems: 'center',
              mx: 'auto',
              marginTop: 4
            }}>
              <img
                src={
                  statusExpired || needContactSupport
                    ? `${process.env.PUBLIC_URL}/assets/icons/webp/expires.webp`
                    : paymentData.status === 0
                    ? `${process.env.PUBLIC_URL}/assets/icons/webp/money.webp`
                    : `${process.env.PUBLIC_URL}/assets/icons/webp/congrats.webp`
                }
                alt="Payment Animation"
                style={{ width: '100%', height: 'auto' }}
              />
            </Box>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <LeftColumn />
            </Grid>
            <Grid item xs={12} md={6}>
              <RightColumn />
            </Grid>
          </Grid>

          {/* FixedBottomButton */}
          {!isTelegramWebApp && !isTonModalOpen && !isPaymentInProgress && (
            <FixedBottomButton sx={{ marginBottom: 8 }}>
              {statusExpired ? (
                <Button
                  variant="contained"
                  color="error"
                  sx={{
                    flex: 1,
                    minHeight: 60,
                    maxWidth: { sm: "100%", md: "400px" }, // Full width on mobile, 300px max on larger screens
                    width: "100%", // Ensure full width on mobile
                  }}
                  onClick={() => {
                    cleanupTelegramMainButton();
                    navigate('/shop');
                  }}
                >
                  <Typography variant="h6">{t.back?.[language] || t.back.en}</Typography>
                </Button>
              ) : needContactSupport ? (
                <Button
                  variant="contained"
                  color="warning"
                  sx={{
                    flex: 1,
                    minHeight: 60,
                    maxWidth: { sm: "100%", md: "400px" }, // Full width on mobile, 300px max on larger screens
                    width: "100%", // Ensure full width on mobile
                  }}
                  onClick={handleContactSupport}
                >
                  <Typography variant="h6">{t.contact_support?.[language] || t.contact_support.en}</Typography>
                </Button>
              ) : paymentData.status === 0 ? (
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    flex: 1,
                    minHeight: 60,
                    maxWidth: { sm: "100%", md: "400px" }, // Full width on mobile, 300px max on larger screens
                    width: "100%", // Ensure full width on mobile
                  }}
                  onClick={handlePay}
                  endIcon={<ArrowForwardIcon />}
                >
                  <Typography variant="h6">{t.proceed?.[language] || t.proceed.en}</Typography>
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<ArrowBackIcon />}
                  sx={{
                    flex: 1,
                    minHeight: 60,
                    maxWidth: { sm: "100%", md: "400px" }, // Full width on mobile, 300px max on larger screens
                    width: "100%", // Ensure full width on mobile
                  }}
                  onClick={() => {
                    cleanupTelegramMainButton();
                    navigate('/create');
                  }}
                >
                  <Typography variant="h6">{t.back?.[language] || t.back.en}</Typography>
                </Button>
              )}
            </FixedBottomButton>
          )}
        </Container>
      </ThemeProvider>
    </TemplateFrame>
  );
}