import React, { useEffect, useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import getSignUpTheme from '../theme/theme/getSignUpTheme';
import TemplateFrame from '../theme/TemplateFrame';
import ReferralSection from '../../components/ReferralSection';
import PurchasesSection from '../../components/PurchasesSection';
import SocialMediaSection from '../../components/SocialMediaSection';
import SettingsSection from '../../components/SettingsSection';
import FAQSection from '../../components/FAQSection';
import UIDSection from '../../components/UIDSection'; 
import { auth, database } from '../../services/firebase';
import { ref, get } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { signInWithCustomToken } from 'firebase/auth';
import OrientationSelection from '../../components/OrientationSelection';
import LanguageSelection from '../../components/LanguageSelection';

const app_verison = "1.2.0";

const LeftColumn = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  alignItems: 'center',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: '50%',
  },
}));

const RightColumn = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  overflowY: 'auto',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: '50%',
  },
}));

const TwoColumnContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  margin: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    gap: theme.spacing(8),
    margin: theme.spacing(4),
  },
}));

const ProfilePageContainer = styled(Box)(({ theme }) => ({
  minHeight: '100%',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
  ...theme.applyStyles('dark'),
}));

export default function Profile() {
  const [mode, setMode] = useState('light');
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const defaultTheme = createTheme({ palette: { mode } });
  const profileTheme = createTheme(getSignUpTheme(mode));
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [referralData, setReferralData] = useState({ link: '', credits: 0 });
  const [purchases, setPurchases] = useState(() => {
    const savedPurchases = localStorage.getItem('purchases');
    return savedPurchases ? JSON.parse(savedPurchases) : [];
  });
  const [faqData, setFaqData] = useState([]);
  const [rewardCredits, setRewardCredits] = useState(0);
  const [emailLinked, setEmailLinked] = useState(false);
  const [credits, setCredits] = useState(null);
  const [isTelegramWebApp, setIsTelegramWebApp] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [supportLink, setSupportLink] = useState(null);
  const [supportEmail, setSupportEmail] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Detect if opened via Telegram Mini App
    if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initData) {
      setIsTelegramWebApp(true);
      const telegramColorScheme = window.Telegram.WebApp.colorScheme;
      setMode(telegramColorScheme === 'dark' ? 'dark' : 'light');
      if (!window.Telegram.WebApp.isExpanded) {
        window.Telegram.WebApp.expand();
      }
      window.Telegram.WebApp.ready();
    } else {
      const savedMode = localStorage.getItem('themeMode');
      if (savedMode) {
        setMode(savedMode);
      } else {
        const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
        setMode(systemPrefersDark ? 'dark' : 'light');
      }
    }

    const token = localStorage.getItem('userToken');
    if (token) {
      auth.onAuthStateChanged(async (currentUser) => {
        if (currentUser) {
          setUser(currentUser);
          try {
            const db = database;
            const savedPurchases = JSON.parse(localStorage.getItem('purchases')) || [];
            const savedPaymentIds = savedPurchases.map((p) => p.id);
            const userRef = ref(db, `users/${currentUser.uid}`);
            const userSnapshot = await get(userRef);
            const fetchedUserData = userSnapshot.val();
            setCredits(fetchedUserData?.credits || 0);
            setUserData(fetchedUserData);

            const paymentsSnapshot = fetchedUserData.payments;
            if (paymentsSnapshot) {
              const paymentIds = paymentsSnapshot;
              const newPaymentIds = paymentIds.filter((id) => !savedPaymentIds.includes(id));
              let newPurchases = [];
              for (const paymentId of newPaymentIds) {
                const paymentRef = ref(db, `payments/${paymentId}`);
                const paymentSnapshot = await get(paymentRef);
                if (paymentSnapshot.exists()) {
                  const payment = paymentSnapshot.val();
                  const expiresAt = payment.timestamp + 60 * 60;
                  const currentTime = Date.now() / 1000;
                  if (payment.status === 1 || currentTime > expiresAt) {
                    newPurchases.push({
                      id: paymentId,
                      date: new Date(payment.timestamp * 1000).toLocaleString(),
                      amount: payment.amount,
                      status: payment.status,
                      timestamp: payment.timestamp,
                      expiresAt: expiresAt,
                    });
                  }
                }
              }
              const updatedPurchases = [...savedPurchases, ...newPurchases];
              localStorage.setItem('purchases', JSON.stringify(updatedPurchases));
              setPurchases(updatedPurchases);
            } else {
              setPurchases(savedPurchases);
            }

            const faqRef = ref(db, `settings/faq`);
            const faqSnapshot = await get(faqRef);
            if (faqSnapshot.exists()) {
              setFaqData(Object.values(faqSnapshot.val()));
            }

            const rewardRef = ref(db, `settings/rewards/email`);
            const rewardSnapshot = await get(rewardRef);
            if (rewardSnapshot.exists()) {
              setRewardCredits(rewardSnapshot.val());
            }

            if (fetchedUserData.email) {
              setEmailLinked(true);
            }
          } catch (error) {
            console.log(error);
            console.error('Error fetching user data:', error);
          }
        } else {
          navigate('/signin');
        }
      });
    } else {
      navigate('/signup');
    }
  }, [navigate]);

  // Authenticate Telegram users
  useEffect(() => {
    const authenticateTelegramUser = async () => {
      if (isTelegramWebApp && !user) {
        const initData = window.Telegram.WebApp.initData;
        if (initData) {
          const formBody = `initData=${encodeURIComponent(initData)}`;
          try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/verify-telegram-webapp`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
              body: formBody,
            });

            const result = await response.json();
            if (result.success && result.customToken) {
              await signInWithCustomToken(auth, result.customToken);
              const idToken = await auth.currentUser.getIdToken();
              localStorage.setItem('userToken', idToken);
              setUser(auth.currentUser);
            } else {
              console.error('Telegram authentication failed:', result.error);
              setSubmitError(result.error || 'Authentication failed.');
            }
          } catch (error) {
            console.error('Error during Telegram authentication:', error);
            setSubmitError('An error occurred during authentication.');
          }
        }
      }
    };
    authenticateTelegramUser();
  }, [isTelegramWebApp, user]);

  // Fetch Referral Data
  useEffect(() => {
    const fetchReferralData = async () => {
      if (userData && isTelegramWebApp !== null) {
        const db = database;
        let earnedCredits = 0;
        if (userData.ref && userData.ref.earned) {
          earnedCredits = userData.ref.earned;
        }

        if (isTelegramWebApp) {
          let referralLink = localStorage.getItem('ref_miniapp');
          if (!referralLink) {
            const botUsernameRef = ref(db, '/telegram/bot/username');
            const botUsernameSnapshot = await get(botUsernameRef);
            if (botUsernameSnapshot.exists()) {
              const botUsername = botUsernameSnapshot.val();
              referralLink = `https://t.me/${botUsername}?startapp=${user.uid}`;
              localStorage.setItem('ref_miniapp', referralLink);
            }
          }
          setReferralData({
            link: referralLink || '',
            credits: earnedCredits,
          });
        } else {
          let referralLink = localStorage.getItem('ref_main');
          if (!referralLink) {
            const domainRef = ref(db, '/settings/links/domain');
            const domainSnapshot = await get(domainRef);
            if (domainSnapshot.exists()) {
              const domain = domainSnapshot.val();
              referralLink = `${domain}?ref=${user.uid}`;
              localStorage.setItem('ref_main', referralLink);
            }
          }
          setReferralData({
            link: referralLink || '',
            credits: earnedCredits,
          });
        }
      }
    };
    fetchReferralData();
  }, [userData, isTelegramWebApp, user]);

  // Setup Telegram MainButton (with stable callback)
  useEffect(() => {
    let isMounted = true;
    const fetchSupportData = async () => {
      if (isTelegramWebApp && user) {
        const db = database;
        let link = localStorage.getItem('support_link');
        let email = localStorage.getItem('support_email');

        if (!link && !email) {
          const supportTgRef = ref(db, 'settings/links/support_tg');
          const supportEmailRef = ref(db, 'settings/links/support_email');

          const [supportTgSnapshot, supportEmailSnapshot] = await Promise.all([
            get(supportTgRef),
            get(supportEmailRef),
          ]);

          if (supportTgSnapshot.exists()) {
            let supportTg = supportTgSnapshot.val();
            supportTg = supportTg.replace('@', '');
            link = `https://t.me/${supportTg}`;
            localStorage.setItem('support_link', link);
          }

          if (supportEmailSnapshot.exists()) {
            email = supportEmailSnapshot.val();
            localStorage.setItem('support_email', email);
          }
        }

        if (!isMounted) return;
        setSupportLink(link || null);
        setSupportEmail(email || null);
      }
    };
    fetchSupportData();

    return () => {
      isMounted = false;
    };
  }, [isTelegramWebApp, user]);

  // Обработчик нажатия кнопки в телеграме
  const handleMainButtonClick = useCallback(() => {
    if (supportLink) {
      console.log('window.Telegram.WebApp.openTelegramLink(supportLink);');
      window.Telegram.WebApp.openTelegramLink(supportLink);
    } else if (supportEmail) {
      window.Telegram.WebApp.showAlert(`You can contact support at ${supportEmail}`);
    }
  }, [supportLink, supportEmail]);

  useEffect(() => {
    if (isTelegramWebApp && user && (supportLink !== null || supportEmail !== null)) {
      const MainButton = window.Telegram.WebApp.MainButton;

      // Снимаем обработчик перед назначением, чтобы не было дубликатов
      MainButton.offClick(handleMainButtonClick);

      // Конфигурируем кнопку
      if (supportLink) {
        MainButton.setText('Contact Support');
        MainButton.setParams({ color: '#FFA000' });
      } else if (supportEmail) {
        MainButton.setText('Contact Support');
        MainButton.setParams({ color: '#FFA000' });
      } else {
        // Нет данных — скрываем кнопку
        MainButton.hide();
        return () => {
          MainButton.offClick(handleMainButtonClick);
          MainButton.hide();
        };
      }

      // Назначаем обработчик
      MainButton.onClick(handleMainButtonClick);
      MainButton.show();

      return () => {
        MainButton.offClick(handleMainButtonClick);
        MainButton.hide();
      };
    }
  }, [isTelegramWebApp, user, handleMainButtonClick, supportLink, supportEmail]);

  const toggleColorMode = () => {
    const newMode = mode === 'dark' ? 'light' : 'dark';
    setMode(newMode);
    if (!isTelegramWebApp) {
      localStorage.setItem('themeMode', newMode);
    }
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  return (
    <TemplateFrame
      toggleCustomTheme={toggleCustomTheme}
      showCustomTheme={showCustomTheme}
      mode={mode}
      toggleColorMode={toggleColorMode}
      credits={credits}
    >
      <ThemeProvider theme={showCustomTheme ? profileTheme : defaultTheme}>
        <CssBaseline enableColorScheme />
        <ProfilePageContainer>
          {/* Mobile Flow */}
          <Box sx={{ display: { xs: 'flex', md: 'none' }, flexDirection: 'column', gap: 4 }}>
            <Typography component="h1" variant="h2" sx={{ fontWeight: 'bold', marginTop: 2 }}>
              Profile
            </Typography>
            {userData?.adult && (
              <OrientationSelection />
            )}
            <LanguageSelection />
            <UIDSection uid={user?.uid} />
            <ReferralSection
              referralLink={referralData.link}
              referralCredits={referralData.credits}
            />
            <SocialMediaSection rewardCredits={rewardCredits} isEmailLinked={emailLinked} />
            <PurchasesSection purchases={purchases} />
            {!isTelegramWebApp && (
              <SettingsSection
                userSignedInWithEmail={!!user?.email}
                toggleColorMode={toggleColorMode}
              />
            )}
            <FAQSection faqs={faqData} />
            <Typography
                component="h1"
                variant="body2"
                sx={{ fontWeight: 'bold', textAlign: 'left' }}
              >
                App version: {app_verison}
            </Typography>
            <Button
              fullWidth
              variant="outlined"
              color="warning"
              sx={{ marginBottom: 20 }}
              onClick={() => {
                if (window.confirm('Are you sure you want to sign out?')) {
                  auth.signOut().then(() => {
                    window.localStorage.clear()
                    console.log(localStorage)
                    navigate('/');
                  });
                }
              }}
            >
              Sign Out
            </Button>
          </Box>

          {/* Desktop Flow */}
          <TwoColumnContainer sx={{ display: { xs: 'none', md: 'flex' } }}>
            <LeftColumn>
              <Typography
                component="h1"
                variant="h2"
                sx={{ fontWeight: 'bold', textAlign: 'left' }}
              >
                Profile
              </Typography>
              {userData?.adult && (
                <OrientationSelection />
              )}
              <LanguageSelection />
              <PurchasesSection purchases={purchases} />
              <Button
                fullWidth
                variant="outlined"
                color="warning"
                onClick={() => {
                  if (window.confirm('Are you sure you want to sign out?')) {
                    auth.signOut().then(() => {
                      localStorage.removeItem('userToken');
                      navigate('/');
                    });
                  }
                }}
              >
                Sign Out
              </Button>
            </LeftColumn>

            <RightColumn>
              <UIDSection uid={user?.uid} />
              <ReferralSection
                referralLink={referralData.link}
                referralCredits={referralData.credits}
              />
              <SocialMediaSection rewardCredits={rewardCredits} isEmailLinked={emailLinked} />
              {!isTelegramWebApp && (
                <SettingsSection
                  userSignedInWithEmail={!!user?.email}
                  toggleColorMode={toggleColorMode}
                />
              )}
              <FAQSection faqs={faqData} />
              <Typography
                component="h1"
                variant="body2"
                sx={{ fontWeight: 'bold', textAlign: 'left' }}
              >
                App version: {app_verison}
              </Typography>
            </RightColumn>
          </TwoColumnContainer>
        </ProfilePageContainer>
      </ThemeProvider>
    </TemplateFrame>
  );
}
