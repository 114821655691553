//ShopPage.js
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  CssBaseline,
  Chip,
  Skeleton,
  Container,
  TextField,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import TemplateFrame from '../theme/TemplateFrame';
import getSignUpTheme from '../theme/theme/getSignUpTheme';
import { getDatabase, ref, get, set } from 'firebase/database';
import { logEvent } from 'firebase/analytics';
import { auth, analytics } from '../../services/firebase';
import CreditsSelectionCard from '../../components/CreditsSelectionCard';
import PaymentOptionsCard from '../../components/PaymentOptionsCard';
import SelectedProviderDropdown from '../../components/SelectedProviderDropdown';
import Select from 'react-select';
import { useNavigate, useLocation } from 'react-router-dom';
import WorldFlags from 'react-world-flags';
import axios from 'axios';
import ErrorModule from '../../components/ErrorModule';
import PaymentInfo from '../../components/PaymentInfo';
import CountrySelector from '../../components/CountrySelector';
import CouponBanner from '../../components/CouponBanner';
import CreditValueHelp from '../../components/CreditValueHelp';
import DiamondIcon from '@mui/icons-material/Diamond';
import TelegramShare from '../../components/TelegramShare';
const ShopContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(4),
  margin: theme.spacing(2),
}));

const CardsContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
  gap: theme.spacing(2),
  width: '100%',
}));

const ScrollableCardsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  overflowX: 'auto',
  width: '100%',
  '&::-webkit-scrollbar': {
    height: 8,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[400],
    borderRadius: 4,
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.grey[100],
  },
}));

const FixedBottomButton = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  left: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 999,
  display: 'flex',
  justifyContent: 'center',
}));

const DiamondImage = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',
}));

const CountrySelectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  width: '100%',
}));

//Add validation functions here as we need to check formData before showing PaymentInfo
function validateName(name) {
  return /^[A-Za-z\s]+$/.test(name.trim()) && name.trim().length > 0;
}
function validateEmail(email) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email.trim());
}
function validatePhone(phone) {
  const re = /^\+[1-9]\d{6,14}$/;
  return re.test(phone.trim());
}

const languageCodes = ['en', 'ru', 'es', 'pt', 'de', 'ja', 'ko', 'zh', 'hi']

const translations = {
  title: {
    label: {
      en: "💎 CREDITS",
      es: "💎 CRÉDITOS",
      pt: "💎 CRÉDITOS",
      de: "💎 CREDITS",
      ja: "💎 クレジット",
      ko: "💎 크레딧",
      zh: "💎 积分",
      ru: "💎 КРЕДИТЫ",
      hi: "💎 क्रेडिट्स"
    },
    description: {
      en: "You need $PRICE credit(s) to create 1 picture. Purchasing credits gives you faster processing speed",
      es: "Necesitas $PRICE crédito(s) para crear 1 imagen. Comprar créditos te da una velocidad de procesamiento más rápida",
      pt: "Você precisa de $PRICE crédito(s) para criar 1 imagem. Comprar créditos oferece uma velocidade de processamento mais rápida",
      de: "Du benötigst $PRICE Kredit(e), um 1 Bild zu erstellen. Der Kauf von Krediten bietet dir eine schnellere Verarbeitungsgeschwindigkeit",
      ja: "$PRICE クレジットが必要です。クレジットを購入すると、処理速度が向上します",
      ko: "1개의 이미지를 생성하려면 $PRICE 크레딧이 필요합니다. 크레딧을 구매하면 처리 속도가 빨라집니다",
      zh: "创建一张图片需要 $PRICE 积分。购买积分可以加快处理速度",
      ru: "Для создания 1 изображения требуется $PRICE кредит(ов). Покупка кредитов ускоряет обработку",
      hi: "1 चित्र बनाने के लिए आपको $PRICE क्रेडिट्स की आवश्यकता है। क्रेडिट्स खरीदने से आपको तेज़ प्रसंस्करण गति मिलती है"
    }
  },
  payment_options: {
    en: "Payment Options",
    es: "Opciones de Pago",
    pt: "Opções de Pagamento",
    de: "Zahlungsmöglichkeiten",
    ja: "お支払いオプション",
    ko: "결제 옵션",
    zh: "支付选项",
    hi: "भुगतान विकल्प",
    ru: "Варианты оплаты"
  },
  button: {
    payment_options: {
      en: '(2/2) Select Payment Option',
      es: '(2/2) Seleccione Opción de Pago',
      pt: '(2/2) Selecione Opção de Pagamento',
      de: '(2/2) Zahlungsoption Wählen',
      ja: '(2/2) 支払いオプションを選択',
      ko: '(2/2) 결제 옵션 선택',
      zh: '(2/2) 选择支付选项',
      hi: '(2/2) भुगतान विकल्प चुनें',
      ru: '(2/2) Выберите Вариант Оплаты'
    },
    credits: {
      en: '(1/2) Select Credits',
      es: '(1/2) Seleccione Créditos',
      pt: '(1/2) Selecione Créditos',
      de: '(1/2) Credits Wählen',
      ja: '(1/2) クレジットを選択',
      ko: '(1/2) 크레딧 선택',
      zh: '(1/2) 选择积分',
      hi: '(1/2) क्रेडिट चुनें',
      ru: '(1/2) Выберите Кредиты'
    },
    confirm: {
      en: 'Confirm',
      es: 'Confirmar',
      pt: 'Confirmar',
      de: 'Bestätigen',
      ja: '確認する',
      ko: '확인',
      zh: '确认',
      hi: 'पुष्टि करें',
      ru: 'Подтвердить'
    },
    buy: [
      {
        en: "💎 Buy ",
        es: "💎 Comprar ",
        pt: "💎 Comprar ",
        de: "💎 Kaufen ",
        ja: "💎 購入 ",
        ko: "💎 구매 ",
        zh: "💎 购买 ",
        hi: "💎 खरीदें ",
        ru: "💎 Купить "
      },
      {
        en: " Credits",
        es: " Créditos",
        pt: " Créditos",
        de: " Credits",
        ja: " クレジット",
        ko: " 크레딧",
        zh: " 积分",
        hi: " क्रेडिट",
        ru: " Камушков"
      }
    ]
  }
};

export default function ShopPage() {
  const [mode, setMode] = useState('dark');
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const defaultTheme = createTheme({ palette: { mode } });
  const shopTheme = createTheme(getSignUpTheme(mode));
  const [isTelegramWebApp, setIsTelegramWebApp] = useState(false);
  const [creditsList, setCreditsList] = useState([]);
  const [adjustedCreditsList, setAdjustedCreditsList] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [providers, setProviders] = useState({});
  const [countries, setCountries] = useState([]);
  const [selectedCredit, setSelectedCredit] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [userCountry, setUserCountry] = useState(null);
  const [rates, setRates] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState(null);
  const [credits, setCredits] = React.useState(null);
  const [loading, setLoading] = useState(true);
  const [couponCode, setCouponCode] = useState('');
  const [couponData, setCouponData] = useState(null);
  const [couponError, setCouponError] = useState('');
  const [error, setError] = useState(null);
  const [processingPayment, setProcessingPayment] = useState(false);
  const [couponTimeRemaining, setCouponTimeRemaining] = useState(null);
  const [couponIntervalId, setCouponIntervalId] = useState(null);
  const [showPaymentInfo, setShowPaymentInfo] = useState(false);
  const [pendingLogData, setPendingLogData] = useState(null);
  const [submitRequested, setSubmitRequested] = useState(false);
  const [language, setLanguage] = useState('en');
  const [showAdultContent, setAdultContent] = useState(false);
  const [picturePrice, setPicturePrice] = useState(1);
  //Add formData here to ensure it's always defined and saved in ShopPage.
  //This will fix the issue of formData being undefined in PaymentInfo and also allow auto-saving.
  const [formData, setFormData] = useState({}); //Data saved automatically as user types

  const providerDataRequest = useMemo(() => {
    if (selectedProvider && providers[selectedProvider] && providers[selectedProvider].data_request) {
      return providers[selectedProvider].data_request;
    }
    return null;
  }, [selectedProvider, providers]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initData) {
      setIsTelegramWebApp(true);
      const telegramColorScheme = window.Telegram.WebApp.colorScheme;
      setMode(telegramColorScheme === 'dark' ? 'dark' : 'light');
      if (!window.Telegram.WebApp.isExpanded) {
        window.Telegram.WebApp.expand();
      }
      window.Telegram.WebApp.disableVerticalSwipes();
      const MainButton = window.Telegram.WebApp.MainButton;
      MainButton.offClick();
      window.Telegram.WebApp.ready();
    } else {
      const savedMode = localStorage.getItem('themeMode');
      setMode(savedMode ? savedMode : 'light');
      if (!savedMode) localStorage.setItem('themeMode', 'light');
    }
    const token = localStorage.getItem('userToken');
    if (token) {
      const unsubscribe = auth.onAuthStateChanged(async (user) => {
        if (user) {
          setUser(user);
          const db = getDatabase();
          const userRef = ref(db, `users/${user.uid}`);
          const snapshot = await get(userRef);
          if (snapshot.exists()) {
            const userData = snapshot.val();
            if (userData?.email) setEmail(userData.email)
            if (userData.adult) setAdultContent(true);

            if (localStorage.getItem("language")) {
              setLanguage(localStorage.getItem("language"))
            } else if (userData?.analytics?.lang && languageCodes.includes(userData?.analytics?.lang)) {
              setLanguage(userData?.analytics?.lang)
            }
            setCredits(userData.credits || 0);
            await loadShopData();
            if (userData.coupon) {
              console.log('user has coupon')
              setCouponCode(userData.coupon);
              await set(ref(db, `users/${user.uid}/coupon`), null);
              console.log("Coupon set to null!");
              applyCoupon(userData.coupon, user.uid);
            } else {
              if (localStorage.getItem('coupon') && localStorage.getItem('coupon_expiresAt')) {
                const couponId = localStorage.getItem('coupon');
                const expiresAt = parseInt(localStorage.getItem('coupon_expiresAt'), 10);
                console.log('user has coupon locally', couponId, expiresAt)
                if (couponId && expiresAt > Date.now()) {
                  localStorage.removeItem('coupon')
                  localStorage.removeItem('coupon_expiresAt')
                  console.log('removing coupon')
                }
                else {
                  console.log('applying coupon')
                  applyCoupon(couponId, user.uid);
                }
              }
              else checkCouponInURL();
            }
          }
        } else {
          if (isTelegramWebApp) {
            try {
              window.Telegram.WebApp.close();
            } catch (error) {
              console.error(error);
              navigate('/signup');
            }
          } else navigate('/signup');
        }
      });
      return () => unsubscribe();
    } else {
      navigate('/signup');
    }
  }, [navigate, isTelegramWebApp]);

  useEffect(() => {
    if (user && !couponData && !couponCode) {
      loadShopData();
      checkCouponInURL();
    }
  }, [user, couponData, couponCode]);

  useEffect(() => {
    if (countries.length > 0 && userCountry) {
      const selected =
        countryOptions.find((option) => option.value === userCountry) ||
        countryOptions[0];
      setSelectedCountry(selected);
    }
  }, [countries, userCountry]);

  useEffect(() => {
    if (creditsList.length > 0) {
      const adjustedCredits = adjustPrices();
      setAdjustedCreditsList(adjustedCredits);
    }
  }, [creditsList, couponData]);

  useEffect(() => {
    return () => {
      if (couponIntervalId) {
        clearInterval(couponIntervalId);
      }
    };
  }, [couponIntervalId]);

  const sortedPaymentMethods = useMemo(() => {
    if (isTelegramWebApp) {
      const telegramStarsMethod = paymentMethods.find(([methodId]) => methodId === 'telegram_stars');
      const otherMethods = paymentMethods.filter(([methodId]) => methodId !== 'telegram_stars');
      return telegramStarsMethod ? [telegramStarsMethod, ...otherMethods] : paymentMethods;
    } else {
      return paymentMethods;
    }
  }, [paymentMethods, isTelegramWebApp]);

  const checkCouponInURL = () => {
    const params = new URLSearchParams(location.search);
    const couponParam = params.get('coupon');
    if (couponParam && user && user.uid) {
      setCouponCode(couponParam);
      applyCoupon(couponParam, user.uid);
      logEvent(analytics, 'page_view_with_coupon', {
        coupon_code: couponParam,
        source: 'URL',
        country: userCountry || 'unknown',
      });
    }
  };

  const loadShopData = async () => {
    try {

      //what they use (with no key): https://get.geojs.io/v1/ip/geo.json

      /* const kadoData = await fetch(`https://api.kado.money/v2/ramp/quote?transactionType=buy&asset=ETH&amount=50&blockchain=ethereum&currency=USD`, {
        method: "GET",
      })
      
      const res = await kadoData.json();
      console.log(res); */
      
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/shop`, {
        method: 'GET',
      });
      const result = await response.json();
      if (response.ok) {
        if (result?.base_price) setPicturePrice(result.base_price);
        
        const savedCountry = localStorage.getItem('selectedCountry');
        if (savedCountry && savedCountry !== 'undefined' && savedCountry !== 'null' && savedCountry !== 'WW') {
          console.log(`users selected country: ${savedCountry}`)
          setUserCountry(savedCountry);
        }
        else {
          if (localStorage.getItem('userCountry') !== null) {
            console.log(`users detected saved country: ${localStorage.getItem('userCountry')}`)
            setUserCountry(localStorage.getItem('userCountry'));
          } else {
            console.log('fetching users country')
            await fetchUserCountry();
          }
        }

        if (result.rates) {
          setRates(result.rates);
        }

        setCreditsList(result.credits);
        setPaymentMethods(Object.entries(result.methods));
        setProviders(result.providers);

        const countriesArray = Object.entries(result.countries).map(
          ([code, data]) => ({
            value: code,
            label: data.label,
            flag: data.flag,
          })
        );
        setCountries(countriesArray);
      } else {
        console.error('Error fetching shop data:', result.error);
        setError('unknownError');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching shop data:', error);
      setLoading(false);
      setError('unknownError');
    }
  };

  const fetchUserCountry = async () => {
    try {
      let response = await axios.get('https://ipapi.co/json/');
      let userCountryCode;

      console.log(response);

      if (response.data && response.data.country_code) {
        userCountryCode = response.data.country_code
      }
      else {
        response = await axios.get('https://get.geojs.io/v1/ip/geo.json');
        if (response.data && response.data.country_code) {
          userCountryCode = response.data.country_code
        }
        console.log(response);
      }

      if (userCountryCode) {
        localStorage.setItem('userCountry', userCountryCode);
        setUserCountry(userCountryCode);
      }
      else {
        setUserCountry('WW');
      }
      
    } catch (error) {
      console.error('Error fetching user country:', error);
      setUserCountry('WW');
    }
  };

  useEffect(() => {
    // Fetch countries and set initial state
    async function fetchCountries() {
      // Simulate fetching countries
      const fetchedCountries = [
        { value: 'KR', label: 'South Korea', flag: 'KR' },
        { value: 'SA', label: 'Saudi Arabia', flag: 'SA' },
        { value: 'WW', label: '🌍 Worldwide', flag: 'WW' },
      ];
      setCountries(fetchedCountries);

      const savedCountry = localStorage.getItem('selectedCountry');
      console.log(savedCountry)
      if (savedCountry) {
        const selectedOption = fetchedCountries.find((country) => country.value === savedCountry);
        if (selectedOption) {
          console.log('setting selecting country to', selectedOption)
          setSelectedCountry(selectedOption);
          return;
        }
      }

      setSelectedCountry(fetchedCountries[0]); // Default to the first country
    }
    fetchCountries();
  }, []);

  const countryOptions = useMemo(
    () => [{ value: 'WW', label: '🌍 Worldwide' }, ...countries],
    [countries]
  );

  const handleBuyButton = async (logData) => {
    try {
      setProcessingPayment(true);
      const idToken = await user.getIdToken();
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/create-payment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: idToken,
        },
        body: JSON.stringify(logData),
      });
      const result = await response.json();
      if (response.ok) {
        const { paymentId, paymentLink, stars_link } = result;
        try {
          logEvent(analytics, 'purchase', {
            transaction_id: paymentId,
            value: logData?.price || 0,
            currency: "USD",
            coupon: couponData ? couponData.code : null,
            items: [{
              item_id: logData?.amount?.toString() || "unknown",
              item_name: `${logData.amount} Credits`,
              quantity: 1,
              price: logData?.price || 0,
            }],
            payment_method: logData?.method || 'unknown',
            provider: logData?.provider || 'unknown',
            country: userCountry || 'unknown',
          });
        } catch(err){
          console.error(err);
        }
        if (isTelegramWebApp) {
          if (stars_link) {
            if (window.Telegram && window.Telegram.WebApp) {
              window.Telegram.WebApp.openInvoice(stars_link, (status) => {
                navigate('/pay/' + paymentId);
              });
            }
          } else {
            if (window.Telegram && window.Telegram.WebApp) {
              window.Telegram.WebApp.showAlert(
                'You will be redirected to an external payment page. Please do not close this window.',
                () => {
                  if (logData.provider === "telegram_stars") window.Telegram.WebApp.openTelegramLink(paymentLink);
                  else if (paymentLink) window.Telegram.WebApp.openLink(paymentLink);
                  navigate('/pay/' + paymentId);
                }
              );
            }
          }
        } else {
          if (paymentLink) {
            window.open(paymentLink, '_blank');
          }
          navigate('/pay/' + paymentId);
        }
      } else {
        console.error('Error creating payment:', result.error);
        setError('unknownError');
        setProcessingPayment(false);
      }
    } catch (error) {
      console.error('Error:', error);
      setError('unknownError');
      setProcessingPayment(false);
    }
  };

  const handleCountrySelection = (option) => {
    setSelectedCountry(option);
    setSelectedPaymentMethod(null);
    setSelectedProvider(null);
  };

  const handleCreditSelection = (credit) => {
    if (isTelegramWebApp) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
    }
    setSelectedCredit(credit);
    logEvent(analytics, 'select_credit', {
      credit_amount: credit.amount || 0,
      credit_price: credit.price || 0,
      country: userCountry || 'unknown',
    });
    if (selectedPaymentMethod) {
      const activeProviders = getActiveProviders(selectedPaymentMethod, credit);
      if (activeProviders.length === 0) {
        setSelectedPaymentMethod(null);
        setSelectedProvider(null);
      } else {
        if (!activeProviders.includes(selectedProvider)) {
          setSelectedProvider(activeProviders[0]);
        }
      }
    } else {
      let preferredMethod;
      let preferredProvider;
      if (userCountry) {
        switch (userCountry) {
          case "BR":
            if (providers?.payssion?.active) {
              if (paymentMethods.some(method => method[0] === 'pix_br')) {
                preferredMethod = 'pix_br'
                preferredProvider = 'payssion'
              } else if (paymentMethods.some(method => method[0] === 'creditcard_br')) {
                preferredMethod = 'creditcard_br'
                preferredProvider = 'payssion'
              } else if (paymentMethods.some(method => method[0] === 'picpay_br')) {
                preferredMethod = 'picpay_br'
                preferredProvider = 'payssion'
              }
            }
            if (!preferredMethod && providers?.myx?.active) {
              preferredMethod = 'card'
              preferredProvider = 'myx'
            }
            break;
          case "KR":
            if (providers?.payssion?.active) {
              if (paymentMethods.some(method => method[0] === 'creditcard_kr')) {
                preferredMethod = 'creditcard_kr'
                preferredProvider = 'payssion'
              } else if (paymentMethods.some(method => method[0] === 'samsungpay_kr')) {
                preferredMethod = 'samsungpay_kr'
                preferredProvider = 'payssion'
              } else if (paymentMethods.some(method => method[0] === 'naverpay_kr')) {
                preferredMethod = 'naverpay_kr'
                preferredProvider = 'payssion'
              }
            }
            if (!preferredMethod && providers?.myx?.active) {
              preferredMethod = 'card'
              preferredProvider = 'myx'
            }
            break;
          case "MX":
            if (providers?.payssion?.active) {
              if (paymentMethods.some(method => method[0] === 'creditcard_mx')) {
                preferredMethod = 'creditcard_mx'
                preferredProvider = 'payssion'
              } else if (paymentMethods.some(method => method[0] === 'paycash_mx')) {
                preferredMethod = 'paycash_mx'
                preferredProvider = 'payssion'
              }
            }
            if (!preferredMethod && providers?.myx?.active) {
              preferredMethod = 'card'
              preferredProvider = 'myx'
            }
            break;
          case "SA":
            if (providers?.payssion?.active) {
              if (paymentMethods.some(method => method[0] === 'mada_sa')) {
                preferredMethod = 'mada_sa'
                preferredProvider = 'payssion'
              } else if (paymentMethods.some(method => method[0] === 'applepay_sa')) {
                preferredMethod = 'applepay_sa'
                preferredProvider = 'payssion'
              } else if (paymentMethods.some(method => method[0] === 'stcpay_sa')) {
                preferredMethod = 'stcpay_sa'
                preferredProvider = 'payssion'
              }
            }
            if (!preferredMethod && providers?.myx?.active) {
              preferredMethod = 'card'
              preferredProvider = 'myx'
            }
            break;
          case "CO":
            if (providers?.payssion?.active) {
              if (paymentMethods.some(method => method[0] === 'pse_co')) {
                preferredMethod = 'pse_co'
                preferredProvider = 'payssion'
              }
            }
            if (!preferredMethod && providers?.myx?.active) {
              preferredMethod = 'card'
              preferredProvider = 'myx'
            }
            break;
          case "RU":
            if (providers?.rukassa?.active || providers?.merchant001?.active) {
              if (paymentMethods.some(method => method[0] === 'russia')) {
                preferredMethod = 'russia'
                preferredProvider = providers?.rukassa?.active ? "rukassa" : "merchant001"
              }
            }
            if (!preferredMethod) {
              preferredMethod = 'crypto'
              preferredProvider = 'hoodpay_crypto'
            }
            break;
          default: 
            break;
        }
      }

      if (preferredMethod && preferredProvider) {
        setSelectedPaymentMethod(paymentMethods.find(method => method[0] === preferredMethod));
        setSelectedProvider(providers[preferredProvider].id);
      }
      else {
        if (providers?.myx?.active && (credit.price > providers?.myx?.min || !providers?.myx?.min) && paymentMethods.some(method => method[0] === 'card')) {
          setSelectedPaymentMethod(paymentMethods.find(method => method[0] === 'card'));
          setSelectedProvider(providers.myx.id);
        } else if (providers?.paypal?.active && paymentMethods.some(method => method[0] === 'paypal')) {
          setSelectedPaymentMethod(paymentMethods.find(method => method[0] === 'paypal'));
          setSelectedProvider(providers.paypal.id)
        }
      }
    }
  };

  const handlePaymentMethodSelection = (method) => {
    if (isTelegramWebApp) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
    }
    setSelectedPaymentMethod(method);
    logEvent(analytics, 'select_payment_method', {
      payment_method: method[0] || 'unknown',
      country: userCountry || 'unknown',
    });
    const activeProviders = getActiveProviders(method);
    if (activeProviders.length > 0) {
      const sortedProviders = activeProviders.sort(
        (a, b) => providers[a].fee - providers[b].fee
      );
      setSelectedProvider(sortedProviders[0]);
    } else {
      setSelectedProvider(null);
    }
  };

  const handleProviderSelection = (provider) => {
    setSelectedProvider(provider);
  };

  const filteredPaymentMethods = () => {
    const methodsWithActiveProviders = sortedPaymentMethods.map(
      ([methodId, method]) => {
        const activeProviders = getActiveProviders([methodId, method]);
        return { methodId, method, hasActiveProviders: activeProviders.length > 0 };
      }
    );
    const countryCode = selectedCountry?.value;
    const countrySpecificMethods = methodsWithActiveProviders.filter(
      ({ method }) => method.countries && (method.countries.includes(countryCode) || method.countries.includes('WW'))
    );
    const globalMethods = methodsWithActiveProviders.filter(({ method }) => !method.countries);
    return [
      ...countrySpecificMethods.filter((m) => m.hasActiveProviders),
      ...globalMethods.filter((m) => m.hasActiveProviders),
      ...methodsWithActiveProviders.filter((m) => !m.hasActiveProviders),
    ];
  };

  const getActiveProviders = (method = selectedPaymentMethod, credit = selectedCredit) => {
    if (!credit || !method) return [];
    return method[1].providers.filter((providerId) => {
      const provider = providers[providerId];
      const isCountryApplicable = !provider?.countries ||
        provider.countries.includes(selectedCountry?.value) ||
        provider.countries.includes('WW') ||
        selectedCountry?.value === 'WW';
      const isActive = provider?.active && isCountryApplicable;
      const minAmount = provider?.min || 0;
      const maxAmount = provider?.max || 1001;
      const price = credit.price;
      const isAmountValid = price >= minAmount && price <= maxAmount;
      return isActive && isAmountValid;
    });
  };

  const adjustPrices = () => {
    if (!creditsList || creditsList.length === 0) {
      console.error('No credits available.');
      return [];
    }
    try {
      let adjustedCredits = creditsList.map((credit) => {
        let price = credit.price;
        if (price === undefined) {
          console.error('Price is undefined for credit:', credit);
          price = 0;
        }
        let local_price = credit.local_price;
        const start_price = credit.start_price || price;
        const start_local_price = credit.start_local_price || local_price;
        let isCouponDiscount = credit?.isCouponDiscount || false;
        price = parseFloat(parseFloat(price).toFixed(2));
        const start_price_rounded = parseFloat(parseFloat(start_price).toFixed(2));
        if (local_price) {
          local_price = parseFloat(parseFloat(local_price).toFixed(2));
        }
        return {
          ...credit,
          price,
          local_price,
          start_price: start_price_rounded,
          start_local_price,
          discountPercentage: Math.round(((start_price - price) / start_price) * 100),
          discountAmount: start_price - price,
          isCouponDiscount,
        };
      });
      return adjustedCredits;
    } catch (error) {
      console.log(error);
    }
  };

  const applyCoupon = async (code, userId) => {
    if (!userId) {
      console.error('User ID is not available');
      setCouponError('User ID is not available.');
      return;
    }
    setCouponError('');
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/coupon`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId: userId, couponCode: code, country: userCountry }),
      });
      const now = Date.now();
      const result = await response.json();
      if (response.ok) {
        const { creditsList: adjustedCredits, coupon } = result;
        let expiresAt = coupon.expiresAt * 1000;

        const local_coupon_id = localStorage.getItem('coupon')
        console.log('users local coupon', local_coupon_id)
        if (local_coupon_id && local_coupon_id !== undefined && local_coupon_id !== 'undefined') {
          if (coupon.code && local_coupon_id === coupon.code) {
            const local_expiration = localStorage.getItem('coupon_expiresAt')
            console.log(local_expiration)
            if (local_expiration && parseInt(local_expiration) <= now) {
              localStorage.removeItem('coupon');
              localStorage.removeItem('coupon_expiresAt')
              return;
            }
            else expiresAt = local_expiration;
          }
          else if (coupon.code !== local_coupon_id) {
            localStorage.setItem("coupon", coupon.code);
            localStorage.setItem("coupon_expiresAt", now + (60 * 60 * 1000))
          }
        }
        else {
          localStorage.setItem("coupon", coupon.code);
          localStorage.setItem("coupon_expiresAt", now + (60 * 60 * 1000))
        }

        logEvent(analytics, 'apply_coupon', { coupon_id: coupon.code || null, country: userCountry || null });
        setCreditsList(adjustedCredits);
        setCouponData(coupon);
        let timeRemaining = expiresAt - now;
        if (timeRemaining > (60 * 60 * 1000)) timeRemaining = 60 * 60 * 1000;

        setCouponTimeRemaining(timeRemaining);
        const intervalId = setInterval(() => {
          const now = Date.now();
          const timeLeft = expiresAt - now;
          if (timeLeft <= 0) {
            clearInterval(intervalId);
            setCouponTimeRemaining(0);
            setCouponData(null);
            setCreditsList([]);
            loadShopData();
          } else {
            setCouponTimeRemaining(timeLeft);
          }
        }, 1000);
        setCouponIntervalId(intervalId);
        const adjustedCreditsList = adjustedCredits;
        setAdjustedCreditsList(adjustedCreditsList);
        const sortedCredits = adjustedCreditsList.slice().sort((a, b) => b.discountPercentage - a.discountPercentage);
        const highestDiscountCredit = sortedCredits[0];
        setSelectedCredit(highestDiscountCredit);
        const availableMethods = filteredPaymentMethods().filter((m) => m.hasActiveProviders);
        if (availableMethods.length > 0) {
          setSelectedPaymentMethod([availableMethods[0].methodId, availableMethods[0].method]);
          const activeProviders = getActiveProviders([availableMethods[0].methodId, availableMethods[0].method], highestDiscountCredit);
          if (activeProviders.length > 0) {
            setSelectedProvider(activeProviders[0]);
          } else {
            setSelectedProvider(null);
          }
        } else {
          setSelectedPaymentMethod(null);
          setSelectedProvider(null);
        }
      } else {
        setCouponError(result.error || 'Error applying coupon.');
        setCouponData(null);
        localStorage.removeItem("coupon");
        localStorage.removeItem("coupon_expiresAt");
      }
    } catch (error) {
      console.log(error);
      setCouponError('Error applying coupon.');
      setCouponData(null);
    }
  };

  const formatTimeRemaining = (time) => {
    const totalSeconds = Math.floor(time / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return { hours, minutes, seconds };
  };

  const formatCountdown = (time) => {
    const { hours, minutes, seconds } = formatTimeRemaining(time);
    if (time <= 0) return 'Expired';
    if (hours < 24) {
      if (hours === 0) {
        return `in ${String(minutes).padStart(2, '0')}m:${String(seconds).padStart(2, '0')}s`;
      } else {
        return `in ${String(hours).padStart(2, '0')}h:${String(minutes).padStart(2, '0')}m`;
      }
    } else {
      return `soon`;
    }
  };

  //Check if formData is valid for the required fields if provider requires data
  function isFormDataValid(dataRequest, formData) {
    if (!dataRequest) return true;
    if (dataRequest.includes('first_name') && !validateName(formData.first_name || '')) return false;
    if (dataRequest.includes('last_name') && !validateName(formData.last_name || '')) return false;
    if (dataRequest.includes('email') && !validateEmail(formData.email || '')) return false;
    if (dataRequest.includes('phone_number') && !validatePhone(formData.phone_number || '')) return false;
    return true;
  }

  const handleMainButtonClick = useCallback(() => {
    if (selectedCredit && selectedPaymentMethod && selectedProvider && !processingPayment) {
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.HapticFeedback.impactOccurred('heavy');
      }
      console.log(user)
      if (
        providerDataRequest &&
        providerDataRequest.length === 1 &&
        providerDataRequest.includes('email') &&
        email
      ) {
        handleBuyButton({
          amount: selectedCredit.amount,
          start_price: selectedCredit.start_price,
          price: selectedCredit.price,
          method: selectedPaymentMethod[0],
          provider: selectedProvider,
          timestamp: Math.floor(Date.now() / 1000),
          coupon: couponData ? couponData.code : null,
          country: userCountry,
          data_request: { email: email }, // Use email directly
        });
      } else if (providerDataRequest && providerDataRequest.length > 0) {
        //Check if data already valid
        if (isFormDataValid(providerDataRequest, formData)) {
          //If valid, proceed directly
          handleBuyButton({
            amount: selectedCredit.amount,
            start_price: selectedCredit.start_price,
            price: selectedCredit.price,
            method: selectedPaymentMethod[0],
            provider: selectedProvider,
            timestamp: Math.floor(Date.now() / 1000),
            coupon: couponData ? couponData.code : null,
            country: userCountry,
            data_request: formData
          });
        } else {
          if (showPaymentInfo && pendingLogData && providerDataRequest.length > 0) {
            //If PaymentInfo already open, this means confirm now
            setSubmitRequested(true);
          } else {
            //Show PaymentInfo
            setPendingLogData({
              amount: selectedCredit.amount,
              start_price: selectedCredit.start_price,
              price: selectedCredit.price,
              method: selectedPaymentMethod[0],
              provider: selectedProvider,
              timestamp: Math.floor(Date.now() / 1000),
              coupon: couponData ? couponData.code : null,
              country: userCountry,
            });
            setShowPaymentInfo(true);
          }
        }
      } else {
        //No data needed
        handleBuyButton({
          amount: selectedCredit.amount,
          start_price: selectedCredit.start_price,
          price: selectedCredit.price,
          method: selectedPaymentMethod[0],
          provider: selectedProvider,
          timestamp: Math.floor(Date.now() / 1000),
          coupon: couponData ? couponData.code : null,
          country: userCountry,
        });
      }
    } else {
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.MainButton.offClick(handleMainButtonClick);
        window.Telegram.WebApp.MainButton.disable();
      }
    }
  }, [
    selectedCredit,
    selectedPaymentMethod,
    selectedProvider,
    processingPayment,
    couponData,
    userCountry,
    providerDataRequest,
    formData,
    showPaymentInfo,
    pendingLogData
  ]);

  useEffect(() => {
    if (isTelegramWebApp) {
      const MainButton = window.Telegram.WebApp.MainButton;
      MainButton.offClick(handleMainButtonClick);
      MainButton.disable();
      MainButton.hide();
      const SecondButton = window.Telegram.WebApp.SecondaryButton;
      SecondButton.setParams({
        is_visible: false,
        is_active: false,
        position: 'left',
        has_shine_effect: false,
        color: window.Telegram.WebApp.themeParams.bottom_bar_bg_color,
        textColor: window.Telegram.WebApp.themeParams.button_color
      });
      MainButton.onClick(handleMainButtonClick);
      if (selectedCredit) {
        if (selectedPaymentMethod) {
          if (showPaymentInfo && providerDataRequest && providerDataRequest.length > 0) {
            MainButton.setText(translations.button.confirm?.[language] || translations.button.confirm.en);
          } else {
            MainButton.setText(`${translations.button.buy[0]?.[language] || translations.button.buy[0].en}${selectedCredit.amount} with ${selectedPaymentMethod[1].name}`);
          }
        } else {
          MainButton.setText(translations.button.payment_options?.[language] || translations.button.payment_options.en);
        }
      } else {
        MainButton.setText(translations.button.credits?.[language] || translations.button.credits.en);
      }
      if (selectedCredit) MainButton.show();
      if (selectedCredit && selectedPaymentMethod && !processingPayment) {
        MainButton.enable();
        MainButton.setParams({ has_shine_effect: true, color: '#5288c1', text_color: '#FFFFFF' });
      } else {
        MainButton.disable();
        MainButton.setParams({ has_shine_effect: false, color: '#5288c1', text_color: '#FFFFFF' });
      }
      if (processingPayment) {
        MainButton.showProgress();
      } else {
        MainButton.hideProgress();
      }
      return () => {
        MainButton.offClick(handleMainButtonClick);
        MainButton.hide();
      };
    }
  }, [
    isTelegramWebApp,
    selectedCredit,
    selectedPaymentMethod,
    selectedProvider,
    processingPayment,
    couponData,
    userCountry,
    handleMainButtonClick,
    showPaymentInfo,
    pendingLogData,
    providerDataRequest
  ]);

  const initiateBuy = async () => {
    //If provider requires data, check if already valid
    if (providerDataRequest && providerDataRequest.length === 1 && providerDataRequest.includes("email") && email) {
      handleBuyButton({
        amount: selectedCredit.amount,
        start_price: selectedCredit.start_price,
        price: selectedCredit.price,
        method: selectedPaymentMethod[0],
        provider: selectedProvider,
        timestamp: Math.floor(Date.now() / 1000),
        coupon: couponData ? couponData.code : null,
        country: userCountry,
        data_request: { email: email }
      });
    }
    else if (providerDataRequest && providerDataRequest.length > 0) {
      if (isFormDataValid(providerDataRequest, formData)) {
        //Already valid, no need to show PaymentInfo
        handleBuyButton({
          amount: selectedCredit.amount,
          start_price: selectedCredit.start_price,
          price: selectedCredit.price,
          method: selectedPaymentMethod[0],
          provider: selectedProvider,
          timestamp: Math.floor(Date.now() / 1000),
          coupon: couponData ? couponData.code : null,
          country: userCountry,
          data_request: formData
        });
      } else {
        //Show PaymentInfo
        setPendingLogData({
          amount: selectedCredit.amount,
          start_price: selectedCredit.start_price,
          price: selectedCredit.price,
          method: selectedPaymentMethod[0],
          provider: selectedProvider,
          timestamp: Math.floor(Date.now() / 1000),
          coupon: couponData ? couponData.code : null,
          country: userCountry,
        });
        setShowPaymentInfo(true);
      }
    } else {
      handleBuyButton({
        amount: selectedCredit.amount,
        start_price: selectedCredit.start_price,
        price: selectedCredit.price,
        method: selectedPaymentMethod[0],
        provider: selectedProvider,
        timestamp: Math.floor(Date.now() / 1000),
        coupon: couponData ? couponData.code : null,
        country: userCountry,
      });

      /* const methodId = selectedPaymentMethod[0];
      if (methodId === "toncoin") {
        // If user is NOT connected => open modal
        if (!wallet) {
          openTonModal(); 
          return;
        }
        // If user IS connected => we do transaction
        await handleTonPayment(selectedCredit.price);
        return;
      } */
    }
  };

  const handlePaymentInfoConfirm = (formData) => {
    setShowPaymentInfo(false);
    if (pendingLogData) {
      const finalData = {
        ...pendingLogData,
        data_request: formData,
      };
      setPendingLogData(null);
      handleBuyButton(finalData);
    }
  };

  const handlePaymentInfoClose = () => {
    setShowPaymentInfo(false);
    setPendingLogData(null);
  };

  const handleCountryChange = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = countryOptions.find((option) => option.value === selectedValue);
    setSelectedCountry(selectedOption);
    localStorage.setItem('selectedCountry', selectedOption.value);
  };

  if (error) {
    return <ErrorModule type={error} />;
  }

  const isConfirming = showPaymentInfo && pendingLogData && providerDataRequest && providerDataRequest.length > 0;
  const buttonText = isConfirming
    ? translations.button.confirm?.[language] || translations.button.confirm.en
    : (selectedCredit && selectedPaymentMethod)
      ? `${translations.button.buy[0]?.[language] || translations.button.buy[0].en}${selectedCredit ? selectedCredit.amount : ''}${translations.button.buy[1]?.[language] || translations.button.buy[1].en}`
      : (selectedCredit ? (translations.button.payment_options?.[language] || translations.button.payment_options.en) : (translations.button.credits?.[language] || translations.button.credits.en));

  return (
      <TemplateFrame
        toggleCustomTheme={() => setShowCustomTheme((prev) => !prev)}
        showCustomTheme={showCustomTheme}
        mode={mode}
        toggleColorMode={() => {
          const newMode = mode === 'dark' ? 'light' : 'dark';
          setMode(newMode);
          if (!isTelegramWebApp) {
            localStorage.setItem('themeMode', newMode);
          }
        }}
        credits={credits}
      >
        <ThemeProvider theme={showCustomTheme ? shopTheme : defaultTheme}>
          <Container maxWidth="sm" sx={{ marginBottom: 20 }}>
            <CssBaseline enableColorScheme />
            <ShopContainer>
              {couponData && (
                <CouponBanner
                  couponData={couponData}
                  timeRemaining={Math.floor(couponTimeRemaining)} // Convert milliseconds to seconds
                  language={language || localStorage.getItem('language') || 'en'} // Use stored language
                />
                )
              }
              <Typography variant="h4" component="h1" fontWeight="bold">
                { translations.title.label?.[language] || translations.title.label.en }
              </Typography>
              <Typography variant="body1" marginTop={-2}>
                { (translations.title.description?.[language] || translations.title.description.en).replace("$PRICE", picturePrice) }
              </Typography>
              {credits < 10 && isTelegramWebApp && (
                <TelegramShare mode={mode} lang={language} adult={showAdultContent} chatId={user?.uid}/>
              )}
              <CardsContainer>
                {loading
                  ? Array.from(new Array(6)).map((_, index) => (
                      <Skeleton key={index} variant="rounded" height={150} />
                    ))
                  : adjustedCreditsList.map((credit, index) => (
                      <CreditsSelectionCard
                        key={index}
                        credit={credit}
                        index={index}
                        selectedCredit={selectedCredit}
                        handleCreditSelection={handleCreditSelection}
                        creditsList={adjustedCreditsList}
                        couponTimeRemaining={couponTimeRemaining}
                        formatCountdown={formatCountdown}
                        rates={rates}
                        countryCode={selectedCountry.value || userCountry}
                      />
                    ))}
              </CardsContainer>
              {!couponData && (
                <Box sx={{ width: '100%' }}>
                  <Typography variant="h6" sx={{ textAlign: 'left', mt: 2 }}>
                    Coupon
                  </Typography>
                  {couponError && (
                    <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                      {couponError}
                    </Typography>
                  )}
                  <TextField
                    fullWidth
                    label="Coupon Code"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            variant="contained"
                            onClick={() => applyCoupon(couponCode, user?.uid)}
                            sx={{ height: '70%' }}
                          >
                            Apply
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              )}
              {selectedCredit && (
                <>
                  <CountrySelectionContainer>
                    <Typography variant="h6" sx={{ flexShrink: 0, whiteSpace: 'nowrap' }}>
                      { translations.payment_options?.[language] || translations.payment_options.en }
                    </Typography>
                    <Box sx={{ flexGrow: 1 }}>
                      <CountrySelector
                        value={selectedCountry?.value || ''}
                        onChange={handleCountryChange}
                        options={countryOptions}
                      />
                    </Box>
                  </CountrySelectionContainer>
                  <ScrollableCardsContainer>
                  {loading
                    ? Array.from(new Array(4)).map((_, index) => (
                        <Skeleton key={index} variant="rounded" width={100} height={100} />
                      ))
                    : filteredPaymentMethods().map(
                        ({ methodId, method, hasActiveProviders }) => (
                          <PaymentOptionsCard
                            key={methodId}
                            methodId={methodId}
                            method={method}
                            selectedCredit={selectedCredit}
                            selectedPaymentMethod={selectedPaymentMethod}
                            handlePaymentMethodSelection={handlePaymentMethodSelection}
                            providers={providers}
                            disabled={!hasActiveProviders}
                          />
                        )
                      )}
                </ScrollableCardsContainer>
                {loading ? (
                  <Skeleton variant="rounded" width={200} height={56} sx={{ mt: 2 }} />
                ) : (
                  selectedCredit &&
                  selectedPaymentMethod && (
                    <SelectedProviderDropdown
                      providers={getActiveProviders()}
                      selectedProvider={selectedProvider}
                      handleProviderSelection={handleProviderSelection}
                      providersData={providers}
                    />
                  )
                )}
                </>
              )}
            </ShopContainer>
            {providerDataRequest && providerDataRequest.length > 0 && (
              <PaymentInfo
                open={showPaymentInfo}
                onClose={handlePaymentInfoClose}
                dataRequest={providerDataRequest}
                onConfirm={handlePaymentInfoConfirm}
                formData={formData} //Pass formData to fix undefined error and save data
                setFormData={setFormData}
                submitRequested={submitRequested}
                isAdult={showAdultContent}
                language={language}
                mode={mode}
              />
            )}
            {!isTelegramWebApp && selectedCredit && (
              <FixedBottomButton sx={{ marginBottom: 6 }}>
                <Button
                  variant="contained"
                  color='secondary'
                  sx={{ width: { xs: '100%', md: '50%' }, minHeight: 60 }}
                  disabled={
                    loading ||
                    !selectedCredit ||
                    !selectedPaymentMethod ||
                    !selectedProvider ||
                    processingPayment
                  }
                  onClick={() => {
                    if (!showPaymentInfo && providerDataRequest && providerDataRequest.length === 1 && email) {
                      initiateBuy();
                    }
                    else if (showPaymentInfo && providerDataRequest && providerDataRequest.length > 0) {
                      //If dialog is open and data needed, pressing this button = confirm
                      setSubmitRequested(true);
                    } else {
                      //Normal buy action
                      initiateBuy();
                    }
                  }}
                >
                  {processingPayment ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <CircularProgress size={24} color="inherit" />
                      <Typography variant="h6">Processing...</Typography>
                    </Box>
                  ) : (
                    <Typography variant="h6">
                      {buttonText}
                    </Typography>
                  )}
                </Button>
              </FixedBottomButton>
            )}
            <Box>
              <Typography variant="body2" color="textSecondary" align="center">
                By proceeding with the purchase, you agree to our{' '}
                <a href="/terms-of-service.pdf" target="_blank" rel="noopener noreferrer">
                  Terms of Service
                </a>{' '}
                and{' '}
                <a href="/privacy-policy.pdf" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
                . You must be{' '}
                {selectedCountry && ['US', 'UK'].includes(selectedCountry.value)
                  ? '21'
                  : '18'}
                + years old to use our services.
              </Typography>
            </Box>
          </Container>
        </ThemeProvider>
      </TemplateFrame>
  );
}
