// MenuChoices.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Slider,
  Link,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle
} from '@mui/material';
import WcIcon from '@mui/icons-material/Wc';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';
import CropFreeIcon from '@mui/icons-material/CropFree';
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LandscapeIcon from '@mui/icons-material/Landscape';
import CameraIcon from '@mui/icons-material/Camera';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import getSignUpTheme from '../pages/theme/theme/getSignUpTheme';

const trnsl = {
  amount: {
    en: "How many?",
    ru: "Количество:",
    es: "¿Cuántos?",
    pt: "Quantos?",
    de: "Wie viele?",
    ja: "いくつ?",
    ko: "얼마나?",
    zh: "多少?",
    hi: "कितने?"
  },
  gender: {
    en: "Gender",
    ru: "Пол",
    es: "Género",
    pt: "Gênero",
    de: "Geschlecht",
    ja: "性別",
    ko: "성별",
    zh: "性别",
    hi: "लिंग"
  },
  clothes: {
    en: "Clothes",
    ru: "Одежда",
    es: "Ropa",
    pt: "Roupas",
    de: "Kleidung",
    ja: "服装",
    ko: "옷",
    zh: "衣服",
    hi: "कपड़े"
  },
  action: {
    en: "Action",
    ru: "Действие",
    es: "Acción",
    pt: "Ação",
    de: "Aktion",
    ja: "行動",
    ko: "행동",
    zh: "动作",
    hi: "क्रिया"
  },
  style: {
    en: "Style",
    ru: "Стиль съемки",
    es: "Estilo",
    pt: "Estilo",
    de: "Stil",
    ja: "スタイル",
    ko: "스타일",
    zh: "风格",
    hi: "शैली"
  },
  framing: {
    en: "Framing",
    ru: "Кадр",
    es: "Encuadre",
    pt: "Enquadramento",
    de: "Rahmung",
    ja: "フレーミング",
    ko: "구도",
    zh: "构图",
    hi: "फ्रेमिंग"
  },
  angle: {
    en: "Angle",
    ru: "Ракурс",
    es: "Ángulo",
    pt: "Ângulo",
    de: "Winkel",
    ja: "角度",
    ko: "각도",
    zh: "角度",
    hi: "कोण"
  },
  lighting: {
    en: "Lighting",
    ru: "Освещение",
    es: "Iluminación",
    pt: "Iluminação",
    de: "Beleuchtung",
    ja: "照明",
    ko: "조명",
    zh: "灯光",
    hi: "प्रकाश व्यवस्था"
  },
  camera: {
    en: "Camera",
    ru: "Камера",
    es: "Cámara",
    pt: "Câmera",
    de: "Kamera",
    ja: "カメラ",
    ko: "카메라",
    zh: "相机",
    hi: "कैमरा"
  },
  setting: {
    en: "Environment",
    ru: "Окружение",
    es: "Entorno",
    pt: "Ambiente",
    de: "Umgebung",
    ja: "環境",
    ko: "환경",
    zh: "环境",
    hi: "पर्यावरण"
  },
  misc: {
    en: "Miscellaneous",
    ru: "Остальное",
    es: "Misceláneo",
    pt: "Diversos",
    de: "Verschiedenes",
    ja: "その他",
    ko: "기타",
    zh: "其他",
    hi: "विविध"
  },
  additional: {
    en: "Additional Settings",
    ru: "Дополнительные настройки",
    es: "Configuraciones Adicionales",
    pt: "Configurações Adicionais",
    de: "Zusätzliche Einstellungen",
    ja: "追加設定",
    ko: "추가 설정",
    zh: "附加设置",
    hi: "अतिरिक्त सेटिंग्स"
  },
  male: {
    en: "Male",
    ru: "Мужчина",
    es: "Hombre",
    pt: "Masculino",
    de: "Männlich",
    ja: "男性",
    ko: "남성",
    zh: "男性",
    hi: "पुरुष"
  },
  female: {
    en: "Female",
    ru: "Женщина",
    es: "Mujer",
    pt: "Feminino",
    de: "Weiblich",
    ja: "女性",
    ko: "여성",
    zh: "女性",
    hi: "महिला"
  },
  trans: {
    en: "Trans",
    ru: "Транс",
    es: "Trans",
    pt: "Trans",
    de: "Trans",
    ja: "トランス",
    ko: "트랜스",
    zh: "跨性别",
    hi: "ट्रांस"
  },
  bodySettings: {
    en: "Body Parameters",
    es: "Parámetros Corporales",
    pt: "Parâmetros do Corpo",
    de: "Körperparameter",
    ja: "身体パラメータ",
    ko: "신체 매개변수",
    zh: "身体参数",
    ru: "Параметры тела",
    hi: "शरीर के पैरामीटर"
  },
  hairColor: {
    en: "Hair Color",
    es: "Color de cabello",
    pt: "Cor do cabelo",
    de: "Haarfarbe",
    ja: "髪の色",
    ko: "머리 색깔",
    zh: "头发颜色",
    ru: "Цвет волос",
    hi: "बालों का रंग"
  },
  bodyType: {
    en: "Body Shape",
    es: "Forma del Cuerpo",
    pt: "Formato do Corpo",
    de: "Körperform",
    ja: "体型",
    ko: "체형",
    zh: "体型",
    ru: "Форма тела",
    hi: "शरीर का आकार"
  },
  male_size: {
    en: "🍌 Size",
    es: "🍌 Tamaño",
    pt: "🍌 Tamanho",
    de: "🍌 Größe",
    ja: "🍌 サイズ",
    ko: "🍌 크기",
    zh: "🍌 大小",
    ru: "🍌 Размер",
    hi: "🍌 आकार"
  },
  female_size: {
    en: "💦 Breasts Size",
    es: "💦 Tamaño de los Senos",
    pt: "💦 Tamanho dos Seios",
    de: "💦 Brustgröße",
    ja: "💦 胸のサイズ",
    ko: "💦 가슴 크기",
    zh: "💦 胸部大小",
    ru: "💦 Размер груди",
    hi: "💦 स्तनों का आकार"
  },
  shuffle: {
    en: "Shuffle",
    ru: "Перемешать",
    es: "Barajar",
    pt: "Embaralhar",
    de: "Mischen",
    ja: "シャッフル",
    ko: "셔플",
    zh: "洗牌",
    hi: "शफल करें"
  },
  artistic: {
    en: "Artistic 🎨",
    es: "Artístico 🎨",
    pt: "Artístico 🎨",
    de: "Künstlerisch 🎨",
    ja: "アーティスティック 🎨",
    ko: "예술적인 🎨",
    zh: "艺术 🎨",
    ru: "Артистичный 🎨",
    hi: "कलात्मक 🎨"
  },
  realistic: {
    en: "Realistic 📷",
    es: "Realista 📷",
    pt: "Realista 📷",
    de: "Realistisch 📷",
    ja: "リアル 📷",
    ko: "사실적인 📷",
    zh: "真实 📷",
    ru: "Реалистичный 📷",
    hi: "यथार्थवादी 📷"
  },
  pulid_explain_title: {
    en: "Artistic vs Realistic",
    es: "Artístico vs Realista",
    pt: "Artístico vs Realista",
    de: "Künstlerisch vs Realistisch",
    ja: "アーティスティック vs リアル",
    ko: "예술적 대 사실적",
    zh: "艺术与真实",
    ru: "Артистичный vs Реалистичный",
    hi: "कलात्मक बनाम यथार्थवादी"
  },
  pulid_explain_text: [
    {
      en: "Artistic (left) produces stylized or more creative images: faster processing time, but not suitable for some extraordinary faces",
      es: "Artístico (izquierda) produce imágenes estilizadas o más creativas: tiempo de procesamiento más rápido, pero no es adecuado para algunas caras extraordinarias",
      pt: "Artístico (esquerda) produz imagens estilizadas ou mais criativas: tempo de processamento mais rápido, mas não é adequado para alguns rostos extraordinários",
      de: "Künstlerisch (links) erzeugt stilisierte oder kreativere Bilder: schnellere Verarbeitung, aber nicht geeignet für außergewöhnliche Gesichter",
      ja: "アーティスティック（左）はスタイリッシュまたはより創造的な画像を生成します：処理時間が短いですが、特異な顔には適していません",
      ko: "예술적(왼쪽)은 스타일화된 창의적인 이미지를 생성합니다: 처리 시간이 빠르지만 일부 특별한 얼굴에는 적합하지 않을 수 있습니다",
      zh: "艺术（左）生成风格化或更具创意的图像：处理时间更快，但不适用于某些特殊面孔",
      ru: "Артистичный (слева) создаёт стилизованные или более креативные изображения: быстрее обрабатывается, но не подходит для необычных лиц",
      hi: "कलात्मक (बाएं) स्टाइलिश या अधिक रचनात्मक छवियां उत्पन्न करता है: तेज़ प्रसंस्करण समय, लेकिन कुछ असाधारण चेहरों के लिए उपयुक्त नहीं"
    },
    {
      en: "Realistic (right) uses advanced technology to create ultrarealistic copy of the face, but is slower and may be more expensive",
      es: "Realista (derecha) utiliza tecnología avanzada para crear una copia ultrarrealista de la cara, pero es más lento y puede ser más caro",
      pt: "Realista (direita) usa tecnologia avançada para criar uma cópia ultrarrealista do rosto, mas é mais lento e pode ser mais caro",
      de: "Realistisch (rechts) nutzt fortschrittliche Technologie, um eine ultrarealistische Kopie des Gesichts zu erstellen, ist jedoch langsamer und möglicherweise teurer",
      ja: "リアル（右）は高度な技術を使用して超現実的な顔のコピーを作成しますが、遅くなる可能性があり、費用がかかる場合があります",
      ko: "사실적(오른쪽)은 고급 기술을 사용하여 초사실적인 얼굴 복사본을 생성합니다. 그러나 더 느리고 비용이 더 들 수 있습니다",
      zh: "真实（右）使用先进技术创建超现实的面部副本，但速度较慢且可能更昂贵",
      ru: "Реалистичный (справа) использует передовые технологии для создания ультрареалистичной копии лица, но медленнее и может быть дороже",
      hi: "यथार्थवादी (दाएं) उन्नत तकनीक का उपयोग करके चेहरे की अल्ट्रारेअलिस्टिक प्रतिलिपि बनाता है, लेकिन धीमा हो सकता है और अधिक महंगा हो सकता है"
    }
  ],
  pulid_explain_confirm: {
    en: "Got it!",
    es: "¡Entendido!",
    pt: "Entendi!",
    de: "Verstanden!",
    ja: "了解しました！",
    ko: "알겠습니다!",
    zh: "明白了！",
    ru: "Понял!",
    hi: "समझ गया!"
  },
}

export default function MenuChoices({
  gender,
  setGender,
  framing,
  setFraming,
  angle,
  setAngle,
  lighting,
  setLighting,
  bodyType,
  setBodyType,
  posture,
  setPosture,
  photoType,
  setPhotoType,
  cupSize,
  setCupSize,
  mediaAmount,
  setMediaAmount,
  clothes,
  setClothes,
  misc,
  setMisc,
  hairColor,
  setHairColor,
  setting,
  setSetting,
  camera,
  setCamera,
  pulid,
  setPulid,
  isAdult = false,
  isTelegramWebApp,
  selectors,
  language,
  theme,
}) {
  const [pulidDialogOpen, setPulidDialogOpen] = useState(false);
  const [dialogPulidImage, setDialogPulidImage] = useState("");

  const navigate = useNavigate();
  const [isAdditionalOpen, setIsAdditionalOpen] = useState(false);
  const [isBodyOpen, setIsBodyOpen] = useState(false);

  const clothesOptions = (selectors?.clothes ?? []).map((item) => ({
    label: item?.name?.[language] || item?.name?.en || "N/A",
    value: item.prompt || "",
    lora: item?.lora || null
  }));

  const sizeMaleOptions = (selectors?.male_size ?? []).map((item) => ({
    label: item?.name?.[language] || item?.name?.en || "N/A",
    value: item.prompt || "",
    lora: item?.lora || null
  }));

  const sizeFemaleOptions = (selectors?.female_size ?? []).map((item) => ({
    label: item?.name?.[language] || item?.name?.en || "N/A",
    value: item.prompt || "",
    lora: item?.lora || null
  }));

  const bodyMaleOptions = (selectors?.male_body ?? []).map((item) => ({
    label: item?.name?.[language] || item?.name?.en || "N/A",
    value: item.prompt || "",
    lora: item?.lora || null
  }));

  const bodyFemaleOptions = (selectors?.female_body ?? []).map((item) => ({
    label: item?.name?.[language] || item?.name?.en || "N/A",
    value: item.prompt || "",
    lora: item?.lora || null
  }));

  const hairColorOptions = (selectors?.hair_color ?? []).map((item) => ({
    label: item?.name?.[language] || item?.name?.en || "N/A",
    value: item.prompt || "",
    lora: item?.lora || null
  }));

  const actionOptions = (selectors?.action ?? []).map((item) => ({
    label: item?.name?.[language] || item?.name?.en || "N/A",
    value: item.prompt || "",
    lora: item?.lora || null
  }));

  const photosetTypeList = (selectors?.style ?? []).map((item) => ({
    label: item?.name?.[language] || item?.name?.en || "N/A",
    value: item.prompt || "",
    lora: item?.lora || null
  }));

  const framingOptions = (selectors?.frame ?? []).map((item) => ({
    label: item?.name?.[language] || item?.name?.en || "N/A",
    value: item.prompt || "",
    lora: item?.lora || null
  }));

  const lightingOptions = (selectors?.lighting ?? []).map((item) => ({
    label: item?.name?.[language] || item?.name?.en || "N/A",
    value: item.prompt || "",
    lora: item?.lora || null
  }));

  const angleOptions = (selectors?.angle ?? []).map((item) => ({
    label: item?.name?.[language] || item?.name?.en || "N/A",
    value: item.prompt || "",
    lora: item?.lora || null
  }));

  const cameraOptions = (selectors?.camera ?? []).map((item) => ({
    label: item?.name?.[language] || item?.name?.en || "N/A",
    value: item.prompt || "",
    lora: item?.lora || null
  }));

  const settingOptions = (selectors?.setting ?? []).map((item) => ({
    label: item?.name?.[language] || item?.name?.en || "N/A",
    value: item.prompt || "",
    lora: item?.lora || null
  }));

  const miscOptions = (selectors?.misc ?? []).map((item) => ({
    label: item?.name?.[language] || item?.name?.en || "N/A",
    value: item.prompt || "",
    lora: item?.lora || null
  }));

  const relevantCupSizeOptions = gender?.value === 'female' ? sizeFemaleOptions : sizeMaleOptions;

  const handleButtonClick = (option, setter) => {
    setter((prev) => {
      // If 'option' is a string, compare directly
      if (typeof option === "string") {
        if (prev === option) {
          return null;
        }
        return option;
      }
      // Else, if 'option' is an object => compare prev?.value
      if (prev?.value === option.value) {
        return null;
      }
      console.log(option)
      return option;
    });

    if (isTelegramWebApp) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
    }
  };

  const handleSelectArtistic = () => {
    setPulid(null);
    if (isTelegramWebApp) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('heavy');
    }
  };
  const handleSelectRealistic = () => {
    setPulid(true);
    if (isTelegramWebApp) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('heavy');
    }
  };
  const openPulidDialog = () => {
    setPulidDialogOpen(true);
  };
  const closePulidDialog = () => {
    setPulidDialogOpen(false);
  };

  const getRandomValue = (arr) => {
    if (!arr?.length) return null;
    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
  };

  const handleShuffleClick = () => {
    // Randomize everything EXCEPT body parameters + gender
    setFraming(getRandomValue(framingOptions) || null);
    setAngle(getRandomValue(angleOptions) || null);
    setLighting(getRandomValue(lightingOptions) || null);
    setClothes(getRandomValue(clothesOptions) || null);
    setPosture(getRandomValue(actionOptions) || null);
    setPhotoType(getRandomValue(photosetTypeList) || null);
    setCamera(getRandomValue(cameraOptions) || null);
    setSetting(getRandomValue(settingOptions) || null);

    if (isTelegramWebApp) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
    }
  };

  useEffect(() => {
    // If the user just switched from 'male' to 'female', 
    // and we have a previously chosen male cup size, reset it:
    if (gender?.value === 'female' && cupSize) {
      // is the old cupSize actually in sizeFemaleOptions?
      const foundInFemale = sizeFemaleOptions.some(
        (opt) => opt.value === cupSize.value
      );
      // If not found, we clear cupSize:
      if (!foundInFemale) {
        setCupSize(null);
      }
    }
  
    // and vice versa if user switched from 'female' to 'male':
    if (gender?.value === 'male' && cupSize) {
      const foundInMale = sizeMaleOptions.some(
        (opt) => opt.value === cupSize.value
      );
      if (!foundInMale) {
        setCupSize(null);
      }
    }
  }, [
    gender,
    cupSize,
    sizeMaleOptions,
    sizeFemaleOptions,
    setCupSize
  ]);
  

  return (
    <Box sx={{ width: '99%', marginBottom: 20 }}>
      {/* Language Changer */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 2,
        }}
      >
        <Link
          component="button"
          onClick={() => navigate('/profile')}
          underline="hover"
          color="primary"
        >
          🌍 Change Language
        </Link>

        <Button
          variant="outlined"
          startIcon={<AutoAwesomeIcon />}
          onClick={handleShuffleClick}
        >
          {trnsl.shuffle?.[language]}
        </Button>
      </Box>

      {/* NEW: ARTISTIC / REALISTIC Buttons with ? icons */}
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 3, width: "100%" }}>
        {/* Artistic Button */}
        <Button
            variant={pulid === null ? "contained" : "outlined"}
            onClick={handleSelectArtistic}
            sx={{
              minWidth: 40,
              borderRadius: 50, // more round
              p: 1,
              flex: '1 1 auto' 
            }}
          >
            {trnsl.artistic?.[language] || trnsl.artistic.en}
        </Button>

        <Button
            variant={pulid === true ? "contained" : "outlined"}
            onClick={handleSelectRealistic}
            sx={{
              minWidth: 40,
              borderRadius: 50,
              p: 1,
              flex: '1 1 auto' 
            }}
          >
            {trnsl.realistic?.[language] || trnsl.realistic.en}
          </Button>

          <IconButton onClick={openPulidDialog} color="info" sx={{
              borderRadius: 50, // more round
          }}>
              <HelpOutlineIcon fontSize="small"/>
          </IconButton>
      </Box>

      {/* Media Amount Selection */}
      <Box sx={{ mb: 2, width: '100%' }}>
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between'
          }}
        >
          {/* Left side: Icon + label */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <AutoAwesomeMosaicIcon />
            <Typography variant="h6" gutterBottom sx={{ m: 0 }}>
              {trnsl.amount?.[language] || trnsl.amount.en}
            </Typography>
          </Box>

          {/* Right side: minus / amount / plus */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Button
              variant="outlined"
              onClick={() => setMediaAmount((prev) => Math.max(1, prev - 1))}
              disabled={mediaAmount <= 1}
            >
              –
            </Button>

            <Box
              sx={{
                minWidth: 40,
                textAlign: 'center',
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 1,
                py: 1,
              }}
            >
              <Typography variant="body1">{mediaAmount}</Typography>
            </Box>

            <Button
              variant="outlined"
              onClick={() => setMediaAmount((prev) => Math.min(20, prev + 1))}
              disabled={mediaAmount >= 20}
            >
              +
            </Button>
          </Box>
        </Box>
      </Box>


      {/* Gender Selection */}
      <Typography variant="h6" gutterBottom>
        <Box display="flex" alignItems="center" gap={1}>
          <WcIcon />
          {trnsl.gender?.[language] || trnsl.gender.en}
        </Box>
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <Button
          variant={gender?.value === 'male' ? 'contained' : 'outlined'}
          onClick={() => handleButtonClick({value: 'male', lora: null}, setGender)}
          fullWidth
        >
          👦 {trnsl.male?.[language] || trnsl.male.en}
        </Button>
        <Button
          variant={gender?.value === 'female' ? 'contained' : 'outlined'}
          onClick={() => handleButtonClick({value: 'female', lora: null}, setGender)}
          fullWidth
        >
          👧 {trnsl.female?.[language] || trnsl.female.en}
        </Button>
        {isAdult && (
          <Button
          variant={gender?.value === 'transgender female' ? 'contained' : 'outlined'}
          onClick={() => handleButtonClick({ value: 'transgender female', lora: null}, setGender)}
          fullWidth
          >
            {trnsl.trans?.[language] || trnsl.trans.en}
          </Button>
        )}
      </Box>

      {/* Clothes Selection */}
      { clothesOptions.length > 0 && (
        <>
          <Typography variant="h6" gutterBottom>
            <Box display="flex" alignItems="center" gap={1}>
              <CheckroomIcon />
              {trnsl.clothes?.[language] || trnsl.clothes.en}
            </Box>
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
            {clothesOptions.map((option) => (
              <Button
                key={option.value}
                variant={clothes?.value === option.value ? 'contained' : 'outlined'}
                onClick={() => handleButtonClick(option, setClothes)}
                sx={{ flex: '1 1 auto' }}
              >
                {option.label}
              </Button>
            ))}
          </Box>
        </>
      )}

      {/* Setting Selection */}
      { settingOptions.length > 0 && (
        <>
          <Typography variant="h6" gutterBottom>
          <Box display="flex" alignItems="center" gap={1}>
            <LandscapeIcon />
            {trnsl.setting?.[language] || trnsl.setting.en}
          </Box>
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
            {settingOptions.map((option) => (
              <Button
                key={option.value}
                variant={setting?.value === option.value ? 'contained' : 'outlined'}
                onClick={() => handleButtonClick(option, setSetting)}
                sx={{ flex: '1 1 auto' }}
              >
                {option.label}
              </Button>
            ))}
          </Box>
        </>
      )}

      {/* Posture Selection */}
      { actionOptions.length > 0 && (
        <>
          <Typography variant="h6" gutterBottom>
            <Box display="flex" alignItems="center" gap={1}>
              <SelfImprovementIcon />
              {trnsl.action?.[language] || trnsl.action.en}
            </Box>
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
            {actionOptions.map((option) => (
              <Button
                key={option.value}
                variant={posture?.value === option.value ? 'contained' : 'outlined'}
                onClick={() => handleButtonClick(option, setPosture)}
                sx={{ flex: '1 1 auto' }}
              >
                {option.label}
              </Button>
            ))}
          </Box>
        </>
      )}

      {/* BODY TOGGLE */}
      { isAdult && (
        <>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ cursor: 'pointer', userSelect: 'none' }}
            onClick={() => setIsBodyOpen(!isBodyOpen)}
          >
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center" gap={1}>
            {trnsl.bodySettings?.[language] || trnsl.bodySettings.en}
            </Box>
            {isBodyOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>
          </Typography>
          {isBodyOpen && (
            <>
              <Typography variant="h6" gutterBottom>
                <Box display="flex" alignItems="center" gap={1}>
                  <SelfImprovementIcon />
                  {trnsl.bodyType?.[language] || trnsl.bodyType.en}
                </Box>
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
                {(gender === 'male' ? bodyMaleOptions : bodyFemaleOptions).map((option) => (
                  <Button
                    key={option.value}
                    variant={bodyType?.value === option.value ? 'contained' : 'outlined'}
                    onClick={() => handleButtonClick(option, setBodyType)}
                    sx={{ flex: '1 1 auto' }}
                  >
                    {option.label}
                  </Button>
                ))}
              </Box>

              { isAdult && (
                  <>
                    <Typography variant="h6" gutterBottom>
                      <Box display="flex" alignItems="center" gap={1}>
                        {gender === 'male' ? (trnsl.male_size?.[language] || trnsl.male_size.en) : (trnsl.female_size?.[language] || trnsl.female_size.en)}
                      </Box>
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
                      {relevantCupSizeOptions.map((option) => (
                        <Button
                          key={option.value}
                          variant={cupSize?.value === option.value ? 'contained' : 'outlined'}
                          onClick={() => handleButtonClick(option, setCupSize)}
                          sx={{flex: '1 1 auto'}}
                        >
                          {option.label}
                        </Button>
                      ))}
                    </Box>
                  </>
                )
              }

              <Typography variant="h6" gutterBottom>
                <Box display="flex" alignItems="center" gap={1}>
                  <SelfImprovementIcon />
                  {trnsl.hairColor?.[language] || trnsl.hairColor.en}
                </Box>
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
                {hairColorOptions.map((option) => (
                  <Button
                    key={option.value}
                    variant={hairColor?.value === option.value ? 'contained' : 'outlined'}
                    onClick={() => handleButtonClick(option, setHairColor)}
                    sx={{ flex: '1 1 auto' }}
                  >
                    {option.label}
                  </Button>
                ))}
              </Box>
            </>
          )}
        </>
      )}

      {/* ADDITIONAL SETTINGS TOGGLE */}
      <Typography
        variant="h6"
        gutterBottom
        sx={{ cursor: 'pointer', userSelect: 'none' }}
        onClick={() => setIsAdditionalOpen(!isAdditionalOpen)}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={1}>
          {trnsl.additional?.[language] || trnsl.additional.en}
          </Box>
          {isAdditionalOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Box>
      </Typography>
      {isAdditionalOpen && (
        <>
          {/* Photoset Type Selection */}
          <Typography variant="h6" gutterBottom>
            <Box display="flex" alignItems="center" gap={1}>
              <CenterFocusStrongIcon />
              {trnsl.style?.[language] || trnsl.style.en}
            </Box>
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
            {photosetTypeList.map((option) => (
              <Button
                key={option.value}
                variant={photoType?.value === option.value ? 'contained' : 'outlined'}
                onClick={() => handleButtonClick(option, setPhotoType)}
                sx={{ flex: '1 1 auto' }}
              >
                {option.label}
              </Button>
            ))}
          </Box>

          {/* Framing Selection */}
          <Typography variant="h6" gutterBottom>
            <Box display="flex" alignItems="center" gap={1}>
              <CropFreeIcon />
              {trnsl.framing?.[language] || trnsl.framing.en}
            </Box>
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
            {framingOptions.map((option) => (
              <Button
                key={option.value}
                variant={framing?.value === option.value ? 'contained' : 'outlined'}
                onClick={() => handleButtonClick(option, setFraming)}
                sx={{ flex: '1 1 auto' }}
              >
                {option.label}
              </Button>
            ))}
          </Box>

          {/* Angle Selection */}
          <Typography variant="h6" gutterBottom>
            <Box display="flex" alignItems="center" gap={1}>
              <ThreeDRotationIcon />
              {trnsl.angle?.[language] || trnsl.angle.en}
            </Box>
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
            {angleOptions.map((option) => (
              <Button
                key={option.value}
                variant={angle?.value === option.value ? 'contained' : 'outlined'}
                onClick={() => handleButtonClick(option, setAngle)}
                sx={{ flex: '1 1 auto' }}
              >
                {option.label}
              </Button>
            ))}
          </Box>

          {/* Lighting Selection */}
          <Typography variant="h6" gutterBottom>
            <Box display="flex" alignItems="center" gap={1}>
              <WbIncandescentIcon />
              {trnsl.lighting?.[language] || trnsl.lighting.en}
            </Box>
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
            {lightingOptions.map((option) => (
              <Button
                key={option.value}
                variant={lighting?.value === option.value ? 'contained' : 'outlined'}
                onClick={() => handleButtonClick(option, setLighting)}
                sx={{ flex: '1 1 auto' }}
              >
                {option.label}
              </Button>
            ))}
          </Box>

          <Typography variant="h6" gutterBottom>
            <Box display="flex" alignItems="center" gap={1}>
              <CameraIcon />
              {trnsl.camera?.[language] || trnsl.camera.en}
            </Box>
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
            {cameraOptions.map((option) => (
              <Button
                key={option.value}
                variant={camera?.value === option.value ? 'contained' : 'outlined'}
                onClick={() => handleButtonClick(option, setCamera)}
                sx={{ flex: '1 1 auto' }}
              >
                {option.label}
              </Button>
            ))}
          </Box>

          {miscOptions.length > 0 && (
            <>
              <Typography variant="h6" gutterBottom>
                <Box display="flex" alignItems="center" gap={1}>
                  {trnsl.misc?.[language] || trnsl.misc.en}
                </Box>
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
                {miscOptions.map((option) => (
                  <Button
                    key={option.value}
                    variant={misc?.value === option.value ? 'contained' : 'outlined'}
                    onClick={() => handleButtonClick(option, setMisc)}
                    sx={{ flex: '1 1 auto' }}
                  >
                    {option.label}
                  </Button>
                ))}
              </Box>
            </>
          )}
        </>
      )}

      {/* The dialog for explaining pulid vs no_pulid */}
      <Dialog open={pulidDialogOpen} onClose={closePulidDialog}>
        <DialogTitle sx={{
          backgroundColor: theme.palette.background.paper
        }}>
          {trnsl.pulid_explain_title?.[language] || trnsl.pulid_explain_title.en}
        </DialogTitle>
        <DialogContent dividers 
          sx={{
            backgroundColor: theme.palette.background.paper
          }}
        >
          <Box sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 2,
            justifyContent: 'center',
            mb: 2
          }}>
            <Box sx={{ flex: '0 0 45%', textAlign: 'center' }}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/nopulid.png`}
                alt="Artistic Example"
                style={{ maxWidth: '100%', borderRadius: 8 }}
              />
              <Typography variant="body2" sx={{ mt: 1 }}>
                {trnsl.artistic?.[language] || trnsl.artistic.en}
              </Typography>
            </Box>
            <Box sx={{ flex: '0 0 45%', textAlign: 'center' }}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/pulid.png`}
                alt="Realistic Example"
                style={{ maxWidth: '100%', borderRadius: 8 }}
              />
              <Typography variant="body2" sx={{ mt: 1 }}>
                {trnsl.realistic?.[language] || trnsl.realistic.en}
              </Typography>
            </Box>
          </Box>

          <DialogContentText variant="body1">
            <Typography sx={{ mb: 1 }}>
              {trnsl.pulid_explain_text[0]?.[language] || trnsl.pulid_explain_text[0].en}
            </Typography>
            <Typography>
              {trnsl.pulid_explain_text[1]?.[language] || trnsl.pulid_explain_text[1].en}
            </Typography>
          </DialogContentText>

          <Button
            onClick={closePulidDialog}
            variant="contained"
            color="secondary"
            fullWidth
            sx={{marginTop: 2}}
          >
            {trnsl.pulid_explain_confirm?.[language] || trnsl.pulid_explain_confirm.en}
          </Button>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
