// UploadBlock.js
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  styled,
  IconButton,
  Snackbar,
  Alert,
  LinearProgress,
} from '@mui/material';import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import * as tf from '@tensorflow/tfjs';
import * as blazeface from '@tensorflow-models/blazeface';

const UploadContainer = styled(Box)(({ theme, isUploaded, isDragOver }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  border: `2px dashed ${isDragOver ? theme.palette.primary.main : theme.palette.divider}`,
  borderRadius: '10px',
  padding: theme.spacing(2),
  width: '100%',
  minHeight: '250px',
  margin: '0 auto',
  overflow: 'hidden',
  backgroundColor: isDragOver ? theme.palette.action.hover : 'transparent',
  transition: 'border-color 0.3s, background-color 0.3s',
}));

const ImagePreviewContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
  maxWidth: '100%',
  overflowX: 'auto',
  paddingBottom: theme.spacing(1),
}));

const ImagePreview = styled(Box)(({ theme }) => ({
  borderRadius: '16px',
  overflow: 'hidden',
  minWidth: '100px',
  minHeight: '100px',
  maxWidth: '100px',
  maxHeight: '100px',
  position: 'relative',
  backgroundColor: theme.palette.grey[200],
}));

const AnimationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const translations = {
  en: {
    uploadTitle: 'Upload up to 5 Photos',
    uploadSubtitle: '.jpg or .png, max 10MB each',
    uploadNote: 'All pictures must have the same person on it.',
    uploadGuide: 'Person on the image should look at the camera, this ensures the best result.',
    loadingModel: 'Loading face detection model...',
    faceModelError: 'Face detection model is loading. Please wait.',
    maxImagesError: 'You can only upload up to 5 images.',
    oversizedError: 'Some files exceed the 10MB size limit and were not uploaded.',
    noFaceErrorSingle: 'One image did not contain a face and was not uploaded.',
    noFaceErrorMultiple: '{count} images did not contain faces and were not uploaded.',
    processingError: 'An error occurred while processing images.',
    uploadButton: 'Upload Photos',
  },
  es: {
    uploadTitle: 'Sube hasta 5 fotos',
    uploadSubtitle: '.jpg o .png, máximo 10MB cada una',
    uploadNote: 'Todas las fotos deben tener la misma persona.',
    uploadGuide: 'La persona en la imagen debe mirar a la cámara para garantizar el mejor resultado.',
    loadingModel: 'Cargando modelo de detección facial...',
    faceModelError: 'El modelo de detección facial se está cargando. Por favor espera.',
    maxImagesError: 'Solo puedes subir hasta 5 imágenes.',
    oversizedError: 'Algunos archivos superan el límite de tamaño de 10MB y no se subieron.',
    noFaceErrorSingle: 'Una imagen no contenía un rostro y no se subió.',
    noFaceErrorMultiple: '{count} imágenes no contenían rostros y no se subieron.',
    processingError: 'Ocurrió un error al procesar las imágenes.',
    uploadButton: 'Subir Fotos',
  },
  pt: {
    uploadTitle: 'Carregue até 5 fotos',
    uploadSubtitle: '.jpg ou .png, máximo 10MB cada',
    uploadNote: 'Todas as fotos devem ter a mesma pessoa.',
    uploadGuide: 'A pessoa na imagem deve olhar para a câmera para garantir o melhor resultado.',
    loadingModel: 'Carregando modelo de detecção facial...',
    faceModelError: 'O modelo de detecção facial está carregando. Por favor, aguarde.',
    maxImagesError: 'Você só pode carregar até 5 imagens.',
    oversizedError: 'Alguns arquivos excedem o limite de tamanho de 10MB e não foram carregados.',
    noFaceErrorSingle: 'Uma imagem não continha um rosto e não foi carregada.',
    noFaceErrorMultiple: '{count} imagens não continham rostos e não foram carregadas.',
    processingError: 'Ocorreu um erro ao processar as imagens.',
    uploadButton: 'Carregar Fotos',
  },
  de: {
    uploadTitle: 'Lade bis zu 5 Fotos hoch',
    uploadSubtitle: '.jpg oder .png, maximal 10MB pro Datei',
    uploadNote: 'Alle Bilder müssen dieselbe Person zeigen.',
    uploadGuide: 'Die Person auf dem Bild sollte in die Kamera schauen, um das beste Ergebnis zu erzielen.',
    loadingModel: 'Lade Gesichtserkennungsmodell...',
    faceModelError: 'Das Gesichtserkennungsmodell wird geladen. Bitte warten.',
    maxImagesError: 'Sie können nur bis zu 5 Bilder hochladen.',
    oversizedError: 'Einige Dateien überschreiten die 10MB-Grenze und wurden nicht hochgeladen.',
    noFaceErrorSingle: 'Ein Bild enthielt kein Gesicht und wurde nicht hochgeladen.',
    noFaceErrorMultiple: '{count} Bilder enthielten keine Gesichter und wurden nicht hochgeladen.',
    processingError: 'Beim Verarbeiten der Bilder ist ein Fehler aufgetreten.',
    uploadButton: 'Fotos Hochladen',
  },
  ja: {
    uploadTitle: '最大5枚の写真をアップロード',
    uploadSubtitle: '.jpgまたは.png、最大10MBまで',
    uploadNote: 'すべての写真に同じ人物が写っている必要があります。',
    uploadGuide: '画像の人物はカメラを見ている必要があります。これにより最良の結果が得られます。',
    loadingModel: '顔検出モデルを読み込んでいます...',
    faceModelError: '顔検出モデルを読み込んでいます。お待ちください。',
    maxImagesError: '最大5枚の画像しかアップロードできません。',
    oversizedError: '一部のファイルが10MBを超えており、アップロードされませんでした。',
    noFaceErrorSingle: '顔が検出されなかった画像が1枚あり、アップロードされませんでした。',
    noFaceErrorMultiple: '{count}枚の画像に顔が検出されず、アップロードされませんでした。',
    processingError: '画像の処理中にエラーが発生しました。',
    uploadButton: '写真をアップロード',
  },
  ko: {
    uploadTitle: '최대 5장의 사진 업로드',
    uploadSubtitle: '.jpg 또는 .png, 각 파일 최대 10MB',
    uploadNote: '모든 사진에는 동일한 사람이 있어야 합니다.',
    uploadGuide: '이미지에 있는 사람이 카메라를 보고 있어야 최상의 결과를 얻을 수 있습니다.',
    loadingModel: '얼굴 인식 모델을 로드 중입니다...',
    faceModelError: '얼굴 인식 모델이 로드 중입니다. 잠시 기다려 주십시오.',
    maxImagesError: '최대 5장까지만 업로드할 수 있습니다.',
    oversizedError: '일부 파일이 10MB 크기 제한을 초과하여 업로드되지 않았습니다.',
    noFaceErrorSingle: '얼굴이 없는 이미지 한 장이 업로드되지 않았습니다.',
    noFaceErrorMultiple: '얼굴이 없는 이미지 {count}장이 업로드되지 않았습니다.',
    processingError: '이미지 처리 중 오류가 발생했습니다.',
    uploadButton: '사진 업로드',
  },
  zh: {
    uploadTitle: '上传最多5张照片',
    uploadSubtitle: '.jpg或.png，每张最大10MB',
    uploadNote: '所有照片必须是同一个人。',
    uploadGuide: '图片中的人应该看着镜头，这能确保最佳效果。',
    loadingModel: '加载面部检测模型中...',
    faceModelError: '面部检测模型正在加载，请稍候。',
    maxImagesError: '最多只能上传5张图片。',
    oversizedError: '某些文件超过了10MB大小限制，未上传。',
    noFaceErrorSingle: '一张图片未检测到面部，未上传。',
    noFaceErrorMultiple: '{count}张图片未检测到面部，未上传。',
    processingError: '处理图片时发生错误。',
    uploadButton: '上传照片',
  },
  hi: {
    uploadTitle: '5 तस्वीरें तक अपलोड करें',
    uploadSubtitle: '.jpg या .png, अधिकतम 10MB प्रति',
    uploadNote: 'सभी तस्वीरों में एक ही व्यक्ति होना चाहिए।',
    uploadGuide: 'छवि में व्यक्ति को कैमरे की ओर देखना चाहिए, इससे सबसे अच्छा परिणाम मिलता है।',
    loadingModel: 'चेहरे की पहचान मॉडल लोड हो रहा है...',
    faceModelError: 'चेहरे की पहचान मॉडल लोड हो रहा है। कृपया प्रतीक्षा करें।',
    maxImagesError: 'आप केवल 5 छवियां तक अपलोड कर सकते हैं।',
    oversizedError: 'कुछ फ़ाइलें 10MB आकार की सीमा से अधिक हैं और अपलोड नहीं की गईं।',
    noFaceErrorSingle: 'एक छवि में चेहरा नहीं था और इसे अपलोड नहीं किया गया।',
    noFaceErrorMultiple: '{count} छवियों में चेहरे नहीं थे और उन्हें अपलोड नहीं किया गया।',
    processingError: 'छवियों को संसाधित करते समय एक त्रुटि हुई।',
    uploadButton: 'तस्वीरें अपलोड करें',
  },
  ru: {
    uploadTitle: '1 Человек, до 5 фото',
    uploadSubtitle: '.jpg или .png, максимум 10 МБ каждая',
    uploadNote: 'На всех фотографиях должен быть один и тот же человек.',
    uploadGuide: 'Человек на изображении должен смотреть в камеру для достижения лучшего результата.',
    loadingModel: 'Загрузка модели распознавания лиц...',
    faceModelError: 'Модель распознавания лиц загружается. Пожалуйста, подождите.',
    maxImagesError: 'Вы можете загрузить не более 5 изображений.',
    oversizedError: 'Некоторые файлы превышают лимит размера 10 МБ и не были загружены.',
    noFaceErrorSingle: 'Одно изображение не содержало лица и не было загружено.',
    noFaceErrorMultiple: '{count} изображений не содержали лица и не были загружены.',
    processingError: 'Произошла ошибка при обработке изображений.',
    uploadButton: 'Загрузить фотографии',
  },
  uploadIssue: [
    {
      en: "Having issues with uploading? Try",
      es: "¿Tienes problemas con la carga? Prueba",
      pt: "Está com problemas para fazer upload? Tente",
      de: "Probleme beim Hochladen? Versuchen Sie",
      ja: "アップロードに問題がありますか？試してください",
      ko: "업로드에 문제가 있습니까? 시도해보세요",
      zh: "上传遇到问题？尝试",
      ru: "Проблемы с загрузкой? Попробуйте",
      hi: "अपलोड करने में समस्या हो रही है? आजमाएं"
    },
    {
      en: "web version",
      es: "la versión web",
      pt: "a versão web",
      de: "die Web-Version",
      ja: "ウェブ版",
      ko: "웹 버전",
      zh: "网页版",
      ru: "веб-версию",
      hi: "वेब संस्करण"
    }
  ]
};


export default function UploadBlock({ images, setImages, isTelegramWebApp = false, lang = 'en', linkArgs = null }) {
  const [showAnimation, setShowAnimation] = useState(true);
  const [isDragOver, setIsDragOver] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'error',
  });
  const [model, setModel] = useState(null);
  const [isModelLoading, setIsModelLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [firstAndroidAttempt, setFirstAndroidAttempt] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);
  const [fallbackLink, setFallBackLink] = useState('https://app.lumipic.pro')

  useEffect(() => {
    if (images.length === 0) {
      setShowAnimation(true);
    } else {
      setShowAnimation(false);
    }
  }, [images]);

  useEffect(() => {
    const link = "https://app.lumipic.pro" + (linkArgs ? linkArgs : "");
    setFallBackLink(link);
  }, [fallbackLink, linkArgs])

  useEffect(() => {
    const checkDeviceData = async () => {
      if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.platform) {
        const platform = window.Telegram.WebApp.platform;
        if (platform.toLowerCase().includes('android')) setIsAndroid(true);
      }
    };

    checkDeviceData();
  }, [isAndroid]);

  useEffect(() => {
    // Cleanup function to revoke object URLs
    return () => {
      images.forEach((image) => URL.revokeObjectURL(image.preview));
    };
  }, [images]);

  useEffect(() => {
    const loadModel = async () => {
      try {
        setIsModelLoading(true);
        setIsProcessing(true);
        setLoadingProgress(null);

        const loadedModel = await blazeface.load();
        setModel(loadedModel);
      } catch (error) {
        setSnackbar({
          open: true,
          message: 'Failed to load face detection model.',
          severity: 'error',
        });
        if (isTelegramWebApp) {
          window.Telegram.WebApp.showAlert('Failed to load face detection model.')
        }
      } finally {
        setIsModelLoading(false);
        setIsProcessing(false);
      }
    };
    loadModel();
  }, []);

  const handleUpload = (event) => {
    const files = Array.from(event.target.files);
    handleFiles(files);
  };

  const handleFiles = async (files) => {

    if (isModelLoading || !model) {
      setSnackbar({
        open: true,
        message: (translations?.[lang]?.faceModelError || translations.en.faceModelError),
        severity: 'warning',
      });
      if (isTelegramWebApp) {
        window.Telegram.WebApp.showAlert(translations?.[lang]?.faceModelError || translations.en.faceModelError)
      }
      return;
    }

    const validFiles = files.filter((file) => {
      const isValidType = file.type === 'image/jpeg' || file.type === 'image/png';
      const isValidSize = file.size <= 30 * 1024 * 1024; // 3MB limit
      return isValidType && isValidSize;
    });

    if (validFiles.length + images.length > 5) {
      setSnackbar({
        open: true,
        message: (translations?.[lang]?.maxImagesError || translations.en.maxImagesError),
        severity: 'error',
      });
      if (isTelegramWebApp) {
        window.Telegram.WebApp.showAlert(translations?.[lang]?.maxImagesError || translations.en.maxImagesError)
      }
      return;
    }

    const oversizedFiles = files.filter((file) => file.size > 30 * 1024 * 1024);
    if (oversizedFiles.length > 0) {
      setSnackbar({
        open: true,
        message: (translations?.[lang]?.oversizedError || translations.en.oversizedError),
        severity: 'warning',
      });
      if (isTelegramWebApp) {
        window.Telegram.WebApp.showAlert(translations?.[lang]?.oversizedError || translations.en.oversizedError);
      }
    }

    const newImages = [];
    let faceNotDetectedCount = 0;

    setIsProcessing(true);
    setLoadingProgress(0);

    const totalFiles = validFiles.length;
    let processedFiles = 0;

    for (const file of validFiles) {
      try {
        const result = await processAndValidateImage(file);
        if (result) {
          newImages.push(result);
        } else {
          faceNotDetectedCount++;
        }
      } catch (error) {
        console.error('Error processing image:', error);
        setSnackbar({
          open: true,
          message: (translations?.[lang]?.processingError || translations.en.processingError),
          severity: 'error',
        });
        if (isTelegramWebApp) {
          window.Telegram.WebApp.showAlert(translations?.[lang]?.processingError || translations.en.processingError);
        }
      } finally {
        processedFiles++;
        setLoadingProgress(Math.round((processedFiles / totalFiles) * 100));
      }
    }

    setImages((prevImages) => [...prevImages, ...newImages]);

    setIsProcessing(false);
    setLoadingProgress(null);

    if (faceNotDetectedCount > 0) {
      const message =
        faceNotDetectedCount === 1
          ? (translations?.[lang]?.noFaceErrorSingle || translations.en.noFaceErrorSingle)
          : (translations?.[lang]?.noFaceErrorMultiple || translations.en.noFaceErrorMultiple).replace("{count}", faceNotDetectedCount);

      if (isTelegramWebApp && window.Telegram) {
        window.Telegram.WebApp.showPopup({
          title: 'No Face Detected',
          message: message,
          buttons: [{ type: 'close', text: 'OK' }],
        });
      } else {
        setSnackbar({
          open: true,
          message: message,
          severity: 'warning',
        });
        if (isTelegramWebApp) {
          window.Telegram.WebApp.showAlert(message);
        }
      }
    }
  };

  const processAndValidateImage = async (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      const reader = new FileReader();

      reader.onload = (e) => {
        img.src = e.target.result;
      };

      img.onload = async () => {
        try {
          // Resize the image and get a compressed Blob
          const canvas = document.createElement('canvas');
          const maxSize = 512;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxSize) {
              height = Math.round((height *= maxSize / width));
              width = maxSize;
            }
          } else {
            if (height > maxSize) {
              width = Math.round((width *= maxSize / height));
              height = maxSize;
            }
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          // Get compressed Blob
          canvas.toBlob(
            async (blob) => {
              if (!blob) {
                reject(new Error('Canvas is empty'));
                return;
              }
              // Face detection
              const imgTensor = tf.browser.fromPixels(canvas);
              const predictions = await model.estimateFaces(imgTensor, false);
              imgTensor.dispose();

              if (predictions.length > 0) {
                // Face detected
                // Create a File from the Blob
                const compressedFile = new File([blob], file.name, { type: blob.type });
                // Create a preview URL
                const preview = URL.createObjectURL(blob);
                resolve({ file: compressedFile, preview });
              } else {
                // No face detected
                resolve(null);
              }
            },
            'image/jpeg',
            0.7 // Adjust quality as needed
          );
        } catch (error) {
          reject(error);
        }
      };

      img.onerror = () => {
        reject(new Error('Failed to load image'));
      };

      reader.readAsDataURL(file);
    });
  };

  const handleDelete = (index) => {
    // Revoke the object URL to free memory
    URL.revokeObjectURL(images[index].preview);
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handlePaste = (event) => {
    const items = event.clipboardData.items;
    const files = [];
    for (let item of items) {
      if (item.kind === 'file') {
        const file = item.getAsFile();
        if (file) {
          files.push(file);
        }
      }
    }
    if (files.length > 0) {
      handleFiles(files);
    }
  };

  useEffect(() => {
    window.addEventListener('paste', handlePaste);
    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  }, [images, model]);

  const handleClick = () => {
    if (isAndroid && isTelegramWebApp) {
      // If this is the first attempt on Android in Telegram
      if (!firstAndroidAttempt) {
        setFirstAndroidAttempt(true);
        // Do the usual "click" trick
        document.getElementById('hiddenFileInput').click();
      } else {
        // Second (or subsequent) attempt, do NOT do the .click() trick
        // The user can manually click the real input, or we show a fallback message
        // Maybe you do nothing here, or instruct them to click the fallback link, etc.
      }
    } else {
      // Normal scenario
      document.getElementById('hiddenFileInput').click();
    }
  };
  
  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!isDragOver) setIsDragOver(true);
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(false);
    const files = Array.from(event.dataTransfer.files);
    handleFiles(files);
  };

  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <>
      <UploadContainer
        isUploaded={images.length > 0}
        isDragOver={isDragOver}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        //onClick={handleClick}
      >
        {showAnimation && (
          <AnimationContainer>
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/other/face.png`}
              alt="Upload Animation"
              style={{ width: '50%', maxWidth: '200px' }}
            />
          </AnimationContainer>
        )}
        <Typography variant="h4" align="center">
          {images.length === 0 ? (translations?.[lang].uploadTitle || translations.en.uploadTitle) : `Upload Photos (${images.length}/5)`}
        </Typography>
        <Typography variant="body2" align="center" marginTop={1}>
          {translations?.[lang].uploadNote || translations.en.uploadNote}
        </Typography>
        {isModelLoading && (
          <Typography variant="body2" color="textSecondary" align="center">
            {translations?.[lang].loadingModel || translations.en.loadingModel}
          </Typography>
        )}
        {(isAndroid && isTelegramWebApp) ? (
          <Button
          variant="contained"
          component="label"
          startIcon={<UploadIcon />}
          sx={{ marginTop: 2, position: 'relative', overflow: 'hidden' }}
          onClick={handleClick}
        >
          {translations?.[lang].uploadButton || translations.en.uploadButton}
          {/* Hidden file input */}
          <input
            id="hiddenFileInput"
            type="file"
            hidden
            multiple
            accept="image/*"
            onChange={handleUpload}
          />
          </Button>
        ): 
        (
          <Button
            variant="contained"
            component="label"
            startIcon={<UploadIcon />}
            sx={{ marginTop: 2, position: 'relative', overflow: 'hidden' }}

          >
            {translations?.[lang].uploadButton || translations.en.uploadButton}
            <input
              type="file"
              hidden
              multiple
              accept="image/*"
              onChange={handleUpload}
            />
          </Button>
        )}

        {isAndroid && isTelegramWebApp && firstAndroidAttempt && (!images || images.length === 0) && (
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ marginTop: 1, textAlign: 'center' }}
          >
            {translations.uploadIssue[0][lang] || translations.uploadIssue[0].en}{' '}
            <a
              href={fallbackLink || '#'}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#00bcd4' }}
            >
              {translations.uploadIssue[1][lang] || translations.uploadIssue[1].en}
            </a>
          </Typography>
        )}

        <ImagePreviewContainer>
          {images.map((image, index) => (
            <ImagePreview key={index}>
              <img
                src={image.preview}
                alt={`Uploaded #${index + 1}`}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent event from bubbling up
                  handleDelete(index);
                }}
                sx={{
                  position: 'absolute',
                  top: 4,
                  right: 4,
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  color: 'white',
                }}
              >
                <DeleteIcon />
              </IconButton>
            </ImagePreview>
          ))}
        </ImagePreviewContainer>

        {/* Progress Bar */}
        {isProcessing && (
            <Box sx={{ width: '100%', mt: 2 }}>
              {loadingProgress !== null ? (
                <LinearProgress variant="determinate" value={loadingProgress} />
              ) : (
                <LinearProgress />
              )}
            </Box>
        )}
      </UploadContainer>

      {/* Snackbar for Notifications */}
      {!isTelegramWebApp && (
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}