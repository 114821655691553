// SignUpDialog.js
import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CssBaseline,
  Divider,
  FormControlLabel,
  FormLabel,
  FormControl,
  Link,
  TextField,
  Typography,
  Stack,
  Card as MuiCard,
  IconButton,
  InputAdornment,
  Dialog,
  DialogContent,
  Skeleton,
  Fade,
  Chip,
  Container,
  useMediaQuery,
  ImageListItem,
  ImageList,
  Card
} from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import XIcon from '@mui/icons-material/X'; // Twitter (X)
import getSignUpTheme from '../pages/theme/theme/getSignUpTheme';
import { useNavigate, useLocation } from 'react-router-dom';

import LanguageSelection from './LanguageSelection';
import OrientationSelection from './OrientationSelection';
import { Send, Share } from '@mui/icons-material';

const SignUpContainer = styled(Stack)(({ theme }) => ({
  minHeight: '100%',
  ...theme.applyStyles('dark'),
}));

const t = {
  card_title: {
    en: "Get $AMOUNT 💎 free for every new user!",
    ru: "$AMOUNT 💎 бесплатно за каждого пользователя!",
    es: "¡Obtén $AMOUNT 💎 gratis por cada usuario nuevo!",
    hi: "$AMOUNT 💎 मुफ्त पाएं प्रत्येक नए उपयोगकर्ता के लिए!",
    zh: "每位新用户可获得 $AMOUNT 💎 免费奖励！",
    de: "Erhalte $AMOUNT 💎 kostenlos für jeden neuen Benutzer!",
    pt: "Ganhe $AMOUNT 💎 grátis para cada novo usuário!",
    ja: "新規ユーザーごとに $AMOUNT 💎 を無料でゲット！",
    ko: "새로운 사용자당 $AMOUNT 💎 무료로 받으세요!"
  },
  dialog: {
    title: {
      en: "New users = Free Credits",
      ru: "Новые пользователи = Бесплатные кредиты",
      es: "Nuevos usuarios = Créditos gratis",
      hi: "नए उपयोगकर्ता = मुफ्त क्रेडिट",
      zh: "新用户 = 免费积分",
      de: "Neue Benutzer = Kostenlose Credits",
      pt: "Novos usuários = Créditos grátis",
      ja: "新規ユーザー = 無料クレジット",
      ko: "새 사용자 = 무료 크레딧"
    },
    description: {
      en: [
        "Press the button below to select groups to share the message with your invite link",
        "Each new user will get you $AMOUNT 💎"
      ],
      ru: [
        "Нажмите кнопку ниже, чтобы выбрать группы для отправки сообщения с вашей пригласительной ссылкой",
        "Каждый новый пользователь принесет вам $AMOUNT 💎"
      ],
      es: [
        "Presiona el botón de abajo para seleccionar grupos y compartir el mensaje con tu enlace de invitación",
        "Cada usuario nuevo te dará $AMOUNT 💎"
      ],
      hi: [
        "निमंत्रण लिंक के साथ संदेश साझा करने के लिए समूहों को चुनने के लिए नीचे बटन दबाएं",
        "प्रत्येक नया उपयोगकर्ता आपको $AMOUNT 💎 देगा"
      ],
      zh: [
        "按下面的按钮选择群组，通过您的邀请链接分享消息",
        "每位新用户可让您获得 $AMOUNT 💎"
      ],
      de: [
        "Drücken Sie die Schaltfläche unten, um Gruppen auszuwählen, mit denen Sie die Nachricht mit Ihrem Einladungslink teilen möchten",
        "Jeder neue Benutzer bringt Ihnen $AMOUNT 💎"
      ],
      pt: [
        "Pressione o botão abaixo para selecionar grupos para compartilhar a mensagem com seu link de convite",
        "Cada novo usuário lhe dará $AMOUNT 💎"
      ],
      ja: [
        "招待リンクでメッセージを共有するグループを選択するには、下のボタンを押してください",
        "新規ユーザーごとに $AMOUNT 💎 を獲得できます"
      ],
      ko: [
        "초대 링크로 메시지를 공유할 그룹을 선택하려면 아래 버튼을 누르세요",
        "새 사용자는 $AMOUNT 💎 를 얻을 수 있습니다"
      ]
    },
    button: {
      en: "Share",
      ru: "Поделиться",
      es: "Compartir",
      hi: "साझा करें",
      zh: "分享",
      de: "Teilen",
      pt: "Compartilhar",
      ja: "共有する",
      ko: "공유"
    }
  }
};

export default function TelegramShare({mode, adult = false, lang = 'en', chatId, perUser = 1}) {
  const defaultTheme = createTheme({ palette: { mode: mode } });
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const [openTGShareDialog, setOpenTGShareDialog] = useState(false);
  const [msgId, setMsgId] = useState(null);

  const signUpTheme = createTheme(getSignUpTheme(mode));

  const fetchMsgId = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/tg-share?lang=${lang}&chatId=${chatId}&adult=${adult}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
  
      const result = await response.json();
      console.log(result.msgId);
      setMsgId(result.msgId)
      return
    }
    catch(error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (chatId && !msgId && openTGShareDialog) {
      fetchMsgId();
    }
  }, [chatId, adult, lang, openTGShareDialog]);
  
  return (
    <ThemeProvider theme={signUpTheme}>
      <CssBaseline />

      <Button
        sx={{
          width: "100%",
        }}
        variant="contained"
        color="secondary"
        onClick={() => setOpenTGShareDialog(true)}
      >
        <Typography variant="button">{(t.card_title?.[lang] || t.card_title.en).replace("$AMOUNT", perUser)}</Typography>
      </Button>
      <Dialog open={openTGShareDialog} onClose={() => setOpenTGShareDialog(false)} 
        fullScreen={useMediaQuery(signUpTheme.breakpoints.down('md'))} 
        PaperProps={{
          sx: {
            [signUpTheme.breakpoints.up('lg')]: {
              width: '30vw',
              margin: 'auto',
              // Example: top corners rounded, bottom corners straight
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            },
            [signUpTheme.breakpoints.down('lg')]: {
              marginTop: 20,
            },
          },
        }}
        sx={{
        zIndex: 9998,
        '& .MuiDialog-paper': {
          // If you want to ensure no other overrides cause rounding at the bottom:
          overflow: 'hidden', 
        },
      }}>
        {/* Close button */}
        <IconButton
          onClick={() => setOpenTGShareDialog(false)}
          sx={{ position: 'absolute', right: 10, top: 10, backgroundColor: "transparent", border: "none" }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent
          dividers
          sx={{ backgroundColor: signUpTheme.palette.background.paper }}
        >
          <SignUpContainer spacing={2} marginTop={6}>
            <Box textAlign="center">
                <Box
                  component="img"
                  src={`${process.env.PUBLIC_URL}/assets/icons/webp/share.webp`}
                  alt="icon"
                  sx={{
                      width: "40%",        // 40% of parent container
                      display: "block",    // ensure it's treated like a block
                      margin: "0 auto",    // center horizontally
                  }}
                />
                <Typography variant="button" fontSize={20} sx={{ mt: 6 }}>
                  {t.dialog.title?.[lang] || t.dialog.title.en}
                </Typography>
                <Typography variant="body1" fontSize={16} sx={{ mt: 2 }}>
                  {t.dialog.description?.[lang]?.[0] || t.dialog.description.en[0]}
                </Typography>
                <Typography variant="body1" fontSize={16} sx={{ mt: 2 }}>
                  {(t.dialog.description?.[lang]?.[1] || t.dialog.description.en[1]).replace("$AMOUNT", perUser)}
                </Typography>
            </Box>
            
            <Button 
              onClick={() => { 
                console.log(msgId, chatId)
                window.Telegram.WebApp.shareMessage(msgId)
              }}
              fullWidth
              variant="contained"
              color="secondary"
              sx={{
                minHeight: 50, 
                borderRadius: 4, 
                fontSize: 26
              }}
              endIcon={<Send/>}
            >
              <Typography variant="button">
                {t.dialog.button?.[lang] || t.dialog.button.en}
              </Typography>
            </Button>
          </SignUpContainer>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}
