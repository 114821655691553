// SignIn.js
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import getSignUpTheme from '../theme/theme/getSignUpTheme';
import { GoogleIcon, TelegramIcon } from '../signup/CustomIcons';
import TemplateFrame from '../theme/TemplateFrameSigning';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Link from '@mui/material/Link';
import { useNavigate, useLocation } from 'react-router-dom';

import { auth, database } from '../../services/firebase';
import {
  GoogleAuthProvider,
  TwitterAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
  linkWithCredential,
} from 'firebase/auth';
import { ref, get } from 'firebase/database'; // Import Firebase Realtime Database functions
import XIcon from '@mui/icons-material/X';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    width: '450px',
  },
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  minHeight: '100%',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
  ...theme.applyStyles('dark'),
}));

const languageCodes = ['en', 'ru', 'es', 'pt', 'de', 'ja', 'ko', 'zh', 'hi'];
const t = {
  sign_up: {
    en: "Sign up",
    ru: "Регистрация",
    hi: "साइन अप करें",
    zh: "注册",
    de: "Registrieren",
    es: "Regístrate"
  },
  email: {
    en: "Email",
    ru: "Почта",
    hi: "ईमेल",
    zh: "电子邮件",
    de: "E-Mail",
    es: "Correo electrónico"
  },
  password: {
    en: "Password",
    ru: "Пароль",
    hi: "पासवर्ड",
    zh: "密码",
    de: "Passwort",
    es: "Contraseña"
  },
  forgot: {
    en: "Forgot Password?",
    ru: "Забыли пароль?",
    hi: "पासवर्ड भूल गए?",
    zh: "忘记密码？",
    de: "Passwort vergessen?",
    es: "¿Olvidaste tu contraseña?"
  },
  sign_in: {
    en: "Sign in",
    ru: "Войти",
    hi: "साइन इन करें",
    zh: "登录",
    de: "Anmelden",
    es: "Iniciar sesión"
  },
  or: {
    en: "or",
    ru: "или",
    hi: "या",
    zh: "或",
    de: "oder",
    es: "o"
  },
  google: {
    en: "Sign in with Google",
    ru: "Войти через Google",
    hi: "Google से साइन इन करें",
    zh: "通过 Google 登录",
    de: "Mit Google anmelden",
    es: "Iniciar sesión con Google"
  },
  x: {
    en: "Sign in with X",
    ru: "Войти через X",
    hi: "X से साइन इन करें",
    zh: "通过 X 登录",
    de: "Mit X anmelden",
    es: "Iniciar sesión con X"
  },
  tg: {
    en: "Sign in with Telegram",
    ru: "Войти через Telegram",
    hi: "Telegram से साइन इन करें",
    zh: "通过 Telegram 登录",
    de: "Mit Telegram anmelden",
    es: "Iniciar sesión con Telegram"
  },
  dontyet: {
    en: "Don't have an account?",
    ru: "Нет учетной записи?",
    hi: "क्या आपके पास खाता नहीं है?",
    zh: "还没有账户吗？",
    de: "Kein Konto?",
    es: "¿No tienes una cuenta?"
  },
  incorrect: {
    en: "Incorrect email or password. Please try again or use",
    ru: "Неверный адрес электронной почты или пароль. Пожалуйста, попробуйте еще раз или используйте",
    hi: "गलत ईमेल या पासवर्ड। कृपया फिर से प्रयास करें या उपयोग करें",
    zh: "邮箱或密码错误。请重试或使用",
    de: "Falsche E-Mail-Adresse oder Passwort. Bitte versuchen Sie es erneut oder verwenden Sie",
    es: "Correo electrónico o contraseña incorrectos. Por favor, inténtalo de nuevo o usa"
  }
};

export default function SignIn() {
  const [mode, setMode] = useState('light');
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const defaultTheme = createTheme({ palette: { mode } });
  const SignUpTheme = createTheme(getSignUpTheme(mode));
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [invalidCredentials, setInvalidCredentials] = useState(false);

  // Settings variables
  const [dmcaLink, setDmcaLink] = useState('');
  const [tosLink, setTosLink] = useState('');
  const [privacyLink, setPrivacyLink] = useState('');
  const [supportEmail, setSupportEmail] = useState('');
  const [mainBotLink, setMainBotLink] = useState('');

  const [language, setLanguage] = useState('en');

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settingsRef = ref(database, '/settings');
        const snapshot = await get(settingsRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          setDmcaLink(data.links?.dmca || '');
          setTosLink(data.links?.tos || '');
          setPrivacyLink(data.links?.privacy || '');
          setSupportEmail(data.links?.support_email || '');
          setMainBotLink(data.links?.main_bot || '');
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
      }
    };

    fetchSettings();

    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setMode(savedMode);
    } else {
      const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setMode(systemPrefersDark ? 'dark' : 'light');
    }

    const queryParams = new URLSearchParams(location.search);
    const lang = queryParams.get('lang')
    if (lang && languageCodes.includes(lang)) {
      setLanguage(lang);
    }
    else if (localStorage.getItem("language")) {
      setLanguage(localStorage.getItem('language'));
    }

    const token = localStorage.getItem('userToken');
    if (token) {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          navigate('/profile');
          return;
        }
      });
    }
  }, [navigate]);

  const toggleColorMode = () => {
    const newMode = mode === 'dark' ? 'light' : 'dark';
    setMode(newMode);
    localStorage.setItem('themeMode', newMode);
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      // Set persistence to browserLocalPersistence to keep the session alive
      await setPersistence(auth, browserLocalPersistence);

      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Save user token to local storage
      const token = await user.getIdToken();
      localStorage.setItem('userToken', token);

      if (result?.additionalUserInfo?.isNewUser) {
        // Send data to server for new user
        await sendUserDataToServer(user.uid, token);
      }

      navigate('/create');
    } catch (error) {
      console.error('Error with Google sign-in or sign-up:', error);
    }
  };

  async function handleTwitterSignIn() {
    const provider = new TwitterAuthProvider();
    try {
      await setPersistence(auth, browserLocalPersistence);
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
  
      const token = await user.getIdToken();
      localStorage.setItem('userToken', token);
  
      // If new user, send data to server
      if (result?.additionalUserInfo?.isNewUser) {
        let twitterUsername = null;
        if (result.additionalUserInfo.profile && result.additionalUserInfo.profile.screen_name) {
          twitterUsername = result.additionalUserInfo.profile.screen_name;
        }
        const email = user.email || null;
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/user-signup`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify({
            uid: user.uid,
            email,
            twitter_username: twitterUsername
          }),
        });
  
        const serverResult = await response.json();
        if (!serverResult.success) {
          console.error('Server error:', serverResult.error);
        }
      }
  
      navigate('/create');
    } catch (error) {
      console.error('Error with Twitter sign-in:', error);
      if (error.code === 'auth/account-exists-with-different-credential') {
        const email = error.customData.email;
        if (email) {
          alert(`The email ${email} is already used by another account. Please sign in with that account first, then we will link your Twitter account.`);
          // Similar logic as above for linking.
        }
      }
    }
  }

  const sendUserDataToServer = async (uid, token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/user-signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          uid,
        }),
      });

      const result = await response.json();

      if (!result.success) {
        console.error('Error during server-side user setup:', result.error);
      }
    } catch (error) {
      console.error('Error sending user data to server:', error);
    }
  };

  const validateInputs = () => {
    const email = document.getElementById('email');
    const password = document.getElementById('password');

    let isValid = true;

    if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
      setEmailError(true);
      setEmailErrorMessage('Please enter a valid email address.');
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    if (!password.value || password.value.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage('Password must be at least 6 characters long.');
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }

    return isValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateInputs()) {
      return;
    }

    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;

    setPersistence(auth, browserLocalPersistence) // Set persistence for session
      .then(() => signInWithEmailAndPassword(auth, email, password))
      .then(async (userCredential) => {
        const user = userCredential.user;

        // Save user token to local storage
        const token = await user.getIdToken();
        localStorage.setItem('userToken', token);

        setInvalidCredentials(false);
        navigate('/create');
      })
      .catch((error) => {
        console.error('Error during sign in:', error);
        if (
          error.code === 'auth/user-not-found' ||
          error.code === 'auth/wrong-password' ||
          error.code === 'auth/invalid-credential'
        ) {
          setInvalidCredentials(true);
          setEmailError(true);
          setPasswordError(true);
          setEmailErrorMessage('Incorrect email or password.');
          setPasswordErrorMessage('Incorrect email or password.');
        }
      });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };

  return (
    <TemplateFrame
      toggleCustomTheme={toggleCustomTheme}
      showCustomTheme={showCustomTheme}
      mode={mode}
      toggleColorMode={toggleColorMode}
      dmcaLink={dmcaLink}
      tosLink={tosLink}
      privacyLink={privacyLink}
      supportEmail={supportEmail}
    >
      <ThemeProvider theme={showCustomTheme ? SignUpTheme : defaultTheme}>
        <CssBaseline enableColorScheme />
        <SignInContainer direction="column" justifyContent="space-between">
          <Card variant="outlined">
            <Typography
              component="h1"
              variant="h4"
              sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
            >
              {t.sign_in?.[language] || t.sign_in.en}
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
            >
              <FormControl>
                <FormLabel htmlFor="email">{t.email?.[language] || t.email.en}</FormLabel>
                <TextField
                  required
                  fullWidth
                  id="email"
                  placeholder="your@email.com"
                  name={t.email?.[language] || t.email.en}
                  autoComplete="email"
                  variant="outlined"
                  error={emailError}
                  helperText={emailErrorMessage}
                  color={emailError ? 'error' : 'primary'}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="password">{t.password?.[language] || t.password.en}</FormLabel>
                <TextField
                  required
                  fullWidth
                  name={t.password?.[language] || t.password.en}
                  placeholder="••••••"
                  type={passwordVisible ? 'text' : 'password'}
                  id="password"
                  autoComplete="current-password"
                  variant="outlined"
                  error={passwordError}
                  helperText={passwordErrorMessage}
                  color={passwordError ? 'error' : 'primary'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={togglePasswordVisibility} edge="end">
                          {passwordVisible ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {invalidCredentials && (
                  <Typography color="error" sx={{ mt: 1 }}>
                    {t.incorrect?.[language] || t.incorrect.en}{" "}{t.forgot?.[language] || t.forgot.en}
                  </Typography>
                )}
                {!invalidCredentials && (
                  <Box sx={{ mt: 1 }}>
                    <Link href="/reset-password" variant="body2">
                    {t.forgot?.[language] || t.forgot.en}
                    </Link>
                  </Box>
                )}
              </FormControl>
              <Button type="submit" fullWidth variant="contained">
              {t.sign_in?.[language] || t.sign_in.en}
              </Button>
              <Typography sx={{ textAlign: 'center' }}>
                {t.dontyet?.[language] || t.dontyet.en}{' '}
                <span>
                  <Link href="/signup" variant="body2" sx={{ alignSelf: 'center' }}>
                  {t.sign_up?.[language] || t.sign_up.en}
                  </Link>
                </span>
              </Typography>
            </Box>
            <Divider>
              <Typography sx={{ color: 'text.secondary' }}>or</Typography>
            </Divider>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Button
                fullWidth
                variant="outlined"
                onClick={handleGoogleSignIn}
                startIcon={<GoogleIcon />}
              >
                {t.google?.[language] || t.google.en}
              </Button>
              <Button
                fullWidth
                variant="outlined"
                onClick={handleTwitterSignIn}
                startIcon={<XIcon />}
              >
                {t.x?.[language] || t.x.en}
              </Button>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => {
                  if (mainBotLink) {
                    window.location.href = mainBotLink;
                  } else {
                    alert('Telegram bot link not available');
                  }
                }}
                startIcon={< TelegramIcon/>}
              >
                {t.tg?.[language] || t.tg.en}
              </Button>
            </Box>
          </Card>
        </SignInContainer>
      </ThemeProvider>
    </TemplateFrame>
  );
}
