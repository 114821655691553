import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ModelWidget from '../components/ModelWidget'; // Assuming the correct path to ModelWidget

const ModelsListContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export default function ModelsList({ models }) {
  return (
    <ModelsListContainer>
      {models.map((model) => (
        <ModelWidget
          name={model.name}
          imageUrl={model.avatar}
          mediaAmount={model.media?.length || '10+'}
          verified={model.verified}
          id={model.modelId}
        />
      ))}
    </ModelsListContainer>
  );
}
