import React from 'react';
import { Box } from '@mui/material';
import Avatar from '@mui/material/Avatar';

export default function Logo() {
  return (
    <Avatar
      src={`${process.env.PUBLIC_URL}/assets/images/logo.jpg`} // Path to your logo file
      alt="Lumipic"
      variant="rounded"
    />
  );
}
