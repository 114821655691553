//CountrySelector.js
import React from 'react';
import { Box, Typography, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import WorldFlags from 'react-world-flags';

const CountrySelector = ({ value, onChange, options }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="country-select-label">Select Country</InputLabel>
      <Select
        labelId="country-select-label"
        value={value}
        onChange={onChange}
        MenuProps={{
          PaperProps: {
            sx: { zIndex: 9999 },
          },
        }}
        renderValue={(selected) => {
          const option = options.find((o) => o.value === selected);
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {option?.flag && (
                <WorldFlags code={option.flag} style={{ width: '20px', height: '20px' }} />
              )}
              <Typography>{option?.label}</Typography>
            </Box>
          );
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value} sx={{ zIndex: 9999}}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1  }}>
              {option.flag && (
                <WorldFlags code={option.flag} style={{ width: '20px', height: '20px' }} />
              )}
              <Typography>{option.label}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CountrySelector;
