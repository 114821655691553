// MasonryGallery.js
import React from 'react';
import { Masonry } from '@mui/lab';
import MasonryItem from './MasonryItem';
import ErrorModule from './ErrorModule';

export default function MasonryGallery({ jobs, onDeleteJob, onNavigateToJob }) {
  if (!jobs || jobs.length === 0) {
    return <ErrorModule type={'empty'} />;
  }
  return (
    <Masonry columns={{ xs: 1, sm: 2, md: 4 }} sx={{ paddingLeft: 2 }}>
      {jobs.map((job) => (
        <MasonryItem
          key={job.jobId}
          job={job}
          onDeleteJob={onDeleteJob}
          onNavigateToJob={onNavigateToJob}
        />
      ))}
    </Masonry>
  );
}
