// CreatePage.js
import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  Box,
  Button,
  CssBaseline,
  Typography,
  Alert,
  Snackbar,
  CircularProgress,
  Tabs,
  Tab,
  Container,
  Card,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import TemplateFrame from '../theme/TemplateFrame';
import getSignUpTheme from '../theme/theme/getSignUpTheme';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import UploadBlock from '../../components/UploadBlock';
import MenuChoices from '../../components/MenuChoices';
import CustomInstructionsInput from '../../components/CustomInstructionsInput';
import AdvancedSettings from '../../components/AdvancedSettings';
import StatusDialog from '../../components/SubmitDialog';
import { getDatabase, ref, get, set } from 'firebase/database';
import { getStorage, ref as storageRef, getDownloadURL } from 'firebase/storage';
import { auth, database } from '../../services/firebase';
import { signInWithCustomToken } from 'firebase/auth';
import BundleList from '../../components/BundlesList';

import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import NotesIcon from '@mui/icons-material/Notes';
import LoginIcon from '@mui/icons-material/Login';

const FixedBottomButton = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  left: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 9999,
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
}));

const languageCodes = ['en', 'ru', 'es', 'pt', 'de', 'ja', 'ko', 'zh', 'hi']
const trnsl = {
  tabs: {
    bundles: {
      en: "Bundles",
      ru: "Фотосеты",
      es: "Paquetes",
      pt: "Pacotes",
      de: "Pakete",
      ja: "バンドル",
      ko: "번들",
      zh: "捆绑包",
      hi: "संकलन"
    },
    selectors: {
      en: "Selectors",
      ru: "Селекторы",
      es: "Selectores",
      pt: "Seletoras",
      de: "Selektoren",
      ja: "セレクタ",
      ko: "선택기",
      zh: "选择器",
      hi: "चयनकर्ता"
    },
    prompt: {
      en: "Advanced",
      ru: "Промптинг",
      es: "Avanzado",
      pt: "Avançado",
      de: "Erweitert",
      ja: "高度な",
      ko: "고급",
      zh: "高级",
      hi: "उन्नत"
    }
  },
  signup: {
    en: "Sign up / Sign In",
    ru: "Зарегестрироваться",
    es: "Regístrate / Inicia sesión",
    pt: "Inscrever-se / Entrar",
    de: "Anmelden / Einloggen",
    ja: "サインアップ / サインイン",
    ko: "가입 / 로그인",
    zh: "注册 / 登录",
    hi: "साइन अप / साइन इन करें"
  },
  submit: {
    en: "SUBMIT",
    ru: "ПОДТВЕРДИТЬ",
    es: "ENVIAR",
    pt: "ENVIAR",
    de: "EINREICHEN",
    ja: "送信",
    ko: "제출",
    zh: "提交",
    hi: "जमा करें"
  },
  submitting: {
    en: "Submitting...",
    ru: "Загрузка...",
    es: "Enviando...",
    pt: "Enviando...",
    de: "Einreichen...",
    ja: "送信中...",
    ko: "제출 중...",
    zh: "提交中...",
    hi: "जमा हो रहा है..."
  },
  errors: {
    unknown: {
      en: "Unknown error occurred",
      ru: "Произошла неизвестная ошибка",
      es: "Ocurrió un error desconocido",
      pt: "Ocorreu um erro desconhecido",
      de: "Unbekannter Fehler aufgetreten",
      ja: "不明なエラーが発生しました",
      ko: "알 수 없는 오류가 발생했습니다",
      zh: "发生未知错误",
      hi: "अज्ञात त्रुटि हुई"
    },
    submit: {
      en: "An error occurred while submitting your job",
      ru: "Произошла ошибка при отправке задания",
      es: "Ocurrió un error al enviar tu trabajo",
      pt: "Ocorreu um erro ao enviar seu trabalho",
      de: "Ein Fehler ist beim Einreichen Ihres Jobs aufgetreten",
      ja: "ジョブの送信中にエラーが発生しました",
      ko: "작업 제출 중 오류가 발생했습니다",
      zh: "提交工作时发生错误",
      hi: "आपका काम जमा करते समय त्रुटि हुई"
    },
    auth: {
      en: "Authentication failed",
      ru: "Ошибка аутентификации",
      es: "Autenticación fallida",
      pt: "Falha na autenticação",
      de: "Authentifizierung fehlgeschlagen",
      ja: "認証に失敗しました",
      ko: "인증 실패",
      zh: "认证失败",
      hi: "प्रमाणीकरण विफल"
    }
  },
  write_request: {
    title: {
      en: "Write Request Denied",
      ru: "Запрос на запись отклонен",
      es: "Solicitud de escritura denegada",
      pt: "Solicitação de escrita negada",
      de: "Schreibanfrage abgelehnt",
      ja: "書き込みリクエストが拒否されました",
      ko: "쓰기 요청 거부됨",
      zh: "写入请求被拒绝",
      hi: "लिखने का अनुरोध अस्वीकृत"
    },
    message: {
      en: "You need to allow our bot to send you messages in order to use this Mini App. Press the button below, open Mini App Again and allow this action",
      ru: "Необходимо разрешить нашему боту отправлять вам сообщения, чтобы использовать это мини-приложение. Нажмите кнопку ниже, снова откройте мини-приложение и разрешите это действие",
      es: "Debes permitir que nuestro bot te envíe mensajes para usar esta Mini App. Presiona el botón de abajo, abre la Mini App nuevamente y permite esta acción",
      pt: "Você precisa permitir que nosso bot envie mensagens para você usar este Mini App. Pressione o botão abaixo, abra o Mini App novamente e permita esta ação",
      de: "Sie müssen unserem Bot erlauben, Ihnen Nachrichten zu senden, um diese Mini-App zu verwenden. Drücken Sie die Schaltfläche unten, öffnen Sie die Mini-App erneut und erlauben Sie diese Aktion",
      ja: "このミニアプリを使用するには、ボットがメッセージを送信できるようにする必要があります。下のボタンを押して、もう一度ミニアプリを開き、この操作を許可してください",
      ko: "이 미니 앱을 사용하려면 봇이 메시지를 보낼 수 있도록 허용해야 합니다. 아래 버튼을 누르고 미니 앱을 다시 열고 이 작업을 허용하세요",
      zh: "您需要允许我们的机器人向您发送消息才能使用此小程序。按下方按钮，重新打开小程序并允许此操作",
      hi: "इस मिनी ऐप का उपयोग करने के लिए आपको हमारे बॉट को संदेश भेजने की अनुमति देनी होगी। नीचे दिए गए बटन को दबाएं, मिनी ऐप को फिर से खोलें और इस क्रिया की अनुमति दें"
    }
  },
  success: {
    title: {
      en: "Job submitted",
      ru: "Задание отправлено",
      es: "Trabajo enviado",
      pt: "Trabalho enviado",
      de: "Auftrag eingereicht",
      ja: "ジョブが送信されました",
      ko: "작업 제출됨",
      zh: "任务已提交",
      hi: "कार्य जमा किया गया"
    },
    togallery: {
      en: "You can navigate to your gallery and wait for your media to be processed or submit more media here",
      ru: "Вы можете перейти в свою галерею и дождаться обработки медиа или отправить еще медиа здесь",
      es: "Puedes ir a tu galería y esperar a que se procese tu contenido o enviar más contenido aquí",
      pt: "Você pode acessar sua galeria e aguardar o processamento de sua mídia ou enviar mais mídia aqui",
      de: "Sie können zu Ihrer Galerie navigieren und auf die Verarbeitung Ihrer Medien warten oder hier weitere Medien einreichen",
      ja: "ギャラリーに移動してメディアの処理を待つか、ここでさらにメディアを送信できます",
      ko: "갤러리로 이동하여 미디어 처리를 기다리거나 여기에서 더 많은 미디어를 제출할 수 있습니다",
      zh: "您可以转到您的图库，等待您的媒体被处理，或在此提交更多媒体",
      hi: "आप अपनी गैलरी पर जा सकते हैं और अपनी मीडिया को प्रोसेस होने का इंतजार कर सकते हैं या यहाँ और मीडिया जमा कर सकते हैं"
    }
  },
  confirm_usage: {
    title: {
      en: "Confirm Usage",
      es: "Confirmar Uso",
      pt: "Confirmar Uso",
      de: "Nutzung bestätigen",
      ja: "使用確認",
      ko: "사용 확인",
      zh: "确认使用",
      ru: "Подтвердить использование",
      hi: "उपयोग की पुष्टि करें"
    },
    description: {
      en: "Do you confirm that you are using pictures of yourself or that you have a right to use this face?",
      es: "¿Confirma que está usando imágenes de usted mismo o que tiene derecho a usar esta cara?",
      pt: "Você confirma que está usando fotos suas ou que tem o direito de usar este rosto?",
      de: "Bestätigen Sie, dass Sie Bilder von sich selbst verwenden oder dass Sie das Recht haben, dieses Gesicht zu verwenden?",
      ja: "自分自身の写真を使用しているか、この顔を使用する権利があることを確認しますか？",
      ko: "자신의 사진을 사용하거나 이 얼굴을 사용할 권리가 있음을 확인합니까?",
      zh: "您确认您正在使用自己的照片或有权使用此面孔吗？",
      ru: "Вы подтверждаете, что используете свои фотографии или имеете право использовать это лицо?",
      hi: "क्या आप पुष्टि करते हैं कि आप अपनी तस्वीरें उपयोग कर रहे हैं या आपके पास इस चेहरे का उपयोग करने का अधिकार है?"
    },
    confirm: {
      en: "Confirm",
      es: "Confirmar",
      pt: "Confirmar",
      de: "Bestätigen",
      ja: "確認",
      ko: "확인",
      zh: "确认",
      ru: "Подтвердить",
      hi: "पुष्टि करें"
    },
    dismiss: {
      en: "Dismiss",
      es: "Descartar",
      pt: "Dispensar",
      de: "Ablehnen",
      ja: "却下",
      ko: "닫기",
      zh: "忽略",
      ru: "Отклонить",
      hi: "खारिज करें"
    }
  },
  no_credits: {
    title: {
      en: "Not Enough Credits",
      es: "No hay suficientes créditos",
      pt: "Créditos Insuficientes",
      de: "Nicht genügend Credits",
      ja: "クレジットが足りません",
      ko: "크레딧이 부족합니다",
      zh: "积分不足",
      ru: "Недостаточно кредитов",
      hi: "पर्याप्त क्रेडिट नहीं"
    },
    description: {
      en: "You have $CREDITS credits, but $PRICE credits needed to start processing. Please visit the shop to get more credits",
      es: "Tienes $CREDITS créditos, pero se necesitan $PRICE créditos para comenzar el procesamiento. Por favor visita la tienda para obtener más créditos",
      pt: "Você tem $CREDITS créditos, mas são necessários $PRICE créditos para iniciar o processamento. Visite a loja para obter mais créditos",
      de: "Sie haben $CREDITS Credits, aber $PRICE Credits werden benötigt, um die Verarbeitung zu starten. Bitte besuchen Sie den Shop, um mehr Credits zu erhalten",
      ja: "$CREDITS クレジットがありますが、処理を開始するには $PRICE クレジットが必要です。ストアにアクセスしてクレジットを取得してください",
      ko: "$CREDITS 크레딧이 있지만 처리 시작에는 $PRICE 크레딧이 필요합니다. 상점을 방문하여 크레딧을 추가로 획득하세요",
      zh: "您有 $CREDITS 积分，但需要 $PRICE 积分才能开始处理。请访问商店获取更多积分",
      ru: "У вас $CREDITS кредитов, но требуется $PRICE кредитов для начала обработки. Пожалуйста, посетите магазин, чтобы получить больше кредитов",
      hi: "आपके पास $CREDITS क्रेडिट हैं, लेकिन प्रोसेसिंग शुरू करने के लिए $PRICE क्रेडिट की आवश्यकता है। कृपया और क्रेडिट प्राप्त करने के लिए दुकान पर जाएं"
    },
    shop: {
      en: "Shop",
      es: "Tienda",
      pt: "Loja",
      de: "Shop",
      ja: "ショップ",
      ko: "상점",
      zh: "商店",
      ru: "Магазин",
      hi: "दुकान"
    },
    dismiss: {
      en: "Dismiss",
      es: "Descartar",
      pt: "Dispensar",
      de: "Ablehnen",
      ja: "却下",
      ko: "닫기",
      zh: "忽略",
      ru: "Отклонить",
      hi: "खारिज करें"
    }
  },
}; 

export default function CreatePage() {
  const [mode, setMode] = useState('light');
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const defaultTheme = createTheme({ palette: { mode } });
  const createPageTheme = createTheme(getSignUpTheme(mode));

  const [avatarURL, setAvatarURL] = useState(null);
  const [bundles, setBundles] = useState([]);

  // State for selections
  const [gender, setGender] = useState(null);
  const [framing, setFraming] = useState(null);
  const [angle, setAngle] = useState(null);
  const [lighting, setLighting] = useState(null);
  const [bodyType, setBodyType] = useState(null);
  const [clothes, setClothes] = useState(null);
  const [posture, setPosture] = useState(null);
  const [photoType, setPhotoType] = useState('(No style)');
  const [cupSize, setCupSize] = useState(null);
  const [mediaAmount, setMediaAmount] = useState(1);
  const [misc, setMisc] = useState(null);
  const [pulid, setPulid] = useState(null);
  const [hairColor, setHairColor] = useState(null);
  const [setting, setSetting] = useState(null);
  const [camera, setCamera] = useState(null);
  const [customInstructionsChips, setCustomInstructionsChips] = useState([]);
  const [images, setImages] = useState([]);

  const [isPaid, setIsPaid] = useState(false);

  const [credits, setCredits] = useState(null); // Initialize to null
  const [user, setUser] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [dialogData, setDialogData] = useState({
    jobId: "",
    title: '',
    description: '',
    supportEmail: '',
  });
  const [basePrice, setBasePrice] = useState(0);
  const [pulidPrice, setPulidPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [showAdultContent, setShowAdultContent] = useState(null);
  const [couponExpiresAt, setCouponExpiresAt] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [language, setLanguage] = useState('en');
  const [isTelegramWebApp, setIsTelegramWebApp] = useState(false);

  const [selectors, setSelectors] = useState(null);

  const navigate = useNavigate();
  const location = useLocation(); // Get current URL location

  // Advanced Settings State
  const [isAdvancedSettingsOpen, setIsAdvancedSettingsOpen] = useState(false);
  const [seed, setSeed] = useState('');
  const [steps, setSteps] = useState(35); // default value
  const [styleStrengthRatio, setStyleStrengthRatio] = useState(20.0); // default value
  const [guidanceScale, setGuidanceScale] = useState(5.0); // default value

  // State for URL parameters
  const [urlParamsProcessed, setUrlParamsProcessed] = useState(false);
  const [urlParamsApplied, setUrlParamsApplied] = useState(false);
  const [parsedUrlParams, setParsedUrlParams] = useState(null);

  const [tabValue, setTabValue] = useState(0);
  const [bundlesFetched, setBundlesFetched] = useState(false);

  // Load user data and fetch credits and base price from database
  useEffect(() => {
    // Detect if the app is opened via Telegram Mini App
    if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initData) {
      setIsTelegramWebApp(true);

      // Set theme based on Telegram's colorScheme
      const telegramColorScheme = window.Telegram.WebApp.colorScheme;
      setMode(telegramColorScheme === 'dark' ? 'dark' : 'light');

      // Expand the Mini App if not expanded
      if (!window.Telegram.WebApp.isExpanded) {
        window.Telegram.WebApp.expand();
      }

      // Disable vertical swipes
      window.Telegram.WebApp.disableVerticalSwipes();

      const tgColor = telegramColorScheme === 'dark' ? "#0C1017" : "#F4F6FB";
      
      window.Telegram.WebApp.setHeaderColor(tgColor);
      window.Telegram.WebApp.setBackgroundColor(tgColor);
      window.Telegram.WebApp.setBottomBarColor(tgColor);

      // Enable closing confirmation only if there are images uploaded
      if (images.length > 0) {
        window.Telegram.WebApp.enableClosingConfirmation();
      } else {
        window.Telegram.WebApp.disableClosingConfirmation();
      }
    } else {
      // Not in Telegram Web App
      const savedMode = localStorage.getItem('themeMode');
      if (savedMode) {
        setMode(savedMode);
      } else {
        const systemPrefersDark = window.matchMedia(
          '(prefers-color-scheme: dark)',
        ).matches;
        setMode(systemPrefersDark ? 'dark' : 'light');
        localStorage.setItem('themeMode', systemPrefersDark ? 'dark' : 'light');
      }
    }

    if (localStorage.getItem("language")) {
      setLanguage(localStorage.getItem("language"))
    }
    
    const token = localStorage.getItem('userToken');
    if (token) {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          setUser(user);

          if (user?.photoURL) {
            console.log(user.photoURL)
            setAvatarURL(user.photoURL)
          }
          // Fetch user data from the database
          const db = getDatabase();
          const userRef = ref(db, `users/${user.uid}`);
          const snapshot = await get(userRef);
          if (snapshot.exists()) {
            const userData = snapshot.val();
            setCredits(userData.credits || 0);
            if (localStorage.getItem("language")) {
              setLanguage(localStorage.getItem("language"))
            } else if (userData?.analytics?.lang && languageCodes.includes(userData?.analytics?.lang)) {
              setLanguage(userData?.analytics?.lang)
            }

            if (userData.credits > 0) setIsPaid(true);
            else setIsPaid(false);

            if (userData?.coupon) {
              const current_date = Math.floor(Date.now() / 1000);
              const couponSnapshot = await get(ref(db, `coupons/${userData.coupon}`))
              if (couponSnapshot.exists()) {
                const couponData = couponSnapshot.val();
                if (couponData?.expiresAt <= current_date || (couponData.amount && couponData.amount <= 0)) {
                  try {
                    const couponRef = ref(`users/${user.uid}/coupon`);
                    set(couponRef, null);
                  }
                  catch(error) {
                    console.error(error);
                  }
                }
                else {
                  if (couponData.expiresAt) {
                    setCouponExpiresAt(couponData.expiresAt)
                  }
                  else {
                    setCouponExpiresAt(current_date + (3600 * 24 * 365));
                  }
                }
              }
              else {
                try {
                  set(ref(`users/${user.uid}/coupon`), null);
                }
                catch(error) {
                  console.error(error);
                }
              }
            }

            if (userData.adult) setShowAdultContent(true)
            else setShowAdultContent(false);

            localStorage.setItem(`user_${user.uid}`, JSON.stringify(userData));
          }
          else {
            setShowAdultContent(false);
          }

          // Fetch base price
          const pricesRef = ref(db, `settings/prices`);
          const pricesSnapshot = await get(pricesRef);
          if (pricesSnapshot.exists()) {
            const data = pricesSnapshot.val();
            setBasePrice(data.base_photo);
            setPulidPrice(data.pulid_factor);
          }
        } else {
          setShowAdultContent(false);
        }
      });
    }
    else {
      setBasePrice(1);
    }
  }, [images.length]);

  //auth for telegram users
  useEffect(() => {
    const authenticateTelegramUser = async () => {
      if (isTelegramWebApp && !user) {
        const initData = window.Telegram.WebApp.initData;

        if (initData) {
          // Send initData as is
          const formBody = `initData=${encodeURIComponent(initData)}`;

          try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/verify-telegram-webapp`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
              body: formBody,
            });

            const result = await response.json();

            window.Telegram.WebApp.ready();

            if (result?.navigator) {
              navigate(result.navigator);
            }

            if (result.success && result.customToken) {
              
              if (result.photo_url) {
                setAvatarURL(result.photo_url)
              }
              if (localStorage.getItem("language")) {
                setLanguage(localStorage.getItem("language"))
              }
              else if (result.language_code && languageCodes.includes(result.language_code)) {
                setLanguage(result.language_code)
                localStorage.setItem("language", result.language_code)
              }
              
              if (!result?.allows_write_to_pm) {
                window.Telegram.WebApp.requestWriteAccess(res => {
                  if (res === false) {
                    window.Telegram.WebApp.showPopup({
                      title: trnsl.write_request.title?.[language] || trnsl.write_request.title.en,
                      message: trnsl.write_request.message?.[language] || trnsl.write_request.message.en,
                      buttons: [
                        {
                          id: "",
                          type: "close",
                        }
                      ]
                    }, res => {
                      window.Telegram.WebApp.close();
                    })
                  }
                })
              }
              await signInWithCustomToken(auth, result.customToken);
              const idToken = await auth.currentUser.getIdToken();
              localStorage.setItem('userToken', idToken);
              setUser(auth.currentUser);
              // Fetch user data from the database
              const db = getDatabase();
              const userRef = ref(db, `users/${auth.currentUser.uid}`);
              const snapshot = await get(userRef);

              if (snapshot.exists()) {
                const userData = snapshot.val();
                setCredits(userData.credits || 0);

                setIsPaid(userData.credits > 0);

                if (userData?.payments || userData?.credits > 10) {
                  setTabValue(0)
                }

                if (userData?.coupon) {
                  const current_date = Math.floor(Date.now() / 1000);
                  const couponSnapshot = await get(ref(db, `coupons/${userData.coupon}`))
                  if (couponSnapshot.exists()) {
                    const couponData = couponSnapshot.val();
                    if (couponData?.expiresAt <= current_date || (couponData.amount && couponData.amount <= 0)) {
                      try {
                        const couponRef = ref(`users/${auth.currentUser.uid}/coupon`);
                        set(couponRef, null);
                      }
                      catch(error) {
                        console.error(error);
                      }
                    }
                    else {
                      if (couponData.expiresAt) {
                        setCouponExpiresAt(couponData.expiresAt)
                      }
                      else {
                        setCouponExpiresAt(current_date + (3600 * 24 * 365));
                      }
                    }
                  }
                  else {
                    set(ref(`users/${auth.currentUser.uid}/coupon`), null);
                  }
                }
              }
              // Fetch base price
              const pricesRef = ref(db, `settings/prices/base_photo`);
              const pricesSnapshot = await get(pricesRef);
              if (pricesSnapshot.exists()) {
                setBasePrice(pricesSnapshot.val());
              }
              
            } else {
              console.error('Telegram authentication failed:', result.error);
              setSubmitError(result.error || (trnsl.errors.auth?.[language] || trnsl.errors.auth.en));
            }
          } catch (error) {
            console.error('Error during Telegram authentication:', error);
            console.log(error)
            setSubmitError('An error occurred during authentication.');
          }
        }
      }
    };

    authenticateTelegramUser();
  }, [isTelegramWebApp, user]);

  // Calculate price whenever basePrice or mediaAmount changes
  useEffect(() => {
    if (basePrice !== 0 && mediaAmount !== null) {
      if (pulidPrice && pulidPrice > 0 && pulid) {
        setPrice(Math.floor(basePrice * mediaAmount * pulidPrice) || 1);
      }
      else {
        setPrice(basePrice * mediaAmount);
      }
    }
  }, [basePrice, mediaAmount, pulidPrice, pulid]);

  // Prevent window close during submission
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isSubmitting) {
        event.preventDefault();
        event.returnValue =
          'Your job submission is in progress. Are you sure you want to leave?';
      }
    };
    if (isSubmitting) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isSubmitting]);

  const performSubmitJob = useCallback(async () => {
    const {
      user,
      credits,
      price,
      gender,
      framing,
      angle,
      lighting,
      bodyType,
      posture,
      photoType,
      hairColor,
      cupSize,
      clothes,
      misc,
      pulid,
      setting,
      camera,
      customInstructionsChips,
      mediaAmount,
      images,
      navigate,
      isTelegramWebApp,
      showAdultContent,
      seed,
      steps,
      styleStrengthRatio,
      guidanceScale,
      tabValue,
      //isAdvancedSettingsOpen,
    } = latestState.current;

    if (isTelegramWebApp) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('rigid');
    }

    if (!user) {
      navigate('/signup');
      return;
    }

    if (credits >= price) {
      setIsSubmitting(true);
      setSubmitError(null);

      if (isTelegramWebApp) {
        window.Telegram.WebApp.MainButton.showProgress();
      }

      try {
        let newJob = {
          userId: user.uid,
          price: price,
          timestamp: Math.floor(Date.now() / 1000),
          mediaAmount,
          isAdult: showAdultContent,
          pulid: pulid || null
        };
    
        if (tabValue === 1) {
          // Simple tab is active
          newJob.selections = {
            gender,
            framing,
            angle,
            lighting,
            bodyType,
            posture,
            photoType,
            clothes,
            hairColor,
            misc,
            setting,
            camera,
            cupSize,
          };

        } else if (tabValue === 2) {
          // Advanced tab is active
          newJob.customInstructions =
            customInstructionsChips.length > 0 ? customInstructionsChips : null;
          newJob.advanced = {
            seed: seed !== '' ? seed : null,
            steps,
            styleStrengthRatio,
            guidanceScale,
          };
        }

        const idToken = await user.getIdToken();

        // Create FormData to include images and data
        const formData = new FormData();
        formData.append('jobData', JSON.stringify(newJob));

        // Append images to formData
        images.forEach((imageObj) => {
          formData.append('images', imageObj.file);
        });

        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/create-test`,
          {
            method: 'POST',
            headers: {
              Authorization: idToken,
            },
            body: formData,
          },
        );

        if (response.ok) {
          const result = await response.json();
          if (isTelegramWebApp) {
            if (!window.popupIsOpen) {
              window.popupIsOpen = true;
              window.Telegram.WebApp.showPopup(
                {
                  title: (trnsl.success.title?.[language] || trnsl.success.title.en),
                  message: (trnsl.success.togallery?.[language] || trnsl.success.togallery.en),
                  buttons: [
                    { id: 'job', type: 'default', text: 'View Media' },
                    { id: 'dismiss', type: 'close', text: 'Dismiss' },
                  ],
                },
                (buttonId) => {
                  window.popupIsOpen = false;
                  if (buttonId === 'job') {
                    navigate('/gallery/' + result.jobId);
                  }
                }
              );
            }
          }
          else {
            setDialogType('submitted');
            setDialogData({
              jobId: result.jobId,
              title: (trnsl.success.title?.[language] || trnsl.success.title.en),
              description: (trnsl.success.togallery?.[language] || trnsl.success.togallery.en),
            });
            setDialogOpen(true);
          }
        } else {
          const errorResult = await response.json();
          setSubmitError(errorResult.error || (trnsl.errors.unknown?.[language] || trnsl.errors.unknown.en));
        }
      } catch (error) {
        setSubmitError(trnsl.errors.submit?.[language] || trnsl.errors.submit.en);
      } finally {
        setIsSubmitting(false);
        if (isTelegramWebApp) {
          window.Telegram.WebApp.MainButton.hideProgress();
        }
      }
    } else {
      // Show Not Enough Credits Popup or Dialog
      if (isTelegramWebApp) {
        if (!window.popupIsOpen) {
          window.popupIsOpen = true;
          window.Telegram.WebApp.showPopup(
            {
              title: trnsl.no_credits.title?.[language] || trnsl.no_credits.title.en,
              message: (trnsl.no_credits.description?.[language] || trnsl.no_credits.description.en).replace("$CREDITS", credits).replace("$PRICE", price),
              buttons: [
                { id: 'shop', type: 'default', text: trnsl.no_credits.shop?.[language] || trnsl.no_credits.shop.en },
                { id: 'dismiss', type: 'close', text: trnsl.no_credits.dismiss?.[language] || trnsl.no_credits.dismiss.en },
              ],
            },
            (buttonId) => {
              window.popupIsOpen = false;
              if (buttonId === 'shop') {
                navigate('/shop');
              }
            }
          );
        }
      } else {
        // Show Not Enough Credits Dialog
        setDialogType('credits');
        setDialogData({
          title: trnsl.no_credits.title?.[language] || trnsl.no_credits.title.en,
          description:
          (trnsl.no_credits.title?.[language] || trnsl.no_credits.title.en).replace("$CREDITS", credits).replace("$PRICE", price),
        });
        setDialogOpen(true);
      }
    }
  }, []);

  const handleSubmitJob = useCallback(() => {
    if (isTelegramWebApp) {
      if (!window.popupIsOpen) {
        window.popupIsOpen = true;
        window.Telegram.WebApp.showPopup(
          {
            title: trnsl.confirm_usage.title?.[language] || trnsl.confirm_usage.title.en,
            message: trnsl.confirm_usage.description?.[language] || trnsl.confirm_usage.description.en,
            buttons: [
              { id: 'confirm', type: 'default', text: trnsl.confirm_usage.confirm?.[language] || trnsl.confirm_usage.confirm.en },
              { id: 'dismiss', type: 'close', text: trnsl.confirm_usage.dismiss?.[language] || trnsl.confirm_usage.dismiss.en },
            ],
          },
          (buttonId) => {
            window.popupIsOpen = false;
            if (buttonId === 'confirm') {
              performSubmitJob();
            }
            // If dismissed, do nothing
          }
        );
      }
    } else {
      setDialogType('proveface');
      setDialogData({
        title: trnsl.confirm_usage.title?.[language] || trnsl.confirm_usage.title.en,
        description: trnsl.confirm_usage.description?.[language] || trnsl.confirm_usage.description.en,
      });
      setDialogOpen(true)
    }
  }, [isTelegramWebApp, performSubmitJob]);

  // Set up Telegram MainButton
  useEffect(() => {
    if (isTelegramWebApp) {
      const MainButton = window.Telegram.WebApp.MainButton;
      const SecondButton = window.Telegram.WebApp.SecondaryButton;

      MainButton.offClick();
      MainButton.onClick(handleSubmitJob);

      let params = {
        is_visible: true,
        is_active: false,
        text_color: '#FFFFFF',
        color: '#5288c1',
        position: 'bottom',
        has_shine_effect: false,
      };
      MainButton.setParams(params);

      SecondButton.setParams({
        is_visible: false,
        is_active: false,
        position: 'left',
        has_shine_effect: false,
        color: window.Telegram.WebApp.themeParams.bottom_bar_bg_color,
        textColor: window.Telegram.WebApp.themeParams.button_color
      });

      // Initial button state

      // Enable or disable button based on conditions
      if (images.length > 0 && price > 0) {
        MainButton.setText(`${trnsl.submit?.[language] || trnsl.submit.en} ${price} 💎`);
        MainButton.show();
        //MainButton.enable();
      } else {
        MainButton.disable();
      }

      return () => {
        MainButton.offClick(handleSubmitJob);
        MainButton.hide();
      };
    }
  }, [isTelegramWebApp, images.length, price, handleSubmitJob]);

  // Update MainButton when images or submission state changes
  useEffect(() => {
    if (isTelegramWebApp) {
      const MainButton = window.Telegram.WebApp.MainButton;

      MainButton.setText(`${trnsl.submit?.[language] || trnsl.submit.en} ${price} 💎`);
      if (price <= 0 || images.length <= 0) {
        MainButton.disable();
      }
      if (price > 0) {
        MainButton.show();
        MainButton.enable();
      } else {
        MainButton.disable();
      }
      if (isSubmitting) {
        MainButton.showProgress();
      } else {
        MainButton.hideProgress();
      }
    }
  }, [isTelegramWebApp, images.length, price, isSubmitting]);

  // Enable or disable closing confirmation based on images
  useEffect(() => {
    if (isTelegramWebApp) {
      if (images.length > 0) {
        window.Telegram.WebApp.enableClosingConfirmation();
      } else {
        window.Telegram.WebApp.disableClosingConfirmation();
      }
    }
  }, [isTelegramWebApp, images.length]);

  const latestState = useRef({});

  //using refs to handle submitting and updating values in telegram
  useEffect(() => {
    latestState.current = {
      user,
      credits,
      price,
      gender,
      framing,
      angle,
      lighting,
      bodyType,
      clothes,
      hairColor,
      misc,
      pulid,
      setting,
      camera,
      posture,
      photoType,
      cupSize,
      customInstructionsChips,
      mediaAmount,
      images,
      showAdultContent,
      navigate,
      isTelegramWebApp,
      seed,
      steps,
      styleStrengthRatio,
      guidanceScale,
      tabValue
      //isAdvancedSettingsOpen,
    };
  }, [
    user,
    credits,
    price,
    gender,
    framing,
    angle,
    lighting,
    bodyType,
    clothes,
    posture,
    photoType,
    hairColor,
    misc,
    pulid,
    setting,
    camera,
    cupSize,
    customInstructionsChips,
    mediaAmount,
    images,
    showAdultContent,
    navigate,
    isTelegramWebApp,
    seed,
    steps,
    styleStrengthRatio,
    guidanceScale,
    tabValue
    //isAdvancedSettingsOpen,
  ]);

  // Handle submit errors
  useEffect(() => {
    if (submitError) {
      if (isTelegramWebApp) {
        window.Telegram.WebApp.showAlert(submitError);
      }
    }
  }, [submitError, isTelegramWebApp]);

  const toggleColorMode = () => {
    const newMode = mode === 'dark' ? 'light' : 'dark';
    setMode(newMode);
    if (!isTelegramWebApp) {
      localStorage.setItem('themeMode', newMode);
    }
  };

  // Parse URL parameters
  useEffect(() => {
    if (!urlParamsProcessed) {
      const searchParams = new URLSearchParams(location.search);
      const promptParam = searchParams.get('prompt');
      const modelIdParam = searchParams.get('model_id');

      const menuChoicesParams = [
        'gender',
        'framing',
        'angle',
        'lighting',
        'bodyType',
        'cupSize',
        'posture',
        'photoType',
        'hairColor',
        'clothes',
        'misc',
        'setting',
        'camera',
      ];
      const selections = {};
      menuChoicesParams.forEach((param) => {
        const value = searchParams.get(param);
        if (value !== null) {
          selections[param] = value;
        }
      });

      const haspulid = searchParams.get('pulid') === 'true';

      setParsedUrlParams({
        prompt: promptParam,
        modelId: modelIdParam,
        selections: selections,
        pulid: haspulid
      });

      // Remove the parameters from the URL
      const newUrl = `${location.pathname}`;
      window.history.replaceState({}, '', newUrl);

      setTabValue(1);

      setUrlParamsProcessed(true);
    }
  }, [location.search, urlParamsProcessed]);

  // Apply URL parameters after user and credits are loaded
  useEffect(() => {
    if (user && credits !== null && parsedUrlParams && selectors && !urlParamsApplied) {
      const { prompt, modelId, selections, pulid } = parsedUrlParams;

      // Process prompt
      if (prompt && credits > 0) {
        setCustomInstructionsChips((prevChips) => [...prevChips, prompt]);
      }

      // Process model_id
      if (modelId) {
        const fetchModelData = async () => {
          const db = database;
          const modelRef = ref(db, `models/${modelId}`);
          const modelSnapshot = await get(modelRef);
          if (modelSnapshot.exists()) {
            const modelData = modelSnapshot.val();

            // Merge selections
            let mergedSelections = { ...modelData.selections };
            mergedSelections = { ...mergedSelections, ...selections };

            // Set menu choices
            setGender(mergedSelections.gender || null);
            setFraming(mergedSelections.framing || null);
            setAngle(mergedSelections.angle || null);
            setLighting(mergedSelections.lighting || null);
            setBodyType(mergedSelections.bodyType || null);
            setCupSize(mergedSelections.cupSize || null);
            setPosture(mergedSelections.posture || null);
            setPhotoType(mergedSelections.photoType || null);
            setClothes(mergedSelections.clothes || null);
            setCamera(mergedSelections.camera || null);
            setHairColor(mergedSelections.hairColor || null);
            setMisc(mergedSelections.misc || null);
            setPulid(mergedSelections.pulid || null);
            setSetting(mergedSelections.setting || null);
            
            // Process input_media
            if (modelData.input_media) {
              const fetchImages = async () => {
                const inputMediaFileNames = modelData.input_media; // Array of file names with extensions
                const storage = getStorage(); // Initialize Storage
            
                const imagePromises = inputMediaFileNames.map(async (fileName) => {
                  try {
                    // Create a reference to the file in Firebase Storage
                    const fileRef = storageRef(storage, `models/${modelId}/${fileName}`);
            
                    // Get the download URL
                    const url = await getDownloadURL(fileRef);
            
                    // Fetch the file blob
                    const response = await fetch(url);
                    const blob = await response.blob();
            
                    // Create a File object
                    const file = new File([blob], fileName, { type: blob.type });
            
                    // Generate a preview URL
                    const previewUrl = URL.createObjectURL(blob);
            
                    return { file, previewUrl };
                  } catch (error) {
                    console.error(`Failed to load image: ${fileName}`, error);
                    // Return null for failed fetches
                    return null;
                  }
                });
            
                const results = await Promise.all(imagePromises);
            
                // Filter out any null results (failed fetches)
                const successfulImages = results.filter((img) => img !== null);
            
                // Update the images state
                setImages((prevImages) => [...prevImages, ...successfulImages]);
              };
            
              fetchImages();
            }
          }
        };

        fetchModelData();
      } else {
        // No model_id, apply selections from URL
        if (selections && selectors) {
          // Example for gender as a simple object
          setGender({ value: selections.gender, lora: null } || null);
        
          // For posture
          let postureObj = selectors.action.find(option => option.prompt === selections.posture);
          if (postureObj) {
            postureObj = {
              label: postureObj.name[language] || postureObj.name.en,
              lora: postureObj.lora || null,
              value: postureObj.prompt
            };
          }
          setPosture(postureObj || null);

          // framing
          let framingObj = selectors.frame.find(option => option.prompt === selections.framing);
          if (framingObj) {
            framingObj = {
              label: framingObj.name[language] || framingObj.name.en,
              lora: framingObj.lora || null,
              value: framingObj.prompt
            };
          }
          setFraming(framingObj || null);
        
          // angle
          let angleObj = selectors.angle.find(option => option.prompt === selections.angle);
          if (angleObj) {
            angleObj = {
              label: angleObj.name[language] || angleObj.name.en,
              lora: angleObj.lora || null,
              value: angleObj.prompt
            };
          }
          setAngle(angleObj || null);
        
          // lighting
          let lightingObj = selectors.lighting.find(option => option.prompt === selections.lighting);
          if (lightingObj) {
            lightingObj = {
              label: lightingObj.name[language] || lightingObj.name.en,
              lora: lightingObj.lora || null,
              value: lightingObj.prompt
            };
          }
          setLighting(lightingObj || null);
        
          // bodyType
          let bodyTypeObj = selectors[selections.gender === 'male' ? 'male_body' : 'female_body']
            .find(option => option.prompt === selections.bodyType);
          if (bodyTypeObj) {
            bodyTypeObj = {
              label: bodyTypeObj.name[language] || bodyTypeObj.name.en,
              lora: bodyTypeObj.lora || null,
              value: bodyTypeObj.prompt
            };
          }
          setBodyType(bodyTypeObj || null);
        
          // cupSize
          let cupSizeObj = selectors[selections.gender === 'male' ? 'male_size' : 'female_size']
            .find(option => option.prompt === selections.cupSize);
          if (cupSizeObj) {
            cupSizeObj = {
              label: cupSizeObj.name[language] || cupSizeObj.name.en,
              lora: cupSizeObj.lora || null,
              value: cupSizeObj.prompt
            };
          }
          setCupSize(cupSizeObj || null);
        
          // photoType
          let photoTypeObj = selectors.style.find(option => option.prompt === selections.photoType);
          if (photoTypeObj) {
            photoTypeObj = {
              label: photoTypeObj.name[language] || photoTypeObj.name.en,
              lora: photoTypeObj.lora || null,
              value: photoTypeObj.prompt
            };
          }
          setPhotoType(photoTypeObj || null);
        
          // clothes
          let clothesObj = selectors.clothes.find(option => option.prompt === selections.clothes);
          if (clothesObj) {
            clothesObj = {
              label: clothesObj.name[language] || clothesObj.name.en,
              lora: clothesObj.lora || null,
              value: clothesObj.prompt
            };
          }
          setClothes(clothesObj || null);
        
          // camera
          let cameraObj = selectors.camera.find(option => option.prompt === selections.camera);
          if (cameraObj) {
            cameraObj = {
              label: cameraObj.name[language] || cameraObj.name.en,
              lora: cameraObj.lora || null,
              value: cameraObj.prompt
            };
          }
          setCamera(cameraObj || null);
        
          // misc
          let miscObj = selectors.misc.find(option => option.prompt === selections.misc);
          if (miscObj) {
            miscObj = {
              label: miscObj.name[language] || miscObj.name.en,
              lora: miscObj.lora || null,
              value: miscObj.prompt
            };
          }
          setMisc(miscObj || null);
        
          // pulid
          setPulid(pulid || null);
        
          // hairColor
          let hairColorObj = selectors.hair_color.find(option => option.prompt === selections.hairColor);
          if (hairColorObj) {
            hairColorObj = {
              label: hairColorObj.name[language] || hairColorObj.name.en,
              lora: hairColorObj.lora || null,
              value: hairColorObj.prompt
            };
          }
          setHairColor(hairColorObj || null);
        
          // setting
          let settingObj = selectors.setting.find(option => option.prompt === selections.setting);
          if (settingObj) {
            settingObj = {
              label: settingObj.name[language] || settingObj.name.en,
              lora: settingObj.lora || null,
              value: settingObj.prompt
            };
          }
          setSetting(settingObj || null);
        
          // Done applying URL parameters
          setUrlParamsApplied(true);
        }
      }

      setUrlParamsApplied(true);
    }
  }, [user, credits, parsedUrlParams, urlParamsApplied, selectors]);

  useEffect(() => {
    if (showAdultContent !== null) {
      if (tabValue === 0 && !bundlesFetched) {
        loadFeedData().then(() => {
          setBundlesFetched(true);
        });
      }

      const getSelectorsData = async () => {
        let selectorsObject;
        const now = Math.floor(Date.now() / 1000);
        if (localStorage.getItem("selectors")) {
          const selectorsLastTime = parseInt(localStorage.getItem("selectors_date") || 0);
          if ((selectorsLastTime + (60 * 60 * 24)) >= now) {
            selectorsObject = JSON.parse(localStorage.getItem("selectors"))

            if (!showAdultContent) {
              selectorsObject = filterAdultContent(selectorsObject);
            }
            setSelectors(selectorsObject);
          }
        }
        if (!selectorsObject) {
          const db = getDatabase();
          const snapshot = await get(ref(db, `settings/prompts/selectors`));
          if (snapshot.exists()) {
            selectorsObject = snapshot.val();

            if (!showAdultContent) {
              selectorsObject = filterAdultContent(selectorsObject);
            }
            setSelectors(selectorsObject);
            localStorage.setItem("selectors", JSON.stringify(selectorsObject));
            localStorage.setItem("selectors_date", now.toString());
          }
        }
      }
      getSelectorsData();
    }
  }, [showAdultContent, tabValue, bundlesFetched]);

  function filterAdultContent(selectorsObject) {
    const filteredSelectors = {};
    
    // Loop through each property key in the object
    for (const key of Object.keys(selectorsObject)) {
      const originalArray = selectorsObject[key];
      if (Array.isArray(originalArray)) {
        // Filter out items that have { adult: true }
        const filteredArray = originalArray.filter(item => !item.adult);
        filteredSelectors[key] = filteredArray;
      } else {
        // If not an array, just copy it as is
        filteredSelectors[key] = selectorsObject[key];
      }
    }
  
    return filteredSelectors;
  }

  const loadFeedData = async () => {
    try {
      console.log('setting loading to true')

      // Check localStorage for feedData and timestamp
      let cachedData = localStorage.getItem('feedData');
      let cachedTimestamp = localStorage.getItem('feedDataTimestamp');

      const now = Math.floor(Date.now() / 1000);

      const FEED_DATA_CACHE_DURATION = 10 * 60; // 10 minutes
      //localStorage.removeItem('feedData');
      //localStorage.removeItem('feedDataTimestamp');

      if (cachedData && cachedTimestamp && now - parseInt(cachedTimestamp) < FEED_DATA_CACHE_DURATION) {
        // Use cached data
        const feedData = JSON.parse(cachedData);

        if (feedData.feed?.length === 0) cachedTimestamp = 0;
        setBundles(feedData.bundles || []);
      } 
      if (!cachedData || !cachedTimestamp || (now - parseInt(cachedTimestamp) >= FEED_DATA_CACHE_DURATION)) {
        // Fetch from server
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/feed`, {
          method: 'GET',
          headers: {
            showadultcontent: showAdultContent ? 'true' : 'false',
          },
        });

        if (response.ok) {
          const result = await response.json();
          setBundles(result.bundles || []);

          // Save data and timestamp to localStorage
          const feedDataToCache = {
            feed: result.feed || [],
            models: result.models || [],
            bundles: result.bundles || [],
          };
          localStorage.setItem('feedData', JSON.stringify(feedDataToCache));
          localStorage.setItem('feedDataTimestamp', now.toString());
        } else {
          console.error('Error fetching feed data:', response.statusText);
        }
      }
    } catch (error) {
      console.error('Error loading feed data:', error);
    }
  };
  
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <TemplateFrame
      toggleCustomTheme={() => setShowCustomTheme((prev) => !prev)}
      showCustomTheme={showCustomTheme}
      mode={mode}
      toggleColorMode={toggleColorMode}
      credits={credits}
      couponExpiresAt={couponExpiresAt}
      avatar={avatarURL}
    >
      <ThemeProvider theme={showCustomTheme ? createPageTheme : defaultTheme}>
        <CssBaseline enableColorScheme />

        <Box sx={{ padding: 2}}>
          <UploadBlock
            images={images}
            setImages={setImages}
            isTelegramWebApp={isTelegramWebApp}
            lang={language}
          />
        </Box>
        

        <Container maxWidth="md">
          <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="Create Tabs"
              variant="fullWidth"
              sx={{ marginBottom: 2 }}
            >
              <Tab 
                label={trnsl.tabs.bundles?.[language] ||trnsl.tabs.bundles.en }
                icon={<WebStoriesIcon />}
              />
              <Tab
                label={trnsl.tabs.selectors?.[language] ||trnsl.tabs.selectors.en }
                icon={<ElectricBoltIcon />}
              />
              <Tab
                label={trnsl.tabs.prompt?.[language] ||trnsl.tabs.prompt.en }
                icon={<NotesIcon />}  
              />
          </Tabs>
          {tabValue === 0 && (
            <BundleList bundles={bundles} language={language} isShowAdultContent={showAdultContent} isPaid={isPaid} isEligable={!isPaid} />
          )}
          {tabValue === 1 && (
            <MenuChoices
              gender={gender}
              setGender={setGender}
              framing={framing}
              setFraming={setFraming}
              angle={angle}
              setAngle={setAngle}
              lighting={lighting}
              setLighting={setLighting}
              bodyType={bodyType}
              setBodyType={setBodyType}
              posture={posture}
              setPosture={setPosture}
              photoType={photoType}
              setPhotoType={setPhotoType}
              cupSize={cupSize}
              setCupSize={setCupSize}
              mediaAmount={mediaAmount}
              setMediaAmount={setMediaAmount}
              clothes={clothes}
              setClothes={setClothes}
              pulid={pulid}
              setPulid={setPulid}
              misc={misc}
              setMisc={setMisc}
              hairColor={hairColor}
              setHairColor={setHairColor}
              setting={setting}
              setSetting={setSetting}
              camera={camera}
              setCamera={setCamera}
              isAdult={showAdultContent}
              isTelegramWebApp={isTelegramWebApp}
              selectors={selectors}
              language={language}
              theme={createPageTheme}
            />
          )}
          {tabValue === 2 && (
            <>
              <CustomInstructionsInput
              customInstructionsChips={customInstructionsChips}
              setCustomInstructionsChips={setCustomInstructionsChips}
              user={user}
              isTelegramWebApp={isTelegramWebApp}
              hasPurchases={isPaid}
              />
              <AdvancedSettings
                  seed={seed}
                  setSeed={setSeed}
                  steps={steps}
                  setSteps={setSteps}
                  styleStrengthRatio={styleStrengthRatio}
                  setStyleStrengthRatio={setStyleStrengthRatio}
                  guidanceScale={guidanceScale}
                  setGuidanceScale={setGuidanceScale}
                  isTelegramWebApp={isTelegramWebApp}
                  credits={credits}
                  user={user}
                  mediaAmount={mediaAmount}
                  setMediaAmount={setMediaAmount}
                  hasPaid={isPaid}
                />
            </>
          )}

          {/* Submit or Sign Up / Sign In Button */}
          <Box sx={{ marginTop: 2, marginBottom: 10 }}>
            <FixedBottomButton sx={{ marginBottom: 8 }}>
              {!user && !isTelegramWebApp && basePrice !== 0 ? (
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    flex: 1,
                    minHeight: 60,
                    maxWidth: { sm: "100%", md: "400px" }, // Full width on mobile, 300px max on larger screens
                    width: "100%", // Ensure full width on mobile
                  }}
                  endIcon={<LoginIcon />}
                  onClick={() => navigate('/signup')}
                >
                  <Typography variant="h6">{trnsl.signup?.[language] || trnsl.signup.en}</Typography>
              </Button>
              ) : (
                (!isTelegramWebApp && tabValue !== 0) && (
                    <Button
                      variant="contained"
                      color={submitError ? 'error' : 'secondary'}
                      sx={{
                        flex: 1,
                        minHeight: 60,
                        maxWidth: { sm: "100%", md: "400px" }, // Full width on mobile, 300px max on larger screens
                        width: "100%", // Ensure full width on mobile
                      }}
                      onClick={handleSubmitJob}
                      disabled={isSubmitting || price === 0 || images.length === 0}
                    >
                      {isSubmitting ? (
                        <>
                          <CircularProgress
                            size={24}
                            color="inherit"
                            sx={{ marginRight: 1 }}
                          />
                          {trnsl.submitting?.[language] || trnsl.submitting.en}
                        </>
                      ) : (
                        <Typography variant="h6">{trnsl.submit?.[language] || trnsl.submit.en} • {price} 💎</Typography>
                      )}
                    </Button>
                )
              )}
            </FixedBottomButton>
          </Box>
        </Container>

        {/* Error Snackbar */}
        {!isTelegramWebApp && (
          <Snackbar
            open={!!submitError}
            autoHideDuration={6000}
            onClose={() => setSubmitError(null)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={{ width: "100%"}}
          >
            <Alert
              onClose={() => setSubmitError(null)}
              severity="error"
              sx={{ width: '100%' }}
            >
              {submitError}
            </Alert>
          </Snackbar>
        )}

        {/* Dialog Component */}
        {!isTelegramWebApp && (
          <StatusDialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            dialogType={dialogType}
            title={dialogData.title}
            description={dialogData.description}
            supportEmail={dialogData.supportEmail}
            mode={mode}
            handlePrimaryAction={() => {
              if (dialogType === 'credits') {
                navigate('/shop');
              }
              else if (dialogType === 'submitted') {
                navigate(`/gallery/${dialogData.jobId}`);
              }
              else if (dialogType === 'proveface') {
                performSubmitJob();
                setDialogOpen(false);
              }
            }}
            jobId={dialogData.jobId}
          />
        )}
      </ThemeProvider>
    </TemplateFrame>
  );
}
