import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import getSignUpTheme from './theme/getSignUpTheme';
import TemplateFrame from './TemplateFrame';
import { auth } from '../../services/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    width: '450px',
  },
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

const ResetPasswordContainer = styled(Stack)(({ theme }) => ({
  minHeight: '100%',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
  ...theme.applyStyles('dark'),
}));

export default function ResetPassword() {
  const [mode, setMode] = React.useState('light');
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const defaultTheme = createTheme({ palette: { mode } });
  const resetPasswordTheme = createTheme(getSignUpTheme(mode));
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [resendTimer, setResendTimer] = React.useState(0);
  const [showDialog, setShowDialog] = React.useState(false);

  React.useEffect(() => {
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setMode(savedMode);
    } else {
      const systemPrefersDark = window.matchMedia(
        '(prefers-color-scheme: dark)',
      ).matches;
      setMode(systemPrefersDark ? 'dark' : 'light');
    }
  }, []);

  React.useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [resendTimer]);

  const toggleColorMode = () => {
    const newMode = mode === 'dark' ? 'light' : 'dark';
    setMode(newMode);
    localStorage.setItem('themeMode', newMode);
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  const validateInputs = () => {
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
      setEmailErrorMessage('Please enter a valid email address.');
      return false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
      return true;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateInputs()) {
      return;
    }

    if (resendTimer > 0) {
      console.warn('Cannot send email reset link before timer expires.');
      return;
    }

    // Create custom redirect URL to sign-in page after password reset
    const actionCodeSettings = {
      url: `${window.location.origin}/signin`,
      handleCodeInApp: true,
    };

    sendPasswordResetEmail(auth, email, actionCodeSettings)
      .then(() => {
        console.log('Password reset email sent');
        setResendTimer(60); // Start cooldown period for resending password reset
        setShowDialog(true); // Show instructions dialog
      })
      .catch((error) => {
        console.error('Error sending password reset email:', error);
        setEmailError(true);
        setEmailErrorMessage('Error sending reset link. Please try again later.');
      });
  };

  return (
    <TemplateFrame
      toggleCustomTheme={toggleCustomTheme}
      showCustomTheme={showCustomTheme}
      mode={mode}
      toggleColorMode={toggleColorMode}
    >
      <ThemeProvider theme={showCustomTheme ? resetPasswordTheme : defaultTheme}>
        <CssBaseline enableColorScheme />
        <ResetPasswordContainer direction="column" justifyContent="space-between">
          <Card variant="outlined">
            {/* WebP Animation */}
            <Box sx={{ textAlign: 'center', mb: 2 }}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/icons/webp/key.webp`}
                alt="Reset Password"
                style={{ width: '100%', maxWidth: '200px', margin: '0 auto' }}
              />
            </Box>

            {/* Reset Password Heading */}
            <Typography
              component="h1"
              variant="h4"
              sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
            >
              Reset Password
            </Typography>

            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
            >
              <FormControl>
                <FormLabel htmlFor="email">Email</FormLabel>
                <TextField
                  required
                  fullWidth
                  id="email"
                  placeholder="your@email.com"
                  name="email"
                  autoComplete="email"
                  variant="outlined"
                  error={emailError}
                  helperText={emailErrorMessage}
                  color={emailError ? 'error' : 'primary'}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={resendTimer > 0}
              >
                {resendTimer > 0 ? `Resend in ${resendTimer}s` : 'Send Password Reset Link'}
              </Button>
              <Typography sx={{ textAlign: 'center', mt: 2 }}>
                Remembered your password?{' '}
                <span>
                  <Link
                    href="/signin"
                    variant="body2"
                    sx={{ alignSelf: 'center' }}
                  >
                    Sign in
                  </Link>
                </span>
              </Typography>
            </Box>
          </Card>

          {/* Dialog with Instructions */}
          <Dialog
            open={showDialog}
            onClose={() => setShowDialog(false)}
          >
            <DialogTitle>
              <IconButton
                aria-label="close"
                onClick={() => setShowDialog(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Box sx={{ textAlign: 'center', mb: 2 }}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/webp/email.webp`}
                  alt="Reset Password Instructions"
                  style={{ width: '100%', maxWidth: '200px', margin: '0 auto' }}
                />
              </Box>
              <Typography variant="h6" align="center">
                Password Reset Instructions
              </Typography>
              <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                We have sent a password reset link to <strong>{email}</strong>.
                Please check your inbox and also your spam folder to complete the process.
              </Typography>
            </DialogContent>
          </Dialog>
        </ResetPasswordContainer>
      </ThemeProvider>
    </TemplateFrame>
  );
}
