import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const SelectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const OptionButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  flexWrap: 'wrap',
  justifyContent: 'center',
  width: '100%',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  flex: '1 1 auto',
  [theme.breakpoints.up('md')]: {
    flex: '0 1 auto',
  },
}));

const translations = {
  en: {
    title: 'Content Preferences',
    options: { straight: 'Straight', gay: 'Gay', trans: 'Trans' },
  },
  es: {
    title: 'Preferencias de contenido',
    options: { straight: 'Heterosexual', gay: 'Gay', trans: 'Trans' },
  },
  pt: {
    title: 'Preferências de conteúdo',
    options: { straight: 'Heterossexual', gay: 'Gay', trans: 'Trans' },
  },
  de: {
    title: 'Inhaltspräferenzen',
    options: { straight: 'Heterosexuell', gay: 'Schwul', trans: 'Trans' },
  },
  ja: {
    title: 'コンテンツの好み',
    options: { straight: 'ストレート', gay: 'ゲイ', trans: 'トランス' },
  },
  ko: {
    title: '콘텐츠 선호도',
    options: { straight: '스트레이트', gay: '게이', trans: '트랜스' },
  },
  zh: {
    title: '内容偏好',
    options: { straight: '异性恋', gay: '同性恋', trans: '跨性别' },
  },
  hi: {
    title: 'सामग्री प्राथमिकताएँ',
    options: { straight: 'स्ट्रेट', gay: 'गे', trans: 'ट्रांस' },
  },
  ru: {
    title: 'Тип контента',
    options: { straight: 'Натурал', gay: 'Гей', trans: 'Трансы' },
  },
};

const OrientationSelection = () => {
  const [selectedOrientation, setSelectedOrientation] = useState(
    localStorage.getItem('orientation') || 'straight'
  );
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');

  const handleSelection = (key) => {
    setSelectedOrientation(key);
    localStorage.setItem('orientation', key); // Save the key (straight, gay, trans)
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'en';
    setLanguage(savedLanguage);
  }, []);

  const translation = translations[language] || translations.en;

  return (
    <SelectionContainer>
      <Typography variant="h6">{translation.title}</Typography>
      <OptionButtons>
        {Object.entries(translation.options).map(([key, label]) => (
          <StyledButton
            key={key}
            variant={selectedOrientation === key ? 'contained' : 'outlined'}
            color={selectedOrientation === key ? 'primary' : 'default'}
            onClick={() => handleSelection(key)}
          >
            {label}
          </StyledButton>
        ))}
      </OptionButtons>
    </SelectionContainer>
  );
};

export default OrientationSelection;
