import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';

const translations = {
  en: {
    creditValue: '1 Picture =',
  },
  es: {
    creditValue: '1 Imagen =',
  },
  pt: {
    creditValue: '1 Imagem =',
  },
  de: {
    creditValue: '1 Bild =',
  },
  ja: {
    creditValue: '1枚の写真 =',
  },
  ko: {
    creditValue: '1 사진 =',
  },
  zh: {
    creditValue: '1 张图片 =',
  },
  hi: {
    creditValue: '1 तस्वीर =',
  },
  ru: {
    creditValue: '1 Картинка =',
  },
};

const BannerContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  borderRadius: '16px',
  padding: theme.spacing(2),
  background: 'linear-gradient(90deg, #1565C0, #42A5F5)', // Gradient for the banner
  color: '#FFFFFF', // White text
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center', // Center content
  boxShadow: theme.shadows[2],
  textAlign: 'center',
}));

const IconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const CreditAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(6),
}));

export default function CreditValueHelp({ value, language = 'en' }) {
  const translation = translations[language] || translations.en;

  return (
    <BannerContainer>
      <IconContainer>
        <Typography variant="h6" fontWeight="bold">
          {translation.creditValue} {value}
        </Typography>
        <CreditAvatar
          src={`${process.env.PUBLIC_URL}/assets/icons/webp/gem.webp`}
          alt="Diamond Icon"
        />
      </IconContainer>
    </BannerContainer>
  );
}
