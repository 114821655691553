// GalleryPage.js
import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Typography,
  LinearProgress,
  CssBaseline,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CollectionsIcon from '@mui/icons-material/Collections';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { auth, database } from '../../services/firebase';
import { useIndexedDB } from '../../services/useIndexedDB';
import { ref, get, set } from 'firebase/database';
import TemplateFrame from '../theme/TemplateFrame';
import getSignUpTheme from '../theme/theme/getSignUpTheme';
import MasonryGallery from '../../components/MasonryGallery';

const languageCodes = ['en', 'ru', 'es', 'pt', 'de', 'ja', 'ko', 'zh', 'hi']

const trnsl = {
  gallery: {
    en: "Gallery",
    ru: "Галлерея",
    es: "Galería",
    pt: "Galeria",
    de: "Galerie",
    ja: "ギャラリー",
    ko: "갤러리",
    zh: "画廊",
    hi: "गैलरी"
  },
  delete_all: {
    en: "Delete All",
    ru: "Удалить все",
    es: "Eliminar todo",
    pt: "Excluir tudo",
    de: "Alle löschen",
    ja: "すべて削除",
    ko: "모두 삭제",
    zh: "删除所有",
    hi: "सभी हटाएं"
  },
  delete_label: {
    en: "Delete All Media?",
    ru: "Удалить все фото?",
    es: "¿Eliminar todo el contenido?",
    pt: "Excluir toda a mídia?",
    de: "Alle Medien löschen?",
    ja: "すべてのメディアを削除しますか？",
    ko: "모든 미디어를 삭제하시겠습니까?",
    zh: "删除所有媒体？",
    hi: "सभी मीडिया हटाएं?"
  },
  delete_message: {
    en: "Are you sure you want to delete all media? This action cannot be undone",
    ru: "Вы уверены, что хотите удалить все фото? Это действие не может быть отменено",
    es: "¿Estás seguro de que quieres eliminar todo el contenido? Esta acción no se puede deshacer",
    pt: "Tem certeza de que deseja excluir toda a mídia? Esta ação não pode ser desfeita",
    de: "Sind Sie sicher, dass Sie alle Medien löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden",
    ja: "すべてのメディアを削除してもよろしいですか？この操作は元に戻せません",
    ko: "모든 미디어를 삭제하시겠습니까? 이 작업은 취소할 수 없습니다",
    zh: "您确定要删除所有媒体吗？此操作无法撤销",
    hi: "क्या आप वाकई सभी मीडिया हटाना चाहते हैं? इस क्रिया को पूर्ववत नहीं किया जा सकता"
  }
};

const GalleryContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  width: '100%',
}));

export default function GalleryPage() {
  
  const { dbInitialized, addImageToCache, getImageFromCache, clearImageCache } = useIndexedDB();

  const [mode, setMode] = useState('dark');
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const defaultTheme = createTheme({ palette: { mode } });
  const galleryTheme = createTheme(getSignUpTheme(mode));
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [credits, setCredits] = React.useState(null);
  const [openDeleteAllDialog, setOpenDeleteAllDialog] = useState(false);
  const [isTelegramWebApp, setIsTelegramWebApp] = useState(false);
  const [language, setLanguage] = useState("en");
  const navigate = useNavigate();

  useEffect(() => {
    // Wait for both user & db to be ready
    if (user && dbInitialized) {
      loadJobs(user.uid).then(() => setLoading(false));
    }
  }, [user, dbInitialized]);

  // Function to cleanup and reset Telegram main and secondary buttons
  const cleanupTelegramButtons = useCallback(() => {
    if (isTelegramWebApp && window.Telegram && window.Telegram.WebApp) {
      const MainButton = window.Telegram.WebApp.MainButton;
      const SecondaryButton = window.Telegram.WebApp.SecondaryButton;

      // Reset main button
      MainButton.offClick();
      MainButton.hide();
      MainButton.setParams({
        is_visible: false,
        is_active: false,
        has_shine_effect: false,
      });

      // Reset secondary button
      SecondaryButton.offClick();
      SecondaryButton.hide();
      SecondaryButton.setParams({
        is_visible: false,
        is_active: false,
        has_shine_effect: false,
      });
    }
  }, [isTelegramWebApp]);

  useEffect(() => {
    // Detect if we are in Telegram Web App
    if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initData) {
      setIsTelegramWebApp(true);

      // Set theme based on Telegram's colorScheme
      const telegramColorScheme = window.Telegram.WebApp.colorScheme;
      setMode(telegramColorScheme === 'dark' ? 'dark' : 'light');

      // Expand the Mini App if not expanded
      if (!window.Telegram.WebApp.isExpanded) {
        window.Telegram.WebApp.expand();
      }

      // Disable vertical swipes
      window.Telegram.WebApp.disableVerticalSwipes();

      // Signal that the app is ready
      window.Telegram.WebApp.ready();
    } else {
      const savedMode = localStorage.getItem('themeMode');
      setMode(savedMode ? savedMode : 'dark');
    }

    if (localStorage.getItem("language")) {
      setLanguage(localStorage.getItem("language"))
    }

    const token = localStorage.getItem('userToken');
    if (token) {
      auth.onAuthStateChanged(async (currentUser) => {
        if (currentUser) {
          setUser(currentUser);

          const interval = setInterval(() => {
            if (dbInitialized) {
              clearInterval(interval);
              setLoading(true);
              loadJobs(currentUser.uid).then(() => setLoading(false));
            }
          }, 200);
        } else {
          navigate('/signup');
        }
      });
    } else {
      navigate('/signup');
    }
  }, [navigate]);

  // Periodic refresh for processing jobs
  useEffect(() => {
    let intervalId;
    if (jobs.some((job) => job.status === 'processing' || job.status === 'queue')) {
      intervalId = setInterval(() => {
        if (user) {
          loadJobs(user.uid);
        }
      }, 5000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [jobs, user]);

  const loadJobs = async (userId) => {
    const db = database;

    try {
      const userRef = ref(db, `users/${userId}`);
      const userSnapshot = await get(userRef);
      const userData = userSnapshot.val();

      if (localStorage.getItem("language")) {
        setLanguage(localStorage.getItem("language"))
      } else if (userData?.analytics?.lang && languageCodes.includes(userData?.analytics?.lang)) {
        setLanguage(userData?.analytics?.lang)
      }

      const jobIdsFromFirebase = userSnapshot.exists() ? (userData?.jobs || []) : [];

      setCredits(userData?.credits || "Buy Credits");

      const jobDetails = await Promise.all(
        jobIdsFromFirebase.map(async (jobId) => {
          const jobRef = ref(db, `jobs/finished/${jobId}`);
          let jobSnapshot = await get(jobRef);
          if (!jobSnapshot.exists()) {
            jobSnapshot = await get(ref(db, `jobs/active/${jobId}`));
          }
          
          if (jobSnapshot.exists()) {
            const jobData = { jobId, ...jobSnapshot.val() };
            return jobData;
          }

          return null;
        })
      );

      const filteredJobDetails = jobDetails.filter((job) => job !== null);

      // Sort the jobs by timestamp in descending order
      const sortedJobs = filteredJobDetails.sort((a, b) => b.timestamp - a.timestamp);

      // Update component state
      setJobs(sortedJobs);
    } catch (error) {
      console.error('Error loading jobs:', error);
    }
  };

  const handleDeleteJob = async (jobId) => {
    const db = database;
    try {
      const userJobRef = ref(db, `users/${user.uid}/jobs`);
      const userJobsSnapshot = await get(userJobRef);
      if (userJobsSnapshot.exists()) {
        const jobIds = userJobsSnapshot.val();
        const jobIndex = jobIds.indexOf(jobId);
        if (jobIndex > -1) {
          jobIds.splice(jobIndex, 1);
          await set(userJobRef, jobIds);
        }
      }

      setJobs((prevJobs) => prevJobs.filter((job) => job.jobId !== jobId));

      // Clear image cache for the deleted job
      clearImageCache(jobId);
    } catch (error) {
      console.error('Error deleting job:', error);
    }
  };

  const handleDeleteAllJobs = async () => {
    const db = database;
    if (user) {
      try {
        // Remove all jobs from Firebase Database
        await set(ref(db, `users/${user.uid}/jobs`), []);

        // Clear IndexedDB for cached images
        clearImageCache();

        // Update component state
        setJobs([]);
        setOpenDeleteAllDialog(false);
      } catch (error) {
        console.error('Error deleting all jobs:', error);
      }
    }
  };

  const handleNavigateToJob = (jobId) => {
    navigate(`/gallery/${jobId}`);
  };

  // Setup telegram main button if needed
  useEffect(() => {
    if (isTelegramWebApp) {
      cleanupTelegramButtons(); // Always cleanup before setting up

      const MainButton = window.Telegram.WebApp.MainButton;
      const SecondButton = window.Telegram.WebApp.SecondaryButton;

      // By default, hide and reset both buttons
      MainButton.hide();
      MainButton.setParams({
        is_visible: false,
        is_active: false,
        has_shine_effect: false,
      });
      SecondButton.hide();
      SecondButton.setParams({
        is_visible: false,
        is_active: false,
        has_shine_effect: false,
      });

      if (jobs && jobs.length > 0) {
        const onClickHandler = () => {
          window.Telegram.WebApp.showPopup(
            {
              title: trnsl.delete_label?.[language] || trnsl.delete_label.en,
              message: trnsl.delete_message?.[language] || trnsl.delete_message.en,
              buttons: [
                { id: 'confirm', type: 'destructive', text: 'Delete All' },
                { id: 'cancel', type: 'cancel', text: 'Cancel' },
              ],
            },
            (buttonId) => {
              if (buttonId === 'confirm') {
                handleDeleteAllJobs();
              }
            }
          );
        };

        MainButton.offClick(onClickHandler); // remove just in case
        MainButton.onClick(onClickHandler);
        MainButton.setText('Delete All');
        MainButton.setParams({
          is_visible: true,
          is_active: true,
          color: '#FF0000',
          text_color: '#FFFFFF',
          has_shine_effect: false,
        });
        MainButton.show();

        return () => {
          MainButton.offClick(onClickHandler);
          MainButton.hide();
        };
      } else {
        // No jobs, no main button needed
        MainButton.offClick();
        MainButton.hide();
      }
    }
  }, [isTelegramWebApp, jobs, handleDeleteAllJobs, cleanupTelegramButtons]);

  return (
    <TemplateFrame
      toggleCustomTheme={() => setShowCustomTheme((prev) => !prev)}
      showCustomTheme={showCustomTheme}
      mode={mode}
      toggleColorMode={() => {
        const newMode = mode === 'dark' ? 'light' : 'dark';
        setMode(newMode);
        if (!isTelegramWebApp) {
          localStorage.setItem('themeMode', newMode);
        }
      }}
      credits={credits}
    >
      <ThemeProvider theme={showCustomTheme ? galleryTheme : defaultTheme}>
        <CssBaseline enableColorScheme />

        <GalleryContainer sx={{ marginBottom: 10 }}>
          <Box display="flex" alignItems="center" justifyContent="space-between" marginY={2} marginX={2}>
            <Typography variant="h4" component="h1" fontWeight="bold">
              <CollectionsIcon sx={{ verticalAlign: 'middle', marginX: 1 }} />
              {trnsl.gallery?.[language] || trnsl.gallery.en}
            </Typography>
            {jobs && jobs.length > 0 && !isTelegramWebApp && (
              <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => setOpenDeleteAllDialog(true)}
              >
                {trnsl.delete_all?.[language] || trnsl.delete_all.en}
              </Button>
            )}
          </Box>

          {loading ? (
            <LinearProgress sx={{ marginX: 2 }} />
          ) : (
            <MasonryGallery
              jobs={jobs}
              onDeleteJob={handleDeleteJob}
              onNavigateToJob={handleNavigateToJob}
            />
          )}
        </GalleryContainer>

        {/* Delete All Dialog */}
        {!isTelegramWebApp && (
          <Dialog open={openDeleteAllDialog} onClose={() => setOpenDeleteAllDialog(false)}>
            <DialogTitle sx={{ backgroundColor: galleryTheme.palette.background.paper}}>
              <Box sx={{ textAlign: 'center', mb: 2 }}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/webp/bomb.webp`}
                  alt="Delete All"
                  style={{ width: '40%', margin: '0 auto' }}
                />
              </Box>
              {trnsl.delete_label?.[language] || trnsl.delete_label.en}
            </DialogTitle>
            <DialogContent sx={{ backgroundColor: galleryTheme.palette.background.paper}}>
              <Typography variant="body2" color="textSecondary">
                {trnsl.delete_message?.[language] || trnsl.delete_message.en}
              </Typography>
            </DialogContent>
            <DialogActions sx={{ backgroundColor: galleryTheme.palette.background.paper}}>
              <Button
                variant="contained"
                color="error"
                fullWidth
                startIcon={<DeleteIcon />}
                onClick={handleDeleteAllJobs}
              >
                {trnsl.delete_all?.[language] || trnsl.delete_all.en}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </ThemeProvider>
    </TemplateFrame>
  );
}
