import React from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, Chip } from '@mui/material';

export default function SelectedProviderDropdown({ providers, selectedProvider, handleProviderSelection, providersData }) {
  return (
    <Box sx={{ width: '100%', mb: 10 }}>
      <FormControl fullWidth variant="outlined">
        <InputLabel>Provider</InputLabel>
        <Select
          value={selectedProvider || ''}
          onChange={(e) => handleProviderSelection(e.target.value)}
          label="Provider"
          MenuProps={{
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null, // Ensures that the dropdown starts from the anchor
          }}
        >
          {providers.map((providerId) => {
            const provider = providersData[providerId];
            return (
              <MenuItem key={providerId} value={providerId}>
                {provider.name || provider.id}
                {provider.fee > 0 && (
                  <Chip label={`+${provider.fee}%`} sx={{ ml: 1 }} />
                )}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}