// FeedPage.js
import React, { useEffect, useState } from 'react';

import { database, auth } from '../../services/firebase';
import { ref, get, getDatabase } from 'firebase/database';
import { signInWithCustomToken } from 'firebase/auth';

import { useNavigate, useLocation } from 'react-router-dom';

import {
  Box,
  Tabs,
  Tab,
  LinearProgress,
  CssBaseline,
  Avatar,
  AvatarGroup,
  Snackbar,
  Alert,
} from '@mui/material';
import FeedIcon from '@mui/icons-material/Feed';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import TemplateFrame from '../theme/TemplateFrame';
import getSignUpTheme from '../theme/theme/getSignUpTheme';
import MasonryFeed from '../../components/MasonryFeed';
import ModelsList from '../../components/ModelsList';
import BundlesList from '../../components/BundlesList';
import StatusDialog from '../../components/SubmitDialog';
import WebStoriesIcon from '@mui/icons-material/WebStories';
const FeedContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  width: '100%',
}));

export default function FeedPage() {
  const [mode, setMode] = useState('light');
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const defaultTheme = createTheme({ palette: { mode } });
  const galleryTheme = createTheme(getSignUpTheme(mode));

  const [media, setMedia] = useState([]);
  const [models, setModels] = useState([]);
  const [bundles, setBundles] = useState([]);

  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [showAdultContent, setShowAdultContent] = useState(null);
  const [avatarIcons, setAvatarIcons] = useState([]);
  const [user, setUser] = useState(null);
  const [credits, setCredits] = useState(null);

  const [isTelegramWebApp, setIsTelegramWebApp] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [dialogData, setDialogData] = useState({
    title: '',
    description: '',
    supportEmail: '',
  });

  const navigate = useNavigate();

  // Load user data and check adult access
  useEffect(() => {
    // Detect if the app is opened via Telegram Mini App
    if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initData) {
      setIsTelegramWebApp(true);

      // Set theme based on Telegram's colorScheme
      const telegramColorScheme = window.Telegram.WebApp.colorScheme;
      setMode(telegramColorScheme === 'dark' ? 'dark' : 'light');

      // Expand the Mini App if not expanded
      if (!window.Telegram.WebApp.isExpanded) {
        window.Telegram.WebApp.expand();
      }

      // Disable vertical swipes
      window.Telegram.WebApp.disableVerticalSwipes();

      // Signal that the app is ready
      window.Telegram.WebApp.ready();
    } else {
      // Not in Telegram Web App
      const savedMode = localStorage.getItem('themeMode');
      if (savedMode) {
        setMode(savedMode);
      } else {
        const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
        setMode(systemPrefersDark ? 'dark' : 'light');
      }
    }

    const token = localStorage.getItem('userToken');
    if (token) {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          setUser(user);
          console.log(user.uid);
          // Fetch user data from the database
          const db = getDatabase();
          const userRef = ref(db, `users/${user.uid}`);
          const snapshot = await get(userRef);
          console.log(snapshot);
          if (snapshot.exists()) {
            const userData = snapshot.val();
            setCredits(userData.credits || 0);

            if (userData.adult) {
              setShowAdultContent(true);
            }
            else setShowAdultContent(false);
          }
        } else {
          setShowAdultContent(false);
        }
      });
    } else {
      setShowAdultContent(false);
    }
  }, []);

  // Authenticate Telegram users
  useEffect(() => {
    const authenticateTelegramUser = async () => {
      if (isTelegramWebApp && !user) {
        const initData = window.Telegram.WebApp.initData;

        if (initData) {
          // Send initData as is
          const formBody = `initData=${encodeURIComponent(initData)}`;

          try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/verify-telegram-webapp`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
              body: formBody,
            });

            const result = await response.json();

            if (result.success && result.customToken) {
              await signInWithCustomToken(auth, result.customToken);
              const idToken = await auth.currentUser.getIdToken();
              localStorage.setItem('userToken', idToken);
              setUser(auth.currentUser);
              // Fetch user data from the database
              const db = getDatabase();
              const userRef = ref(db, `users/${auth.currentUser.uid}`);
              const snapshot = await get(userRef);
              if (snapshot.exists()) {
                const userData = snapshot.val();
                setCredits(userData.credits || 0);
                if (userData.adult) {
                  setShowAdultContent(true);
                } else {
                  setShowAdultContent(false);
                }
              }

              if (result?.navigator) {
                navigate(result.navigator);
              }
            } else {
              console.error('Telegram authentication failed:', result.error);
              setSubmitError(result.error || 'Authentication failed.');
              setShowAdultContent(false);
            }
          } catch (error) {
            console.error('Error during Telegram authentication:', error);
            setSubmitError('An error occurred during authentication.');
            setShowAdultContent(false);
          }
        }
      }
    };

    authenticateTelegramUser();
  }, [isTelegramWebApp, user, navigate]);

  useEffect(() => {
    if (showAdultContent !== null) {
      loadFeedData(); // Load feed data only when `showAdultContent` is determined
    }
  }, [showAdultContent]);

  const loadFeedData = async () => {
    try {
      setLoading(true);

      // Check localStorage for feedData and timestamp
      let cachedData = localStorage.getItem('feedData');
      let cachedTimestamp = localStorage.getItem('feedDataTimestamp');

      const now = Math.floor(Date.now() / 1000);

      const FEED_DATA_CACHE_DURATION = 10 * 60; // 12 hours in milliseconds
      console.log(`has cached data? ${(cachedData && cachedTimestamp && now - parseInt(cachedTimestamp) < FEED_DATA_CACHE_DURATION)}`)

      if (cachedData && cachedTimestamp && now - parseInt(cachedTimestamp) < FEED_DATA_CACHE_DURATION) {
        // Use cached data
        const feedData = JSON.parse(cachedData);

        if (feedData.feed?.length === 0) cachedTimestamp = 0;

        setMedia(feedData.feed || []);
        setModels(feedData.models || []);
        setBundles(feedData.bundles || []);
        
        if (feedData.models && feedData.models.length > 0) {
          const avatars = feedData.models.map((model) => model.avatar);
          setAvatarIcons(avatars);
        }
      } 
      if (!cachedData || !cachedTimestamp || (now - parseInt(cachedTimestamp) >= FEED_DATA_CACHE_DURATION)) {
        // Fetch from server
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/feed`, {
          method: 'GET',
          headers: {
            showadultcontent: showAdultContent ? 'true' : 'false',
          },
        });

        if (response.ok) {
          const result = await response.json();

          setMedia(result.feed || []);
          setModels(result.models || []);
          setBundles(result.bundles || []);

          if (result.models && result.models.length > 0) {
            const avatars = result.models.map((model) => model.avatar);
            setAvatarIcons(avatars);
          }

          // Save data and timestamp to localStorage
          const feedDataToCache = {
            feed: result.feed || [],
            models: result.models || [],
            bundles: result.bundles || [],
          };
          localStorage.setItem('feedData', JSON.stringify(feedDataToCache));
          localStorage.setItem('feedDataTimestamp', now.toString());
        } else {
          console.error('Error fetching feed data:', response.statusText);
        }
      }
    } catch (error) {
      console.error('Error loading feed data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const toggleColorMode = () => {
    const newMode = mode === 'dark' ? 'light' : 'dark';
    setMode(newMode);
    if (!isTelegramWebApp) {
      localStorage.setItem('themeMode', newMode);
    }
  };

  return (
    <TemplateFrame
      toggleCustomTheme={() => setShowCustomTheme((prev) => !prev)}
      showCustomTheme={showCustomTheme}
      mode={mode}
      toggleColorMode={toggleColorMode}
      credits={credits}
      needPadding={true}
    >
      <ThemeProvider theme={showCustomTheme ? galleryTheme : defaultTheme}>
        <CssBaseline enableColorScheme />

        <FeedContainer sx={{ marginBottom: 10 }}>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={tabValue}
            onChange={handleChangeTab}
            aria-label="Feed Tabs"
            sx={{
              marginX: 2,
              marginY: 2,
              width: '90%',
              display: 'flex',
              justifyContent: { xs: 'center', md: 'flex-start' },
            }}
          >
            <Tab icon={<FeedIcon />} label="Feed" />

            {bundles.length > 0 && (
              <Tab
                icon={<WebStoriesIcon />}
                label="Bundles"
              />
            )}

            {models.length > 0 && (
              <Tab
                icon={
                  <AvatarGroup max={3} sx={{ '& .MuiAvatar-root': { width: 24, height: 24 } }}>
                    {avatarIcons.map((avatarUrl, index) => (
                      <Avatar
                        key={index}
                        alt={`Model ${index + 1}`}
                        src={avatarUrl}
                        imgProps={{ loading: 'lazy' }}
                      />
                    ))}
                  </AvatarGroup>
                }
                label="Models"
              />
            )}
          </Tabs>

          {loading ? (
            <LinearProgress sx={{ marginX: 2 }} />
          ) : tabValue === 0 ? (
            <MasonryFeed media={media} />
          ) : tabValue === 1 ? (
            <BundlesList bundles={bundles} language_code={"en"} />
          ) : (
            <ModelsList models={models} />
          )}
        </FeedContainer>

        {/* Error Snackbar */}
        {!isTelegramWebApp && (
          <Snackbar
            open={!!submitError}
            autoHideDuration={6000}
            onClose={() => setSubmitError(null)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={{ width: '100%' }}
          >
            <Alert onClose={() => setSubmitError(null)} severity="error" sx={{ width: '100%' }}>
              {submitError}
            </Alert>
          </Snackbar>
        )}

        {/* Dialog Component */}
        {!isTelegramWebApp && (
          <StatusDialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            dialogType={dialogType}
            title={dialogData.title}
            description={dialogData.description}
            supportEmail={dialogData.supportEmail}
            handlePrimaryAction={() => {
              if (dialogType === 'credits') {
                navigate('/shop');
              } else if (dialogType === 'submitted') {
                navigate(`/gallery/${dialogData.jobId}`);
              } else if (dialogType === 'proveface') {
                // performSubmitJob();
              }
            }}
            jobId={dialogData.jobId}
          />
        )}
      </ThemeProvider>
    </TemplateFrame>
  );
}
