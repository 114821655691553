import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { useNavigate } from 'react-router-dom';
import { database } from '../services/firebase';
import { ref, get } from 'firebase/database';
import ModelWidget from "../components/ModelWidget";

const Item = styled(Card)(({ theme }) => ({
  position: 'relative',
  width: '90%',
  overflow: 'hidden',
  margin: theme.spacing(2),
}));

const TransparentOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'transparent',
  zIndex: 10,
  cursor: 'default', // Use standard cursor
}));

export default function FeedItem({ media }) {
  const [modelData, setModelData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Load model data if media contains a model_id
    if (media.model_id) {
      loadModelData(media.model_id);
    }
  }, [media]);

  const loadModelData = async (modelId) => {
    const db = database;
    try {
      const modelRef = ref(db, `models/${modelId}`);
      const modelSnapshot = await get(modelRef);
      if (modelSnapshot.exists()) {
        setModelData(modelSnapshot.val());
      } else {
        console.error('Model data not found');
      }
    } catch (error) {
      console.error('Error fetching model data:', error);
    }
  };

  const handleUsePromptClick = () => {
    let navigateUrl = "/create"

    if (media.model_id) {
      navigateUrl += `?model_id=${encodeURIComponent(media.model_id)}`
      navigate(`/create?prompt=${encodeURIComponent(media.prompt)}`);
    }
    if (media && media.prompt) {
      navigateUrl += `?prompt=${encodeURIComponent(media.prompt)}`
    }
    else if (media && media.custom_instructions) {
      navigateUrl += `?prompt=${encodeURIComponent(media.custom_instructions)}`
    }
    navigate(navigateUrl);
  };

  const handleModelWidgetClick = (modelId) => {
    navigate(`/model/${modelId}`);
  };

  const handleImageClick = () => {
    navigate(`/feed/${media.jobId}`);
  };

  return (
    <Item>
      {/* Media Image with navigation to media detail page */}
      <CardActionArea onClick={handleImageClick} sx={{ position: 'relative', width: '100%' }}>
        <CardMedia
          component="img"
          image={media.link}
          alt={`Media ${media.jobId}`}
          sx={{ cursor: 'pointer', borderRadius: 1, width: '100%' }}
        />
        <TransparentOverlay />
      </CardActionArea>

      {/* Use Prompt Button and Model Widget */}
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          {modelData && (
            <CardActionArea
              onClick={() => handleModelWidgetClick(media.model_id)}
              sx={{ width: "100%", borderRadius: '10px', marginTop: 1 }}
            >
              <ModelWidget
                name={modelData.name}
                imageUrl={modelData.avatar}
                mediaAmount={modelData.media?.length || "10+"}
                verified={modelData?.verified || false}
                id={media.model_id}
              />
            </CardActionArea>
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<AutoAwesomeIcon />}
            onClick={handleUsePromptClick}
          >
            Use Prompt
          </Button>
        </Box>
      </CardContent>
    </Item>
  );
}
