import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Typography,
  Chip,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { getDatabase, ref, get } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';

const ChipContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  minHeight: '56px',
}));

const CustomChip = styled(Chip)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius / 2,
  padding: theme.spacing(0.5, 1),
  fontSize: '3rem',
}));

export default function CustomInstructionsInput({ customInstructionsChips, setCustomInstructionsChips, user, isTelegramWebApp, hasPurchases = false }) {
  const [customInstructions, setCustomInstructions] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const navigate = useNavigate();

  const handleCustomInstructionsClick = () => {
    if (hasPurchases === false) {
      if (isTelegramWebApp) {
        window.Telegram.WebApp.showPopup(
          {
            title: 'Custom Instructions Locked',
            message: 'Custom instructions are available only after purchasing credits.',
            buttons: [
              { id: 'shop', type: 'default', text: 'Go to Shop' },
              { id: 'close', type: 'close', text: 'Close' },
            ],
          },
          (buttonId) => {
            if (buttonId === 'shop') {
              navigate('/shop');
            }
          }
        );
      } else {
        setShowDialog(true);
      }
    }
  };

  const handleCustomInstructionsChange = (e) => {
    const value = e.target.value;
    if (hasPurchases === false) {
      return;
    }
    if (value.endsWith(',')) {
      const newInstruction = value.slice(0, -1).trim();
      if (newInstruction && countTotalCharacters() + newInstruction.length <= 300) {
        setCustomInstructionsChips([...customInstructionsChips, newInstruction]);
      }
      setCustomInstructions('');
    } else {
      setCustomInstructions(value);
    }
  };

  const handleDeleteChip = (chipToDelete) => {
    setCustomInstructionsChips((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  const countTotalCharacters = () => {
    return customInstructionsChips.join('').length + customInstructions.length;
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        <Box display="flex" alignItems="center" gap={1}>
          <IntegrationInstructionsIcon />
          Custom Instructions
        </Box>
      </Typography>
      <ChipContainer onClick={handleCustomInstructionsClick}>
        {customInstructionsChips.map((chip, index) => (
          <CustomChip key={index} label={chip} onDelete={() => handleDeleteChip(chip)} />
        ))}
        <TextField
          value={customInstructions}
          onChange={handleCustomInstructionsChange}
          placeholder="Write your custom instructions here..."
          variant="outlined"
          inputProps={{ maxLength: 300 }}
          multiline
          fullWidth
          sx={{
            flex: 1,
            minWidth: '150px',
            margin: (theme) => theme.spacing(0.5),
          }}
        />
      </ChipContainer>
      <Typography variant="caption" sx={{ mt: 1 }}>
        {`${countTotalCharacters()}/300 characters`}
      </Typography>

      {/* Dialog */}
      {!isTelegramWebApp && (
        <Dialog open={showDialog} onClose={() => setShowDialog(false)} maxWidth="sm" fullWidth>
          <DialogTitle>Custom Instructions Locked</DialogTitle>
          <DialogContent>
            <Box sx={{ textAlign: 'center' }}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/icons/webp/sparkles.webp`}
                alt="Custom Instructions Locked"
                style={{ width: '40%', marginBottom: 16 }}
              />
              <Typography>
                Custom instructions are available only after purchasing credits.
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => navigate('/shop')} color="primary" variant="contained">
              Go to Shop
            </Button>
            <Button onClick={() => setShowDialog(false)} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}