import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { InputAdornment } from '@mui/material';
import BadgeIcon from '@mui/icons-material/Badge';

export default function ReferralSection({ uid }) {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(uid || '');
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography component="h2" variant="h5" sx={{ fontWeight: 'bold' }}>
        <Box display="flex" alignItems="center" gap={1}>
            <BadgeIcon />
            User ID
        </Box>
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography variant="body1">To contact support, you must provide them your User ID. Do not share with anyone</Typography>
      </Box>
      <TextField
        fullWidth
        label="User ID"
        value={uid || ''}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleCopy}>
                {copied ? <DoneIcon color="success" /> : <ContentCopyIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        variant="outlined"
        sx={{ '& .MuiInputBase-root': { textAlign: 'center' } }}
      />
    </Box>
  );
}
