import React, { useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Dialog,
  Slide,
  Box,
  Divider,
  TextField,
  InputAdornment,
  DialogContent,
  DialogTitle,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import getSignUpTheme from '../pages/theme/theme/getSignUpTheme';

function validateName(name) {
  return /^[A-Za-z\s]+$/.test(name.trim()) && name.trim().length > 0;
}
function validateEmail(email) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email.trim());
}
function validatePhone(phone) {
  const re = /^\+[1-9]\d{6,14}$/;
  return re.test(phone.trim());
}

const translations = {
  en: {
    title: 'Last step (3/3)',
    confirm: 'Confirm',
    instructions: 'Please provide the following details before proceeding with your payment.',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    phoneNumber: 'Phone Number',
    emailPlaceholder: 'email@example.com',
    phonePlaceholder: '+14155552671',
    firstNameError: 'Please enter a valid first name (letters and spaces only).',
    lastNameError: 'Please enter a valid last name (letters and spaces only).',
    emailError: 'Please enter a valid email address.',
    phoneError: 'Phone must start with +countrycode and contain 7-15 digits total (e.g. +14155552671).',
    compliance: 'We do not store or use your personal data in any way. This data is securely passed to the payment provider in compliance with GDPR and CCPA regulations.',
    isAdultBenefits: [
      '✅ No adult transaction in your bank statement',
      '✅ No recurring fees or subscriptions',
    ],
  },
  es: {
    title: 'Último paso (3/3)',
    confirm: 'Confirmar',
    instructions: 'Por favor, proporcione los siguientes detalles antes de continuar con su pago.',
    firstName: 'Nombre',
    lastName: 'Apellido',
    email: 'Correo electrónico',
    phoneNumber: 'Número de teléfono',
    emailPlaceholder: 'correo@example.com',
    phonePlaceholder: '+34123456789',
    firstNameError: 'Por favor, introduzca un nombre válido (solo letras y espacios).',
    lastNameError: 'Por favor, introduzca un apellido válido (solo letras y espacios).',
    emailError: 'Por favor, introduzca una dirección de correo electrónico válida.',
    phoneError: 'El teléfono debe comenzar con +código de país y contener de 7 a 15 dígitos en total (ej. +34123456789).',
    compliance: 'No almacenamos ni utilizamos sus datos personales de ninguna manera. Estos datos se envían de forma segura al proveedor de pagos en cumplimiento con las normativas GDPR y CCPA.',
    isAdultBenefits: [
      '✅ Sin transacciones para adultos en su estado bancario',
      '✅ Sin cuotas recurrentes o suscripciones',
    ],
  },
  pt: {
    title: 'Última etapa (3/3)',
    confirm: 'Confirmar',
    instructions: 'Por favor, forneça os detalhes abaixo antes de prosseguir com o pagamento.',
    firstName: 'Nome',
    lastName: 'Sobrenome',
    email: 'Email',
    phoneNumber: 'Número de telefone',
    emailPlaceholder: 'email@exemplo.com',
    phonePlaceholder: '+5511987654321',
    firstNameError: 'Por favor, insira um nome válido (apenas letras e espaços).',
    lastNameError: 'Por favor, insira um sobrenome válido (apenas letras e espaços).',
    emailError: 'Por favor, insira um endereço de email válido.',
    phoneError: 'O número deve começar com +código do país e conter entre 7 e 15 dígitos (ex. +5511987654321).',
    compliance: 'Não armazenamos ou utilizamos seus dados pessoais de nenhuma maneira. Estes dados são enviados de forma segura ao provedor de pagamento em conformidade com os regulamentos GDPR e CCPA.',
    isAdultBenefits: [
      '✅ Sem transações adultas no extrato bancário',
      '✅ Sem taxas recorrentes ou assinaturas',
    ],
  },
  de: {
    title: 'Letzter Schritt (3/3)',
    confirm: 'Bestätigen',
    instructions: 'Bitte geben Sie die folgenden Daten ein, bevor Sie mit der Zahlung fortfahren.',
    firstName: 'Vorname',
    lastName: 'Nachname',
    email: 'E-Mail',
    phoneNumber: 'Telefonnummer',
    emailPlaceholder: 'email@beispiel.com',
    phonePlaceholder: '+4915123456789',
    firstNameError: 'Bitte geben Sie einen gültigen Vornamen ein (nur Buchstaben und Leerzeichen).',
    lastNameError: 'Bitte geben Sie einen gültigen Nachnamen ein (nur Buchstaben und Leerzeichen).',
    emailError: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
    phoneError: 'Die Telefonnummer muss mit +Ländervorwahl beginnen und 7-15 Ziffern enthalten (z. B. +4915123456789).',
    compliance: 'Wir speichern oder verwenden Ihre persönlichen Daten in keiner Weise. Diese Daten werden sicher an den Zahlungsanbieter weitergeleitet und entsprechen den GDPR- und CCPA-Vorschriften.',
    isAdultBenefits: [
      '✅ Keine Erwähnung von Erwachseneninhalten auf Ihrem Kontoauszug',
      '✅ Keine wiederkehrenden Gebühren oder Abonnements',
    ],
  },
  ja: {
    title: '最終ステップ (3/3)',
    confirm: '確認',
    instructions: 'お支払いを進める前に以下の詳細を入力してください。',
    firstName: '名',
    lastName: '姓',
    email: 'メールアドレス',
    phoneNumber: '電話番号',
    emailPlaceholder: 'メール@example.com',
    phonePlaceholder: '+819012345678',
    firstNameError: '有効な名前を入力してください（文字とスペースのみ）。',
    lastNameError: '有効な姓を入力してください（文字とスペースのみ）。',
    emailError: '有効なメールアドレスを入力してください。',
    phoneError: '電話番号は+国番号で始まり、7〜15桁の数字を含める必要があります（例：+819012345678）。',
    compliance: 'お客様の個人データは一切保存または利用されません。このデータは、GDPRおよびCCPA規制に準拠して、支払いプロバイダーに安全に送信されます。',
    isAdultBenefits: [
      '✅ 銀行取引明細書にアダルト取引なし',
      '✅ 定期料金やサブスクリプションなし',
    ],
  },
  ko: {
    title: '마지막 단계 (3/3)',
    confirm: '확인',
    instructions: '결제를 진행하기 전에 다음 세부 정보를 입력하세요.',
    firstName: '이름',
    lastName: '성',
    email: '이메일',
    phoneNumber: '전화번호',
    emailPlaceholder: '이메일@example.com',
    phonePlaceholder: '+821012345678',
    firstNameError: '유효한 이름을 입력하세요 (문자와 공백만 허용).',
    lastNameError: '유효한 성을 입력하세요 (문자와 공백만 허용).',
    emailError: '유효한 이메일 주소를 입력하세요.',
    phoneError: '전화번호는 +국가코드로 시작해야 하며 총 7~15자리여야 합니다 (예: +821012345678).',
    compliance: '귀하의 개인 데이터는 어떠한 방식으로도 저장하거나 사용되지 않습니다. 이 데이터는 GDPR 및 CCPA 규정을 준수하여 결제 제공업체에 안전하게 전송됩니다.',
    isAdultBenefits: [
      '✅ 은행 명세서에 성인 거래 없음',
      '✅ 정기 요금 또는 구독 없음',
    ],
  },
  zh: {
    title: '最后一步 (3/3)',
    confirm: '确认',
    instructions: '请在继续支付之前提供以下详细信息。',
    firstName: '名字',
    lastName: '姓氏',
    email: '邮箱',
    phoneNumber: '电话号码',
    emailPlaceholder: '邮箱@example.com',
    phonePlaceholder: '+8612345678901',
    firstNameError: '请输入有效的名字（仅字母和空格）。',
    lastNameError: '请输入有效的姓氏（仅字母和空格）。',
    emailError: '请输入有效的电子邮件地址。',
    phoneError: '电话号码必须以+国家代码开头，并且总共包含7-15位数字（例如：+8612345678901）。',
    compliance: '我们不会以任何方式存储或使用您的个人数据。这些数据会根据GDPR和CCPA法规安全地传递给支付提供商。',
    isAdultBenefits: [
      '✅ 银行账单中无成人交易',
      '✅ 无经常性费用或订阅',
    ],
  },
  hi: {
    title: 'अंतिम चरण (3/3)',
    confirm: 'पुष्टि करें',
    instructions: 'कृपया भुगतान के साथ आगे बढ़ने से पहले निम्नलिखित विवरण प्रदान करें।',
    firstName: 'पहला नाम',
    lastName: 'अंतिम नाम',
    email: 'ईमेल',
    phoneNumber: 'फोन नंबर',
    emailPlaceholder: 'email@example.com',
    phonePlaceholder: '+911234567890',
    firstNameError: 'कृपया मान्य पहला नाम दर्ज करें (केवल अक्षर और स्थान)।',
    lastNameError: 'कृपया मान्य अंतिम नाम दर्ज करें (केवल अक्षर और स्थान)।',
    emailError: 'कृपया एक मान्य ईमेल पता दर्ज करें।',
    phoneError: 'फोन नंबर +देश कोड से शुरू होना चाहिए और कुल 7-15 अंकों का होना चाहिए (उदा: +911234567890)।',
    compliance: 'हम आपके व्यक्तिगत डेटा को किसी भी तरह से संग्रहीत या उपयोग नहीं करते हैं। यह डेटा GDPR और CCPA नियमों का पालन करते हुए सुरक्षित रूप से भुगतान प्रदाता को भेजा जाता है।',
    isAdultBenefits: [
      '✅ आपके बैंक स्टेटमेंट में कोई वयस्क लेनदेन नहीं',
      '✅ कोई आवर्ती शुल्क या सदस्यता नहीं',
    ],
  },
  ru: {
    title: 'Последний шаг (3/3)',
    confirm: 'Подтвердить',
    instructions: 'Пожалуйста, предоставьте следующую информацию перед продолжением оплаты.',
    firstName: 'Имя',
    lastName: 'Фамилия',
    email: 'Электронная почта',
    phoneNumber: 'Номер телефона',
    emailPlaceholder: 'почта@example.com',
    phonePlaceholder: '+79123456789',
    firstNameError: 'Пожалуйста, введите действительное имя (только буквы и пробелы).',
    lastNameError: 'Пожалуйста, введите действительную фамилию (только буквы и пробелы).',
    emailError: 'Пожалуйста, введите действительный адрес электронной почты.',
    phoneError: 'Номер телефона должен начинаться с +код страны и содержать от 7 до 15 цифр (например: +79123456789).',
    compliance: 'Мы не храним и не используем ваши персональные данные каким-либо образом. Эти данные безопасно передаются поставщику платежей в соответствии с правилами GDPR и CCPA.',
    isAdultBenefits: [
      '✅ Без упоминания о контенте для взрослых в выписке',
      '✅ Без подписок или регулярных платежей',
    ],
  },
};


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PaymentInfo({
  open,
  onClose,
  dataRequest,
  onConfirm,
  formData,
  setFormData,
  submitRequested,
  isAdult = false,
  language = 'en',
  mode = 'dark'
}) {
  // Ensure formData is always defined to prevent "formData is undefined" error
  formData = formData || {};

  const pagetheme = createTheme(getSignUpTheme(mode));

  // Local state for errors
  const [errors, setErrors] = React.useState({});
  const translation = translations[language] || translations.en;

  // Confirm if submitRequested changes to true and no errors remain
  useEffect(() => {
    if (submitRequested && open) {
      confirmAndClose();
    }
  }, [submitRequested, open]);

  const confirmAndClose = () => {
    let newErrors = {};
    if (dataRequest.includes('first_name') && !validateName(formData.first_name || '')) {
      newErrors.first_name = translation.firstNameError;
    }
    if (dataRequest.includes('last_name') && !validateName(formData.last_name || '')) {
      newErrors.last_name = translation.lastNameError;
    }
    if (dataRequest.includes('email') && !validateEmail(formData.email || '')) {
      newErrors.email = translation.emailError;
    }
    if (dataRequest.includes('phone_number') && !validatePhone(formData.phone_number || '')) {
      newErrors.phone_number = translation.phoneError;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // If no errors, confirm
    onConfirm(formData);
  };

  const commonInputProps = {
    InputProps: { style: { fontSize: '16px' } },
    InputLabelProps: { style: { fontSize: '16px' } }
  };

  return (
    <Dialog
      fullScreen={useMediaQuery(pagetheme.breakpoints.down('md'))} 
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: { 
          [pagetheme.breakpoints.up('lg')]: {
            width: '50vw',
            margin: 'auto',
            // Example: top corners rounded, bottom corners straight
            borderTopLeftRadius: 36,
            borderTopRightRadius: 36,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
          [pagetheme.breakpoints.down('lg')]: {
            marginTop: 36,
          },
          backgroundColor: pagetheme.palette.background.paper,
        }
      }}
      sx={{ zIndex: 9999 }}
    >
      <DialogTitle sx={{ backgroundColor: pagetheme.palette.background.paper}}>
        <Toolbar>
          <IconButton edge="start" sx={{borderColor: "transparent", backgroundColor: "transparent"}} onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6">
            {translation.title}
          </Typography>
          <Button color="inherit" onClick={confirmAndClose}>
          <Typography variant="button">
            {translation.confirm}
          </Typography>
          </Button>
        </Toolbar>
      </DialogTitle>
      <DialogContent
        sx={{ backgroundColor: pagetheme.palette.background.paper }}
      >
        <Typography variant="body1" sx={{marginBottom: 3}}>
          {translation.instructions}
        </Typography>
        {dataRequest.includes('first_name') && (
          <TextField
            {...commonInputProps}
            label={translation.firstName}
            fullWidth
            value={formData.first_name || ''}
            onChange={e => setFormData(prev => ({ ...prev, first_name: e.target.value }))}
            error={!!errors.first_name}
            helperText={errors.first_name}
          />
        )}
        {dataRequest.includes('last_name') && (
          <TextField
            {...commonInputProps}
            label={translation.lastName}
            fullWidth
            value={formData.last_name || ''}
            onChange={e => setFormData(prev => ({ ...prev, last_name: e.target.value }))}
            error={!!errors.last_name}
            helperText={errors.last_name}
          />
        )}
        {dataRequest.includes('phone_number') && (
          <TextField
            {...commonInputProps}
            label={translation.phoneNumber}
            placeholder={translation.phonePlaceholder}
            fullWidth
            value={formData.phone_number || ''}
            onChange={e => setFormData(prev => ({ ...prev, phone_number: e.target.value }))}
            error={!!errors.phone_number}
            helperText={errors.phone_number}
            InputProps={{
              ...commonInputProps.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon />
                  <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                </InputAdornment>
              ),
            }}
          />
        )}
        {dataRequest.includes('email') && (
          <TextField
            {...commonInputProps}
            label={translation.email}
            placeholder={translation.emailPlaceholder}
            fullWidth
            value={formData.email || ''}
            onChange={e => setFormData(prev => ({ ...prev, email: e.target.value }))}
            error={!!errors.email}
            helperText={errors.email}
            InputProps={{
              ...commonInputProps.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                  <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                </InputAdornment>
              ),
            }}
          />
        )}
        <Typography variant="body1" sx={{marginTop: 4, marginBottom: 2}}>
          {translation.compliance}
        </Typography>
        {isAdult && (
          <>
            <Divider />
            {translation.isAdultBenefits.map((benefit, index) => (
              <Typography key={index} variant="body1">
                {benefit}
              </Typography>
            ))}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
