import React from 'react';
import {
  Box,
  Avatar,
  Typography,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';

const CreditsWidgetContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  borderRadius: '16px',
  padding: theme.spacing(1),
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  background: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
}));

const GradientOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: `linear-gradient(to top, ${alpha(theme.palette.background.paper, 0.8)}, ${alpha(theme.palette.primary.main, 0)})`,
  borderRadius: 'inherit',
}));

const CreditsAvatar = styled(Avatar)(({ theme }) => ({
  height: '100%',
  width: 'auto',
  aspectRatio: '1 / 1',
  marginRight: theme.spacing(1),
  maxHeight: theme.spacing(6),
}));

const TextContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  marginRight: theme.spacing(1),
  overflow: 'hidden',
}));

const TitleRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '0.25em',
});

const trnsl = {
  delete: {
    title: {
      en: "Deletes in",
      ru: "Удаляется через",
      es: "Se elimina en",
      pt: "Exclui em",
      de: "Löscht in",
      ja: "削除までの時間",
      ko: "삭제까지 남은 시간",
      zh: "将在以下时间删除",
      hi: "में हट जाएगा"
    },
    message: {
      en: "This job will be deleted in $TIME unless you make a purchase",
      ru: "Это задание будет удалено через $TIME, если вы не сделаете покупку",
      es: "Este trabajo se eliminará en $TIME a menos que realices una compra",
      pt: "Este trabalho será excluído em $TIME, a menos que você faça uma compra",
      de: "Dieser Auftrag wird in $TIME gelöscht, es sei denn, Sie tätigen einen Kauf",
      ja: "このジョブは $TIME 以内に購入しないと削除されます",
      ko: "이 작업은 $TIME 내에 구매하지 않으면 삭제됩니다",
      zh: "此任务将在 $TIME 内被删除，除非您进行购买",
      hi: "यह कार्य $TIME में हटा दिया जाएगा जब तक आप खरीदारी नहीं करते"
    },
  },
  waiting: {
    title: {
      en: "Processing other images",
      ru: "Обработка, еще",
      es: "Procesando otras imágenes",
      pt: "Processando outras imagens",
      de: "Verarbeitung anderer Bilder",
      ja: "他の画像を処理しています",
      ko: "다른 이미지를 처리 중",
      zh: "正在处理其他图像",
      hi: "अन्य छवियों को संसाधित कर रहा है"
    },
    message: {
      en: "Wait time:",
      ru: "Время ожидания:",
      es: "Tiempo de espera:",
      pt: "Tempo de espera:",
      de: "Wartezeit:",
      ja: "待ち時間:",
      ko: "대기 시간:",
      zh: "等待时间:",
      hi: "प्रतीक्षा समय:" 
    }
  },
  unblur: {
    title: {
      en: "Unblur with Credits",
      ru: "Разблокируйте с помощью кредитов",
      es: "Desbloquear con créditos",
      pt: "Desbloquear com créditos",
      de: "Mit Credits entsperren",
      ja: "クレジットでぼかしを解除",
      ko: "크레딧으로 흐림 해제",
      zh: "使用积分解锁",
      hi: "क्रेडिट के साथ धुंध हटाएं"
    },
    message: {
      en: "Unlock all images by purchasing any amount of credits",
      ru: "Разблокируйте все изображения, купив любое количество кредитов",
      es: "Desbloquea todas las imágenes comprando cualquier cantidad de créditos",
      pt: "Desbloqueie todas as imagens comprando qualquer quantidade de créditos",
      de: "Entsperren Sie alle Bilder, indem Sie eine beliebige Anzahl von Credits kaufen",
      ja: "クレジットを購入してすべての画像をアンロック",
      ko: "크레딧을 구매하여 모든 이미지를 잠금 해제하세요",
      zh: "通过购买任意数量的积分解锁所有图片",
      hi: "कोई भी मात्रा के क्रेडिट खरीदकर सभी चित्रों को अनलॉक करें"
    }
  },
  speedup: {
    title: {
      en: "Skip The Queue!",
      ru: "Пропустите очередь!",
      es: "¡Sáltate la fila!",
      pt: "Pule a fila!",
      de: "Überspringe die Warteschlange!",
      ja: "キューをスキップ!",
      ko: "대기열 건너뛰기!",
      zh: "跳过队列！",
      hi: "पंक्ति को छोड़ें!"
    },
    message: {
      en: "Users with credits are always first in queue",
      ru: "Пользователи с кредитами всегда первые в очереди",
      es: "Los usuarios con créditos siempre son los primeros en la fila",
      pt: "Usuários com créditos são sempre os primeiros na fila",
      de: "Benutzer mit Credits sind immer die Ersten in der Warteschlange",
      ja: "クレジットを持つユーザーは常にキューで最初になります",
      ko: "크레딧이 있는 사용자는 항상 대기열에서 우선입니다",
      zh: "拥有积分的用户总是排在队列的最前面",
      hi: "क्रेडिट वाले उपयोगकर्ता हमेशा पंक्ति में पहले होते हैं"
    }
  },
};

export default function PurchaseRequiredWidget({ source, expiresIn, language = 'en', mediaLeft }) {
  const navigate = useNavigate();
  const formatTime = (seconds) => {
    if (seconds >= 3600) { // Check if it's more than an hour
      const hours = Math.floor(seconds / 3600);
      const remainingMinutes = Math.floor((seconds % 3600) / 60);
      return `${hours}h ${remainingMinutes}m`;
    } else { // Less than an hour
      if (seconds <= 0) return '00:00:00'
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}m ${remainingSeconds}s`;
    }
  };

  return (
    <CreditsWidgetContainer onClick={() => navigate("/shop")}>
      <GradientOverlay />
      <CreditsAvatar src={`${process.env.PUBLIC_URL}/assets/icons/webp/${source === 'expires' ? "bomb" : (source === 'credits' || source === 'speed') ? "gem" : "magic"}.webp`} alt="Icon" />
      <TextContent>
        <TitleRow>
            {source === 'expires' && expiresIn && (
              <Typography variant="subtitle2" fontWeight="bold" noWrap>
                  {trnsl.delete.title?.[language] || trnsl.delete.title.en} {formatTime(expiresIn)}
              </Typography>
            )}
            {source === 'credits' && (
              <Typography variant="subtitle2" fontWeight="bold" noWrap>
                  {trnsl.unblur.title?.[language] || trnsl.unblur.title.en}
              </Typography>
            )}
            {source === 'speed' && (
              <Typography variant="subtitle2" fontWeight="bold" noWrap>
                  {trnsl.speedup.title?.[language] || trnsl.speedup.title.en}
              </Typography>
            )}
            {
              source === 'waiting' && (
                <Typography variant="subtitle2" fontWeight="bold" noWrap>
                  {trnsl.waiting.title?.[language] || trnsl.waiting.title.en} {mediaLeft ? ` (${mediaLeft})` : ""}
                </Typography>
              )
            }
        </TitleRow>
        {source === 'expires' && (
          <Typography variant="caption" color="textSecondary" noWrap>
            {(trnsl.delete.message?.[language] || trnsl.delete.message.en).replace("$TIME", formatTime(expiresIn))}
          </Typography>
        )}
        {source === 'credits' && (
          <Typography variant="caption" color="textSecondary" noWrap>
            {trnsl.unblur.message?.[language] || trnsl.unblur.message.en}
          </Typography>
        )}
        {source === 'speed' && (
          <Typography variant="caption" color="textSecondary" noWrap>
            {trnsl.speedup.message?.[language] || trnsl.speedup.message.en}
          </Typography>
        )}
        {source === 'waiting' && expiresIn && (
          <Typography variant="caption" color="textSecondary" noWrap>
            {trnsl.waiting.message?.[language] || trnsl.waiting.message.en} {formatTime(expiresIn)}
          </Typography>
        )}
      </TextContent>
      <ArrowForwardIosIcon sx={{ color: 'text.secondary', fontSize: 'small', marginLeft: 1 }} />
    </CreditsWidgetContainer>
  );
}
