// SignUpDialog.js
import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CssBaseline,
  Divider,
  FormControlLabel,
  FormLabel,
  FormControl,
  Link,
  TextField,
  Typography,
  Stack,
  Card as MuiCard,
  IconButton,
  InputAdornment,
  Dialog,
  DialogContent,
  Skeleton,
  Fade,
  Chip,
  Container,
  useMediaQuery,
  ImageListItem,
  ImageList
} from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import XIcon from '@mui/icons-material/X'; // Twitter (X)
import { GoogleIcon, TelegramIcon } from './CustomIcons'; // or wherever you store them
import getSignUpTheme from '../theme/theme/getSignUpTheme';
import { useNavigate, useLocation } from 'react-router-dom';

// Firebase
import { auth, database } from '../../services/firebase';
import {
  GoogleAuthProvider,
  TwitterAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  setPersistence,
  browserLocalPersistence,
  signInWithEmailAndPassword,
  signInWithCustomToken
} from 'firebase/auth';
import { ref, get } from 'firebase/database';

const t = {
  sign_up: {
    en: "Sign up",
    ru: "Регистрация",
    hi: "साइन अप करें",
    zh: "注册",
    de: "Registrieren",
    es: "Regístrate",
    ko: "가입하기",
    ja: "登録"
  },
  email: {
    en: "Email",
    ru: "Почта",
    hi: "ईमेल",
    zh: "电子邮件",
    de: "E-Mail",
    es: "Correo electrónico",
    ko: "이메일",
    ja: "メール"
  },
  password: {
    en: "Password",
    ru: "Пароль",
    hi: "पासवर्ड",
    zh: "密码",
    de: "Passwort",
    es: "Contraseña",
    ko: "비밀번호",
    ja: "パスワード"
  },
  forgot: {
    en: "Forgot Password?",
    ru: "Забыли пароль?",
    hi: "पासवर्ड भूल गए?",
    zh: "忘记密码？",
    de: "Passwort vergessen?",
    es: "¿Olvidaste tu contraseña?",
    ko: "비밀번호를 잊으셨나요?",
    ja: "パスワードをお忘れですか？"
  },
  no_promo: {
    en: "I do not wish to receive promotional emails",
    ru: "Я не хочу получать рекламные письма",
    hi: "मैं प्रचारात्मक ईमेल प्राप्त नहीं करना चाहता",
    zh: "我不希望收到促销邮件",
    de: "Ich möchte keine Werbe-E-Mails erhalten",
    es: "No deseo recibir correos electrónicos promocionales",
    ko: "홍보 이메일을 받고 싶지 않습니다",
    ja: "プロモーションメールを受信したくありません"
  },
  age: {
    en: "I confirm that I am at least 21 years old",
    ru: "Я подтверждаю, что мне не менее 21 года",
    hi: "मैं पुष्टि करता हूं कि मैं कम से कम 21 वर्ष का हूं",
    zh: "我确认我至少21岁",
    de: "Ich bestätige, dass ich mindestens 21 Jahre alt bin",
    es: "Confirmo que tengo al menos 21 años",
    ko: "저는 최소 21세 이상임을 확인합니다",
    ja: "私は21歳以上であることを確認します"
  },
  agree: {
    en: "I agree with the Terms of Service and Privacy Policy",
    ru: "Я согласен с Условиями обслуживания и Политикой конфиденциальности",
    hi: "मैं सेवा की शर्तों और गोपनीयता नीति से सहमत हूं",
    zh: "我同意服务条款和隐私政策",
    de: "Ich stimme den Nutzungsbedingungen und der Datenschutzerklärung zu",
    es: "Estoy de acuerdo con los Términos de servicio y la Política de privacidad",
    ko: "서비스 약관 및 개인정보 보호정책에 동의합니다",
    ja: "利用規約およびプライバシーポリシーに同意します"
  },
  already: {
    en: "Already have an account?",
    ru: "Уже есть аккаунт?",
    hi: "पहले से ही एक खाता है?",
    zh: "已经有账户了？",
    de: "Schon ein Konto?",
    es: "¿Ya tienes una cuenta?",
    ko: "이미 계정이 있으신가요?",
    ja: "すでにアカウントをお持ちですか？"
  },
  sign_in: {
    en: "Sign in",
    ru: "Войти",
    hi: "साइन इन करें",
    zh: "登录",
    de: "Anmelden",
    es: "Iniciar sesión",
    ko: "로그인",
    ja: "ログイン"
  },
  or: {
    en: "or",
    ru: "или",
    hi: "या",
    zh: "或",
    de: "oder",
    es: "o",
    ko: "또는",
    ja: "または"
  },
  google: {
    en: "Sign up with Google",
    ru: "Зарегистрироваться через Google",
    hi: "गूगल के साथ साइन अप करें",
    zh: "通过Google注册",
    de: "Mit Google anmelden",
    es: "Regístrate con Google",
    ko: "Google로 가입",
    ja: "Googleで登録"
  },
  x: {
    en: "Sign up with X",
    ru: "Зарегистрироваться через X",
    hi: "X के साथ साइन अप करें",
    zh: "通过X注册",
    de: "Mit X anmelden",
    es: "Regístrate con X",
    ko: "X로 가입",
    ja: "Xで登録"
  },
  tg: {
    en: "Sign up with Telegram",
    ru: "Зарегистрироваться через Telegram",
    hi: "टेलीग्राम के साथ साइन अप करें",
    zh: "通过Telegram注册",
    de: "Mit Telegram anmelden",
    es: "Regístrate con Telegram",
    ko: "Telegram으로 가입",
    ja: "Telegramで登録"
  },
  must_agree: {
    en: "You must agree to the Terms of Service and Privacy Policy to proceed",
    ru: "Вы должны согласиться с Условиями использования и Политикой конфиденциальности, чтобы продолжить",
    hi: "आगे बढ़ने के लिए आपको सेवा की शर्तों और गोपनीयता नीति से सहमत होना होगा",
    zh: "您必须同意服务条款和隐私政策才能继续",
    de: "Sie müssen den Nutzungsbedingungen und der Datenschutzrichtlinie zustimmen, um fortzufahren",
    es: "Debes aceptar los Términos de servicio y la Política de privacidad para continuar",
    ko: "서비스 약관 및 개인정보 보호정책에 동의해야 계속 진행할 수 있습니다",
    ja: "利用規約およびプライバシーポリシーに同意する必要があります"
  },
  bad_pass: {
    en: "Password must be at least 6 characters long",
    ru: "Пароль должен быть не менее 6 символов",
    hi: "पासवर्ड कम से कम 6 वर्ण लंबा होना चाहिए",
    zh: "密码必须至少包含6个字符",
    de: "Das Passwort muss mindestens 6 Zeichen lang sein",
    es: "La contraseña debe tener al menos 6 caracteres",
    ko: "비밀번호는 최소 6자 이상이어야 합니다",
    ja: "パスワードは6文字以上である必要があります"
  },
  invalid_pass: {
    en: "Please enter a valid email address",
    ru: "Пожалуйста, введите действительный адрес электронной почты",
    hi: "कृपया एक वैध ईमेल पता दर्ज करें",
    zh: "请输入有效的电子邮箱地址",
    de: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
    es: "Por favor, introduce una dirección de correo electrónico válida",
    ko: "유효한 이메일 주소를 입력해주세요",
    ja: "有効なメールアドレスを入力してください"
  },
  verify_email: {
    en: "Verify Email",
    ru: "Подтвердите электронную почту",
    hi: "ईमेल सत्यापित करें",
    zh: "验证邮箱",
    de: "E-Mail verifizieren",
    es: "Verificar correo electrónico",
    ko: "이메일 인증",
    ja: "メール確認"
  },
  output_label: {
    en: "OUTPUT",
    ru: "РЕЗУЛЬТАТ",
    hi: "परिणाम",
    zh: "输出",
    de: "AUSGABE",
    es: "SALIDA",
    ko: "출력",
    ja: "出力"
  },
  input_label: {
    en: "SELFIE",
    ru: "СЕЛФИ",
    hi: "सेल्फी",
    zh: "自拍",
    de: "SELFIE",
    es: "SELFIE",
    ko: "셀카",
    ja: "自撮り"
  },
  dialog: {
    title: {
      en: "Last step: sign up to get this content for free!",
      ru: "Последний шаг: зарегистрируйтесь, чтобы получить этот контент бесплатно!",
      hi: "अंतिम चरण: इस सामग्री को मुफ्त में प्राप्त करने के लिए साइन अप करें!",
      zh: "最后一步：注册以免费获取此内容！",
      de: "Letzter Schritt: Melden Sie sich an, um diesen Inhalt kostenlos zu erhalten!",
      es: "Último paso: ¡Regístrate para obtener este contenido gratis!",
      ko: "마지막 단계: 이 콘텐츠를 무료로 받으려면 가입하세요!",
      ja: "最後のステップ：このコンテンツを無料で取得するためにサインアップしてください！"
    },
    description: {
      en: "Sign in with one of the methods below with a few clicks. After that, your job will be instantly submitted and will start processing",
      ru: "Войдите с помощью одного из способов ниже за несколько кликов. После этого ваша задача будет мгновенно отправлена и начнется обработка",
      hi: "नीचे दिए गए तरीकों में से किसी एक के साथ कुछ ही क्लिक में साइन इन करें। इसके बाद, आपका कार्य तुरंत सबमिट हो जाएगा और प्रोसेसिंग शुरू हो जाएगी",
      zh: "通过以下任意方法点击几下即可登录。之后，您的任务将立即提交并开始处理",
      de: "Melden Sie sich mit einer der unten aufgeführten Methoden mit wenigen Klicks an. Danach wird Ihr Auftrag sofort eingereicht und bearbeitet",
      es: "Inicia sesión con uno de los métodos a continuación con unos pocos clics. Después de eso, tu trabajo será enviado instantáneamente y comenzará a procesarse",
      ko: "아래 방법 중 하나로 몇 번의 클릭으로 로그인하세요. 그 후 작업이 즉시 제출되고 처리됩니다",
      ja: "以下の方法のいずれかで数クリックでログインしてください。その後、ジョブが即座に送信され、処理が開始されます"
    }
  },
  verification: {
    label: {
      en: "Check your email",
      ru: "Проверьте свою электронную почту",
      hi: "अपना ईमेल जांचें",
      zh: "检查您的电子邮件",
      de: "Überprüfen Sie Ihre E-Mails",
      es: "Revisa tu correo electrónico"
    },
    description: {
      en: "A verification link has been sent to $EMAIL. Please check your inbox and also your spam folder to confirm your account.",
      ru: "Ссылка для подтверждения отправлена на $EMAIL. Пожалуйста, проверьте ваш почтовый ящик и папку со спамом, чтобы подтвердить учетную запись.",
      hi: "एक सत्यापन लिंक $EMAIL पर भेजा गया है। कृपया अपना इनबॉक्स और स्पैम फ़ोल्डर जांचें ताकि आप अपना खाता पुष्टि कर सकें।",
      zh: "验证链接已发送到 $EMAIL。请检查您的收件箱和垃圾邮件文件夹以确认您的账户。",
      de: "Ein Bestätigungslink wurde an $EMAIL gesendet. Bitte überprüfen Sie Ihren Posteingang und auch Ihren Spam-Ordner, um Ihr Konto zu bestätigen.",
      es: "Se ha enviado un enlace de verificación a $EMAIL. Por favor, revisa tu bandeja de entrada y también tu carpeta de spam para confirmar tu cuenta."
    },
    resend_in: {
      en: "Resend in",
      ru: "Повторно отправить через",
      hi: "पुनः भेजें में",
      zh: "重新发送于",
      de: "Erneut senden in",
      es: "Reenviar en"
    },
    resend: {
      en: "Resend Verification Link",
      ru: "Отправить еще раз",
      hi: "सत्यापन लिंक पुनः भेजें",
      zh: "重新发送验证链接",
      de: "Bestätigungslink erneut senden",
      es: "Reenviar enlace de verificación"
    },
    error: {
      en: "Error occured during verification. Please try again later. If you already used this email, close this dialog and go to sign in page. Error code:",
      ru: "Произошла ошибка во время проверки. Пожалуйста, попробуйте позже. Если вы уже использовали эту электронную почту, закройте это окно и перейдите на страницу входа. Код ошибки:",
      hi: "सत्यापन के दौरान त्रुटि हुई। कृपया बाद में पुनः प्रयास करें। यदि आपने पहले ही इस ईमेल का उपयोग किया है, तो इस संवाद को बंद करें और साइन इन पेज पर जाएं। त्रुटि कोड:",
      zh: "验证过程中发生错误。请稍后再试。如果您已经使用过此电子邮件，请关闭此对话框并转到登录页面。错误代码：",
      de: "Bei der Überprüfung ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal. Wenn Sie diese E-Mail bereits verwendet haben, schließen Sie dieses Dialogfeld und gehen Sie zur Anmeldeseite. Fehlercode:",
      es: "Ocurrió un error durante la verificación. Por favor, inténtalo de nuevo más tarde. Si ya usaste este correo electrónico, cierra este cuadro de diálogo y ve a la página de inicio de sesión. Código de error:"
    },
    change_email: {
      en: "Change Email",
      es: "Cambiar correo electrónico",
      pt: "Alterar Email",
      de: "E-Mail ändern",
      ja: "メールを変更する",
      ko: "이메일 변경",
      zh: "更改邮箱",
      ru: "Изменить адрес электронной почты",
      hi: "ईमेल बदलें"
    },
    masonryLabel: {
      en: "You are one step from getting this content:",
      es: "Estás a un paso de obtener este contenido:",
      pt: "Você está a um passo de obter este conteúdo:",
      de: "Sie sind einen Schritt davon entfernt, diesen Inhalt zu erhalten:",
      ja: "このコンテンツを取得するまであと一歩です:",
      ko: "이 콘텐츠를 얻기까지 한 단계 남았습니다:",
      zh: "您只需一步即可获得此内容:",
      ru: "Вы в одном шаге от получения этого контента:",
      hi: "आप इस सामग्री को प्राप्त करने से एक कदम दूर हैं:"
    }
  },
};

const RowContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(2),
  position: 'relative',
}));
const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    width: '450px',
  },
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));
const SignUpContainer = styled(Stack)(({ theme }) => ({
  minHeight: '100%',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
  ...theme.applyStyles('dark'),
}));
const HalfContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  flex: '0 0 48%',
  borderRadius: '8px',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
const StyledChip = styled(Chip)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(2),
  left: theme.spacing(2),
  zIndex: 3,
  backgroundColor: theme.palette.info.info,
  color: theme.palette.primary.contrastText,
  fontSize: '0.75rem',
  height: 24,
}));

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}
function makeSinglePair(sourceUrl, outputUrls) {
  if (!sourceUrl || !Array.isArray(outputUrls) || outputUrls.length === 0) {
    return [];
  }
  return [
    {
      source: sourceUrl,
      examples: outputUrls,
      name: "Your Upload", // or whatever label you want
    },
  ];
}

export default function SignUpDialog({
  open = false,
  onClose = () => {},
  onSignUpSuccess = () => {},
  mode = 'dark',
  description = null,
  language = 'en', // pass in language if you want
  sourceImage = "",       // Single "selfie" URL
  outputImages = []
}) {
  const defaultTheme = createTheme({ palette: { mode: mode } });
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const signUpTheme = createTheme(getSignUpTheme(mode));
  
  // Email/pw states
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState('');
  const [twitterUsername, setTwitterUsername] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  
  const [shuffledBundles, setShuffledBundles] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5);
    
  const visibleBundles = shuffledBundles.slice(0, visibleCount);
  
  const navigate = useNavigate();
  
  // Checkboxes
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  
  // Verification states
  const [showVerificationDialog, setShowVerificationDialog] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [resendTimer, setResendTimer] = useState(0);
  const [resendAttempts, setResendAttempts] = useState(0);
  
  // Slideshow logic
  const [pairsForDialog, setPairsForDialog] = useState([]);
  const [dialogIndex, setDialogIndex] = useState(0);
  const [exampleIndex, setExampleIndex] = useState(0);
  
  const [rowVisible, setRowVisible] = useState(false);
  const [sourceLoaded, setSourceLoaded] = useState(false);
  const [exampleLoaded, setExampleLoaded] = useState(false);
  
  // Timings
  const BUNDLE_DISPLAY_MS = 3000;
  const FADE_DURATION_MS = 500;
  const GAP_BETWEEN_EXAMPLES = 500;
  
  // 1) Prefetch images once outputImages changes
  useEffect(() => {
    outputImages.forEach((imageUrl) => {
      const img = new Image();
      img.src = imageUrl;
    });
  }, [outputImages]);

  useEffect(() => {
    let verIntervalId;
    
    async function checkVerification() {
      if (!user || !email || !password) return;
  
      try {
        const token = await user.getIdToken();
        if (token) {
          auth.onAuthStateChanged(async (user) => {
            if (user && user.emailVerified) {
              const result = await signInWithEmailAndPassword(auth, email, password);
              onSignUpSuccess(result);
              onClose();
              clearInterval(verIntervalId);
              return;
            }
          });
        }
  
      } catch (err) {
        console.log(err);
      }
    }
  
    if (user && email && password) {
      verIntervalId = setInterval(checkVerification, 5000);
    }
  
    return () => {
      if (verIntervalId) clearInterval(verIntervalId);
    };
  }, [user, email, password]);
  
  // 2) Build the single pair from user’s selfie + array of outputs
  useEffect(() => {
    const singlePair = makeSinglePair(sourceImage, outputImages);  
    //console.log(singlePair);
    setPairsForDialog(singlePair);
  
    // Reset states
    setDialogIndex(0);
    setExampleIndex(0);
    setRowVisible(false);
    setSourceLoaded(false);
    setExampleLoaded(false);
  
    // Fade in row after short delay if we have images
    if (singlePair.length) {
      const timer = setTimeout(() => setRowVisible(true), 200);
      return () => clearTimeout(timer);
    }
  }, [sourceImage, outputImages]);
  
  // 3) If resendTimer > 0, decrement it each second
  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setTimeout(() => setResendTimer((prev) => prev - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [resendTimer]);
  
  // 4) On new example load => schedule a fade-out -> next example (infinite loop)
  const handleExampleLoad = () => {
    setExampleLoaded(true);
  
    // show for BUNDLE_DISPLAY_MS
    setTimeout(() => {
      // fade out the current example
      setExampleLoaded(false);
  
      setTimeout(() => {
        if (!pairsForDialog[dialogIndex]) return;
        const totalExamples = pairsForDialog[dialogIndex].examples.length;
  
        // pick nextEx in a LOOP (so it cycles back to 0)
        const nextEx = (exampleIndex + 1) % totalExamples;
  
        setExampleIndex(nextEx);
        // We do NOT set rowVisible(false) — leaving row visible for infinite loop
      }, GAP_BETWEEN_EXAMPLES);
  
    }, BUNDLE_DISPLAY_MS);
  };

  // Validation
  const validateInputs = () => {
    let isValid = true;
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
      setEmailErrorMessage(t.invalid_pass?.[language] || t.invalid_pass.en);
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    const passElem = document.getElementById('password');
    if (!passElem || passElem.value.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage(t.bad_pass?.[language] || t.bad_pass.en);
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }

    if (!agreedToTerms) {
      alert(t.must_agree?.[language] || t.must_agree.en);
      isValid = false;
    }
    return isValid;
  };

  // Email sign up
  const handleSubmitEmailSignUp = async (e) => {
    e.preventDefault();
    if (!validateInputs()) return;

    const passElem = document.getElementById('password');
    const passwordVal = passElem.value || '';

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, passwordVal);
      console.log(userCredential)
      if (userCredential.user) {
        // Send verification
        await sendEmailVerification(userCredential.user);
        setUserEmail(email);
        setPassword(passwordVal)
        setUser(userCredential.user);
        setShowVerificationDialog(true);
      }
    } catch (err) {
      console.log(err)
      if (err.code === 'auth/email-already-in-use') {
        try {
          const userCredential = await signInWithEmailAndPassword(auth, email, passwordVal);
          if (userCredential?.user?.emailVerified) {
            onSignUpSuccess(userCredential);
            onClose();
          }
          else {
            await sendEmailVerification(userCredential.user);
            setUserEmail(email);
            setPassword(passwordVal)
            setUser(userCredential.user);
            setShowVerificationDialog(true);
          }
        }
        catch(error) {
          console.log(error);
        }
      }
      //navigate("/signin")
    }
  };

  // Social sign up
  const handleGoogleSignIn = async () => {
    try {
      await setPersistence(auth, browserLocalPersistence);
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      if (result.user) {
        onSignUpSuccess(result);
        onClose();
      }
    } catch (error) {
      console.error('Error with Google sign in:', error);
    }
  };

  const handleTwitterSignIn = async () => {
    try {
      await setPersistence(auth, browserLocalPersistence);
      const provider = new TwitterAuthProvider();
      const result = await signInWithPopup(auth, provider);
      if (result.user) {
        // Possibly get username
        if (
          result.additionalUserInfo &&
          result.additionalUserInfo.profile &&
          result.additionalUserInfo.profile.screen_name
        ) {
          setTwitterUsername(result.additionalUserInfo.profile.screen_name);
        }

        onSignUpSuccess(result);
        onClose();
      }
    } catch (error) {
      console.error('Error with Twitter sign in:', error);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };

  const changeEmail = () => {
    setUser(null);
    setEmail(null);
    setShowVerificationDialog((prev) => !prev);
  };

  const sendVerificationEmail = async () => {
    if (resendTimer === 0) {
      try {
        await sendEmailVerification(user, {
          url: "https://app.lumipic.pro/?closeIt=true&language=" + language
        });
        const cooldown = resendAttempts < 2 ? 60 : 300;
        setResendTimer(cooldown);
        setResendAttempts((prev) => prev + 1);
      } catch (error) {
        console.log(error);
        if (error.code === 'auth/too-many-requests') {
          const cooldown = resendAttempts < 2 ? 60 : 300;
          setResendTimer(cooldown);
          setResendAttempts((prev) => prev + 1);
        }
      }
    }
  };

  return (
    <ThemeProvider theme={signUpTheme}>
      <CssBaseline />
      <Dialog open={open} onClose={onClose} 
        fullScreen={useMediaQuery(signUpTheme.breakpoints.down('md'))} 
        PaperProps={{
          sx: {
            [signUpTheme.breakpoints.up('lg')]: {
              width: '50vw',
              margin: 'auto',
              // Example: top corners rounded, bottom corners straight
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            },
            [signUpTheme.breakpoints.down('lg')]: {
              marginTop: 20,
            },
          },
        }}
        sx={{
        zIndex: 9999,
        '& .MuiDialog-paper': {
          // If you want to ensure no other overrides cause rounding at the bottom:
          overflow: 'hidden', 
        },
      }}>
        {/* Close button */}
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', right: 10, top: 10, backgroundColor: "transparent", border: "none" }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent
          dividers
          sx={{ backgroundColor: signUpTheme.palette.background.paper }}
        >
          <SignUpContainer spacing={2} marginTop={6}>
            <Container maxWidth="sm" sx={{alignSelf: "center"}}>
              {/* Top row: Source (left) / Output slideshow (right) */}
              {pairsForDialog.length > 0 && (
                <Fade in={rowVisible} timeout={FADE_DURATION_MS} key={dialogIndex}>
                  <RowContainer>
                    {/* LEFT => Source */}
                    <HalfContainer>
                      {!sourceLoaded && (
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={200}
                          sx={{ position: 'absolute', top: 0, left: 0 }}
                        />
                      )}
                      <StyledChip label={t.input_label?.[language] || t.input_label.en} />
                      <Box
                        component="img"
                        alt="Selfie"
                        src={pairsForDialog[dialogIndex].source}
                        onLoad={() => setSourceLoaded(true)}
                        sx={{
                          width: '100%',
                          height: 'auto',
                          objectFit: 'contain',
                          zIndex: 2,
                        }}
                      />
                    </HalfContainer>

                    {/* RIGHT => Output images that fade in/out */}
                    <HalfContainer>
                      {!exampleLoaded && (
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={200}
                          sx={{ position: 'absolute', top: 0, left: 0 }}
                        />
                      )}
                      <StyledChip label={t.output_label?.[language] || t.output_label.en} />
                      <Fade
                        in={exampleLoaded}
                        timeout={FADE_DURATION_MS}
                        key={exampleIndex}
                      >
                        <Box
                          component="img"
                          alt="Output"
                          src={pairsForDialog[dialogIndex].examples[exampleIndex] || ''}
                          onLoad={handleExampleLoad}
                          sx={{
                            width: '100%',
                            height: 'auto',
                            objectFit: 'contain',
                            zIndex: 2,
                          }}
                        />
                      </Fade>
                    </HalfContainer>
                  </RowContainer>
                </Fade>
              )}

              {showVerificationDialog ? (
                <>
                  <Box sx={{ textAlign: 'center', mb: 2, mt: 2 }}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/icons/webp/email.webp`}
                      alt="Email Sent"
                      style={{ width: '50%', maxWidth: '200px', margin: '0 auto' }}
                    />
                  </Box>
                  <Typography variant="h5" align="center">
                    {t.verification.label?.[language] || t.verification.label.en}
                  </Typography>
                  <Typography variant="body1" align="center" sx={{ mt: 2, mb: 2 }}>
                    {(t.verification.description?.[language] || t.verification.description.en).replace("$EMAIL", email)}
                  </Typography>            
                  <Button
                      variant="outlined"
                      onClick={changeEmail}
                      sx={{ marginBottom: 2}}
                    >
                    {t.verification.change_email?.[language] || t.verification.change_email.en}
                  </Button>
                  <Button
                      variant="outlined"
                      disabled={resendTimer > 0}
                      onClick={sendVerificationEmail}
                      sx={{ marginBottom: 2}}
                    >
                    {resendTimer > 0 ? `${t.verification.resend_in?.[language] || t.verification.resend_in.en} ${resendTimer}` : (t.verification.resend?.[language] || t.verification.resend.en)}
                  </Button>
                  <Typography variant="h5" align="left" marginBottom={2}>
                    {t.verification.masonryLabel?.[language] || t.verification.masonryLabel.en}
                  </Typography>
                  <ImageList variant="masonry" cols={2} gap={8}>
                    {outputImages.map((item) => (
                      <ImageListItem key={item}>
                        <img
                            srcSet={`${item}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            src={`${item}?w=248&fit=crop&auto=format`}
                            alt={`content`}
                            loading="lazy"
                            style={{
                              borderRadius: '12px',
                            }}
                          />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </>
              ): 
              (
                <>
                  {/* Title */}
                  <Typography variant="h5" sx={{ mt: 4 }}>
                    {t.dialog.title?.[language] || t.dialog.title.en}
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    {description ? description : (t.dialog.description?.[language] || t.dialog.description.en)}
                  </Typography>
                  <Stack spacing={2} sx={{ mt: 2 }}>
                    <Button
                      fullWidth
                      variant="outlined"
                      startIcon={<GoogleIcon />}
                      onClick={handleGoogleSignIn}
                    >
                      {t.google?.[language] || t.google.en}
                    </Button>
                    <Button
                      fullWidth
                      variant="outlined"
                      startIcon={<XIcon />}
                      onClick={handleTwitterSignIn}
                    >
                      {t.x?.[language] || t.x.en}
                    </Button>
                  </Stack>

                  <Divider sx={{ my: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                      {t.or?.[language] || t.or.en}
                    </Typography>
                  </Divider>

                  {/* Email sign up form */}
                  <Box
                    component="form"
                    onSubmit={handleSubmitEmailSignUp}
                    sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
                  >
                    {/* Email */}
                    <FormControl>
                      <FormLabel htmlFor="email">
                        {t.email?.[language] || t.email.en}
                      </FormLabel>
                      <TextField
                        id="email"
                        fullWidth
                        required
                        placeholder="your@email.com"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={emailError}
                        helperText={emailErrorMessage}
                      />
                    </FormControl>

                    {/* Password */}
                    <FormControl>
                      <FormLabel htmlFor="password">
                        {t.password?.[language] || t.password.en}
                      </FormLabel>
                      <TextField
                        id="password"
                        type={passwordVisible ? 'text' : 'password'}
                        required
                        placeholder="••••••"
                        variant="outlined"
                        error={passwordError}
                        helperText={passwordErrorMessage}
                        onChange={(e) => setPassword(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={togglePasswordVisibility} edge="end">
                                {passwordVisible ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>

                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          required
                          checked={agreedToTerms}
                          onChange={(e) => setAgreedToTerms(e.target.checked)}
                        />
                      }
                      label={t.agree?.[language] || t.agree.en}
                    />

                    <Button variant="contained" type="submit" fullWidth>
                      {t.verify_email?.[language] || t.verify_email.en}
                    </Button>
                  </Box>

                  <Typography variant="body2" sx={{ mt: 2, mb: 10, textAlign: 'center' }}>
                    {t.already?.[language] || t.already.en}{' '}
                    <Link href="/signin" variant="body2">
                      {t.sign_in?.[language] || t.sign_in.en}
                    </Link>
                  </Typography>
                </>
              )}
            </Container>
          </SignUpContainer>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}
