// AdvancedSettings.js

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Slider,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';

export default function AdvancedSettings({
  seed,
  setSeed,
  steps,
  setSteps,
  styleStrengthRatio,
  setStyleStrengthRatio,
  guidanceScale,
  setGuidanceScale,
  isTelegramWebApp,
  credits,
  user,
  hasPaid = false,
  mediaAmount,
  setMediaAmount, // Accept mediaAmount and setMediaAmount as props
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpenDialog = () => {
    if (credits > 0) {
      setDialogOpen(false);
    } else {
      setDialogOpen(true);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <Box sx={{ width: '99%', marginBottom: 20, marginTop: 2}}>
      <Typography variant="h6" gutterBottom>
        <Box display="flex" alignItems="center" gap={1}>
          <SettingsIcon />
          Advanced Settings
        </Box>
      </Typography>
      {hasPaid > 0 ? (
        <Box sx={{ mb: 2, width: '100%' }}>
          {/* Media Amount Slider */}
          <Typography variant="subtitle1" gutterBottom>
            Pictures Amount
          </Typography>
          <Slider
            value={mediaAmount}
            onChange={(e, newValue) => setMediaAmount(newValue)}
            aria-labelledby="media-amount-slider"
            valueLabelDisplay="auto"
            step={1}
            marks
            min={1}
            max={5}
            sx={{ height: 12 }}
          />

          {/* Seed Input */}
          <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
            Seed (1 - 2,000,000,000)
          </Typography>
          <TextField
            type="number"
            value={seed}
            onChange={(e) => {
              const value = e.target.value;
              if (value === '') {
                setSeed('');
              } else {
                const num = parseInt(value, 10);
                if (num >= 1 && num <= 2000000000) {
                  setSeed(num);
                }
              }
            }}
            fullWidth
            placeholder="Enter seed value"
            inputProps={{ min: 1, max: 2000000000 }}
          />

          {/* Steps Slider */}
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Steps (15 - 50)
            </Typography>
            <Slider
              value={steps}
              onChange={(e, newValue) => setSteps(newValue)}
              aria-labelledby="steps-slider"
              valueLabelDisplay="auto"
              step={1}
              marks
              min={15}
              max={50}
              sx={{ height: 12 }}
            />
          </Box>

          {/* Style Strength Ratio Slider */}
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Style Strength Ratio (0 - 100)
            </Typography>
            <Slider
              value={styleStrengthRatio}
              onChange={(e, newValue) => setStyleStrengthRatio(newValue)}
              aria-labelledby="style-strength-ratio-slider"
              valueLabelDisplay="auto"
              step={1}
              marks
              min={0}
              max={100}
              sx={{ height: 12 }}
            />
          </Box>

          {/* Guidance Scale Slider */}
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Guidance Scale (0 - 10)
            </Typography>
            <Slider
              value={guidanceScale}
              onChange={(e, newValue) => setGuidanceScale(newValue)}
              aria-labelledby="guidance-scale-slider"
              valueLabelDisplay="auto"
              step={0.5}
              marks
              min={0}
              max={10}
              sx={{ height: 12 }}
            />
          </Box>
        </Box>
      ) : (
        <>
          <Typography variant="body1" color="textSecondary">
            Advanced Settings are available after purchasing credits.
          </Typography>
          {/* Dialog Component */}
          {!isTelegramWebApp && (
            <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="sm" fullWidth>
              <DialogTitle>Advanced Features Locked</DialogTitle>
              <DialogContent>
                <Box sx={{ textAlign: 'center' }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/webp/sparkles.webp`}
                    alt="Advanced Features Locked"
                    style={{ width: '40%', marginBottom: 16 }}
                  />
                  <Typography>
                    Advanced Settings are available only after purchasing credits.
                  </Typography>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => navigate('/shop')} color="primary" variant="contained">
                  Go to Shop
                </Button>
                <Button onClick={handleDialogClose} color="secondary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </>
      )}
    </Box>
  );
}
