// ModelPage.js
import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Avatar,
  Typography,
  Button,
  Divider,
  IconButton,
  CssBaseline,
  Chip,
  LinearProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import VerifiedIcon from '@mui/icons-material/Verified';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import TelegramIcon from '@mui/icons-material/Telegram';
import { useParams, useNavigate } from 'react-router-dom';
import { ref, get } from 'firebase/database';
import { database, auth } from '../../services/firebase';
import TemplateFrame from '../theme/TemplateFrame';
import getSignUpTheme from '../theme/theme/getSignUpTheme';
import MasonryFeed from '../../components/MasonryFeed';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { signInWithCustomToken } from 'firebase/auth';
import StatusDialog from '../../components/SubmitDialog';
import { getDatabase } from 'firebase/database';

const ModelAvatar = styled(Avatar)(({ theme }) => ({
  width: '30%',
  maxWidth: '200px',
  height: 'auto',
  aspectRatio: '1 / 1',
  borderRadius: '50%',
  marginBottom: theme.spacing(2),
}));

const VerifiedBadge = styled(VerifiedIcon)(({ theme }) => ({
  color: theme.palette.info.main,
  marginLeft: theme.spacing(0.5),
}));

const ModelPageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const SocialsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const SocialIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export default function ModelPage() {
  const [mode, setMode] = useState('light');
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const defaultTheme = createTheme({ palette: { mode } });
  const modelPageTheme = createTheme(getSignUpTheme(mode));
  const [modelData, setModelData] = useState(null);
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAdultContent, setShowAdultContent] = useState(null);
  const [user, setUser] = useState(null);
  const [credits, setCredits] = useState(null);

  const [isTelegramWebApp, setIsTelegramWebApp] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const { model_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Detect if the app is opened via Telegram Mini App
    if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initData) {
      setIsTelegramWebApp(true);

      // Set theme based on Telegram's colorScheme
      const telegramColorScheme = window.Telegram.WebApp.colorScheme;
      setMode(telegramColorScheme === 'dark' ? 'dark' : 'light');

      // Expand the Mini App if not expanded
      if (!window.Telegram.WebApp.isExpanded) {
        window.Telegram.WebApp.expand();
      }

      // Disable vertical swipes
      window.Telegram.WebApp.disableVerticalSwipes();

      // Enable closing confirmation only if there are media items
      if (media.length > 0) {
        window.Telegram.WebApp.enableClosingConfirmation();
      } else {
        window.Telegram.WebApp.disableClosingConfirmation();
      }

      // Signal that the app is ready
      window.Telegram.WebApp.ready();
    } else {
      // Not in Telegram Web App
      const savedMode = localStorage.getItem('themeMode');
      if (savedMode) {
        setMode(savedMode);
      } else {
        const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
        setMode(systemPrefersDark ? 'dark' : 'light');
      }
    }

    const token = localStorage.getItem('userToken');
    if (token) {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          setUser(user);
          await checkAdultAccess(user.uid);
          // Fetch user data from the database
          const db = getDatabase();
          const userRef = ref(db, `users/${user.uid}`);
          const snapshot = await get(userRef);
          if (snapshot.exists()) {
            const userData = snapshot.val();
            setCredits(userData.credits || 0);
          }
        } else {
          setShowAdultContent(false);
        }
      });
    } else {
      setShowAdultContent(false);
    }

    loadModelData();
  }, [media.length]);

  // Authenticate Telegram users
  useEffect(() => {
    const authenticateTelegramUser = async () => {
      if (isTelegramWebApp && !user) {
        const initData = window.Telegram.WebApp.initData;

        if (initData) {
          // Send initData as is
          const formBody = `initData=${encodeURIComponent(initData)}`;

          try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/verify-telegram-webapp`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
              body: formBody,
            });

            const result = await response.json();

            if (result.success && result.customToken) {
              await signInWithCustomToken(auth, result.customToken);
              const idToken = await auth.currentUser.getIdToken();
              localStorage.setItem('userToken', idToken);
              setUser(auth.currentUser);
              // Fetch user data from the database
              const db = getDatabase();
              const userRef = ref(db, `users/${auth.currentUser.uid}`);
              const snapshot = await get(userRef);
              if (snapshot.exists()) {
                const userData = snapshot.val();
                setCredits(userData.credits || 0);
              }

              if (result?.navigator) {
                navigate(result.navigator);
              }
            } else {
              console.error('Telegram authentication failed:', result.error);
              setSubmitError(result.error || 'Authentication failed.');
            }
          } catch (error) {
            console.error('Error during Telegram authentication:', error);
            setSubmitError('An error occurred during authentication.');
          }
        }
      }
    };

    authenticateTelegramUser();
  }, [isTelegramWebApp, user]);

  const checkAdultAccess = async (userId) => {
    try {
      // Check localStorage for user data first
      let cachedUserData = JSON.parse(localStorage.getItem(`user_${userId}`));

      if (cachedUserData && cachedUserData.hasOwnProperty('adult')) {
        setShowAdultContent(cachedUserData.adult);
      } else {
        // Fetch user data from the database if not found in cache
        const db = database;
        const userRef = ref(db, `users/${userId}`);
        const userSnapshot = await get(userRef);
        if (userSnapshot.exists()) {
          const userData = userSnapshot.val();
          const isAdult = userData.adult === true;

          // Update the component state
          setShowAdultContent(isAdult);

          // Update the cached user data
          const updatedUserData = {
            ...userData,
            adult: isAdult ? true : false,
          };
          localStorage.setItem(`user_${userId}`, JSON.stringify(updatedUserData));
        } else {
          // Set default to false if no record exists
          setShowAdultContent(false);

          // Update the cached user data with default value
          localStorage.setItem(`user_${userId}`, JSON.stringify({ adult: false }));
        }
      }
    } catch (error) {
      console.error('Error checking adult access:', error);
    }
  };

  const loadModelData = async () => {
    const db = database;

    try {
      setLoading(true);

      // Fetch model data
      const modelRef = ref(db, `models/${model_id}`);
      console.log(`models/${model_id}`);
      const modelSnapshot = await get(modelRef);
      if (modelSnapshot.exists()) {
        const model = modelSnapshot.val();
        setModelData(model);

        // Fetch media data related to the model
        const mediaIds = model.media || [];
        const mediaRef = ref(db, `feed/${model.adult ? "adult" : "default"}`);
        const mediaSnapshot = await get(mediaRef);

        if (mediaSnapshot.exists()) {
          const mediaData = mediaSnapshot.val();
          const modelMediaList = Object.entries(mediaData)
            .filter(([id]) => mediaIds.includes(id))
            .map(([id, value]) => ({ ...value, jobId: id }));
          setMedia(modelMediaList);
        }
      } else {
        console.error('Model data not found');
      }
    } catch (error) {
      console.error('Error loading model data:', error);
    } finally {
      setLoading(false);
    }
  };

  const toggleColorMode = () => {
    const newMode = mode === 'dark' ? 'light' : 'dark';
    setMode(newMode);
    if (!isTelegramWebApp) {
      localStorage.setItem('themeMode', newMode);
    }
  };

  const renderSocialIcons = () => {
    if (!modelData?.socials) return null;

    const socialEntries = Object.entries(modelData.socials);

    // Separate socials with icons and socials without icons
    const socialsWithIcons = socialEntries.filter(([social]) =>
      ['instagram', 'twitter', 'telegram'].includes(social)
    );

    const socialsWithoutIcons = socialEntries.filter(([social]) =>
      !['instagram', 'twitter', 'telegram'].includes(social)
    );

    return (
      <SocialsContainer>
        {/* Render socials with icons first */}
        {socialsWithIcons.map(([social, link]) => {
          const SocialIcon =
            social === 'instagram' ? InstagramIcon :
            social === 'twitter' ? XIcon :
            social === 'telegram' ? TelegramIcon :
            null;

          return SocialIcon ? (
            <SocialIconButton
              key={social}
              href={link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIcon />
            </SocialIconButton>
          ) : null;
        })}

        {/* Render socials without icons next */}
        {socialsWithoutIcons.map(([social, link]) => {
          // Extract domain name from the link to use as text
          let domainName = link
            .replace(/^(https?:\/\/)?(www\.)?/, '') // Remove protocol and www
            .split('/')[0]; // Take the domain part
          domainName = domainName.includes('.')
            ? domainName.substring(0, domainName.lastIndexOf('.'))
            : domainName; // Remove top-level domain if necessary (e.g., .com)

          return (
            <Button
              key={social}
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              variant="text"
              color="primary"
              sx={{ textTransform: 'capitalize' }} // Capitalize first letter for style
            >
              {domainName}
            </Button>
          );
        })}
      </SocialsContainer>
    );
  };

  const handleFaceSwapClick = useCallback(async () => {
    try {
      const db = database;
      const settingsRef = ref(db, 'settings/links/faceswap');
      const settingsSnapshot = await get(settingsRef);
      if (settingsSnapshot.exists()) {
        const faceSwapLink = settingsSnapshot.val();
        if (isTelegramWebApp && window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.openLink) {
          if (faceSwapLink.includes("t.me")) {
            window.Telegram.WebApp.openTelegramLink(faceSwapLink);
          }
          else {
            window.Telegram.WebApp.openLink(faceSwapLink, { try_instant_view: false });
          }
        } else {
          window.open(faceSwapLink, '_blank');
        }
      } else {
        console.error('Face Swap link not found in settings.');
        if (!isTelegramWebApp) {
          setSubmitError('Face Swap link is unavailable.');
        } else {
          window.Telegram.WebApp.showAlert('Face Swap link is unavailable.');
        }
      }
    } catch (error) {
      console.error('Error fetching Face Swap link:', error);
      if (!isTelegramWebApp) {
        setSubmitError('Failed to open Face Swap link.');
      } else {
        window.Telegram.WebApp.showAlert('Failed to open Face Swap link.');
      }
    }
  }, [isTelegramWebApp]);

  return (
    <TemplateFrame
      toggleCustomTheme={() => setShowCustomTheme((prev) => !prev)}
      showCustomTheme={showCustomTheme}
      mode={mode}
      toggleColorMode={toggleColorMode}
      credits={credits}
      needPadding={true}
    >
      <ThemeProvider theme={showCustomTheme ? modelPageTheme : defaultTheme}>
        <CssBaseline enableColorScheme />
        <ModelPageContainer sx={{ marginBottom: 10 }}>
          {loading ? (
            <LinearProgress />
          ) : modelData ? (
            <>
              <Button
                startIcon={<ArrowBackIcon />}
                size="large"
                onClick={() => navigate('/feed')}
                sx={{ alignSelf: 'flex-start', marginLeft: 2, marginTop: 2 }}
              >
                Models List
              </Button>
              {/* Avatar */}
              <ModelAvatar src={modelData.avatar} alt={`${modelData.name} avatar`} />

              {/* Model Name and Verification */}
              <Typography variant="h4" component="h1" align="center" gutterBottom>
                {modelData.name}
                {modelData?.verified ? (
                  <VerifiedBadge fontSize="small" />
                ) : (
                  <Chip label="Created with AI" variant="outlined" sx={{ marginLeft: 1 }} />
                )}
              </Typography>

              {/* Create and Face Swap Buttons */}
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<AutoAwesomeIcon />}
                  onClick={() => navigate(`/create?model_id=${model_id}`)}
                >
                  Create
                </Button>
                <Button
                  variant="text"
                  color="inherit"
                  onClick={handleFaceSwapClick}
                >
                  Face Swap
                </Button>
              </Box>

              {renderSocialIcons()}

              {/* Divider */}
              <Divider sx={{ width: '100%', my: 3 }} />

              {/* Masonry Feed */}
              <MasonryFeed media={media} />
            </>
          ) : (
            <Typography variant="h6" color="textSecondary">
              Model data not found.
            </Typography>
          )}
        </ModelPageContainer>

        {/* Error Snackbar */}
        {!isTelegramWebApp && (
          <Snackbar
            open={!!submitError}
            autoHideDuration={6000}
            onClose={() => setSubmitError(null)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={{ width: '100%' }}
          >
            <Alert onClose={() => setSubmitError(null)} severity="error" sx={{ width: '100%' }}>
              {submitError}
            </Alert>
          </Snackbar>
        )}
      </ThemeProvider>
    </TemplateFrame>
  );
}
