//PaymentOptionsCard.js
import React from 'react';
import { Card, Typography, Box, Chip } from '@mui/material';

export default function PaymentOptionsCard({
  methodId,
  method,
  selectedCredit,
  selectedPaymentMethod,
  handlePaymentMethodSelection,
  providers,
}) {
  // Get active providers for this method
  const activeProviders = method.providers
    .map((pid) => providers[pid])
    .filter((p) => p && p.active);

  // Check if the payment method can be used
  const canBeUsed = activeProviders.some((provider) => {
    const minRequired = provider.min || 0;
    const maxLimit = provider.max || Infinity;
    return selectedCredit?.price >= minRequired && selectedCredit?.price <= maxLimit;
  });

  // Determine reason chips for limits
  let reasonChip = null;
  if (!canBeUsed && activeProviders.length > 0 && selectedCredit) {
    // Find smallest min and largest max among active providers
    const mins = activeProviders.map((p) => p.min || 0);
    const maxs = activeProviders.map((p) => p.max || Infinity);

    const smallestMin = Math.min(...mins);
    const largestMax = Math.max(...maxs);

    if (selectedCredit.price < smallestMin) {
      reasonChip = (
        <Chip
          label={`from $${smallestMin}`}
          size="small"
          sx={{ position: 'absolute', top: 4, right: 4 }}
        />
      );
    } else if (selectedCredit.price > largestMax) {
      reasonChip = (
        <Chip
          label={`up to $${largestMax}`}
          size="small"
          sx={{ position: 'absolute', top: 4, right: 4 }}
        />
      );
    }
  }

  // Hide card if it cannot be used for other reasons
  if (!selectedCredit && activeProviders.some((p) => p.countries)) {
    return null;
  }

  return (
    <Card
      onClick={() => canBeUsed && handlePaymentMethodSelection([methodId, method])}
      sx={{
        width: "20%",
        height: "120px",
        minWidth: 120,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 1,
        textAlign: 'center',
        opacity: canBeUsed ? 1 : 0.5,
        pointerEvents: canBeUsed ? 'auto' : 'none',
        borderColor: selectedPaymentMethod?.[0] === methodId ? 'primary.main' : 'grey.300',
        cursor: canBeUsed ? 'pointer' : 'not-allowed',
        borderWidth: 2,
        borderStyle: 'solid',
        marginRight: 2,
        marginBottom: 2,
      }}
    >
      {/* Show reason chip */}
      {reasonChip}

      {/* Payment Method Image */}
      <Box
        component="img"
        src={`${process.env.PUBLIC_URL}/assets/icons/payments/${methodId.includes("card") ? 'card' : methodId}.png`}
        alt={`${method.name} Icon`}
        sx={{
          width: '60%',
          height: '60%',
          marginTop: 1,
          objectFit: 'contain',
        }}
      />

      {/* Payment Method Name */}
      <Typography variant="body2" sx={{ marginTop: 1 }}>
        {method.name}
      </Typography>
    </Card>
  );
}
