// BundleWidget.js

import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Typography,
  Divider,
  Chip,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { createTheme, styled } from '@mui/material/styles';
import getSignUpTheme from '../pages/theme/theme/getSignUpTheme';
import ModelsDialog from './ModelsDialog';
// Import Viewer.js
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';

const SourceImageWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'inline-block',
  height: '100%',
  width: 'auto',
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  left: theme.spacing(2),
  fontSize: '0.75rem',
  height: '24px',
}));

const BundleWidgetContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
}));

const ImagesContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  overflowX: 'auto',
  overflowY: 'hidden',
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(1),
  maxHeight: '200px',
  scrollBehavior: 'smooth',
  gap: theme.spacing(1),
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
  flexShrink: 0,
}));

const StyledImage = styled('img')(({ theme }) => ({
  height: '100%',
  borderRadius: theme.shape.borderRadius,
  maxWidth: '150px',
  maxHeight: '150px',
  cursor: 'pointer',
}));

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  height: '100%',
  minHeight: '150px',
  borderRadius: theme.shape.borderRadius,
  maxWidth: '150px',
}));

const BottomContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const InfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

const trnsl = {
  source: {
    en: "Source Image",
    ru: "Исходное изображение",
    hi: "स्रोत छवि",
    zh: "源图像",
    de: "Quellbild",
    es: "Imagen fuente"
  },
  price: {
    en: "Price:",
    ru: "Цена:",
    hi: "मूल्य:",
    zh: "价格:",
    de: "Preis:",
    es: "Precio:"
  },
  pictures: {
    en: "Pictures:",
    ru: "Картинки:",
    hi: "चित्र:",
    zh: "图片:",
    de: "Bilder:",
    es: "Imágenes:"
  },
  explore: {
    en: "Explore",
    ru: "Исследовать",
    hi: "अन्वेषण करें",
    zh: "探索",
    de: "Erkunden",
    es: "Explorar"
  },
  free: {
    en: "Free",
    ru: "Бесплатно",
    hi: "मुफ़्त",
    zh: "免费",
    de: "Kostenlos",
    es: "Gratis"
  },
  tags: {
    pulid: {
      en: "📷 Realistic",
      ru: "📷 Реалистичный",
      hi: "📷 यथार्थवादी",
      zh: "📷 现实主义",
      de: "📷 Realistisch",
      es: "📷 Realista"
    },
    v3: {
      en: "👤 Ultrarealistic",
      ru: "👤 Ультрареалистичный",
      hi: "👤 अति यथार्थवादी",
      zh: "👤 超现实主义",
      de: "👤 Ultrarealistisch",
      es: "👤 Ultrarrealista"
    },
    blow: {
      en: "Blowjob",
      ru: "Минет",
      hi: "ब्लोजॉब",
      zh: "口交",
      de: "Blasen",
      es: "Fellatio"
    },
    sex: {
      en: "Sex",
      ru: "Секс",
      hi: "सेक्स",
      zh: "性",
      de: "Sex",
      es: "Sexo"
    },
    gangbang: {
      en: "GangBang",
      ru: "Групповой секс",
      hi: "गैंगबैंग",
      zh: "群交",
      de: "Gruppensex",
      es: "Orgía"
    },
    soft: {
      en: "Soft",
      ru: "Мягкий",
      hi: "मुलायम",
      zh: "软",
      de: "Sanft",
      es: "Suave"
    },
    fantasy: {
      en: "Fantasy",
      ru: "Фантазия",
      hi: "कल्पना",
      zh: "幻想",
      de: "Fantasie",
      es: "Fantasía"
    }
  },
  artistic: {
    en: "Artistic 🎨",
    es: "Artístico 🎨",
    pt: "Artístico 🎨",
    de: "Künstlerisch 🎨",
    ja: "アーティスティック 🎨",
    ko: "예술적인 🎨",
    zh: "艺术 🎨",
    ru: "Артистичный 🎨",
    hi: "कलात्मक 🎨"
  },
  realistic: {
    en: "Realistic 📷",
    es: "Realista 📷",
    pt: "Realista 📷",
    de: "Realistisch 📷",
    ja: "リアル 📷",
    ko: "사실적인 📷",
    zh: "真实 📷",
    ru: "Реалистичный 📷",
    hi: "यथार्थवादी 📷"
  },
  ultrarealistic: {
    en: "Ultrarealistic 📷",
    ru: "Ультрареалистичный 📷",
    hi: "अत्यधिक यथार्थवादी 📷",
    zh: "超真实 📷",
    de: "Ultrarealistisch 📷",
    es: "Ultrarrealista 📷"
  },
  pulid_explain_title: {
    en: "Artistic vs Realistic",
    es: "Artístico vs Realista",
    pt: "Artístico vs Realista",
    de: "Künstlerisch vs Realistisch",
    ja: "アーティスティック vs リアル",
    ko: "예술적 대 사실적",
    zh: "艺术与真实",
    ru: "Артистичный vs Реалистичный",
    hi: "कलात्मक बनाम यथार्थवादी"
  },
  pulid_explain_text: [
    {
      en: "Artistic (left) produces stylized or more creative images: faster processing time, but not suitable for some extraordinary faces",
      es: "Artístico (izquierda) produce imágenes estilizadas o más creativas: tiempo de procesamiento más rápido, pero no es adecuado para algunas caras extraordinarias",
      pt: "Artístico (esquerda) produz imagens estilizadas ou mais criativas: tempo de processamento mais rápido, mas não é adequado para alguns rostos extraordinários",
      de: "Künstlerisch (links) erzeugt stilisierte oder kreativere Bilder: schnellere Verarbeitung, aber nicht geeignet für außergewöhnliche Gesichter",
      ja: "アーティスティック（左）はスタイリッシュまたはより創造的な画像を生成します：処理時間が短いですが、特異な顔には適していません",
      ko: "예술적(왼쪽)은 스타일화된 창의적인 이미지를 생성합니다: 처리 시간이 빠르지만 일부 특별한 얼굴에는 적합하지 않을 수 있습니다",
      zh: "艺术（左）生成风格化或更具创意的图像：处理时间更快，但不适用于某些特殊面孔",
      ru: "Артистичный (слева) создаёт стилизованные или более креативные изображения: быстрее обрабатывается, но не подходит для необычных лиц",
      hi: "कलात्मक (बाएं) स्टाइलिश या अधिक रचनात्मक छवियां उत्पन्न करता है: तेज़ प्रसंस्करण समय, लेकिन कुछ असाधारण चेहरों के लिए उपयुक्त नहीं"
    },
    {
      en: "Realistic (right) uses advanced technology to create ultrarealistic copy of the face, but is slower and may be more expensive",
      es: "Realista (derecha) utiliza tecnología avanzada para crear una copia ultrarrealista de la cara, pero es más lento y puede ser más caro",
      pt: "Realista (direita) usa tecnologia avançada para criar uma cópia ultrarrealista do rosto, mas é mais lento e pode ser mais caro",
      de: "Realistisch (rechts) nutzt fortschrittliche Technologie, um eine ultrarealistische Kopie des Gesichts zu erstellen, ist jedoch langsamer und möglicherweise teurer",
      ja: "リアル（右）は高度な技術を使用して超現実的な顔のコピーを作成しますが、遅くなる可能性があり、費用がかかる場合があります",
      ko: "사실적(오른쪽)은 고급 기술을 사용하여 초사실적인 얼굴 복사본을 생성합니다. 그러나 더 느리고 비용이 더 들 수 있습니다",
      zh: "真实（右）使用先进技术创建超现实的面部副本，但速度较慢且可能更昂贵",
      ru: "Реалистичный (справа) использует передовые технологии для создания ультрареалистичной копии лица, но медленнее и может быть дороже",
      hi: "यथार्थवादी (दाएं) उन्नत तकनीक का उपयोग करके चेहरे की अल्ट्रारेअलिस्टिक प्रतिलिपि बनाता है, लेकिन धीमा हो सकता है और अधिक महंगा हो सकता है"
    }
  ],
  pulid_explain_confirm: {
    en: "Got it!",
    es: "¡Entendido!",
    pt: "Entendi!",
    de: "Verstanden!",
    ja: "了解しました！",
    ko: "알겠습니다!",
    zh: "明白了！",
    ru: "Понял!",
    hi: "समझ गया!"
  },
  v3_explain_title: {
      en: "Realistic vs Ultrarealistic",
      ru: "Реалистичный vs Ультрареалистичный",
      hi: "यथार्थवादी बनाम अत्यधिक यथार्थवादी",
      zh: "真实 vs 超真实",
      de: "Realistisch vs Ultrarealistisch",
      es: "Realista vs Ultrarrealista"
  },
  v3_explain_text: [
      {
          en: "Realistic (left) uses advanced technology to create realistic copy of the face, but can not provide enough close up details with close up on face photos",
          ru: "Реалистичный (слева) использует передовые технологии для создания реалистичной копии лица, но не может предоставить достаточно деталей при крупном плане лица",
          hi: "यथार्थवादी (बाएं) उन्नत तकनीक का उपयोग करके चेहरे की यथार्थवादी प्रति बनाता है, लेकिन चेहरे की तस्वीरों के क्लोज़ अप में पर्याप्त विवरण प्रदान नहीं कर सकता है।",
          zh: "真实（左）使用先进技术创建面部的真实复制，但在面部特写照片中无法提供足够的细节。",
          de: "Realistisch (links) nutzt fortschrittliche Technologie, um eine realistische Kopie des Gesichts zu erstellen, kann jedoch keine ausreichenden Nahaufnahmen liefern, wenn das Gesicht nah fokussiert ist.",
          es: "Realista (izquierda) utiliza tecnología avanzada para crear una copia realista de la cara, pero no puede proporcionar suficientes detalles en primeros planos de la cara."
      },
      {
          en: "Ultrarealistic (right) is state-of-the-art model which can provide extremely realistic close up portraits, but is the most expensive and takes the most time to process, and may produce broken content with artifacts",
          ru: "Ультрареалистичный (справа) - это передовая модель, которая может предоставлять чрезвычайно реалистичные портреты крупным планом, но она самая дорогая, требует больше всего времени на обработку и может создавать сломанный контент с артефактами.",
          hi: "अत्यधिक यथार्थवादी (दाएं) एक अत्याधुनिक मॉडल है जो अत्यधिक यथार्थवादी क्लोज़ अप पोर्ट्रेट प्रदान कर सकता है, लेकिन यह सबसे महंगा है और इसे संसाधित करने में सबसे अधिक समय लगता है और यह आर्टिफैक्ट्स के साथ टूटे हुए सामग्री का उत्पादन कर सकता है।",
          zh: "超真实（右）是一种最先进的模型，可以提供极其真实的特写肖像，但最昂贵，处理时间最长，并可能产生带有伪影的破损内容。",
          de: "Ultrarealistisch (rechts) ist ein hochmodernes Modell, das extrem realistische Nahaufnahmen liefern kann, aber am teuersten ist, die meiste Zeit für die Verarbeitung benötigt und möglicherweise fehlerhafte Inhalte mit Artefakten erzeugt.",
          es: "Ultrarrealista (derecha) es un modelo de última generación que puede proporcionar retratos extremadamente realistas en primer plano, pero es el más caro, el que más tiempo tarda en procesar y puede producir contenido defectuoso con artefactos."
      }
  ],
};


export default function BundleWidget({
  name,
  description,
  source_photo,
  bundle,
  price,
  bundle_id,
  language = 'en',
  showData = true,
  isEligable = false,
  camp,
  openUnlockDialog,
  tags,
  mode = 'dark'
}) {

  const customTheme = createTheme(getSignUpTheme(mode));
  const navigate = useNavigate();
  const [imageLoaded, setImageLoaded] = useState({});
  const containerRef = useRef(null);   // container for images
  const viewerRef = useRef(null);      // to store Viewer instance

  const [pulidOpen, setPulidOpen] = useState(false);
  const [ultraOpen, setUltraOpen] = useState(false);

  // Combine source photo + bundle images
  const images = [source_photo, ...bundle.map(item => item.example)];

  // Preload images (optional)
  useEffect(() => {
    images.forEach((imageUrl) => {
      const img = new Image();
      img.src = imageUrl;
    });
  }, [images]);

  // 1) Instantiate Viewer.js in useEffect after first render
  useEffect(() => {
    if (!containerRef.current) return;

    // Destroy any old viewer before creating a new one
    if (viewerRef.current) {
      viewerRef.current.destroy();
      viewerRef.current = null;
    }

    // Viewer automatically finds all <img> inside containerRef
    const viewer = new Viewer(containerRef.current, {
      // For modal mode, set inline: false (default).
      inline: false,
      // Add extra config as needed:
      zIndex: 9999,             // We want it above all
      title: false,              // Show image title
      toolbar: false,            // Show toolbar (zoom/rotate/etc.)
      navbar: true,            // If you want nav thumbs hidden
      button: true,             // Show close button top-right
      transition: true,  
      movable: false,
      tooltip: false,       
      // More options: https://github.com/fengyuanchen/viewerjs
    });

    viewerRef.current = viewer;

    return () => {
      // Cleanup on unmount
      if (viewerRef.current) {
        viewerRef.current.destroy();
        viewerRef.current = null;
      }
    };
  }, [images]);

  const handleImageLoad = (index) => {
    setImageLoaded((prev) => ({
      ...prev,
      [index]: true,
    }));
  };

  const handleClick = () => {
    // If isEligable but user must unlock
    if (isEligable && bundle.length > 5) {
      openUnlockDialog();
    } else {
      navigate(
        `/bundle?bundle_id=${bundle_id}${camp ? `&camp=${camp}` : ""}&lang=${language}`
      );
    }
  };

  return (
    <>
    <BundleWidgetContainer>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {/* Ref container - viewerjs will hook onto all <img> inside here */}
        <ImagesContainer ref={containerRef}>
          {images.map((imageUrl, index) => (
            <ImageWrapper key={`${bundle_id}-${index}`}>
              {index === 0 ? (
                // first image with a "Source Image" Chip
                <SourceImageWrapper>
                  {!imageLoaded[index] && (
                    <StyledSkeleton variant="rectangular" minWidth={200} width={200} />
                  )}
                  <StyledImage
                    src={imageUrl}
                    alt={`BundleImage-${index}`}
                    onLoad={() => handleImageLoad(index)}
                    style={{ display: imageLoaded[index] ? 'block' : 'none' }}
                  />
                  <StyledChip
                    label={trnsl.source?.[language] || trnsl.source.en}
                    size="medium"
                  />
                </SourceImageWrapper>
              ) : (
                <>
                  {!imageLoaded[index] && (
                    <StyledSkeleton variant="rectangular" minWidth={200} width={200} />
                  )}
                  <StyledImage
                    src={imageUrl}
                    alt={`BundleImage-${index}`}
                    onLoad={() => handleImageLoad(index)}
                    style={{ display: imageLoaded[index] ? 'block' : 'none' }}
                  />
                </>
              )}
            </ImageWrapper>
          ))}
        </ImagesContainer>

        {showData && (
          <>
            <Divider />
            {tags && tags?.length > 0 && (
              <Box sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                padding: 1,
                overflowX: "auto",
                
              }}>
                {tags.map((key => (
                  <Chip
                    size="medium"
                    label={(trnsl.tags?.[key]?.[language] || trnsl.tags?.[key]?.en || key).toUpperCase() }
                    color={['pulid', 'v3'].includes(key) ? 'primary' : 'default'}
                    clickable={['pulid', 'v3'].includes(key)}
                    onClick={() => key === 'pulid' ? setPulidOpen(true) : key === 'v3' ? setUltraOpen(true) : null}
                  />
                )))}
              </Box>
            )}
            <BottomContainer>
              <InfoContainer>
                <Typography variant="body1" sx={{ mx: 2 }}>
                  {trnsl.pictures?.[language] || trnsl.pictures.en} {bundle.length}
                </Typography>
                {isEligable && bundle.length <= 5 ? (
                  <Typography variant="body1" color="success.main">
                    {trnsl.free?.[language] || trnsl.free.en}
                  </Typography>
                ) : (
                  <Typography variant="body1">
                    {trnsl.price?.[language] || trnsl.price.en} {price} 💎
                  </Typography>
                )}
              </InfoContainer>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClick();
                }}
              >
                {trnsl.explore?.[language] || trnsl.explore.en}
              </Button>
            </BottomContainer>
          </>
        )}
      </Box>
    </BundleWidgetContainer>

    <ModelsDialog mode={mode} language={language} pulidOpen={pulidOpen} setPulidOpen={setPulidOpen} ultraOpen={ultraOpen} setUltraOpen={setUltraOpen}/>
    </>
  );
}
