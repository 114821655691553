// BundlePage.js
import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Button,
  CssBaseline,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  Skeleton,
  Chip,
  Container,
  LinearProgress,
  Divider,
  Fade
} from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import { styled } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { getDatabase, ref, get } from 'firebase/database';
import { signInWithCustomToken } from 'firebase/auth';
import { logEvent } from 'firebase/analytics';
import useMediaQuery from '@mui/material/useMediaQuery';

import OutputCycle from './OutputCycle';
import UploadBlock from '../../components/UploadBlock';
import BundlesList from '../../components/BundlesList';
import CouponBanner from '../../components/CouponBanner';

import { auth, analytics } from '../../services/firebase';
import getSignUpTheme from '../theme/theme/getSignUpTheme';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import RedeemIcon from '@mui/icons-material/Redeem';
import BurstModeIcon from '@mui/icons-material/BurstMode';
import SellIcon from '@mui/icons-material/Sell';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SignUpDialog from '../signup/SignUpDialog';
import ModelsDialog from '../../components/ModelsDialog';

// ::::::::::::::::::::::::: STYLED COMPONENTS :::::::::::::::::::::::::
const ContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  textAlign: 'center',
  overflow: 'hidden',
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  width: '90%',
  marginY: 10,
  overflow: 'hidden',
  position: 'relative',
  maxHeight: '60vh',
  [theme.breakpoints.up('md')]: {
    maxHeight: '90vh',
  },
}));

const ScrollingContent = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  height: '100%',
  position: 'relative',
  overflow: 'hidden',
}));

const Title = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

const Description = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  textAlign: 'left',
}));

const FixedBottomButton = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  left: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 9998,
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
}));

const UploadProgress = styled(Box)(({ theme }) => ({
  width: '100%',
  margin: theme.spacing(2, 0),
}));

const LoadingOverlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  inset: 0,
  zIndex: 999999,
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(0, 0, 0, 0.7)'
      : 'rgba(255, 255, 255, 0.7)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const t = {
    bundles_header: {
        en: "Explore other Bundles",
        es: "Explora otros paquetes",
        pt: "Explore outros pacotes",
        de: "Entdecke weitere Pakete",
        ja: "他のバンドルを探索する",
        ko: "다른 번들 탐색",
        zh: "探索其他套餐",
        ru: "Другие Фотосеты",
        hi: "अन्य बंडलों का अन्वेषण करें"
    },
    bunlde_description: {
        en: [
            "Bundles = Photosets",
            "Upload a selfie of a person, and get same images as you see above",
            "Each bundle costs credits. 1 credit = 1 high-quality picture"
        ],
        es: [
            "Paquetes = Conjuntos de fotos",
            "Sube una selfie de una persona y obtén las mismas imágenes que ves arriba",
            "Cada paquete cuesta créditos. 1 crédito = 1 imagen de alta calidad"
        ],
        pt: [
            "Pacotes = Conjuntos de fotos",
            "Envie uma selfie de uma pessoa e obtenha as mesmas imagens que você vê acima",
            "Cada pacote custa créditos. 1 crédito = 1 imagem de alta qualidade"
        ],
        de: [
            "Pakete = Fotosets",
            "Laden Sie ein Selfie einer Person hoch und erhalten Sie dieselben Bilder wie oben",
            "Jedes Paket kostet Credits. 1 Credit = 1 hochqualitatives Bild"
        ],
        ja: [
            "バンドル = 写真セット",
            "人物のセルフィーをアップロードすると、上記と同じ画像が得られます",
            "各バンドルにはクレジットが必要です。1クレジット = 1高品質画像"
        ],
        ko: [
            "번들 = 사진 세트",
            "사람의 셀카를 업로드하면 위와 동일한 이미지를 얻을 수 있습니다",
            "각 번들은 크레딧이 필요합니다. 1크레딧 = 1고품질 이미지"
        ],
        zh: [
            "套餐 = 照片集",
            "上传一个人的自拍，即可获得与上面相同的图片",
            "每个套餐需要积分。1积分 = 1高质量图片"
        ],
        ru: [
            "Фотосеты = Наборы фото",
            "Загрузите селфи человека и получите такие же изображения, как выше",
            "Каждый фотосет стоит несколько камушков. 1 кредит = 1 качественное изображение"
        ],
        hi: [
            "बंडल्स = फोटो सेट",
            "किसी व्यक्ति की सेल्फी अपलोड करें और ऊपर दिखाए गए समान चित्र प्राप्त करें",
            "प्रत्येक बंडल की लागत क्रेडिट्स है। 1 क्रेडिट = 1 उच्च-गुणवत्ता चित्र"
        ]
    },
    price: {
        en: "Price: ",
        es: "Precio: ",
        pt: "Preço: ",
        de: "Preis: ",
        ja: "価格: ",
        ko: "가격: ",
        zh: "价格: ",
        ru: "Цена: ",
        hi: "मूल्य: "
    },
    pictures: {
        en: "Pictures: ",
        es: "Imágenes: ",
        pt: "Imagens: ",
        de: "Bilder: ",
        ja: "写真: ",
        ko: "사진: ",
        zh: "图片: ",
        ru: "Фото: ",
        hi: "चित्र: "
    },
    free: {
        en: "FREE",
        es: "GRATIS",
        pt: "GRÁTIS",
        de: "KOSTENLOS",
        ja: "無料",
        ko: "무료",
        zh: "免费",
        ru: "БЕСПЛАТНО",
        hi: "नि: शुल्क"
    },
    preparing: {
        en: "Preparing your photoset...",
        es: "Preparando tu conjunto de fotos...",
        pt: "Preparando seu conjunto de fotos...",
        de: "Bereite dein Fotoset vor...",
        ja: "写真セットを準備中...",
        ko: "사진 세트를 준비 중...",
        zh: "正在准备您的照片集...",
        ru: "Загрузка фотосета...",
        hi: "आपके फोटो सेट को तैयार किया जा रहा है..."
    },
    upload: {
        header: {
            en: "LAST STEP: Upload a selfie",
            es: "ÚLTIMO PASO: Sube una selfie",
            pt: "ÚLTIMO PASSO: Faça upload de uma selfie",
            de: "LETZTER SCHRITT: Lade ein Selfie hoch",
            ja: "最後のステップ: セルフィーをアップロード",
            ko: "마지막 단계: 셀카를 업로드하세요",
            zh: "最后一步: 上传自拍",
            ru: "ПОСЛЕДНИЙ ШАГ: Загрузите селфи",
            hi: "अंतिम चरण: एक सेल्फी अपलोड करें"
        },
        description: {
            en: [
                "You can upload from 1 to 5 photos",
                "There must be the same person on each photo",
                "Face on a photo should be clearly seen",
                "For best result, avoid pictures with hats, eyewear",
                "Try to include part of a body of a person, so AI can understand the body shape"
            ],
            es: [
                "Puedes subir de 1 a 5 fotos",
                "Debe ser la misma persona en cada foto",
                "La cara en una foto debe verse claramente",
                "Para mejores resultados, evita fotos con sombreros o gafas",
                "Intenta incluir parte del cuerpo de la persona para que la IA pueda entender la forma del cuerpo"
            ],
            pt: [
                "Você pode enviar de 1 a 5 fotos",
                "Deve ser a mesma pessoa em cada foto",
                "O rosto em uma foto deve estar claramente visível",
                "Para melhores resultados, evite fotos com chapéus ou óculos",
                "Tente incluir parte do corpo da pessoa para que a IA possa entender a forma do corpo"
            ],
            de: [
                "Du kannst 1 bis 5 Fotos hochladen",
                "Es muss dieselbe Person auf jedem Foto sein",
                "Das Gesicht auf einem Foto sollte deutlich zu sehen sein",
                "Für beste Ergebnisse, vermeide Bilder mit Hüten oder Brillen",
                "Versuche, einen Teil des Körpers der Person einzubeziehen, damit die KI die Körperform verstehen kann"
            ],
            ja: [
                "1から5枚の写真をアップロードできます",
                "各写真には同じ人物が写っている必要があります",
                "写真の顔は明確に見える必要があります",
                "最良の結果を得るために、帽子やメガネのある写真を避けてください",
                "AIが体型を理解できるように、人物の一部を含めるようにしてください"
            ],
            ko: [
                "1에서 5장의 사진을 업로드할 수 있습니다",
                "각 사진에는 동일한 사람이 있어야 합니다",
                "사진의 얼굴이 명확히 보여야 합니다",
                "최상의 결과를 위해 모자나 안경이 있는 사진을 피하세요",
                "AI가 체형을 이해할 수 있도록 사람의 일부를 포함하려고 노력하세요"
            ],
            zh: [
                "您可以上传1到5张照片",
                "每张照片上必须是同一个人",
                "照片上的脸部应该清晰可见",
                "为了获得最佳效果，请避免戴帽子或眼镜的照片",
                "尝试包括人物的一部分，以便AI能够理解体型"
            ],
            ru: [
                "Вы можете загрузить от 1 до 5 фотографий",
                "На каждой фотографии должен быть один и тот же человек",
                "Лицо на фотографии должно быть четко видно",
                "Для лучшего результата избегайте фотографий в шляпах или очках",
                "Старайтесь включать часть тела человека, чтобы ИИ мог понять форму тела"
            ],
            hi: [
                "आप 1 से 5 फ़ोटो अपलोड कर सकते हैं",
                "प्रत्येक फ़ोटो में एक ही व्यक्ति होना चाहिए",
                "फ़ोटो में चेहरा स्पष्ट रूप से दिखाई देना चाहिए",
                "सर्वोत्तम परिणामों के लिए, टोपी या चश्मे वाली तस्वीरों से बचें",
                "AI को शरीर की आकृति समझने के लिए व्यक्ति के शरीर का हिस्सा शामिल करने का प्रयास करें"
            ]
        }
    },
    results: {
      poor: {
        en: "👍 If it's a close up face photo, one is enough",
        ru: "👍 Если это фото крупным планом лица, одного достаточно",
        hi: "👍 यदि यह चेहरे की क्लोज़-अप फ़ोटो है, तो एक पर्याप्त है",
        zh: "👍 如果是面部特写照片，一张就够了",
        de: "👍 Wenn es ein Nahaufnahme-Foto des Gesichts ist, reicht eines aus",
        es: "👍 Si es una foto de primer plano de la cara, una es suficiente",
        ko: "👍 얼굴 근접 사진이라면 한 장이면 충분합니다",
        ja: "👍 顔のクローズアップ写真であれば、1枚で十分です"
      },      
      good: {
          en: "Good result expected ✅",
          es: "Se espera un buen resultado ✅",
          pt: "Bom resultado esperado ✅",
          de: "Gutes Ergebnis erwartet ✅",
          ja: "良い結果が期待されます ✅",
          ko: "좋은 결과가 예상됩니다 ✅",
          zh: "预计效果良好 ✅",
          ru: "Ожидается хороший результат ✅",
          hi: "अच्छे परिणाम की उम्मीद है ✅"
      },
      great: {
          en: "Great result expected ✅",
          es: "Se espera un gran resultado ✅",
          pt: "Ótimo resultado esperado ✅",
          de: "Tolles Ergebnis erwartet ✅",
          ja: "素晴らしい結果が期待されます ✅",
          ko: "훌륭한 결과가 예상됩니다 ✅",
          zh: "预计效果极佳 ✅",
          ru: "Ожидается отличный результат ✅",
          hi: "उत्कृष्ट परिणाम की उम्मीद है ✅"
      }
    },
    buttons: {
        skip: {
            en: "Skip",
            es: "Saltar",
            pt: "Pular",
            de: "Überspringen",
            ja: "スキップ",
            ko: "건너뛰기",
            zh: "跳过",
            ru: "Пропустить",
            hi: "छोड़ें"
        },
        create: {
            en: "Create",
            es: "Crear",
            pt: "Criar",
            de: "Erstellen",
            ja: "作成",
            ko: "생성",
            zh: "创建",
            ru: "Создать",
            hi: "बनाएं"
        },
        upload: {
            en: "Upload",
            es: "Subir",
            pt: "Fazer upload",
            de: "Hochladen",
            ja: "アップロード",
            ko: "업로드",
            zh: "上传",
            ru: "Загрузить",
            hi: "अपलोड करें"
        },
        continue: {
            en: "Continue",
            es: "Continuar",
            pt: "Continuar",
            de: "Fortfahren",
            ja: "続ける",
            ko: "계속",
            zh: "继续",
            ru: "Продолжить",
            hi: "जारी रखें"
        },
        submitting: {
            en: "Submitting...",
            es: "Enviando...",
            pt: "Enviando...",
            de: "Einreichen...",
            ja: "送信中...",
            ko: "제출 중...",
            zh: "提交中...",
            ru: "Отправка...",
            hi: "प्रस्तुत कर रहा है..."
        },
        cancel: {
            en: "Cancel",
            es: "Cancelar",
            pt: "Cancelar",
            de: "Abbrechen",
            ja: "キャンセル",
            ko: "취소",
            zh: "取消",
            ru: "Отмена",
            hi: "रद्द करें"
        },
        explore: {
          en: "Explore",
          es: "Explorar",
          pt: "Explorar",
          de: "Entdecken",
          ja: "探検する",
          ko: "탐험",
          zh: "探索",
          ru: "Исследовать",
          hi: "खोजें"
        },
        iwait: {
          en: "I'll wait",
          es: "Esperaré",
          pt: "Eu vou esperar",
          de: "Ich warte",
          ja: "待ちます",
          ko: "기다릴게요",
          zh: "我会等",
          ru: "Я подожду",
          hi: "मैं इंतजार करूंगा"
        }
    },
    skipDialog: {
      title: {
          en: "Are you sure you want to skip?",
          es: "¿Estás seguro de que quieres saltar?",
          pt: "Tem certeza de que deseja pular?",
          de: "Möchten Sie wirklich überspringen?",
          ja: "スキップしてもよろしいですか？",
          ko: "건너뛰시겠습니까?",
          zh: "您确定要跳过吗？",
          ru: "Вы уверены, что хотите пропустить?",
          hi: "क्या आप वाकई छोड़ना चाहते हैं?"
      },
      description: {
          en: "By skipping, you'll miss out on getting exactly what you came for. You can skip and explore all the power of Lumipic.",
          es: "Al saltar, te perderás exactamente lo que viniste a buscar. Puedes saltar y explorar todo el poder de Lumipic.",
          pt: "Ao pular, você perderá exatamente o que veio buscar. Você pode pular e explorar todo o poder do Lumipic.",
          de: "Wenn Sie überspringen, verpassen Sie genau das, wofür Sie gekommen sind. Sie können überspringen und die gesamte Leistung von Lumipic entdecken.",
          ja: "スキップすると、目的のものを見逃してしまいます。スキップしてLumipicの全機能を探索できます。",
          ko: "건너뛰면 원하는 것을 놓칠 수 있습니다. Lumipic의 모든 기능을 탐색할 수 있습니다.",
          zh: "跳过将会错过您想要的内容。您可以跳过并探索 Lumipic 的所有功能。",
          ru: "Пропустив, вы упустите то, зачем пришли. Вы можете пропустить и изучить все возможности Lumipic.",
          hi: "छोड़ने पर, आप वह खो देंगे जिसके लिए आप आए थे। आप छोड़ सकते हैं और Lumipic की सभी शक्ति का अन्वेषण कर सकते हैं।"
      }
    },
    skipPaidUser: {
      title: {
        en: "Confirm action",
        es: "Confirmar acción",
        pt: "Confirmar ação",
        de: "Aktion bestätigen",
        ja: "操作を確認",
        ko: "작업 확인",
        zh: "确认操作",
        ru: "Подтвердить действие",
        hi: "क्रिया की पुष्टि करें"
      },
      description: {
        en: "You are about to leave this page",
        es: "Estás a punto de salir de esta página",
        pt: "Você está prestes a sair desta página",
        de: "Sie sind dabei, diese Seite zu verlassen",
        ja: "このページを離れようとしています",
        ko: "이 페이지를 떠나려고 합니다",
        zh: "您即将离开此页面",
        ru: "Вы собираетесь покинуть эту страницу",
        hi: "आप इस पृष्ठ को छोड़ने वाले हैं"
      }
    },
    processed: {
      en: "Your job is being processed. Please don’t close this page.",
      es: "Tu trabajo está siendo procesado. Por favor, no cierres esta página.",
      pt: "Seu trabalho está sendo processado. Por favor, não feche esta página.",
      de: "Ihr Job wird bearbeitet. Bitte schließen Sie diese Seite nicht.",
      ja: "ジョブを処理しています。このページを閉じないでください。",
      ko: "작업이 처리 중입니다. 이 페이지를 닫지 마십시오.",
      zh: "您的工作正在处理中。请不要关闭此页面。",
      ru: "Ваше задание обрабатывается. Пожалуйста, не закрывайте эту страницу.",
      hi: "आपका काम प्रक्रिया में है। कृपया इस पृष्ठ को बंद न करें।"
    },
    ready: {
      en: "Your content is ready! View now",
      es: "¡Tu contenido está listo! Ver ahora",
      pt: "Seu conteúdo está pronto! Veja agora",
      de: "Ihr Inhalt ist fertig! Jetzt ansehen",
      ja: "コンテンツの準備ができました！今すぐ表示",
      ko: "콘텐츠가 준비되었습니다! 지금 보기",
      zh: "您的内容已准备好！立即查看",
      ru: "Ваш контент готов! Просмотр сейчас",
      hi: "आपकी सामग्री तैयार है! अभी देखें"
    },
    dialogSure: {
      confirmTitle: {
        en: "Are you sure?",
        es: "¿Estás seguro?",
        pt: "Você tem certeza?",
        de: "Bist du sicher?",
        ja: "本当によろしいですか？",
        ko: "확실합니까?",
        zh: "你确定吗？",
        ru: "Вы уверены?",
        hi: "क्या आपको यकीन है?"
      },
      confirmDescription: {
        en: "You might miss out on faster results. Are you sure you want to wait?",
        es: "Podrías perderte resultados más rápidos. ¿Estás seguro de que quieres esperar?",
        pt: "Você pode perder resultados mais rápidos. Tem certeza de que deseja esperar?",
        de: "Du könntest schnellere Ergebnisse verpassen. Bist du sicher, dass du warten möchtest?",
        ja: "より早い結果を逃す可能性があります。本当に待ちますか？",
        ko: "더 빠른 결과를 놓칠 수 있습니다. 기다리시겠습니까?",
        zh: "你可能会错过更快的结果。你确定要等吗？",
        ru: "Вы можете упустить более быстрые результаты. Вы уверены, что хотите подождать?",
        hi: "आप तेज़ परिणामों से चूक सकते हैं। क्या आप वाकई इंतजार करना चाहते हैं?"
      }
    },
    process: {
      upload: [
        {
          en: "Uploading files to our server...",
          es: "Subiendo archivos a nuestro servidor...",
          pt: "Fazendo upload de arquivos para o nosso servidor...",
          de: "Dateien auf unseren Server hochladen...",
          ja: "ファイルをサーバーにアップロードしています...",
          ko: "파일을 서버에 업로드 중입니다...",
          zh: "正在将文件上传到我们的服务器...",
          ru: "Загрузка файлов на наш сервер...",
          hi: "हमारे सर्वर पर फ़ाइलें अपलोड की जा रही हैं..."
        },
        {
          en: "Please do not close this page.",
          es: "Por favor, no cierres esta página.",
          pt: "Por favor, não feche esta página.",
          de: "Bitte schließen Sie diese Seite nicht.",
          ja: "このページを閉じないでください。",
          ko: "이 페이지를 닫지 마십시오.",
          zh: "请不要关闭此页面。",
          ru: "Пожалуйста, не закрывайте эту страницу.",
          hi: "कृपया इस पृष्ठ को बंद न करें।"
        }
      ],
      digitalCopy: [
        {
          en: "Creating digital copy of your face...",
          es: "Creando copia digital de tu rostro...",
          pt: "Criando cópia digital do seu rosto...",
          de: "Erstellen einer digitalen Kopie Ihres Gesichts...",
          ja: "顔のデジタルコピーを作成しています...",
          ko: "얼굴의 디지털 사본을 생성 중입니다...",
          zh: "正在创建您的面部数字副本...",
          ru: "Создание цифровой копии вашего лица...",
          hi: "आपके चेहरे की डिजिटल कॉपी बनाई जा रही है..."
        },
        {
          en: "Securely removing your original photos from our servers",
          es: "Eliminando de forma segura tus fotos originales de nuestros servidores",
          pt: "Removendo com segurança suas fotos originais de nossos servidores",
          de: "Sichere Entfernung Ihrer Originalfotos von unseren Servern",
          ja: "サーバーから元の写真を安全に削除しています",
          ko: "서버에서 원본 사진을 안전하게 삭제 중입니다",
          zh: "正在从我们的服务器安全地删除您的原始照片",
          ru: "Безопасное удаление ваших оригинальных фотографий с наших серверов",
          hi: "हमारे सर्वरों से आपके मूल फ़ोटो को सुरक्षित रूप से हटा रहा है"
        }
      ],
      queue: [
        {
          en: "Queuing job, please wait...",
          es: "Colocando trabajo en cola, por favor espera...",
          pt: "Enfileirando trabalho, por favor, espere...",
          de: "Job wird in die Warteschlange gestellt, bitte warten...",
          ja: "ジョブをキューに入れています、お待ちください...",
          ko: "작업 대기열에 추가 중입니다. 기다려주세요...",
          zh: "正在排队，请稍候...",
          ru: "Постановка задачи в очередь, пожалуйста, подождите...",
          hi: "काम को कतार में डाल रहा है, कृपया प्रतीक्षा करें..."
        },
        {
          en: "This won't take long",
          es: "Esto no tomará mucho tiempo",
          pt: "Isso não vai demorar",
          de: "Das wird nicht lange dauern",
          ja: "時間はかかりません",
          ko: "오래 걸리지 않습니다",
          zh: "这不会花太长时间",
          ru: "Это не займет много времени",
          hi: "इसमें अधिक समय नहीं लगेगा"
        }
      ]
    },
    funnel: {
      description: {
        en: "Looks like there is a lot of demand on our tool right now. We prioritize users with paid credits over new users. If you don't want to wait, just click the button on the bottom right to explore options",
        es: "Parece que hay mucha demanda en nuestra herramienta en este momento. Priorizamos a los usuarios con créditos pagos sobre los nuevos usuarios. Si no quieres esperar, simplemente haz clic en el botón en la parte inferior derecha para explorar opciones",
        pt: "Parece que há muita demanda em nossa ferramenta agora. Priorizamos os usuários com créditos pagos em relação aos novos usuários. Se você não quiser esperar, basta clicar no botão no canto inferior direito para explorar opções",
        de: "Es sieht so aus, als gäbe es gerade eine hohe Nachfrage nach unserem Tool. Wir priorisieren Benutzer mit bezahlten Credits vor neuen Benutzern. Wenn Sie nicht warten möchten, klicken Sie einfach auf die Schaltfläche unten rechts, um Optionen zu erkunden",
        ja: "現在、当社のツールに非常に多くの需要があるようです。有料クレジットを持つユーザーを新規ユーザーより優先します。待ちたくない場合は、右下のボタンをクリックしてオプションを探索してください",
        ko: "현재 도구에 대한 수요가 많은 것 같습니다. 유료 크레딧 사용자를 새로운 사용자보다 우선합니다. 기다리고 싶지 않다면 오른쪽 하단 버튼을 클릭하여 옵션을 탐색하세요",
        zh: "看来我们目前的工具需求很大。我们优先考虑有付费积分的用户，而不是新用户。如果您不想等待，只需点击右下角的按钮探索选项",
        ru: "Кажется, сейчас на наш инструмент большой спрос. Мы отдаем приоритет пользователям с платными кредитами перед новыми пользователями. Если вы не хотите ждать, просто нажмите кнопку в правом нижнем углу, чтобы изучить варианты",
        hi: "ऐसा लगता है कि अभी हमारे उपकरण पर बहुत मांग है। हम नए उपयोगकर्ताओं की तुलना में भुगतान किए गए क्रेडिट वाले उपयोगकर्ताओं को प्राथमिकता देते हैं। यदि आप प्रतीक्षा नहीं करना चाहते हैं, तो विकल्पों का पता लगाने के लिए नीचे दाईं ओर बटन पर क्लिक करें"
      },
      header: {
        en: "Don't want to wait? Join now and get:",
        es: "¿No quieres esperar? Únete ahora y obtén:",
        pt: "Não quer esperar? Junte-se agora e obtenha:",
        de: "Möchten Sie nicht warten? Jetzt beitreten und erhalten:",
        ja: "待ちたくありませんか？今すぐ参加して、以下を取得:",
        ko: "기다리고 싶지 않습니까? 지금 가입하고 얻으세요:",
        zh: "不想等待？立即加入并获得：",
        ru: "Не хотите ждать? Присоединяйтесь сейчас и получите:",
        hi: "प्रतीक्षा नहीं करना चाहते? अभी शामिल हों और प्राप्त करें:"
      },
      bullet: {
        en: [
          "⚡️ Fastest processing, highest priority, no more wait!",
          "✨ Highest face similarity with our new realism model - only for paid users",
          "📷 Dozens of bundles like you just submitted, with new coming every day",
          "🕹 Be your own photographer! Use simple options to setup your scene, lighting and action in just a few clicks. The only limit is your imagination",
          "⚙️ Custom Prompts, Advanced settings, and many more"
        ],
        es: [
          "⚡️ Procesamiento más rápido, máxima prioridad, ¡no más esperas!",
          "✨ Mayor similitud facial con nuestro nuevo modelo de realismo, solo para usuarios pagos",
          "📷 Docenas de paquetes como los que acabas de enviar, con nuevos todos los días",
          "🕹 ¡Sé tu propio fotógrafo! Usa opciones simples para configurar tu escena, iluminación y acción en solo unos clics. El único límite es tu imaginación",
          "⚙️ Solicitudes personalizadas, configuraciones avanzadas y muchos más"
        ],
        pt: [
          "⚡️ Processamento mais rápido, prioridade máxima, sem mais esperas!",
          "✨ Maior semelhança facial com nosso novo modelo de realismo - apenas para usuários pagos",
          "📷 Dezenas de pacotes como os que você acabou de enviar, com novos chegando todos os dias",
          "🕹 Seja seu próprio fotógrafo! Use opções simples para configurar sua cena, iluminação e ação em apenas alguns cliques. O único limite é sua imaginação",
          "⚙️ Solicitações personalizadas, configurações avançadas e muito mais"
        ],
        de: [
          "⚡️ Schnellste Verarbeitung, höchste Priorität, keine Wartezeit!",
          "✨ Höchste Gesichtsähnlichkeit mit unserem neuen Realismusmodell - nur für zahlende Nutzer",
          "📷 Dutzende Pakete wie das, das Sie gerade eingereicht haben, mit neuen, die täglich hinzukommen",
          "🕹 Seien Sie Ihr eigener Fotograf! Nutzen Sie einfache Optionen, um Ihre Szene, Beleuchtung und Aktion mit nur wenigen Klicks einzurichten. Die einzige Grenze ist Ihre Vorstellungskraft",
          "⚙️ Benutzerdefinierte Eingabeaufforderungen, erweiterte Einstellungen und vieles mehr"
        ],
        ja: [
          "⚡️ 最速の処理、最高の優先順位、もう待ちません！",
          "✨ 新しいリアリズムモデルで最高の顔の類似性 - 有料ユーザーのみ",
          "📷 あなたが提出したような何十ものバンドルがあり、新しいものが毎日追加されます",
          "🕹 自分の写真家になりましょう！ シーン、照明、アクションをわずか数回クリックするだけで設定できます。唯一の制限はあなたの想像力です",
          "⚙️ カスタムプロンプ्ट、高度な設定など"
        ],
        ko: [
          "⚡️ 가장 빠른 처리, 최고의 우선 순위, 더 이상 기다릴 필요 없음!",
          "✨ 새로운 사실주의 모델과 함께 최고의 얼굴 유사성 - 유료 사용자 전용",
          "📷 방금 제출한 것과 같은 수십 개의 번들, 매일 새로운 번들 추가",
          "🕹 자신만의 사진 작가가 되어보세요! 몇 번의 클릭만으로 장면, 조명 및 동작을 설정하는 간단한 옵션을 사용하십시오. 유일한 한계는 당신의 상상력입니다",
          "⚙️ 사용자 지정 프롬프트, 고급 설정 및 더 많은 것들"
        ],
        zh: [
          "⚡️ 最快的处理速度，最高优先级，不再等待！",
          "✨ 使用我们新的真实感模型实现最高的面部相似性 - 仅适用于付费用户",
          "📷 提交的几十个套餐，每天都会有新的",
          "🕹 成为自己的摄影师！ 使用简单的选项，只需点击几下即可设置场景、灯光和动作。唯一的限制是你的想象力",
          "⚙️ 自定义提示，高级设置以及更多"
        ],
        ru: [
          "⚡️ Самая быстрая обработка, высший приоритет, больше никакого ожидания!",
          "✨ Наивысшее сходство лица с нашей новой моделью реализма - только для платных пользователей",
          "📷 Десятки пакетов, подобных тому, который вы только что отправили, с новыми, появляющимися каждый день",
          "🕹 Будьте своим собственным фотографом! Используйте простые параметры для настройки сцены, освещения и действий всего за несколько кликов. Единственное ограничение - ваше воображение",
          "⚙️ Пользовательские подсказки, расширенные настройки и многое другое"
        ],
        hi: [
          "⚡️ सबसे तेज़ प्रसंस्करण, उच्चतम प्राथमिकता, अब और प्रतीक्षा नहीं!",
          "✨ हमारे नए यथार्थवाद मॉडल के साथ उच्चतम चेहरे की समानता - केवल भुगतान करने वाले उपयोगकर्ताओं के लिए",
          "📷 आपके द्वारा सबमिट किए गए जैसे दर्जनों बंडल, हर दिन नए आने वाले",
          "🕹 अपना फोटोग्राफर बनें! अपने दृश्य, प्रकाश व्यवस्था और क्रिया को केवल कुछ क्लिक में सेट करने के लिए सरल विकल्पों का उपयोग करें। एकमात्र सीमा आपकी कल्पना है",
          "⚙️ कस्टम प्रॉम्प्ट्स, उन्नत सेटिंग्स और बहुत कुछ"
        ]
      },
      subheading: {
        en: "Get access to",
        es: "Obtén acceso a",
        pt: "Tenha acesso a",
        de: "Zugriff auf",
        ja: "アクセスを取得",
        ko: "접근 권한 얻기",
        zh: "获取访问权限",
        ru: "Получить доступ к",
        hi: "पहुंच प्राप्त करें"
      }
    },
    seconds: {
      en: "seconds left",
      es: "segundos restantes",
      pt: "segundos restantes",
      de: "verbleibende Sekunden",
      ja: "残り秒数",
      ko: "남은 초",
      zh: "剩余秒数",
      ru: "секунд осталось",
      hi: "सेकंड शेष"
    },
    hours: {
      en: "hours to wait",
      es: "horas para esperar",
      pt: "horas para esperar",
      de: "Stunden zu warten",
      ja: "待つ時間",
      ko: "기다릴 시간",
      zh: "等待小时数",
      ru: "часы ожидания",
      hi: "प्रतीक्षा के घंटे"
    },
    minutes: {
      en: "minutes to wait",
      es: "minutos para esperar",
      pt: "minutos para esperar",
      de: "Minuten zu warten",
      ja: "待つ分数",
      ko: "기다릴 분",
      zh: "等待分钟数",
      ru: "минут ожидания",
      hi: "प्रतीक्षा के मिनट"
    },
    jobSubmitted: {
      title: {
        en: "Job submitted ✅",
        es: "Trabajo enviado ✅",
        pt: "Trabalho enviado ✅",
        de: "Auftrag eingereicht ✅",
        ja: "ジョブが送信されました ✅",
        ko: "작업이 제출되었습니다 ✅",
        zh: "任务已提交 ✅",
        ru: "Задача отправлена ✅",
        hi: "काम सबमिट हो गया ✅"
      },
      message: {
        en: "Now you can navigate to your media page",
        es: "Ahora puedes navegar a tu página de medios",
        pt: "Agora você pode navegar para sua página de mídia",
        de: "Jetzt können Sie zu Ihrer Medienseite navigieren",
        ja: "これでメディアページに移動できます",
        ko: "이제 미디어 페이지로 이동할 수 있습니다",
        zh: "现在您可以转到您的媒体页面",
        ru: "Теперь вы можете перейти на свою медиастраницу",
        hi: "अब आप अपनी मीडिया पेज पर जा सकते हैं"
      }
    },
    no_credits: {
      title: {
        en: "Not Enough Credits",
        es: "No hay suficientes créditos",
        pt: "Créditos insuficientes",
        de: "Nicht genügend Credits",
        ja: "クレジットが足りません",
        ko: "크레딧이 부족합니다",
        zh: "积分不足",
        ru: "Недостаточно кредитов",
        hi: "पर्याप्त क्रेडिट नहीं"
      },
      message: {
        en: "You have $CREDITS credits, but need $PRICE credits to submit the job. Please visit the shop to purchase more credits.",
        es: "Tienes $CREDITS créditos, pero necesitas $PRICE créditos para enviar el trabajo. Por favor visita la tienda para comprar más créditos.",
        pt: "Você tem $CREDITS créditos, mas precisa de $PRICE créditos para enviar o trabalho. Por favor, visite a loja para comprar mais créditos.",
        de: "Sie haben $CREDITS Credits, benötigen jedoch $PRICE Credits, um den Auftrag einzureichen. Bitte besuchen Sie den Shop, um weitere Credits zu kaufen.",
        ja: "$CREDITS クレジットがありますが、ジョブを送信するには $PRICE クレジットが必要です。ストアでクレジットを購入してください。",
        ko: "$CREDITS 크레딧이 있지만 작업을 제출하려면 $PRICE 크레딧이 필요합니다. 상점을 방문하여 추가 크레딧을 구매하세요.",
        zh: "您有 $CREDITS 积分，但需要 $PRICE 积分才能提交任务。请访问商店购买更多积分。",
        ru: "У вас есть $CREDITS кредитов, но для отправки задания нужно $PRICE кредитов. Пожалуйста, посетите магазин, чтобы купить больше кредитов.",
        hi: "आपके पास $CREDITS क्रेडिट हैं, लेकिन काम सबमिट करने के लिए $PRICE क्रेडिट की आवश्यकता है। कृपया अधिक क्रेडिट खरीदने के लिए दुकान पर जाएं।"
      },
      shop: {
        en: "Shop",
        es: "Tienda",
        pt: "Loja",
        de: "Shop",
        ja: "ショップ",
        ko: "상점",
        zh: "商店",
        ru: "Магазин",
        hi: "दुकान"
      }
    },
    pulid: {
      en: "📷 Realistic",
      ru: "📷 Реалистичный",
      hi: "📷 यथार्थवादी",
      zh: "📷 现实主义",
      de: "📷 Realistisch",
      es: "📷 Realista"
    },
    v3: {
      en: "👤 Ultrarealistic",
      ru: "👤 Ультрареалистичный",
      hi: "👤 अति यथार्थवादी",
      zh: "👤 超现实主义",
      de: "👤 Ultrarealistisch",
      es: "👤 Ultrarrealista"
    },
}

// ::::::::::::::::::::::::: MAIN COMPONENT :::::::::::::::::::::::::
export default function BundlePage() {
  const [mode, setMode] = useState('dark');
  const createPageTheme = createTheme(getSignUpTheme(mode));

  const [openSignUp, setOpenSignUp] = useState(false);

  const [currentPage, setCurrentPage] = useState(2);
  const [feedData, setFeedData] = useState(null);

  const [images, setImages] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  //const [openStartDialog, setOpenStartDialog] = useState(true);

  const [user, setUser] = useState(null);

  const [fromCampaign, setFromCampaign] = useState(false);
  const [isEligible, setIsEligible] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [jobId, setJobId] = useState(null);
  const [submitError, setSubmitError] = useState(null);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState({
    message: '',
    severity: 'info',
  });

  const [isTelegramWebApp, setIsTelegramWebApp] = useState(false);
  const [showAdultContent, setShowAdultContent] = useState(null);

  const [credits, setCredits] = useState(0);
  const [price, setPrice] = useState(0);

  const [loadedImages, setLoadedImages] = useState([]);
  const [loadingScreenVisible, setLoadingScreenVisible] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const [bundleId, setBundleId] = useState(null);
  const [bundleData, setBundleData] = useState(null);
  const [camp, setCamp] = useState(null);
  const [referrer, setReferrer] = useState(null);
  const [language, setLanguage] = useState('en');
  const languageCodes = ['en', 'ru', 'es', 'pt', 'de', 'ja', 'ko', 'zh', 'hi'];

  const [countdownValue, setCountdownValue] = useState(60); // 1 minute

  const [activeStage, setActiveStage] = useState(0);
  const [funnelVisible, setFunnelVisible] = useState(true);

  const [jobReady, setJobReady] = useState(false);
  const [showFinalButtons, setShowFinalButtons] = useState(false);
  const [showUpsell, setShowUpsell] = useState(false);
  const [estimatedWaitSeconds, setEstimatedWaitSeconds] = useState(60);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const [pulidOpen, setPulidOpen] = useState(false);
  const [ultraOpen, setUltraOpen] = useState(false);

  const funnelContainerStyle = {
    position: 'fixed',
    inset: 0,
    zIndex: 999999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: (theme) =>
      theme.palette.mode === 'dark'
        ? 'rgba(0, 0, 0, 0.7)'
        : 'rgba(255, 255, 255, 0.7)',
    textAlign: 'center',
    transition: 'opacity 0.3s ease-in-out'
  };

  function logStep(stepName, extraParams = {}) {
    // Only track if isEligible === true
    if (!isEligible) return;
  
    // 1) Save to localStorage
    const storageKey = `logstepper_${bundleData?.bundle_id || 'unknown'}`;
    // Attempt to read existing steps or make a fresh object
    let currentLog = {};
    try {
      const existing = localStorage.getItem(storageKey);
      if (existing) currentLog = JSON.parse(existing);
    } catch (err) {
      console.warn("Couldn't parse existing logstepper data");
    }
  
    // Record/Overwrite the step name
    currentLog[stepName] = {
      timestamp: Date.now(),
      ...extraParams
    };
  
    // Write it back
    localStorage.setItem(storageKey, JSON.stringify(currentLog));
  
    // 2) Log to analytics
    // (We’ll store stepName in 'step' to keep it consistent with earlier code)
    logEvent(analytics, 'bundle_path_step', {
      step: stepName,
      bundle_id: bundleData?.bundle_id || null,
      ...extraParams
    });
  }

  const handleSignUpSuccess = async (result) => {
    const newlyCreatedUser = result.user;
    const token = await newlyCreatedUser.getIdToken();
    localStorage.setItem('userToken', token);

    const db = getDatabase();
    const userRef = ref(db, `users/${newlyCreatedUser.uid}`);
    const snapshot = await get(userRef);

    logStep('signed_up');

    if (snapshot.exists()) {
      try {
        const userData = snapshot.val();
        setCredits(userData.credits || 0);

        const hasNoCredits = userData.credits === 0;
        const hasNoJobs = !userData.jobs || userData.jobs.length === 0;
        if (hasNoCredits && hasNoJobs) {
          setIsEligible(true);
        }
        else {
          setIsEligible(false);
        }

        setUser(newlyCreatedUser);
        setOpenSignUp(false);
      }
      catch(error) {
        navigate(`/signin?lang=${language}`)
      }
    }
    else {

      let twitterUsername = null;
      if ( newlyCreatedUser?.additionalUserInfo?.profile?.screen_name ) {
        twitterUsername = result.additionalUserInfo.profile.screen_name
      }
      else if (newlyCreatedUser?.additionalUserInfo?.profile?.screenName) {
        twitterUsername = result.additionalUserInfo.profile.screenName
      }
      else if (newlyCreatedUser?.reloadUserInfo?.screenName) {
        twitterUsername = result.reloadUserInfo.screenName
      }

      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/user-signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          uid: newlyCreatedUser.uid,
          camp: camp,
          ref: referrer,
          email: newlyCreatedUser.email,
          twitter_username: twitterUsername
        }),
      });
      const result = await response.json();

      if (!result.success) {
        navigate(`/signup?lang=${language}`)
      }

      setUser(newlyCreatedUser);
      setOpenSignUp(false);
    }
  };

  useEffect(() => {
    // 1) Extract new bundle_id from URL
    const searchParams = new URLSearchParams(location.search);
    const newBundleId = searchParams.get('bundle_id') || null;
  
    // 2) Only re-run load if the ID changed
    if (newBundleId !== bundleId && bundleId) {
      setBundleId(newBundleId); // triggers your data-loading effect
      window.location.reload();
    }
  }, [location.search, bundleId]);
  

  useEffect(() => {
    if (currentPage === 4) {
      let step = 0;
      const stepInterval = setInterval(() => {
        // fade out the old stage
        setFunnelVisible(false);

        if (step === 5) {
          logStep('waited_minute');
        }

        setTimeout(() => {
          // increment stage
          step++;
          if (step <= 2) {
            setActiveStage(step);
            // fade in new stage
            setFunnelVisible(true);
          } else {
            clearInterval(stepInterval);
            // check queue time
            // fade out old stage
            setFunnelVisible(false);

            setTimeout(() => {
              setActiveStage(3);
              setCountdownValue(60);
              setFunnelVisible(true);
            }, 300); // fade-out time
          }
        }, 300); // wait for fade-out
      }, 10_000);

      return () => clearInterval(stepInterval);
    }
  }, [currentPage]);

  useEffect(() => {
    if (activeStage === 3) {
      let val = 60;
      const intv = setInterval(async () => {
        val--;
  
        // At 5s left => check if job is ready
        if (val === 5) {
          const isReady = await checkJobIsReady();
          if (isReady) {
            // job is ready => still let them finish countdown or 
            // optionally let them view early
            setJobReady(true);
          }
        }
  
        if (val <= 0) {
          clearInterval(intv);
          setFunnelVisible(false);
          // after fade out, show stage=5 always
          setTimeout(() => {
            setActiveStage(5);
            setFunnelVisible(true);
          }, 300);
        } else {
          setCountdownValue(val);
        }
      }, 1000);
  
      setCountdownValue(val);
      return () => clearInterval(intv);
    }
  }, [activeStage]);

  // Your function to check if job output is ready:
  const checkJobIsReady = async () => {
    const db = getDatabase();
    try {
      const jobRef = ref(db, `jobs/active/${jobId}/output`);
      const snap = await get(jobRef);
      if (snap.exists()) {
        const output = snap.val();
        if (Array.isArray(output) && output.length > 0) {
          return true;
        }
        else {
          return false;
        }
      }
      return true;
    } catch(e) {
      return false;
    }
  };

  // final "Go to gallery" button => user can see their results
  const handleGoToGallery = () => {
    cleanupTelegramButtons();
    // assume you have jobId
    navigate('/gallery');
  };

  function formatWaitTime(seconds) {
    // Up to you how to handle hours vs minutes vs seconds
    // For example:
    if (seconds > 3600) {
      const hours = Math.floor(seconds / 3600);
      const mins = Math.floor((seconds % 3600) / 60);
      return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')} ${t.hours?.[language] || t.hours.en}`;
    } else if (seconds > 60) {
      const mins = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')} ${t.minutes?.[language] || t.minutes.en}`;
    } else {
      // show seconds + optionally ms. For simplicity, just SS
      return `${String(seconds).padStart(2,'0')} ${t.seconds?.[language] || t.seconds.en}`;
    }
  }

  // :::::::::::::::: On mount => parse URL for bundle_id
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const bundleIdParam = searchParams.get('bundle_id');
    const campParam = searchParams.get('camp');
    const refParam = searchParams.get('ref');
    const langParam = searchParams.get('lang');

    if (langParam && languageCodes.includes(langParam)) {
      setLanguage(langParam)
    }

    if (campParam) {
      setFromCampaign(true);
      setCamp(campParam);
    }
    if (refParam) {
      setReferrer(refParam);
    }
    if (bundleIdParam) {
      setBundleId(bundleIdParam);
    }
  }, [location.search]);

  // Telegram cleanup
  const cleanupTelegramButtons = useCallback(() => {
    if (isTelegramWebApp && window.Telegram && window.Telegram.WebApp) {
      const MainButton = window.Telegram.WebApp.MainButton;
      const SecondButton = window.Telegram.WebApp.SecondaryButton;
      MainButton.offClick();
      MainButton.hide();

      SecondButton.setParams({
        has_shine_effect: false,
        color: window.Telegram.WebApp.themeParams.bottom_bar_bg_color,
        textColor: window.Telegram.WebApp.themeParams.button_color,
      });
      SecondButton.offClick();
      SecondButton.hide();

      const tgColor = mode === 'dark' ? "#0C1017" : "#F4F6FB";
      
      window.Telegram.WebApp.setHeaderColor(tgColor);
      window.Telegram.WebApp.setBackgroundColor(tgColor);
      window.Telegram.WebApp.setBottomBarColor(tgColor);
    }
  }, [isTelegramWebApp]);

  // Pre-init: load theme, user, etc.
  useEffect(() => {
    // If Telegram
    if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initData) {
      setIsTelegramWebApp(true);
      //console.log(window.Telegram.WebApp.platform, window.Telegram.WebApp.version)
      const telegramColorScheme = window.Telegram.WebApp.colorScheme;
      setMode(telegramColorScheme === 'dark' ? 'dark' : 'light');
      if (!window.Telegram.WebApp.isExpanded) window.Telegram.WebApp.expand();
      window.Telegram.WebApp.disableVerticalSwipes();
      window.Telegram.WebApp.enableClosingConfirmation();

      /* const tgColor = telegramColorScheme === 'dark' ? "#05070B" : "#FCFCFC";

      window.Telegram.WebApp.setHeaderColor(tgColor);
      window.Telegram.WebApp.setBackgroundColor(tgColor);
      window.Telegram.WebApp.setBottomBarColor(tgColor); */

    } else {
      // Normal
      const savedMode = localStorage.getItem('themeMode');
      if (savedMode) {
        setMode(savedMode);
      } else {
        const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
        setMode(systemPrefersDark ? 'dark' : 'light');
      }
    }

    // Language
    if (localStorage.getItem('language') && (!language || language === 'en')) {
      setLanguage(localStorage.getItem('language'));
    }

    // Auth
    const token = localStorage.getItem('userToken');
    if (token) {
      auth.onAuthStateChanged(async (currentUser) => {
        if (currentUser) {
          setUser(currentUser);
          const db = getDatabase();
          const userRef = ref(db, `users/${currentUser.uid}`);
          const snapshot = await get(userRef);
          if (snapshot.exists()) {
            const userData = snapshot.val();
            setShowAdultContent(!!userData.adult);
            setCredits(userData.credits || 0);

            // Language
            if (localStorage.getItem('language') && (!language || language === 'en')) {
              setLanguage(localStorage.getItem('language'));
            } else if (
              userData?.analytics?.lang &&
              languageCodes.includes(userData?.analytics?.lang)
              && (!language || language === 'en')
            ) {
              setLanguage(userData?.analytics?.lang);
            }

            const hasNoCredits = userData.credits === 0;
            const hasNoJobs = !userData.jobs || userData.jobs.length === 0;
            if (hasNoCredits && hasNoJobs) {
              setIsEligible(true);
            }
          }
        }
        else {
          setIsEligible(true);
        }
      });
    }
    else {
      setIsEligible(true);
    }
  }, [languageCodes]);

  useEffect(() => {
    if (bundleData) {
      logStep('arrived');
    }
  }, [bundleData]);

  // If Telegram and not authenticated
  useEffect(() => {
    const authenticateTelegramUser = async () => {
      if (isTelegramWebApp && !user) {
        const initData = window.Telegram.WebApp.initData;
        if (initData) {
          const formBody = `initData=${encodeURIComponent(initData)}`;
          try {
            const response = await fetch(
              `${process.env.REACT_APP_SERVER_URL}/verify-telegram-webapp`,
              {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: formBody,
              }
            );
            const result = await response.json();
            if (result.success && result.customToken) {

              if (!localStorage.getItem('language') && (!language || language === 'en')) {
                if (result.language_code && languageCodes.includes(result.language_code)) {
                  setLanguage(result.language_code);
                  localStorage.setItem("language", result.language_code)
                }
              }

              await signInWithCustomToken(auth, result.customToken);
              const idToken = await auth.currentUser.getIdToken();
              localStorage.setItem('userToken', idToken);
              setUser(auth.currentUser);

              const db = getDatabase();
              const userRef = ref(db, `users/${auth.currentUser.uid}`);
              const snapshot = await get(userRef);
              if (snapshot.exists()) {
                const userData = snapshot.val();
                setShowAdultContent(!!userData.adult);
                setCredits(userData.credits || 0);

                if (localStorage.getItem('language') && (!language || language === 'en')) {
                  setLanguage(localStorage.getItem('language'));
                } else if (
                  userData?.analytics?.lang &&
                  languageCodes.includes(userData?.analytics?.lang)
                  && (!language || language === 'en')
                ) {
                  setLanguage(userData?.analytics?.lang);
                  localStorage.setItem('language', userData?.analytics?.lang)
                }

                const hasNoCredits = userData.credits === 0;
                const hasNoJobs = !userData.jobs || userData.jobs.length === 0;
                if (hasNoCredits && hasNoJobs) setIsEligible(true);
              }
            } else {
              console.error('Telegram authentication failed:', result.error);
              setSubmitError(result.error || 'Authentication failed.');
            }
          } catch (error) {
            console.error('Error during Telegram authentication:', error);
            setSubmitError('An error occurred during authentication.');
          }
        }
      }
    };
    authenticateTelegramUser();
  }, [isTelegramWebApp, user, languageCodes]);

  // Once we know bundleId => load feed
  useEffect(() => {
    if (bundleId && !bundleData) {
      loadFeedData();
    }
  }, [bundleId, bundleData]);

  const loadFeedData = async () => {
    try {
      const db = getDatabase();
      const bundleRef = ref(db, `bundles`);
      const snapshot = await get(bundleRef);
      if (snapshot.exists()) {
        const bundles = snapshot.val();
        const bundle = Object.values(bundles).find(item => item.bundle_id === bundleId);
        if (bundle) {
          setShowAdultContent(bundle?.adult ? true : false);
          const filteredBundles = Object.values(bundles).filter(item => item?.adult ? bundle.adult ? true : false : !bundle.adult ? true : false)
          console.log(filteredBundles);
          setFeedData({ bundles: filteredBundles})
          setBundleData(bundle);

          if (bundle?.isEligable === false) setIsEligible(false);
          if (bundle?.bundle?.length > 5) setIsEligible(false);

          let orientation = localStorage.getItem('orientation')
          if (!orientation && bundle.orientation) {
            localStorage.setItem('orientation', bundle.orientation)
          }
        }
      }
    } catch (error) {
      console.error('Error loading feed data:', error);
    }
  };

  // For the Masonry top images (case 1 page)
  /* useEffect(() => {
    const fetchMasonryImages = async () => {
      const db = getDatabase();
      const feedPath = showAdultContent ? '/feed/adult' : '/feed/default';
      const feedRef = ref(db, feedPath);
      try {
        const snap = await get(feedRef);
        if (snap.exists()) {
          const imageIdsObj = snap.val();
          let mediaIdsArray = [];
          if (Array.isArray(imageIdsObj)) {
            mediaIdsArray = imageIdsObj;
          } else if (imageIdsObj && typeof imageIdsObj === 'object') {
            mediaIdsArray = Object.keys(imageIdsObj);
          }

          const imagePromises = mediaIdsArray.map(async (mediaId) => {
            const linkRef = ref(db, `/media/${mediaId}/link`);
            const linkSnap = await get(linkRef);
            if (linkSnap.exists()) {
              return { id: mediaId, link: linkSnap.val() };
            }
            return null;
          });
          const fetched = await Promise.all(imagePromises);
          const valids = fetched.filter((it) => it !== null);
          setMasonryImages(valids);
          // Make sure loadedImages can hold them
          setLoadedMasonryImages(Array(valids.length).fill(false));
        }
      } catch (err) {
        console.error('Error fetching masonry images:', err);
      }
    };
    fetchMasonryImages();
  }, [showAdultContent]); */

  // Preload the main bundle images => hide loader once done
  useEffect(() => {
    if (!bundleData) return;
    const allUrls = [
      bundleData.source_photo,
      ...(bundleData.bundle?.map((item) => item.example) || []),
    ].filter(Boolean);

    if (!allUrls.length) {
      setLoadingScreenVisible(false);
      return;
    }

    let loadedCount = 0;
    allUrls.forEach((url) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        loadedCount++;
        if (loadedCount === allUrls.length) {
          setLoadingScreenVisible(false);
        }
      };
      img.onerror = () => {
        loadedCount++;
        if (loadedCount === allUrls.length) {
          setLoadingScreenVisible(false);
        }
      };
    });
  }, [bundleData]);

  // Recalc price whenever bundleData or isEligible changes
  useEffect(() => {
    if (bundleData) {
      const calcPrice = bundleData.price_full ?? bundleData.price ?? 0;
      setPrice(isEligible ? 0 : calcPrice);
    }
  }, [bundleData, isEligible]);

  // ----- Page transitions
  const handleContinue = useCallback(() => {
    try {
      if (bundleData && bundleData.bundle_id) {
        logEvent(analytics, 'bundle', {
          step: currentPage + 1,
          id: bundleData.bundle_id || null,
        });
      }
    } catch (error) {
      console.error(error);
    }

    if (currentPage === 1) setCurrentPage(2);
    else if (currentPage === 2) setCurrentPage(3);
  }, [analytics, bundleData, currentPage]);

  // Skip logic => dialog
  const handleDialogClose = useCallback(
    (proceed) => {
      setDialogOpen(false);
      if (proceed) {
        cleanupTelegramButtons();
        navigate('/create');
      }
    },
    [cleanupTelegramButtons, navigate]
  );

  const handleSkip = useCallback(() => {
    if (isTelegramWebApp) {
      if (!window.popupIsOpen) {
        window.popupIsOpen = true;
        window.Telegram.WebApp.showPopup(
          {
            title: isEligible ? (t.skipDialog.title?.[language] || t.skipDialog.title.en) : (t.skipPaidUser.title?.[language] || t.skipPaidUser.title.en),
            message: isEligible ? (t.skipDialog.description?.[language] || t.skipDialog.description.en) : (t.skipPaidUser.description?.[language] || t.skipPaidUser.description.en),
            buttons: [
              { id: 'skip', type: 'destructive', text: t.buttons.skip?.[language] || t.buttons.skip.en },
              { id: 'cancel', type: 'cancel', text: t.buttons.cancel?.[language] || t.buttons.cancel.en },
            ],
          },
          (buttonId) => {
            window.popupIsOpen = false;
            if (buttonId === 'skip') {
              cleanupTelegramButtons();
              navigate('/create');
            }
          }
        );
      }
    } else {
      setDialogOpen(true);
    }
  }, [isTelegramWebApp, currentPage, cleanupTelegramButtons, navigate]);

  // Upload progress
  const getUploadProgress = useCallback(() => {
    if (!images.length) return '';
    else {
      logStep('uploaded_file');
    }

    if (images.length === 1) return (t.results.poor?.[language] || t.results.poor.en);
    if (images.length === 2) return (t.results.good?.[language] || t.results.good.en);
    if (images.length >= 3) return (t.results.great?.[language] || t.results.great.en);
    return '';
  }, [images]);

  // Submit => create job
  const handleSubmit = useCallback(async () => {
    if (!user) {
      setOpenSignUp(true);
      return;
    }
    logStep('submitted');

    if (credits < price && !isEligible) {
      if (isTelegramWebApp) {
        if (!window.popupIsOpen) {
          window.popupIsOpen = true;
          window.Telegram.WebApp.showPopup(
            {
              title: t.no_credits.title?.[language] || t.no_credits.title.en,
              message: (t.no_credits.message?.[language] || t.no_credits.message.en).replace("$CREDITS", credits).replace("$PRICE", price),
              buttons: [
                { id: 'shop', type: 'default', text: t.no_credits.shop?.[language] || t.no_credits.shop.en },
                { id: 'dismiss', type: 'close', text:  t.buttons.cancel?.[language] || t.buttons.cancel.en },
              ],
            },
            (buttonId) => {
              window.popupIsOpen = false;
              if (buttonId === 'shop') {
                cleanupTelegramButtons();
                navigate('/shop');
              }
            }
          );
        }
      } else {
        setSubmitError((t.no_credits.message?.[language] || t.no_credits.message.en).replace("$CREDITS", credits).replace("$PRICE", price));
      }
      return;
    }

    setIsSubmitting(true);
    if (isTelegramWebApp) window.Telegram.WebApp.MainButton.showProgress();

    try {
      let finalPrice = bundleData.price_full ?? bundleData.price ?? 0;
      if (isEligible) finalPrice = 0;

      const newJob = {
        userId: user.uid,
        price: finalPrice,
        timestamp: Math.floor(Date.now() / 1000),
        mediaAmount: images.length,
        isAdult: showAdultContent || null,
        bundle: bundleData.bundle || null,
        bundle_id: bundleData.bundle_id,
        blur_after: bundleData.blur_after || null,
      };

      let sessionKey = sessionStorage.getItem("sessionKey");

      const idToken = await user.getIdToken();
      const formData = new FormData();
      formData.append('jobData', JSON.stringify(newJob));
      if (camp) formData.append('camp', camp);

      if (sessionKey) {
        formData.append('sessionKey', sessionKey);
        sessionStorage.removeItem('sessionKey');
      }

      formData.append('estimateWaitTime', "true");
      images.forEach((imgObj) => {
        formData.append('images', imgObj.file);
      });

      /* setCurrentPage(4);
      setJobId("wepogjowpjgiwjgioew")
      setEstimatedWaitSeconds(21560)
      return; */

      const resp = await fetch(`${process.env.REACT_APP_SERVER_URL}/create`, {
        method: 'POST',
        headers: { Authorization: idToken },
        body: formData,
      });

      if (resp.ok) {
        const result = await resp.json();
        if (isEligible) {
          setCurrentPage(4);
          if (result.estimateWaitTime) {
            setEstimatedWaitSeconds(result.estimateWaitTime)
          }
          else {
            setEstimatedWaitSeconds(5783)
          }
          if (result.jobId) {
            setJobId(result.jobId)
          }
        } else {
          if (isTelegramWebApp) {
            if (!window.popupIsOpen) {
              window.popupIsOpen = true;
              window.Telegram.WebApp.showPopup(
                {
                  title: t.jobSubmitted.title?.[language] || t.jobSubmitted.title.en,
                  message: t.jobSubmitted.message?.[language] || t.jobSubmitted.message.en,
                  buttons: [{ id: 'job', type: 'ok' }],
                },
                (buttonId) => {
                  window.popupIsOpen = false;
                  cleanupTelegramButtons();
                  if (buttonId === 'job') {
                    navigate('/gallery/' + result.jobId);
                  }
                }
              );
            }
          } else {
            setShowSnackbar(true);
            setSnackbarContent({
              message: t.jobSubmitted.title?.[language] || t.jobSubmitted.title.en,
              severity: 'success',
            });
            cleanupTelegramButtons();
            navigate('/gallery/' + result.jobId);
          }
        }
        // log event
        try {
          if (bundleData?.bundle_id) {
            logEvent(analytics, 'bundle', { step: 4, id: bundleData.bundle_id });
          }
        } catch (logErr) {
          console.error(logErr);
        }
      } else {
        const errRes = await resp.json();
        console.log('issue', errRes);
        setSubmitError(errRes.error || 'Unknown error occurred.');
      }
    } catch (err) {
      setSubmitError('An error occurred while submitting your job.');
    } finally {
      setIsSubmitting(false);
      if (isTelegramWebApp) {
        window.Telegram.WebApp.MainButton.hideProgress();
      }
    }
  }, [
    user,
    credits,
    price,
    isEligible,
    bundleData,
    images,
    showAdultContent,
    isTelegramWebApp,
    analytics,
    cleanupTelegramButtons,
    navigate
  ]);

  // TELEGRAM MAIN BUTTONS
  const handleMainButtonClick = useCallback(() => {
    if (!isTelegramWebApp) return;
    if (currentPage === 1) handleContinue();
    else if (currentPage === 2) handleContinue();
    else if (currentPage === 3) handleSubmit();
  }, [isTelegramWebApp, currentPage, handleContinue, handleSubmit]);

  // Setup TG Buttons
  useEffect(() => {
    if (!isTelegramWebApp || !window.Telegram?.WebApp) return;
    const MainButton = window.Telegram.WebApp.MainButton;
    const SecondButton = window.Telegram.WebApp.SecondaryButton;

    // Reset
    MainButton.offClick();
    MainButton.hide();
    MainButton.setParams({
      is_visible: false,
      is_active: false,
      text_color: '#FFFFFF',
      has_shine_effect: false,
      color: window.Telegram.WebApp.themeParams.button_color,
      textColor: window.Telegram.WebApp.themeParams.button_text_color,
    });
    SecondButton.offClick();
    SecondButton.hide();
    SecondButton.setParams({
      is_visible: false,
      is_active: false,
      position: 'left',
      has_shine_effect: false,
      color: mode === 'dark' ? "#05070B" : "#FCFCFC",
      textColor: window.Telegram.WebApp.themeParams.button_color,
    });

    // Cases
    if (currentPage === 2) {
      MainButton.onClick(handleMainButtonClick);
      MainButton.setText(t.buttons.upload?.[language] || t.buttons.upload.en);
      MainButton.setParams({
        is_visible: true,
        is_active: true,
        text_color: '#FFFFFF',
        has_shine_effect: true,
        color: '#5288c1',
      });
      MainButton.show();

      SecondButton.onClick(handleSkip);
      SecondButton.setText(t.buttons.skip?.[language] || t.buttons.skip.en);
      SecondButton.setParams({
        is_visible: true,
        is_active: true,
        has_shine_effect: false,
        color: window.Telegram.WebApp.themeParams.bottom_bar_bg_color,
        textColor: window.Telegram.WebApp.themeParams.button_color,
      });
      SecondButton.show();
    } else if (currentPage === 3) {
      MainButton.onClick(handleMainButtonClick);
      MainButton.setText(isSubmitting ? (t.buttons.submitting?.[language] || t.buttons.submitting.en) : `${(t.buttons.create?.[language] || t.buttons.create.en)} ✨`);
      if (isSubmitting) MainButton.showProgress();
      else MainButton.hideProgress();

      if (!images.length || isSubmitting) MainButton.disable();
      else MainButton.enable();

      MainButton.setParams({ is_visible: true, is_active: true, text_color: '#FFFFFF' });
      MainButton.show();

      SecondButton.onClick(handleSkip);
      SecondButton.setText((t.buttons.skip?.[language] || t.buttons.skip.en));
      SecondButton.setParams({
        is_visible: true,
        is_active: true,
        has_shine_effect: false,
        color: mode === 'dark' ? "#05070B" : "#FCFCFC",
        textColor: window.Telegram.WebApp.themeParams.button_color,
      });
      SecondButton.show();
    } else if (currentPage === 4) {
      // Final step
      if (activeStage < 5) {
        MainButton.offClick();
        MainButton.hide();
        SecondButton.offClick();
        SecondButton.hide();
      }
      else {
        MainButton.offClick();
        SecondButton.offClick();
  
        MainButton.onClick(() => {
          cleanupTelegramButtons();
          navigate('/shop');
        });
        MainButton.setText((t.buttons.explore?.[language] || t.buttons.explore.en) + "✨");
        MainButton.setParams({
          is_visible: true,
          is_active: true,
          text_color: '#FFFFFF',
          has_shine_effect: true,
          color: '#5288c1',
        });
        MainButton.show();

        SecondButton.onClick(() => {
          cleanupTelegramButtons();
          navigate(`/gallery/${jobId ? `/${jobId}` : ""}`);
        });
        SecondButton.setText((t.buttons.iwait?.[language] || t.buttons.iwait.en));
        SecondButton.setParams({
          is_visible: true,
          is_active: true,
          has_shine_effect: false,
          color: mode === 'dark' ? "#05070B" : "#FCFCFC",
          textColor: window.Telegram.WebApp.themeParams.button_color,
        });
        SecondButton.show();
      }
      
    }

    // Cleanup
    return () => {
      MainButton.offClick(handleMainButtonClick);
      MainButton.hide();
      SecondButton.offClick(handleSkip);
      SecondButton.hide();
    };
  }, [
    isTelegramWebApp,
    currentPage,
    activeStage,
    images.length,
    isSubmitting,
    handleSkip,
    handleMainButtonClick,
    cleanupTelegramButtons,
  ]);

  useEffect(() => {
    if (isTelegramWebApp && currentPage === 3) {
      const MainButton = window.Telegram.WebApp.MainButton;
      MainButton.setText(isSubmitting ? (t.buttons.submitting?.[language] || t.buttons.submitting.en) : `${t.buttons.create?.[language] || t.buttons.create.en} ✨`);
      if (isSubmitting) MainButton.showProgress();
      else MainButton.hideProgress();
      if (!images.length || isSubmitting) MainButton.disable();
      else MainButton.enable();
    }
  }, [isTelegramWebApp, images.length, isSubmitting, currentPage]);

  function renderStage5WaitScreen() {
    const waitString = formatWaitTime(estimatedWaitSeconds);
  
    // "I'll Wait" => open confirm dialog
    const handleILlWait = () => {
      logStep('pressed_ill_wait');
      if (isTelegramWebApp) {
        // telegram => show popup
        if (!window.popupIsOpen) {
          window.popupIsOpen = true;
          window.Telegram.WebApp.showPopup({
            title: t.dialogSure.confirmTitle?.[language] || t.dialogSure.confirmTitle.en,
            message: t.dialogSure.confirmDescription?.[language] || t.dialogSure.confirmDescription.en,
            buttons: [
              { id: 'explore', type: 'destructive', text: t.buttons.explore?.[language] || t.buttons.explore.en },
              { id: 'wait', type: 'ok', text: t.buttons.iwait?.[language] || t.buttons.iwait.en },
            ],
          }, (buttonId) => {
            window.popupIsOpen = false;
            if (buttonId === 'explore') {
              navigate('/shop');
            } else {
              // actually wait
              navigate('/gallery');
            }
          });
        }
      } else {
        // normal web => open MUI confirm
        setConfirmDialogOpen(true);
      }
    };
  
    return (
      <>
        <ContentContainer sx={{ textAlign: 'left' }}>
          {/* The updated time left */}
          <img
              src={`${process.env.PUBLIC_URL}/assets/icons/webp/expires.webp`}
              alt="busy servers"
              style={{ width: '50%', maxWidth: "300px", marginBottom: 8, marginTop: 12 }}
          />
          <Typography variant="h1" gutterBottom>
            {waitString}
          </Typography>

          {fromCampaign && (
            <Box sx={{ marginY: 4 }}>
              {/* import CouponBanner from ../components/CouponBanner */}
              <CouponBanner timeRemaining={60 * 60 * 1000} language={language} />
            </Box>
          )}

          <Container maxWidth="md">
            <Typography variant="body1" sx={{ mb: 4 }}>
              {t.funnel.description?.[language] || t.funnel.description.en}
            </Typography>
      
            {/* Big left aligned heading */}
            <Typography variant="h3" sx={{ mb: 2 }}>
              {t.funnel.header?.[language] || t.funnel.header.en}
            </Typography>
            
            {/* Bullets */}
            {(t.funnel.bullet?.[language] || t.funnel.bullet.en).map((line, i) => (
              <Typography key={i} variant="h6" sx={{ mb: 1 }}>
              {line}
              </Typography>
            ))}
          </Container>
    
          {/* "Get access to:" */}
          <Typography variant="h3" sx={{ mt: 2, mb: 2 }}>
            {t.funnel.subheading?.[language] || t.funnel.subheading.en}
          </Typography>
    
          {/* Buttons => I'll Wait / Explore */}
          {!isTelegramWebApp && (
            <FixedBottomButton sx={{ gap: 2 }}>
              <Button
                variant="outlined"
                color="inherit"
                onClick={handleILlWait}
                fullWidth
                sx={{ minHeight: 60, maxWidth: { xs: '40%', sm: 400 } }}
              >
                {t.buttons.iwait?.[language] || t.buttons.iwait.en}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  cleanupTelegramButtons();
                  navigate('/shop');
                }}
                fullWidth
                sx={{ minHeight: 60, maxWidth: { xs: '100%', sm: 400 } }}
              >
                {t.buttons.explore?.[language] || t.buttons.explore.en}
              </Button>
            </FixedBottomButton>
          )}
    
          {/* Confirm Dialog if normal web */}
          <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
            <DialogTitle>
              {t.dialogSure.confirmTitle?.[language] || t.dialogSure.confirmTitle.en}
            </DialogTitle>
            <DialogContent>
            {t.dialogSure.confirmDescription?.[language] || t.dialogSure.confirmDescription.en}
            </DialogContent>
            <DialogActions>
              <Button 
                onClick={() => {
                  setConfirmDialogOpen(false);
                  // user picks I'll wait => gallery
                  cleanupTelegramButtons();
                  navigate('/gallery');
                }}
                color="secondary"
              >
                {t.buttons.iwait?.[language] || t.buttons.iwait.en}
              </Button>
              <Button 
                onClick={() => {
                  setConfirmDialogOpen(false);
                  // user picks Explore => go shop
                  navigate('/shop');
                }}
                color="primary"
              >
                {t.buttons.explore?.[language] || t.buttons.explore.en}
              </Button>
            </DialogActions>
          </Dialog>
        </ContentContainer>
        
        <Container maxWidth="lg" sx={{marginBottom: 20}}>
          <BundlesList
            bundles={feedData?.bundles?.filter((b) => b.bundle_id !== bundleData?.bundle_id) || []}
            language={language}
            isPaid={false}
            isShowAdultContent={showAdultContent}
            showData={false}
            showOrientation={false}
            isEligible={isEligible}
            camp={camp || null}
            mode={mode}
          />
        </Container>
      </>
    );
  }

  const renderFunnelOverlay = () => {
    // If stage=4 => we rely on your "upsell" below
    if (activeStage === 4 && showUpsell) return null;
    if (activeStage === 5) return null;  // We'll show it in main body

    let funnelContent;
    if (activeStage === 0) {
      funnelContent = (
        <>
          <Typography variant="h5" gutterBottom>
            {t.process.upload[0]?.[language] || t.process.upload[0].en}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {t.process.upload[1]?.[language] || t.process.upload[1].en}
          </Typography>
          <LinearProgress sx={{ width: '80%', maxWidth: 300 }} />
        </>
      );
    } else if (activeStage === 1) {
      funnelContent = (
        <>
          <img
            src={`${process.env.PUBLIC_URL}/assets/icons/webp/swap.webp`}
            alt="face copy..."
            style={{ width: '40%', maxWidth: "300px", marginBottom: 16 }}
          />
            <Typography variant="h5" gutterBottom>
          {t.process.digitalCopy[0]?.[language] || t.process.digitalCopy[0].en}
          </Typography>
            <Typography variant="body1">
          {t.process.digitalCopy[1]?.[language] || t.process.digitalCopy[1].en}
          </Typography>
        </>
      );
    } else if (activeStage === 2) {
      funnelContent = (
        <>
          <img
            src={`${process.env.PUBLIC_URL}/assets/icons/webp/compass.webp`}
            alt="queueing..."
            style={{ width: '40%', maxWidth: "300px", marginBottom: 16 }}
          />
          <Typography variant="h5" gutterBottom>
            {t.process.queue[0]?.[language] || t.process.queue[0].en}
          </Typography>
          <Typography variant="body1">
            {t.process.queue[1]?.[language] || t.process.queue[1].en}
          </Typography>
        </>
      );
    } else if (activeStage === 3) {
      // The countdown stage
      funnelContent = (
        <>
          <Typography 
            variant="h2" 
            sx={{ 
              width: '40vw', // 40% screen width
              marginBottom: 2
            }}
          >
            {countdownValue} 
            <Typography variant="h6" component="span" sx={{ ml: 1 }}>
              {t.seconds?.[language] || t.seconds.en}
            </Typography>
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t.processed?.[language] || t.processed.en}
          </Typography>
          <Box sx={{ width: '80%', maxWidth: 400, margin: '0 auto', mb: 2 }}>
            <LinearProgress
              variant="determinate"
              value={(60 - countdownValue) / 60 * 100}
            />
          </Box>
          {jobReady && showFinalButtons && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleGoToGallery}
            >
              {t.ready?.[language] || t.ready.en}
            </Button>
          )}
        </>
      );
    } else {
      // in case anything else 
      return null;
    }

    return (
      <Fade in={funnelVisible} timeout={300} mountOnEnter unmountOnExit>
        <Box sx={funnelContainerStyle}>
          {funnelContent}
        </Box>
      </Fade>
    );
  };

  // :::::::::::::: RENDER PAGES ::::::::::::::
  const renderPageContent = () => {
    if (!bundleData) {
      return (
        <ContentContainer>
          <CircularProgress />
          <Typography variant="h6" marginTop={2}>
            Loading...
          </Typography>
        </ContentContainer>
      );
    }

    switch (currentPage) {
      case 1:
        return null
      case 2:
        return (
          <ContentContainer>
            <Fade in={!loadingScreenVisible} timeout={600}>
              <Box>
                <ImageContainer>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                      flexDirection: { xs: 'column', sm: 'row' },
                      gap: 2,
                      width: '100%',
                      marginLeft: 3,
                      marginTop: 3,
                    }}
                  >
                    {/* LEFT => SELFIE */}
                    <Box
                      sx={{
                        flex: '0 0 auto',
                        maxWidth: '40%',
                        position: 'relative',
                        alignSelf: 'center',
                      }}
                    >
                      {bundleData?.source_photo ? (
                        <>
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height="auto"
                            sx={{
                              display: loadedImages[0] ? 'none' : 'block',
                              borderRadius: '8px',
                            }}
                          />
                          <img
                            src={bundleData.source_photo}
                            alt="Before"
                            style={{
                              width: '100%',
                              height: 'auto',
                              borderRadius: '8px',
                              display: loadedImages[0] ? 'block' : 'none',
                            }}
                            onLoad={() => {
                                console.log('source_photo loaded');
                                setLoadedImages((prev) => {
                                    const updated = [...prev];
                                    updated[0] = true;
                                    return updated;
                                })
                             }
                            }
                          />
                          <Chip
                            label="SELFIE"
                            color="default"
                            size="medium"
                            sx={{
                              position: 'absolute',
                              top: 16,
                              left: 16,
                              fontSize: '1rem',
                            }}
                          />
                        </>
                      ) : (
                        <Skeleton
                          variant="rounded"
                          width="100%"
                          height="auto"
                          sx={{ borderRadius: '8px' }}
                        />
                      )}
                    </Box>

                    {/* RIGHT => OutputCycle child component */}
                    <Box
                      sx={{
                        flexBasis: { xs: '80%', sm: '45%' },
                        position: 'relative',
                        alignSelf: 'center',
                        maxWidth: { xs: '70%', sm: '70%' },
                      }}
                    >
                      {bundleData?.bundle?.length > 0 ? (
                        <OutputCycle
                          afterImages={
                            bundleData?.bundle?.map((item) => item.example) || []
                          }
                        />
                      ) : (
                        <Skeleton
                          variant="rounded"
                          width="100%"
                          height="auto"
                          sx={{ borderRadius: '8px' }}
                        />
                      )}
                    </Box>
                  </Box>
                </ImageContainer>

                <Container maxWidth="md">
                    <Title variant="h4">{bundleData?.name?.[language] || bundleData.name.en}</Title>
                    <Description variant="body1">
                        {isEligible ? (
                          <>
                            {(t.bunlde_description?.[language] || t.bunlde_description.en).map((line, i) => (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <ArrowRightIcon sx={{ fontSize: 24 }} />
                                    <Typography key={i} variant="body1" align="left">
                                        {line}
                                    </Typography>
                                </Box>
                            ))}
                          </>
                        ) : (
                          <>
                            {bundleData?.description?.[language] || bundleData.description.en}
                          </>
                        )}
                        
                    </Description>
                    <Box
                        sx={{
                          marginBottom: 2,
                        }}
                    >
                        {bundleData?.bundle?.some(bundle => bundle?.flow === 'pulid') && (
                          <Chip 
                            size='medium'
                            color="primary"
                            label={ <Typography variant="h6">{t.pulid?.[language] || t.pulid.en}</Typography>}
                            clickable
                            onClick={() => setPulidOpen(true)}
                            sx={{margin: 1}}
                          />
                        )}
                        {bundleData?.bundle?.some(bundle => bundle?.flow === 'v3') && (
                          <Chip 
                            size='medium'
                            color="primary"
                            label={ <Typography variant="h6">{t.v3?.[language] || t.v3.en}</Typography>}
                            clickable
                            onClick={() => setUltraOpen(true)}
                            sx={{margin: 1}}
                          />
                        )}
                        <Chip 
                          size='medium'
                          color="default"
                          sx={{margin: 1}}
                          label={
                            isEligible ? 
                            <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography
                                    component="span"
                                    variant="h6"
                                    sx={{
                                    textDecoration: 'line-through',
                                    color: 'grey',
                                    marginRight: 1,
                                    }}
                                >
                                    {bundleData?.price} 💎
                                </Typography>
                                <Typography
                                    component="span"
                                    variant="h6"
                                    sx={{ color: 'green', fontWeight: 'bold' }}
                                >
                                    {t.free?.[language] || t.free.en}
                                </Typography>
                              </Typography>
                            : 
                            <Typography variant="h6">💎 {t.price?.[language] || t.price.en}{bundleData?.price}</Typography>
                          }
                        />
                        <Chip 
                          size='medium'
                          color="default"
                          icon={<BurstModeIcon />}
                          sx={{margin: 1, padding: 1}}
                          label={ <Typography variant="h6">{t.pictures?.[language] || t.pictures.en}{bundleData?.bundle?.length || 0}</Typography>}
                        />
                    </Box>

                    <Divider sx={{marginTop: 3, marginBottom: 4}}/>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <WebStoriesIcon sx={{ fontSize: 24 }} />
                        <Typography variant="h5" align="left" gutterBottom>
                            {t.bundles_header?.[language] || t.bundles_header.en}
                        </Typography>
                    </Box>
                </Container>

                {/* SKIP / UPLOAD buttons for normal web => pinned at bottom */}
                {!isTelegramWebApp && (
                  <FixedBottomButton sx={{ gap: 2 }}>
                    <Button
                      variant="contained"
                      color="inherit"
                      onClick={handleSkip}
                      fullWidth
                      sx={{
                        minHeight: 60,
                        maxWidth: { xs: '40%', sm: 400 },
                      }}
                    >
                      <Typography variant="h6">{t.buttons.skip?.[language] || t.buttons.skip.en}</Typography>
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleContinue}
                      fullWidth
                      sx={{
                        minHeight: 60,
                        maxWidth: { xs: '100%', sm: 400 },
                      }}
                      endIcon={<ArrowForwardIcon />}
                    >
                      <Typography variant="h6">{t.buttons.upload?.[language] || t.buttons.upload.en}</Typography>
                    </Button>
                  </FixedBottomButton>
                )}
              </Box>
            </Fade>
            {/* We only load BundlesList once; it won't re-render on the after-image cycle. */}
            <Container maxWidth="lg" sx={{marginBottom: 20}}>
                <BundlesList
                  bundles={
                    feedData?.bundles?.filter((b) => b.bundle_id !== bundleData.bundle_id) || []
                  }
                  language={language}
                  isPaid={!isEligible}
                  isShowAdultContent={showAdultContent}
                  showOrientation={false}
                  camp={camp}
                  isEligable={isEligible}
                  mode={mode}
                />
            </Container>
          </ContentContainer>
        );
      // PAGE 3 => user uploads photos => skip / create
      case 3:
        return (
          <ContentContainer>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/logo.jpg`}
              alt="Logo"
              style={{ width: '80px', borderRadius: '16px', marginTop: 20 }}
            />
            <Title variant="h3" sx={{ marginBottom: 2 }}>
              Lumipic Pro
            </Title>
            <Container maxWidth="md" sx={{marginBottom: 10}}>
              <UploadBlock
                images={images}
                setImages={setImages}
                isTelegramWebApp={isTelegramWebApp}
                lang={language}
                linkArgs={`/bundle?bundle_id=${bundleId}&lang=${language}${camp ? `&camp=${camp}` : ""}`}
              />

              <UploadProgress>
                <Typography variant="body2">{getUploadProgress()}</Typography>
              </UploadProgress>

              <Title variant="h4">{t.upload.header?.[language] || t.upload.header.en}</Title>
              <Description variant="h6" gutterBottom>
                {(t.upload.description?.[language] || t.upload.description.en).map((txt, i) => (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <ArrowRightIcon sx={{ fontSize: 24 }} />
                        <Typography key={i} variant="h6" marginBottom={2}>
                            {txt}
                        </Typography>
                    </Box>
                ))}
              </Description>
            </Container>

            {!isTelegramWebApp && (
              <FixedBottomButton sx={{ gap: 2 }}>
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={handleSkip}
                  fullWidth
                  sx={{ minHeight: 60, maxWidth: { xs: '40%', sm: 400 } }}
                >
                  <Typography variant="h6">{t.buttons.skip?.[language] || t.buttons.skip.en}</Typography>
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleSubmit}
                  disabled={isSubmitting || images.length === 0}
                  fullWidth
                  sx={{ minHeight: 60, maxWidth: { xs: '100%', sm: 400 } }}
                  endIcon={<AutoFixHighIcon />}
                >
                  <Typography variant="h6">
                    {isSubmitting ? <CircularProgress size={24} /> : (t.buttons.create?.[language] || t.buttons.create.en)}
                  </Typography>
                </Button>
              </FixedBottomButton>
            )}
          </ContentContainer>
        );

      // PAGE 4 => final: job submitted => upsell
      case 4:
        if (activeStage < 5) {
          return <>{renderFunnelOverlay()}</>;
        } else {
          // stage=5 => show the final "post-countdown" screen
          return renderStage5WaitScreen();
        }
      default:
        return null;
    }
  };

  // :::::::::::::: RENDER ::::::::::::::
  return (
    <ThemeProvider theme={createPageTheme}>
      <CssBaseline enableColorScheme />

      {/* SIGN UP DIALOG => triggered if user is null */}
      <SignUpDialog
        open={openSignUp}
        onClose={() => setOpenSignUp(false)}
        onSignUpSuccess={handleSignUpSuccess}
        language={language || 'en'}
        mode={mode}
        description={bundleData ? (bundleData?.description?.[language] || bundleData?.description?.en || null) : null}
        sourceImage={images?.[0]?.preview || ""}
        outputImages={bundleData ? bundleData?.bundle?.map(bundle => bundle.example) : []}
      />

      {/* If still loading images => show overlay */}
      {loadingScreenVisible && (
        <LoadingOverlay>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
            <Typography variant="h5" gutterBottom>
                {t.preparing?.[language] || t.preparing.en}
            </Typography>
            <Box sx={{ width: '100%', maxWidth: 300 }}>
                <LinearProgress />
            </Box>
            </Box>
        </LoadingOverlay>
        )}

      {/* Fade the entire page content once loading is done */}
      <Fade in={!loadingScreenVisible} timeout={500}>
        <Box sx={{ opacity: loadingScreenVisible ? 0 : 1 }}>
          {renderPageContent()}
        </Box>
      </Fade>

      {/* Skip confirm dialog */}
      <Dialog open={dialogOpen} onClose={() => handleDialogClose(false)}>
        <DialogTitle sx={{backgroundColor: createPageTheme.palette.background.paper }}>{isEligible ? (t.skipDialog.title?.[language] || t.skipDialog.title.en) : (t.skipPaidUser.title?.[language] || t.skipPaidUser.title.en)}</DialogTitle>
        <DialogContent sx={{backgroundColor: createPageTheme.palette.background.paper }}>
        {isEligible ? (t.skipDialog.description?.[language] || t.skipDialog.description.en) : (t.skipPaidUser.description?.[language] || t.skipPaidUser.description.en)}
        </DialogContent>
        <DialogActions sx={{backgroundColor: createPageTheme.palette.background.paper }}>
          <Button onClick={() => handleDialogClose(false)} color="primary">
          {t.buttons.cancel?.[language] || t.buttons.cancel.en}
          </Button>
          <Button onClick={() => handleDialogClose(true)} color="secondary">
          {t.buttons.skip?.[language] || t.buttons.skip.en}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Toast */}
      <Snackbar
        open={showSnackbar || !!submitError}
        autoHideDuration={6000}
        onClose={() => {
          setShowSnackbar(false);
          setSubmitError(null);
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => {
            setShowSnackbar(false);
            setSubmitError(null);
          }}
          severity={submitError ? 'error' : snackbarContent.severity}
          sx={{ width: '100%' }}
        >
          {submitError || snackbarContent.message}
        </Alert>
      </Snackbar>

      <ModelsDialog language={language} mode={mode} pulidOpen={pulidOpen} setPulidOpen={setPulidOpen} ultraOpen={ultraOpen} setUltraOpen={setUltraOpen} />
    </ThemeProvider>
  );
}
