import React from 'react';
import {
  Box,
  Avatar,
  Typography,
  Chip,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import VerifiedIcon from '@mui/icons-material/Verified';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';

const ModelWidgetContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  borderRadius: '16px', // Adjusted for better corner rounding in full-width style
  padding: theme.spacing(1),
  cursor: 'pointer',
}));

const GradientOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: `linear-gradient(to top, ${alpha(theme.palette.background.paper, 0.8)}, ${alpha(theme.palette.primary.main, 0)})`,
  borderRadius: 'inherit',
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  zIndex: 1, // Ensures the content is above the gradient overlay
}));

const ModelAvatar = styled(Avatar)(({ theme }) => ({
  height: '100%',
  width: 'auto',
  aspectRatio: '1 / 1',
  marginRight: theme.spacing(1),
  maxHeight: theme.spacing(6),
}));

const TextContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  marginRight: theme.spacing(1),
  overflow: 'hidden',
}));

const TitleRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '0.25em',
});

const VerifiedBadge = styled(VerifiedIcon)(({ theme }) => ({
  color: theme.palette.info.main,
  marginLeft: theme.spacing(0.5),
}));

const AIBadge = styled(FaceRetouchingNaturalIcon)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  paddingBottom: 1
}));

export default function ModelWidget({ name, imageUrl, mediaAmount, verified, id }) {
    const navigate = useNavigate();

    return (
        <ModelWidgetContainer onClick={() => navigate("/model/" + id)}>
            <GradientOverlay />
            <ContentContainer>
                <ModelAvatar src={imageUrl} alt={`${name} avatar`} />
                <TextContent>
                    <TitleRow>
                        <Typography variant="subtitle2" fontWeight="bold" noWrap>
                            {name}
                        </Typography>
                        {verified ? <VerifiedBadge fontSize="small" /> : <Chip label="Created with AI" variant="outlined" sx={{ marginLeft: 1}}/>}
                    </TitleRow>
                    <Typography variant="caption" color="textSecondary" noWrap>
                        See other {mediaAmount} media of this model
                    </Typography>
                </TextContent>
                <ArrowForwardIosIcon sx={{ color: 'text.secondary', fontSize: 'small', marginLeft: 1 }} />
            </ContentContainer>
        </ModelWidgetContainer>
    );
}
