import React, { useEffect, useState } from 'react';
import { Box, Card, Chip, Typography, Divider } from '@mui/material';

export default function CreditsSelectionCard({
  credit,
  index,
  selectedCredit,
  handleCreditSelection,
  creditsList,
  couponTimeRemaining,
  formatCountdown,
  rates,
  countryCode,
}) {
  const countryToCurrency = {
    KR: { code: 'KRW', symbol: '₩' },
    SA: { code: 'SAR', symbol: '﷼' },
    BR: { code: 'BRL', symbol: 'R$' },
    MX: { code: 'MXN', symbol: '$' },
    CO: { code: 'COP', symbol: 'COP$' },
    US: { code: 'USD', symbol: '$' },
    WW: { code: 'USD', symbol: '$' }, // Worldwide default
    RU: { code: 'RUB', symbol: '₽' },
  };

  const [formattedPrice, setFormattedPrice] = useState('');
  const [formattedStartPrice, setFormattedStartPrice] = useState('');

  useEffect(() => {
    const exchangeRate = rates ? rates[countryToCurrency[countryCode]?.code] : null;
    const symbol = countryToCurrency[countryCode]?.symbol || '$';

    // Format the price with custom rules
    const formatPrice = (price) => {
      if (price >= 1000) {
        return price.toLocaleString(undefined, { maximumFractionDigits: 0 });
      } else if (price < 100) {
        return price.toFixed(2);
      }
      return price.toFixed(0);
    };

    if (exchangeRate) {
      setFormattedPrice(`${symbol}${formatPrice(credit.price * exchangeRate)}`);
      setFormattedStartPrice(`${symbol}${formatPrice(credit.start_price * exchangeRate)}`);
    } else {
      setFormattedPrice(`$${formatPrice(credit.price)}`);
      setFormattedStartPrice(`$${formatPrice(credit.start_price)}`);
    }
  }, [credit.price, credit.start_price, countryCode, rates]);

  // Find the base price per credit from the lowest credit option
  const baseCredit = creditsList.reduce((prev, curr) =>
    prev.amount < curr.amount ? prev : curr
  );
  const basePricePerCredit = baseCredit.start_price / baseCredit.amount;

  // Calculate expected price based on base price per credit
  const expectedPrice = credit.amount * basePricePerCredit;

  // Calculate discount percentage
  const discountPercentage = Math.round(
    ((expectedPrice - credit.price) / expectedPrice) * 100
  );

  return (
    <Card
      onClick={() => handleCreditSelection(credit)}
      sx={{
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        position: 'relative',
        borderColor: selectedCredit === credit ? 'primary.main' : 'grey.300',
        borderWidth: selectedCredit === credit ? 3 : 1,
        cursor: 'pointer',
        textAlign: 'center',
        transition: 'transform 0.2s ease-in-out', // Smooth scaling transition
        ':active': {
          transform: 'scale(0.95)', // Scale down on click
        },
      }}
    >
      {/* Discount Chip on Top Right */}
      {discountPercentage > 0 && (
        <Chip
          label={`${discountPercentage}% OFF`}
          color="secondary"
          size="small"
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            fontWeight: 'bold',
          }}
        />
      )}

      {/* Top Image */}
      <Box
        component="img"
        src={`${process.env.PUBLIC_URL}/assets/icons/credits/${index}.webp`}
        alt="Diamond"
        sx={{ width: '50%', alignSelf: 'center' }}
      />

      {/* Horizontal Divider */}
      <Divider sx={{ width: '100%' }} />

      {/* Diamonds Info Row */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">💎 {credit.amount}</Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1,
            width: '100%',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: 'clamp(1rem, 2.5vw, 1.5rem)', // Responsive font size
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: '100%',
            }}
          >
            {formattedPrice}
          </Typography>
          {credit.price < credit.start_price && (
            <Typography
              variant="body1"
              sx={{ textDecoration: 'line-through', color: 'text.secondary' }}
            >
              {formattedStartPrice}
            </Typography>
          )}
        </Box>
      </Box>
    </Card>
  );
}
