import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import CreatePage from './pages/create/CreatePage';
import SignUp from "./pages/signup/SignUp";
import SignIn from "./pages/signin/SignIn";
import ResetPassword from "./pages/resetpassword/ResetPassword";
import ProfilePage from "./pages/profile/ProfilePage";
import ShopPage from "./pages/shop/ShopPage";
import GalleryPage from './pages/gallery/GalleryPage';
import MediaViewer from './pages/viewer/MediaViewer';
import FeedPage from './pages/feed/FeedPage';
import ModelPage from './pages/model/modelPage';
import PayPage from './pages/pay/payPage';
import ErrorModule from './components/ErrorModule';
import BundlePage from './pages/bundles/BundlePage';

import { useNavigate } from "react-router-dom";

function RedirectToCreateWithParams() {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = location.search;
  const params = new URLSearchParams(searchParams);

  const campValue = params.get("camp");
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      if (!campValue) {
        setLoading(false);
        return;
      }

      let sessionKey = sessionStorage.getItem("sessionKey");
      if (sessionKey) {
        setLoading(false);
        return;
      }

      console.log('no session key')

      // Otherwise generate a new session key
      sessionKey = crypto.randomUUID();
      sessionStorage.setItem("sessionKey", sessionKey);

      // Make the /campaign call
      try {
        const url = `${process.env.REACT_APP_SERVER_URL}/campaign?camp=${encodeURIComponent(
          campValue
        )}&sessionKey=${encodeURIComponent(sessionKey)}`;
        const resp = await fetch(url);
        const result = await resp.json();
        console.log("Server /campaign response:", result);

        if (result.redirectBundleId) {
          console.log(`redirect path: ${result.redirectBundleId}`)
          navigate(`/bundle?bundle_id=${result.redirectBundleId}&camp=${campValue}`)
        } else {
          console.log(`no redirect => create`)
          navigate('/create' + searchParams)
        }
      } catch (err) {
        console.error("Error calling /campaign:", err);
        navigate('/create' + searchParams)
      }

      setLoading(false);
    })();
  }, [campValue, searchParams]);

  // If still loading, show a spinner or "Loading"
  if (loading) {
    return
  }
  return <Navigate replace to={'/create'} />;
}

function App() {

  React.useEffect(() => {
    const authenticateTelegramUser = async () => {
      if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initDataUnsafe) {
        const initData = window.Telegram.WebApp.initData;

        if (initData) {
          // Send initData as is
          const formBody = `initData=${encodeURIComponent(initData)}`;

          try {
            await fetch(`${process.env.REACT_APP_SERVER_URL}/verify-telegram-webapp`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
              body: formBody,
            });

          } catch (error) {
            console.error('Error during Telegram authentication:', error);
          }
        }
      }
    };
    authenticateTelegramUser();
  }, []);

  return (
    <TonConnectUIProvider manifestUrl="https://app.lumipic.pro/tonconnect-manifest.json">
      <Router>
        <Routes>
          <Route path="/" element={<RedirectToCreateWithParams />} />
          <Route path="/create" element={<CreatePage />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/shop" element={<ShopPage />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/feed" element={<FeedPage />} />
          <Route path="/bundle" element={<BundlePage />} />

          {/* MediaViewer Routes */}
          <Route path="/gallery/:id" element={<MediaViewer />} />
          <Route path="/feed/:id" element={<MediaViewer />} />
          <Route path="/model/:model_id" element={<ModelPage />} />
          <Route path="/pay/:id" element={<PayPage />} />

          <Route path="*" element={<ErrorModule type="notFound" />} />
        </Routes>
      </Router>
    </TonConnectUIProvider>
  );
}

export default App;
