//useIndexedDB.js
import { useState, useEffect } from 'react';

export const useIndexedDB = () => {
  const [db, setDb] = useState(null);
  const [dbInitialized, setDbInitialized] = useState(false);

  useEffect(() => {
    const openRequest = indexedDB.open('GalleryDB', 1);

    openRequest.onupgradeneeded = () => {
      const db = openRequest.result;
      if (!db.objectStoreNames.contains('jobImages')) {
        db.createObjectStore('jobImages', { keyPath: 'jobId' });
      }
    };

    openRequest.onsuccess = () => {
      setDb(openRequest.result);
      setDbInitialized(true);
    };

    openRequest.onerror = () => {
      console.error('Error opening IndexedDB');
    };
  }, []);

  // Store an entire array of image URLs
  const addImagesToCache = (jobId, imageUrlsArray) => {
    if (dbInitialized && db) {
      const tx = db.transaction('jobImages', 'readwrite');
      const store = tx.objectStore('jobImages');
      store.put({ jobId, images: imageUrlsArray });
      tx.oncomplete = () => {
        console.log(`Image array cached for job ${jobId}`);
      };
      tx.onerror = () => {
        console.error('Error caching images');
      };
    } else {
      console.error('Cannot add to cache: IndexedDB not initialized yet');
    }
  };

  // Retrieve the array of image URLs (or null if not found)
  const getImagesFromCache = (jobId) => {
    return new Promise((resolve, reject) => {
      if (dbInitialized && db) {
        const tx = db.transaction('jobImages', 'readonly');
        const store = tx.objectStore('jobImages');
        const request = store.get(jobId);
        request.onsuccess = () => {
          if (request.result) {
            resolve(request.result.images);  // the array
          } else {
            resolve(null);
          }
        };
        request.onerror = () => {
          reject('Error fetching from cache');
        };
      } else {
        reject('IndexedDB not initialized');
      }
    });
  };

  const clearImageCache = () => {
    // same as your original
    if (dbInitialized && db) {
      const tx = db.transaction('jobImages', 'readwrite');
      const store = tx.objectStore('jobImages');
      const clearRequest = store.clear();

      clearRequest.onsuccess = () => {
        console.log('All images cleared from cache');
      };

      clearRequest.onerror = () => {
        console.error('Error clearing image cache');
      };
    } else {
      console.error('Cannot clear cache: IndexedDB not initialized yet');
    }
  };

  return { dbInitialized, addImagesToCache, getImagesFromCache, clearImageCache };
};

