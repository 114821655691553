// FAQSection.js
import React from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQSection({ faqs }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography component="h2" variant="h5" sx={{ fontWeight: 'bold' }}>
        FAQ
      </Typography>
      {faqs.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {faq.name}
          </AccordionSummary>
          <AccordionDetails>
            {faq.description}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}
