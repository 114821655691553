import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { Email, LockOutlined } from '@mui/icons-material';
import { getAuth, sendEmailVerification, updateEmail } from 'firebase/auth';
import { styled } from '@mui/material/styles';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#aab4be',
        ...theme.applyStyles('dark', {
          backgroundColor: '#8796A5',
        }),
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#001e3c',
    width: 32,
    height: 32,
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
    ...theme.applyStyles('dark', {
      backgroundColor: '#003892',
    }),
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#aab4be',
    borderRadius: 20 / 2,
    ...theme.applyStyles('dark', {
      backgroundColor: '#8796A5',
    }),
  },
}));

export default function SocialMediaSection({ rewardCredits, isEmailLinked }) {
  const [email, setEmail] = useState('');
  const [verificationDialogOpen, setVerificationDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [offersEnabled, setOffersEnabled] = useState(true);
  const [productUpdatesEnabled, setProductUpdatesEnabled] = useState(true);

  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    if (user && user.email) {
      setEmail(user.email);
    }
  }, [user]);

  // Function to handle adding email
  const handleAddEmail = async () => {
    if (!validateEmail(email)) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }
    try {
      await updateEmail(user, email);
      await sendEmailVerification(user);
      setVerificationDialogOpen(true);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  // Email validation function (similar to sign in/up)
  const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography component="h2" variant="h5" sx={{ fontWeight: 'bold' }}>
        Link Email
        {!isEmailLinked && rewardCredits > 0 && (
          <Chip
            label={`💎 ${rewardCredits}`}
            sx={{ ml: 2 }}
          />
        )}
      </Typography>
      <Typography variant="body1">
        {!isEmailLinked && (
          <>
            Add your email address to receive important updates and make sure you have access to your account if you lose access to it.
          </>
        )}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <TextField
            fullWidth
            label="Email Address"
            variant="outlined"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setErrorMessage('');
            }}
            error={!!errorMessage}
            helperText={errorMessage}
            disabled={isEmailLinked}
          />
          {!isEmailLinked && (
            <Button
              variant="contained"
              onClick={handleAddEmail}
              startIcon={<Email />}
              disabled={!validateEmail(email)}
            >
              Verify
            </Button>
          )}
        </Box>
      </Box>

      {/* Verification Dialog */}
      <Dialog open={verificationDialogOpen} onClose={() => setVerificationDialogOpen(false)}>
        <DialogTitle>Email Verification</DialogTitle>
        <DialogContent>
          <Box
            component="img"
            src={`${process.env.PUBLIC_URL}/assets/icons/webp/verify_email.webp`}
            alt="Email Verification Animation"
            sx={{ width: '50%', maxWidth: 150, margin: '0 auto 16px', display: 'block' }}
          />
          <Typography>
            A verification email has been sent to {email}. Please check your inbox and follow the link to verify your email address. Don't forget to check your spam folder if you don't see it.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setVerificationDialogOpen(false); }}>OK</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
