import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CopyIcon from '@mui/icons-material/ContentCopy';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import getSignUpTheme from '../pages/theme/theme/getSignUpTheme';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

// Define a reusable SubmitDialog component
const SubmitDialog = ({
  open,
  onClose,
  dialogType,
  title,
  description,
  supportEmail,
  handlePrimaryAction,
  jobId,
  mode = 'dark'
}) => {

  const customTheme = createTheme(getSignUpTheme(mode));

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent sx={{backgroundColor: customTheme.palette.background.paper}}>
        {(['submitted', 'credits'].includes(dialogType)) && (
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/webp/${dialogType === "credits" ? "gem" : dialogType === 'submitted' ? "tick" : dialogType}.webp`}
              alt={dialogType}
              style={{ width: '100%', maxWidth: '200px', margin: '0 auto' }}
            />
          </Box>
        )}
        <Typography variant="body1" align="center" sx={{ mt: 2 }}>
          {description}
        </Typography>

        {dialogType === 'suspended' && (
          <Box sx={{ mt: 4 }}>
            <TextField
              label="Support Email"
              value={supportEmail}
              fullWidth
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      navigator.clipboard.writeText(supportEmail);
                    }}
                  >
                    <CopyIcon />
                  </IconButton>
                ),
              }}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', backgroundColor: customTheme.palette.background.paper }}>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
        {dialogType !== 'suspended' && (
          <Button
            variant="contained"
            color="primary"
            startIcon={dialogType === 'credits' || dialogType === 'offer' ? <ShoppingCartIcon /> : null}
            onClick={handlePrimaryAction}
          >
            {dialogType === 'credits' || dialogType === 'offer'
              ? 'Go to Shop'
              : dialogType === 'suspended'
              ? 'Contact Support' : dialogType === 'submitted' ? "View Job"
              : dialogType === 'proveface' ? "Confirm" : 'Primary Action'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

// Default props for flexibility
SubmitDialog.defaultProps = {
  dialogType: 'info', // Default type if none provided
  title: 'Dialog Title',
  description: 'Dialog description goes here.',
  supportEmail: '',
  handlePrimaryAction: () => {},
  onClose: () => {},
};

export default SubmitDialog;
