import React, { useEffect, useState } from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const BannerContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  borderRadius: '16px',
  padding: theme.spacing(2),
  background: 'linear-gradient(90deg, #4A148C, #6A1B9A)', // Matches gradient style
  color: '#FFFFFF', // White text
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxShadow: theme.shadows[2],
  cursor: 'pointer',
}));

const IconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const CouponAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(6),
}));

const TimerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const TimerBlock = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  minWidth: theme.spacing(4),
}));

const translations = {
    en: {
      discount: 'DISCOUNT ACTIVATED!',
    },
    es: {
      discount: '¡CUPÓN ACTIVADO!',
    },
    pt: {
      discount: 'CUPOM ATIVADO!',
    },
    de: {
      discount: 'RABATTGUTSCHEIN AKTIVIERT!',
    },
    ja: {
      discount: '割引クーポンが有効になりました！',
    },
    ko: {
      discount: '할인 쿠폰이 활성화되었습니다!',
    },
    zh: {
      discount: '折扣券已激活！',
    },
    hi: {
      discount: 'डिस्काउंट कूपन सक्रिय!',
    },
    ru: {
      discount: 'КУПОН АКТИВИРОВАН!',
    },
};
  

export default function CouponBanner({ timeRemaining = (60 * 45 * 1000), language = 'en' }) {
  const navigate = useNavigate();
  const [milliseconds, setMilliseconds] = useState(timeRemaining);

  const translation = translations[language] || translations.en;

  // Timer logic
  useEffect(() => {
    const timer = setInterval(() => {
      setMilliseconds((prev) => (prev > 0 ? prev - 10 : 0));
    }, 10);

    return () => clearInterval(timer);
  }, []);

  const formatCountdown = (time) => {
    const minutes = Math.floor(time / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    const millis = Math.floor((time % 1000) / 10); // Show two digits of milliseconds
    return { minutes, seconds, millis };
  };

  const { minutes, seconds, millis } = formatCountdown(milliseconds);

  return (
    <BannerContainer onClick={() => navigate('/shop')}>
      <IconContainer>
        <CouponAvatar
          src={`${process.env.PUBLIC_URL}/assets/icons/webp/gem.webp`}
          alt="Coupon Icon"
        />
        <Typography variant="h6" fontWeight="bold">
          {translation.discount}
        </Typography>
      </IconContainer>
      <TimerContainer>
        <TimerBlock>
          <Typography variant="h5" fontWeight="bold">
            {String(minutes).padStart(2, '0')}
          </Typography>
          <Typography variant="caption">Min</Typography>
        </TimerBlock>
        <TimerBlock>
          <Typography variant="h5" fontWeight="bold">
            {String(seconds).padStart(2, '0')}
          </Typography>
          <Typography variant="caption">Sec</Typography>
        </TimerBlock>
        <TimerBlock>
          <Typography variant="h5" fontWeight="bold">
            {String(millis).padStart(2, '0')}
          </Typography>
          <Typography variant="caption">Ms</Typography>
        </TimerBlock>
      </TimerContainer>
    </BannerContainer>
  );
}
