// SignUp.js
import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import getSignUpTheme from '../theme/theme/getSignUpTheme';
import { GoogleIcon, TelegramIcon } from './CustomIcons';
import TemplateFrame from '../theme/TemplateFrameSigning';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import XIcon from '@mui/icons-material/X';  // Import X (Twitter) icon

import { useNavigate, useLocation } from 'react-router-dom';

import { auth, database } from '../../services/firebase'; // Import Firebase Database
import {
  GoogleAuthProvider,
  TwitterAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  setPersistence,
  browserLocalPersistence,
  signInWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  applyActionCode,
} from 'firebase/auth';
import { ref, get } from 'firebase/database'; // Import Firebase Realtime Database

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    width: '450px',
  },
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));
const SignUpContainer = styled(Stack)(({ theme }) => ({
  minHeight: '100%',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
  ...theme.applyStyles('dark'),
}));

const languageCodes = ['en', 'ru', 'es', 'pt', 'de', 'ja', 'ko', 'zh', 'hi'];
const t = {
  label: {
    en: "Sign up",
    ru: "Регистрация",
    hi: "साइन अप करें",
    zh: "注册",
    de: "Registrieren",
    es: "Regístrate"
  },
  email: {
    en: "Email",
    ru: "Почта",
    hi: "ईमेल",
    zh: "电子邮件",
    de: "E-Mail",
    es: "Correo electrónico"
  },
  password: {
    en: "Password",
    ru: "Пароль",
    hi: "पासवर्ड",
    zh: "密码",
    de: "Passwort",
    es: "Contraseña"
  },
  forgot: {
    en: "Forgot Password?",
    ru: "Забыли пароль?",
    hi: "पासवर्ड भूल गए?",
    zh: "忘记密码？",
    de: "Passwort vergessen?",
    es: "¿Olvidaste tu contraseña?"
  },
  no_promo: {
    en: "I do not wish to receive promotional emails",
    ru: "Я не хочу получать рекламные письма",
    hi: "मैं प्रचारात्मक ईमेल प्राप्त नहीं करना चाहता",
    zh: "我不希望收到促销邮件",
    de: "Ich möchte keine Werbe-E-Mails erhalten",
    es: "No deseo recibir correos electrónicos promocionales"
  },
  age: {
    en: "I confirm that I am at least 21 years old",
    ru: "Я подтверждаю, что мне не менее 21 года",
    hi: "मैं पुष्टि करता हूं कि मैं कम से कम 21 वर्ष का हूं",
    zh: "我确认我至少21岁",
    de: "Ich bestätige, dass ich mindestens 21 Jahre alt bin",
    es: "Confirmo que tengo al menos 21 años"
  },
  agree: {
    en: "I agree with the Terms of Service and Privacy Policy",
    ru: "Я согласен с Условиями обслуживания и Политикой конфиденциальности",
    hi: "मैं सेवा की शर्तों और गोपनीयता नीति से सहमत हूं",
    zh: "我同意服务条款和隐私政策",
    de: "Ich stimme den Nutzungsbedingungen und der Datenschutzerklärung zu",
    es: "Estoy de acuerdo con los Términos de servicio y la Política de privacidad"
  },
  already: {
    en: "Already have an account?",
    ru: "Уже есть аккаунт?",
    hi: "पहले से ही एक खाता है?",
    zh: "已经有账户了？",
    de: "Schon ein Konto?",
    es: "¿Ya tienes una cuenta?"
  },
  sign_in: {
    en: "Sign in",
    ru: "Войти",
    hi: "साइन इन करें",
    zh: "登录",
    de: "Anmelden",
    es: "Iniciar sesión"
  },
  or: {
    en: "or",
    ru: "или",
    hi: "या",
    zh: "或",
    de: "oder",
    es: "o"
  },
  google: {
    en: "Sign up with Google",
    ru: "Зарегистрироваться через Google",
    hi: "गूगल के साथ साइन अप करें",
    zh: "通过Google注册",
    de: "Mit Google anmelden",
    es: "Regístrate con Google"
  },
  x: {
    en: "Sign up with X",
    ru: "Зарегистрироваться через X",
    hi: "X के साथ साइन अप करें",
    zh: "通过X注册",
    de: "Mit X anmelden",
    es: "Regístrate con X"
  },
  tg: {
    en: "Sign up with Telegram",
    ru: "Зарегистрироваться через Telegram",
    hi: "टेलीग्राम के साथ साइन अप करें",
    zh: "通过Telegram注册",
    de: "Mit Telegram anmelden",
    es: "Regístrate con Telegram"
  },
  verification: {
    label: {
      en: "Check your email",
      ru: "Проверьте свою электронную почту",
      hi: "अपना ईमेल जांचें",
      zh: "检查您的电子邮件",
      de: "Überprüfen Sie Ihre E-Mails",
      es: "Revisa tu correo electrónico"
    },
    description: {
      en: "A verification link has been sent to $EMAIL. Please check your inbox and also your spam folder to confirm your account.",
      ru: "Ссылка для подтверждения отправлена на $EMAIL. Пожалуйста, проверьте ваш почтовый ящик и папку со спамом, чтобы подтвердить учетную запись.",
      hi: "एक सत्यापन लिंक $EMAIL पर भेजा गया है। कृपया अपना इनबॉक्स और स्पैम फ़ोल्डर जांचें ताकि आप अपना खाता पुष्टि कर सकें।",
      zh: "验证链接已发送到 $EMAIL。请检查您的收件箱和垃圾邮件文件夹以确认您的账户。",
      de: "Ein Bestätigungslink wurde an $EMAIL gesendet. Bitte überprüfen Sie Ihren Posteingang und auch Ihren Spam-Ordner, um Ihr Konto zu bestätigen.",
      es: "Se ha enviado un enlace de verificación a $EMAIL. Por favor, revisa tu bandeja de entrada y también tu carpeta de spam para confirmar tu cuenta."
    },
    resend_in: {
      en: "Resend in",
      ru: "Повторно отправить через",
      hi: "पुनः भेजें में",
      zh: "重新发送于",
      de: "Erneut senden in",
      es: "Reenviar en"
    },
    resend: {
      en: "Resend Verification Link",
      ru: "Отправить еще раз",
      hi: "सत्यापन लिंक पुनः भेजें",
      zh: "重新发送验证链接",
      de: "Bestätigungslink erneut senden",
      es: "Reenviar enlace de verificación"
    },
    error: {
      en: "Error occured during verification. Please try again later. If you already used this email, close this dialog and go to sign in page. Error code:",
      ru: "Произошла ошибка во время проверки. Пожалуйста, попробуйте позже. Если вы уже использовали эту электронную почту, закройте это окно и перейдите на страницу входа. Код ошибки:",
      hi: "सत्यापन के दौरान त्रुटि हुई। कृपया बाद में पुनः प्रयास करें। यदि आपने पहले ही इस ईमेल का उपयोग किया है, तो इस संवाद को बंद करें और साइन इन पेज पर जाएं। त्रुटि कोड:",
      zh: "验证过程中发生错误。请稍后再试。如果您已经使用过此电子邮件，请关闭此对话框并转到登录页面。错误代码：",
      de: "Bei der Überprüfung ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal. Wenn Sie diese E-Mail bereits verwendet haben, schließen Sie dieses Dialogfeld und gehen Sie zur Anmeldeseite. Fehlercode:",
      es: "Ocurrió un error durante la verificación. Por favor, inténtalo de nuevo más tarde. Si ya usaste este correo electrónico, cierra este cuadro de diálogo y ve a la página de inicio de sesión. Código de error:"
    }
  },
  verified: {
    label: {
      en: "Email Verified!",
      es: "¡Correo Electrónico Verificado!",
      pt: "E-mail Verificado!",
      de: "E-Mail Verifiziert!",
      ja: "メール認証済み！",
      ko: "이메일 인증 완료!",
      zh: "电子邮件已验证！",
      ru: "Электронная почта подтверждена!",
      hi: "ईमेल सत्यापित!"
    },
    description: {
      en: "Now close this page and continue on the previous one. Otherwise you can explore other features",
      es: "Ahora cierra esta página y continúa en la anterior. De lo contrario, puedes explorar otras funciones",
      pt: "Agora feche esta página e continue na anterior. Caso contrário, você pode explorar outros recursos",
      de: "Schließen Sie diese Seite und fahren Sie auf der vorherigen fort. Andernfalls können Sie andere Funktionen erkunden",
      ja: "このページを閉じて前のページに戻ってください。あるいは他の機能を探索することもできます",
      ko: "이 페이지를 닫고 이전 페이지에서 계속하세요. 그렇지 않으면 다른 기능을 탐색할 수 있습니다",
      zh: "现在关闭此页面并继续前一个页面。否则，您可以探索其他功能",
      ru: "Закройте эту страницу и продолжайте на предыдущей. Или вы можете изучить другие функции",
      hi: "अब इस पेज को बंद करें और पिछले पेज पर जारी रखें। अन्यथा आप अन्य सुविधाओं का अन्वेषण कर सकते हैं"
    }
  }
};

export default function SignUp() {
  const [mode, setMode] = useState('dark');
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const defaultTheme = createTheme({ palette: { mode } });
  const SignUpTheme = createTheme(getSignUpTheme(mode));
  const [email, setEmail] = useState(null);
  const [twitterUsername, setTwitterUsername] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [agreedToAge, setAgreedToAge] = useState(false);
  const [noEmails, setNoEmails] = useState(false);
  const [showVerificationDialog, setShowVerificationDialog] = useState(false);
  const [verificationError, setVerificationError] = useState(false);
  const [verificationErrorMessage, setVerificationErrorMessage] = useState(null);
  const [userData, setUserData] = useState(null);
  const [resendTimer, setResendTimer] = useState(0);
  const [resendAttempts, setResendAttempts] = useState(0);
  const [language, setLanguage] = useState('en');
  const [requestReturn, setRequestReturn] = useState(null);
  const [showVerifiedDialog, setShowVerifiedDialog] = useState(false);
  const [closeMe, setCloseMe] = useState(false);

  const [turnstileToken, setTurnstileToken] = useState(null);
  const [widgetRendered, setWidgetRendered] = useState(false);
  const [captchaFinished, setCaptchaFinished] = useState(false);
  const turnstileWidgetRef = useRef(null);

  // Settings variables
  const [dmcaLink, setDmcaLink] = useState('https://app.lumipic.pro/dmca.pdf');
  const [tosLink, setTosLink] = useState('https://app.lumipic.pro/terms-of-service.pdf');
  const [privacyLink, setPrivacyLink] = useState('https://app.lumipic.pro/privacy-policy.pdf');
  const [supportEmail, setSupportEmail] = useState('https://app.lumipic.pro/');
  const [mainBotLink, setMainBotLink] = useState('https://t.me/lumipicbot');

  const navigate = useNavigate();
  const location = useLocation();

  // URL parameters
  const [redirectTo, setRedirectTo] = useState(null);
  const [camp, setCamp] = useState(null);
  const [referrer, setReferrer] = useState(null);

  useEffect(() => {
    // Create the script element
    const script = document.createElement('script');
    script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
    script.async = true;
    script.defer = true;

    script.onload = () => {
      const widgetId = window.turnstile.render('#turnstile-widget', {
        sitekey: "0x4AAAAAAA4K8i8c7ms9LRCA",  // from Cloudflare
        callback: (token) => {
          console.log(token)
          // This gets called any time Turnstile validates successfully
          setTurnstileToken(token);
        },
        'error-callback': () => {
          setTurnstileToken(null);
        },
        'timeout-callback': () => {
          setTurnstileToken(null);
        },
      });
      turnstileWidgetRef.current = widgetId;
      setWidgetRendered(true);
    };

    // Append to <body> or <head>
    document.body.appendChild(script);

    // Cleanup => remove script if user navigates away
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {

    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setMode(savedMode);
    } else {
      const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setMode(systemPrefersDark ? 'dark' : 'light');
    }

    // Read URL parameters
    const queryParams = new URLSearchParams(location.search);
    const redirectTo = queryParams.get('redirectTo')
    const camp = queryParams.get('camp')
    const referrer = queryParams.get('ref')
    let lang = queryParams.get('lang')
    const closeIt = queryParams.get("closeIt")

    if (closeIt) {
      setCloseMe(true);
      if (queryParams.get('language')) {
        lang = queryParams.get('language')
      }
    }
    if (lang && languageCodes.includes(lang)) setLanguage(lang);

    if (redirectTo) setRedirectTo(redirectTo);
    if (camp) setCamp(camp);
    if (referrer) setReferrer(referrer);

    const fetchSettings = async () => {
      try {
        const settingsRef = ref(database, '/settings');
        const snapshot = await get(settingsRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          setDmcaLink(data.links?.dmca || '');
          setTosLink(data.links?.tos || '');
          setPrivacyLink(data.links?.privacy || '');
          setSupportEmail(data.links?.support_email || '');
          setMainBotLink(data.links?.main_bot || '');
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
      }
    };
    fetchSettings();

    const actionCode = queryParams.get('oobCode')
    const mode = queryParams.get('mode')

    if (actionCode && mode === 'verifyEmail') {
      console.log(actionCode, mode)
      handleVerificationCheck(auth, actionCode);
      return
    }
    else {
      window.onload = () => {
        if (window.google) {
          window.google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            callback: handleGoogleOneTapSignIn,
            auto_select: true,
            cancel_on_tap_outside: false,
          });
          window.google.accounts.id.prompt();
        }
      };
    }

    const token = localStorage.getItem('userToken'); // Get user token from local storage
    if (token) {
      console.log(token)
      auth.onAuthStateChanged(async (user) => {
        console.log(user)
        if (user && user.emailVerified) {
          navigate('/profile');
          return;
        }
        else if (user && user.email && !user.emailVerified) {
          sendVerificationEmail();
          setEmail(user.email);
          setShowVerificationDialog(true);
        }
      });
    }
    else {
      if (language !== 'en') {
        localStorage.setItem("language", language)
      }
    }

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        console.log(user)
        if (user.emailVerified) {
          // User has verified their email, proceed to send data to the server
          const token = await user.getIdToken();
          localStorage.setItem('userToken', token);

          const requestReturn = await sendUserDataToServer(user.uid, token);

          if (redirectTo) {
            navigate(redirectTo);
          } else {
            navigate('/create');
          }
        } else {
          if (user.email) {
            setEmail(user.email)
            setShowVerificationDialog(true);
          }
        }
      }
    });

    return () => unsubscribe();

  }, [navigate, redirectTo]);

  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [resendTimer]);

  const toggleColorMode = () => {
    const newMode = mode === 'dark' ? 'light' : 'dark';
    setMode(newMode);
    localStorage.setItem('themeMode', newMode);
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  const handleGoogleOneTapSignIn = async (response) => {
    try {
      const provider = new GoogleAuthProvider();
      await setPersistence(auth, browserLocalPersistence);
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Save user token to local storage
      const token = await user.getIdToken();
      localStorage.setItem('userToken', token);


      const requestReturn = await sendUserDataToServer(user.uid, token);

      if (redirectTo) {
        navigate(redirectTo);
      } else {
        if (requestReturn && requestReturn?.bundle_id) {
          navigate('/bundle?bundle_id=' + requestReturn?.bundle_id + (camp ? `&camp=${camp}` : ""));
        }
        else {
          navigate('/create');
        }
      }
    } catch (error) {
      console.error('Error with Google One Tap sign-in:', error);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await setPersistence(auth, browserLocalPersistence);

      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const token = await user.getIdToken();
      localStorage.setItem('userToken', token);

      console.log(result);

      const requestReturn = await sendUserDataToServer(user.uid, token);

      if (redirectTo) {
        navigate(redirectTo);
      } else {
        if (requestReturn && requestReturn?.bundle_id) {
          navigate('/bundle?bundle_id=' + requestReturn?.bundle_id  + (camp ? `&camp=${camp}` : ""));
        }
        else {
          navigate('/create');
        }
      }
    } catch (error) {
      console.error('Error with Google signin or signup:', error);
    }
  };

  const handleTwitterSignIn = async () => {
    const provider = new TwitterAuthProvider();
    try {
      await setPersistence(auth, browserLocalPersistence);

      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const token = await user.getIdToken();
      localStorage.setItem('userToken', token);

      console.log(result);

      let twitterUsername = null;
      if (result.additionalUserInfo && result.additionalUserInfo.profile && result.additionalUserInfo.profile.screen_name) {
        twitterUsername = result.additionalUserInfo.profile.screen_name;
      }
      else if (result?.additionalUserInfo?.profile?.screenName) {
        twitterUsername = result.additionalUserInfo.profile.screenName
      }
      else if (result?.reloadUserInfo?.screenName) {
        twitterUsername = result.reloadUserInfo.screenName
      }
      setTwitterUsername(twitterUsername)

      // Attempt to get email from user
      const email = user.email || null;
      setEmail(email);

      const requestReturn = await sendUserDataToServer(user.uid, token, email, twitterUsername);

      if (redirectTo) {
        navigate(redirectTo);
      } else {
        if (requestReturn && requestReturn?.bundle_id) {
          navigate('/bundle?bundle_id=' + requestReturn?.bundle_id  + (camp ? `&camp=${camp}` : ""));
        }
        else {
          navigate('/create');
        }
      }
    } catch (error) {
      console.error('Error with Twitter (X) signin or signup:', error);
    }
  };

  const sendUserDataToServer = async (uid, token) => {
    try {
      console.log('sending data to server...', uid, token)
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/user-signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          uid,
          camp,
          ref: referrer,
          email,
          twitter_username: twitterUsername
        }),
      });

      const result = await response.json();

      if (!result.success) {
        console.error('Error during server-side user setup:', result.error);
      }

      return result;
    } catch (error) {
      console.error('Error sending user data to server:', error);
      return false
    }
  };

  const validateInputs = () => {
    const email = document.getElementById('email');
    const password = document.getElementById('password');

    let isValid = true;

    if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
      setEmailError(true);
      setEmailErrorMessage('Please enter a valid email address.');
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    if (!password.value || password.value.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage('Password must be at least 6 characters long.');
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }

    if (!agreedToTerms) {
      alert('You must agree to the Terms of Service and Privacy Policy to sign up.');
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateInputs()) {
      return;
    }

    console.log(turnstileToken, captchaFinished)
    if (turnstileToken && !captchaFinished) {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/signup-captcha`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ turnstileToken }),
      });
  
      const result = await response.json();
      if (result.success) setCaptchaFinished(true);
      if (!result.success) {
        alert('Sign-up failed or captcha invalid.');
        return;
      }
    }

    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;

    setEmail(email);

    let userCredential;
    let user;

    try {
      //trying to sign up the user
      userCredential = await createUserWithEmailAndPassword(auth, email, password);
      user = userCredential.user;

      localStorage.setItem("email", email);
      localStorage.setItem("password", password);

      setUserData(user);

      const token = await user.getIdToken();

      if (user && !user.emailVerified) {
        sendVerificationEmail()
        setShowVerificationDialog(true);
      }

      const requestReturn = await sendUserDataToServer(user.uid, token, email, twitterUsername);
      setRequestReturn(requestReturn);
    }
    catch(error) {
      console.log(error.code);
      if (error.code === 'auth/email-already-exists' || error.code === 'auth/email-already-in-use') {
        //user is already registered
        try {
          userCredential = await signInWithEmailAndPassword(auth, email, password);
          user = userCredential.user;
          setUserData(user);

          if (user && !user.emailVerified) {
            //user didn't verify the email, sending email
            localStorage.setItem("email", email);
            localStorage.setItem("password", password);

            sendVerificationEmail()
            setShowVerificationDialog(true);
          }
          else {
            navigate('/create')
          }
        }
        catch(err) {
          //user provided correct email and wrong password
          console.log(err.code)
          if (err.code === 'auth/invalid-credential') {
            navigate('/signin')
          }
        }
      }
    }
  };

  const sendVerificationEmail = async () => {
    if (resendTimer === 0) {
      try {
        const email = document.getElementById('email').value;
        const password = document.getElementById('password').value;

        let user = userData;
        if (!user) {
          const userCredential = await signInWithEmailAndPassword(auth, email, password);
          user = userCredential.user;
        }

        if (!user.emailVerified) {
          await sendEmailVerification(user);

          const cooldown = resendAttempts < 2 ? 60 : 300;
          setResendTimer(cooldown);
          setResendAttempts((prev) => prev + 1);
        } else {
          navigate('/create')
        }
      } catch (error) {
        console.error('Error resending verification email:', error);
      }
    }
  };

  const handleVerificationCheck = async (auth, actionCode) => {
    try {
      await applyActionCode(auth, actionCode);
      if (closeMe) setShowVerifiedDialog(true);
      const email = localStorage.getItem("email");
      const password = localStorage.getItem("password");
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (user.emailVerified) {
        localStorage.removeItem("email")
        localStorage.removeItem("password")

        console.log(requestReturn, redirectTo)
        if (redirectTo) {
          navigate(redirectTo);
        } else {
          if (requestReturn && requestReturn?.bundle_id) {
            navigate('/bundle?bundle_id=' + requestReturn?.bundle_id + (camp ? `&camp=${camp}` : ""));
          }
          else {
            navigate('/create');
          }
        }
      }
      else {
        setEmail(email);
        setUserData(user);
        sendVerificationEmail();
        setVerificationError(false);
        setVerificationErrorMessage(null)
        setShowVerificationDialog(true);
      }
    }
    catch(error) {
      console.log(error.code);
      if (closeMe) return
      setVerificationError(true);
      setVerificationErrorMessage(error.code)
      setShowVerificationDialog(true);
    }
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };

  return (
    <TemplateFrame
      toggleCustomTheme={toggleCustomTheme}
      showCustomTheme={showCustomTheme}
      mode={mode}
      toggleColorMode={toggleColorMode}
      dmcaLink={dmcaLink}
      tosLink={tosLink}
      privacyLink={privacyLink}
      supportEmail={supportEmail}
    >
      <ThemeProvider theme={showCustomTheme ? SignUpTheme : defaultTheme}>
        <CssBaseline enableColorScheme />
        <SignUpContainer direction="column" justifyContent="space-between">
          <Card variant="outlined" /* sx={{borderColor: "transparent"}} */>
            <Typography
              component="h1"
              variant="h4"
              sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
            >
              {t.label?.[language] || t.label.en}
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
            >
              <FormControl>
                <FormLabel htmlFor="email">{t.email?.[language] || t.email.en}</FormLabel>
                <TextField
                  required
                  fullWidth
                  id="email"
                  placeholder="your@email.com"
                  name={t.email?.[language] || t.email.en}
                  autoComplete="email"
                  variant="outlined"
                  error={emailError}
                  helperText={emailErrorMessage}
                  color={emailError ? 'error' : 'primary'}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="password">{t.password?.[language] || t.password.en}</FormLabel>
                <TextField
                  required
                  fullWidth
                  name={t.password?.[language] || t.password.en}
                  placeholder="••••••"
                  type={passwordVisible ? 'text' : 'password'}
                  id="password"
                  autoComplete="new-password"
                  variant="outlined"
                  error={passwordError}
                  helperText={passwordErrorMessage}
                  color={passwordError ? 'error' : 'primary'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={togglePasswordVisibility} edge="end">
                          {passwordVisible ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box sx={{ mt: 1 }}>
                  <Link href="/reset-password" variant="body2">
                  {t.forgot?.[language] || t.forgot.en}
                  </Link>
                </Box>
              </FormControl>
              <FormControlLabel
                control={<Checkbox value="noEmails" color="secondary" />}
                label={t.no_promo?.[language] || t.no_promo.en}
                checked={noEmails}
                onChange={(e) => setNoEmails(e.target.checked)}
              />
              <FormControlLabel
                control={<Checkbox required value="agreedToAge" color="secondary" />}
                label={t.age?.[language] || t.age.en}
                checked={agreedToAge}
                onChange={(e) => setAgreedToAge(e.target.checked)}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    required
                    color="primary"
                    checked={agreedToTerms}
                    onChange={(e) => setAgreedToTerms(e.target.checked)}
                  />
                }
                label={t.agree?.[language] || t.agree.en}
              />

              <div id="turnstile-widget"/>

              <Button type="submit" fullWidth variant="contained" sx={{marginTop: 2}}>
              {t.label?.[language] || t.label.en}
              </Button>
              <Typography sx={{ textAlign: 'center' }}>
              {t.already?.[language] || t.already.en}{' '}
                <span>
                  <Link href="/signin" variant="body2" sx={{ alignSelf: 'center' }}>
                  {t.sign_in?.[language] || t.sign_in.en}
                  </Link>
                </span>
              </Typography>
            </Box>
            <Divider>
              <Typography sx={{ color: 'text.secondary' }}>{t.or?.[language] || t.or.en}</Typography>
            </Divider>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Button
                fullWidth
                variant="outlined"
                onClick={handleGoogleSignIn}
                startIcon={<GoogleIcon />}
              >
                {t.google?.[language] || t.google.en}
              </Button>
              <Button
                fullWidth
                variant="outlined"
                onClick={handleTwitterSignIn}
                startIcon={<XIcon />}
              >
                {t.x?.[language] || t.x.en}
              </Button>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => {
                  if (mainBotLink) {
                    window.location.href = mainBotLink;
                  } else {
                    alert('Telegram bot link not available');
                  }
                }}
                startIcon={<TelegramIcon />}
              >
                {t.tg?.[language] || t.tg.en}
              </Button>
            </Box>
          </Card>

          {/* Verification Code Dialog */}
          <Dialog 
            open={showVerificationDialog}
            onClose={() => setShowVerificationDialog(false)}
          >
            <DialogTitle
              sx={{
                backgroundColor: SignUpTheme.palette.background.paper
              }}
            >
              <IconButton
                aria-label="close"
                onClick={() => setShowVerificationDialog(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent 
              sx={{
                backgroundColor: SignUpTheme.palette.background.paper
              }}
            >
              <Box sx={{ textAlign: 'center', mb: 2 }}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/webp/${verificationError ? "legal" : "email"}.webp`}
                  alt="Email Sent"
                  style={{ width: '50%', maxWidth: '200px', margin: '0 auto' }}
                />
              </Box>
              <Typography variant="h6" align="center">
              {t.verification.label?.[language] || t.verification.label.en}
              </Typography>
              {(verificationError ? (
                <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                  {(t.verification.error?.[language] || t.verification.error.en) + " " + verificationErrorMessage} 
                </Typography>
              ) : (
                <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                  {(t.verification.description?.[language] || t.verification.description.en).replace("$EMAIL", email)}
                </Typography>
              ))}              
              <Box sx={{ mt: 4, textAlign: 'center' }}>
                {verificationError ? (
                  <></>
                ) : (
                  <Button
                  variant="outlined"
                  disabled={resendTimer > 0}
                  onClick={sendVerificationEmail}
                >
                  {resendTimer > 0 ? `${t.verification.resend_in?.[language] || t.verification.resend_in.en} ${resendTimer}` : (t.verification.resend?.[language] || t.verification.resend.en)}
                </Button>
                )}
              </Box>
            </DialogContent>
          </Dialog>

          {/* Confirmation dialog */}
          <Dialog 
            open={showVerifiedDialog}
            onClose={() => setShowVerificationDialog(false)}
          >
            <DialogContent 
              sx={{
                backgroundColor: SignUpTheme.palette.background.paper
              }}
            >
              <Box sx={{ textAlign: 'center', mb: 2 }}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/webp/tick.webp`}
                  alt="Email Sent"
                  style={{ width: '50%', maxWidth: '200px', margin: '0 auto' }}
                />
              </Box>
              <Typography variant="h6" align="center">
              {t.verified.label?.[language] || t.verified.label.en}
              </Typography>
              <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                {(t.verified.description?.[language] || t.verified.description.en)} 
              </Typography>             
              <Box sx={{ mt: 4, textAlign: 'center' }}>
                <Button
                  variant="outlined"
                  disabled={resendTimer > 0}
                  onClick={() => navigate('/create')}
                >
                  {t.sign_in?.[language] || t.sign_in.en}
                </Button>
              </Box>
            </DialogContent>
          </Dialog>
        </SignUpContainer>
      </ThemeProvider>
    </TemplateFrame>
  );
}
