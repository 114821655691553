// TemplateFrame.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  BottomNavigation,
  BottomNavigationAction,
  useMediaQuery,
  Button,
  IconButton,
  Badge,
} from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useNavigate, useLocation } from 'react-router-dom';
import getSignUpTheme from './theme/getSignUpTheme';

import CollectionsIcon from '@mui/icons-material/Collections';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import AddIcon from '@mui/icons-material/Add';
import FeedIcon from '@mui/icons-material/Feed';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

// Import SVG or Logo Component
import Logo from '../../components/Logo'; // assuming you have a Logo component in your project

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  borderBottom: '1px solid',
  borderColor: theme.palette.divider,
  backgroundColor: theme.palette.background.paper,
  boxShadow: 'none',
  backgroundImage: 'none',
  zIndex: 100,
  flex: '0 0 auto',
}));

function TemplateFrame({
  showCustomTheme,
  toggleCustomTheme,
  mode,
  toggleColorMode,
  children,
  credits, // New prop for credits
  needPadding = false,
  couponExpiresAt = 0, // Timestamp in seconds
  avatar = 0,
}) {
  const signUpTheme = createTheme(getSignUpTheme(mode));
  const navigate = useNavigate();
  const location = useLocation();
  const isMobileOrTablet = useMediaQuery('(max-width: 1024px)');

  const currentPath = location.pathname.split('/')[1]; // Get the first segment of the URL
  const navValue = currentPath || 'create'; // Default to 'create' if no path segment is found

  const handleNavigate = (page) => {
    navigate(`/${page}`);
  };

  const [language, setLanguage] = useState('en');

  // State for remaining time in seconds
  const [remainingTime, setRemainingTime] = React.useState(0);
  const [showTimer, setShowTimer] = React.useState(false);

  const trnsl = {
    create: {
      en: "Create",
      ru: "Создать",
      es: "Crear",
      pt: "Criar",
      de: "Erstellen",
      ja: "作成",
      ko: "만들기",
      zh: "创建",
      hi: "सृजित करें"
    },
    feed: {
      en: "Feed",
      ru: "Лента",
      es: "Feed",
      pt: "Feed",
      de: "Feed",
      ja: "フィード",
      ko: "피드",
      zh: "动态",
      hi: "फ़ीड"
    },
    shop: {
      en: "Shop",
      ru: "Магазин",
      es: "Tienda",
      pt: "Loja",
      de: "Shop",
      ja: "ショップ",
      ko: "상점",
      zh: "商店",
      hi: "दुकान"
    },
    soon: {
      en: "Soon",
      ru: "Скоро",
      es: "Pronto",
      pt: "Em breve",
      de: "Bald",
      ja: "近日公開",
      ko: "곧",
      zh: "即将",
      hi: "जल्द ही"
    },
    gallery: {
      en: "Gallery",
      ru: "Галлерея",
      es: "Galería",
      pt: "Galeria",
      de: "Galerie",
      ja: "ギャラリー",
      ko: "갤러리",
      zh: "画廊",
      hi: "गैलरी"
    },
    buy_credits: {
      en: "Buy Credits",
      ru: "Камушки",
      es: "Comprar Créditos",
      pt: "Comprar Créditos",
      de: "Credits kaufen",
      ja: "クレジットを購入",
      ko: "크레딧 구매",
      zh: "购买积分",
      hi: "क्रेडिट खरीदें"
    }
  };  

  // Calculate remaining time based on couponExpiresAt
  React.useEffect(() => {
    const calculateRemainingTime = () => {
      const currentTime = Date.now();
      const couponId = localStorage.getItem('coupon');
      const expiresAt = parseInt(localStorage.getItem('coupon_expiresAt'), 10);

      if (couponId && expiresAt > currentTime) {
        setRemainingTime(expiresAt - currentTime);
        setShowTimer(true);
      } else {
        setShowTimer(false);
        localStorage.removeItem('coupon'); // Clean up expired coupon
        localStorage.removeItem('coupon_expiresAt');
      }
    };

    if (localStorage.getItem("language")) {
      setLanguage(localStorage.getItem("language"))
    }

    calculateRemainingTime();

    // Update remaining time every millisecond if necessary
    let timerId;
    if (showTimer) {
      timerId = setInterval(() => {
        calculateRemainingTime();
      }, 100);
    }

    return () => {
      if (timerId) clearInterval(timerId);
    };
  }, [showTimer]);

  // Helper function to format remaining time into mm:ss
  const formatTime = (ms) => {
    const seconds = Math.floor(ms / 1000);
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
  };

  // Function to render the Shop icon with appropriate Badge
  const renderShopIcon = () => {
    if (remainingTime > 60) {
      if (remainingTime < 3600) {
        // Less than 1 hour: show countdown timer
        return (
          <Badge
            badgeContent={formatTime(remainingTime)}
            color="secondary"
            overlap="circular"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <LocalMallIcon />
          </Badge>
        );
      } else {
        return (
          <Badge
            variant="dot"
            color="secondary"
            overlap="circular"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <LocalMallIcon />
          </Badge>
        );
      }
    }
    // Otherwise, show icon without Badge
    return <LocalMallIcon />;
  };

  return (
    <ThemeProvider theme={signUpTheme}>
      <Box sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}>
        <StyledAppBar>
          <Toolbar
            variant="dense"
            disableGutters
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              p: '8px 12px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Button
                variant="text"
                color="inherit"
                onClick={() => handleNavigate('')}
              >
                <Logo />
                <Typography variant="h6" marginLeft={2}>
                  Lumipic
                </Typography>
              </Button>
            </Box>

            {!isMobileOrTablet && (
              // Render the navigation buttons on the top header for desktop view
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Button color="inherit" onClick={() => handleNavigate('create')}>
                  {trnsl.create?.[language] || trnsl.create.en}
                </Button>
                {/* <Button color="inherit" onClick={() => handleNavigate('feed')}>
                  {trnsl.feed?.[language] || trnsl.feed.en}
                </Button> */}
                <Button color="inherit" onClick={() => handleNavigate('shop')}>
                  {trnsl.shop?.[language] || trnsl.shop.en}
                </Button>
                {/* <Button
                  color="inherit"
                  onClick={() => handleNavigate('tasks')}
                  disabled // Disable the "Tasks" button
                >
                  {trnsl.soon?.[language] || trnsl.soon.en}
                </Button> */}
                <Button color="inherit" onClick={() => handleNavigate('gallery')}>
                  {trnsl.gallery?.[language] || trnsl.gallery.en}
                </Button>
              </Box>
            )}

            {/* Grouped Box for Credits Button and Profile Button */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginRight: 2 }}>
              {showTimer ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleNavigate('shop')}
                  startIcon={<span role="img" aria-label="gift">🎁</span>}
                >
                  {formatTime(remainingTime)}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleNavigate('shop')}
                  startIcon={
                    credits > 0 ? (
                      <span role="img" aria-label="gift">🎁</span>
                    ) : (
                      <span role="img" aria-label="diamond">💎</span>
                    )
                  }
                >
                  {credits > 0 ? credits : (trnsl.buy_credits?.[language] || trnsl.buy_credits.en)}
                </Button>
              )}
                {avatar ? (
                  <Button
                    onClick={() => handleNavigate('profile')}
                    sx={{
                      minWidth: 35,
                      minHeight: 35,
                      width: 35,
                      height: 35,
                      padding: 0,       // no extra padding so the img can fill the button
                    }}
                  >
                    <img
                      src={avatar}
                      style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '20%',
                        objectFit: 'cover',
                        display: 'block',
                      }}
                    />
                  </Button>
                ) : (
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => handleNavigate('profile')}
                    /* sx={{ width: 40, height: 40 }} */
                  >
                    <AccountCircle />
                  </IconButton>
                )}

            </Box>
          </Toolbar>
        </StyledAppBar>

        {/* Main content with children */}
        <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
          {children}
        </Box>

        {/* Bottom Navigation only for Mobile or Tablet view */}
        {isMobileOrTablet && (
          <BottomNavigation
            value={navValue}
            onChange={(event, newValue) => handleNavigate(newValue)}
            showLabels
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              minHeight: needPadding ? 80 : 60,
              zIndex: 99,
              paddingBottom: needPadding ? 2 : 0,
            }}
          >
            {/* <BottomNavigationAction label={trnsl.feed?.[language] || trnsl.feed.en} value="feed" icon={<FeedIcon />} /> */}
            <BottomNavigationAction label={trnsl.gallery?.[language] || trnsl.gallery.en} value="gallery" icon={<CollectionsIcon />} />
            <BottomNavigationAction label={trnsl.create?.[language] || trnsl.create.en} value="create" icon={<AddIcon />} />
            <BottomNavigationAction
              label={trnsl.shop?.[language] || trnsl.shop.en}
              value="shop"
              icon={renderShopIcon()}
            />
            {/* <BottomNavigationAction
              label={trnsl.soon?.[language] || trnsl.soon.en}
              value="tasks"
              icon={<EmojiEventsIcon />}
              disabled // Disable the "Tasks" button in mobile navigation
            /> */}
          </BottomNavigation>
        )}
      </Box>
    </ThemeProvider>
  );
}

TemplateFrame.propTypes = {
  children: PropTypes.node,
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  showCustomTheme: PropTypes.bool.isRequired,
  toggleColorMode: PropTypes.func.isRequired,
  toggleCustomTheme: PropTypes.func.isRequired,
  credits: PropTypes.number, // Add PropType for credits
  needPadding: PropTypes.bool,
  couponExpiresAt: PropTypes.number, // PropType for couponExpiresAt
};

TemplateFrame.defaultProps = {
  credits: 0, // Optional: set default credits if not provided
  needPadding: false,
  couponExpiresAt: 0, // Default to 0 (no coupon)
};

export default TemplateFrame;
