import React from 'react';
import { Masonry } from '@mui/lab';
import FeedItem from './FeedItem';

export default function MasonryFeed({ media }) {
  return (
    <Masonry columns={{ xs: 1, sm: 2, md: 4 }} spacing={2} sx={{ paddingLeft: 2 }}>
      {media.map((mediaItem) => (
        <FeedItem
          key={mediaItem.jobId}
          media={mediaItem}
        />
      ))}
    </Masonry>
  );
}
