import React from 'react';
import { Box, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination, Icon } from '@mui/material';
import ViewListIcon from '@mui/icons-material/ViewList';
import { useNavigate } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

export default function PurchasesSection({ purchases }) {
  const [page, setPage] = React.useState(1);
  const rowsPerPage = 5;

  const navigate = useNavigate();

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleOpenInvoice = (paymentId) => {
    navigate("/pay/" + paymentId)
  }

  const displayedPurchases = purchases.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: "100%" }}>
      <Typography component="h2" variant="h5" sx={{ fontWeight: 'bold' }}>
        <Box display="flex" alignItems="center" gap={1}>
            <ShoppingCartIcon />
            Purchases
        </Box>
      </Typography>
      <TableContainer component={Paper} sx={{ height: { md: '50vh' } }}>
        {purchases.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Payment ID</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Credits</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedPurchases.map((purchase) => (
                <TableRow key={purchase.id}>
                  <TableCell onClick={() => handleOpenInvoice(purchase.id)}>{purchase.id.slice(0, 10)}...{purchase.id.slice(-4)}</TableCell>
                  <TableCell>{purchase.date}</TableCell>
                  <TableCell>{purchase.amount} 💎</TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: 'inline-flex',
                        backgroundColor:
                          purchase.status === 1 ? 'green' : purchase.timestamp > purchase.expiresAt ? "orange" : "red",
                        color: '#fff',
                        borderRadius: 1,
                        p: 1,
                      }}
                    >
                      {purchase.status === 1 ? "COMPLETED" : purchase.timestamp > purchase.expiresAt ? "WAITING" : "CANCELLED"}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
              marginY: 3
            }}
          >
            <Icon component={ViewListIcon} sx={{ fontSize: 60 }} color="disabled" />
            <Typography>No payments made yet.</Typography>
            <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/shop")}
          >
            💎 Shop
          </Button>
          </Box>
        )}
      </TableContainer>
      {purchases.length > rowsPerPage && (
        <Pagination
          count={Math.ceil(purchases.length / rowsPerPage)}
          page={page}
          onChange={handlePageChange}
        />
      )}
    </Box>
  );
}
