// MediaViewer.js
import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  Box,
  Button,
  Card,
  CardMedia,
  CardActionArea,
  CircularProgress,
  CssBaseline,
  TextField,
  Typography,
  Divider,
  IconButton,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Container,
} from '@mui/material';
import { createTheme, ThemeProvider, styled, useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import WebStoriesIcon from '@mui/icons-material/WebStories';

import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { database, auth } from '../../services/firebase';
import { useIndexedDB } from '../../services/useIndexedDB';
import { ref, get, remove, set, getDatabase } from 'firebase/database';
import { signInWithCustomToken } from 'firebase/auth';
import { getStorage, ref as storageRef, deleteObject } from 'firebase/storage';
import TemplateFrame from '../theme/TemplateFrame';
import getSignUpTheme from '../theme/theme/getSignUpTheme';
import ModelWidget from '../../components/ModelWidget';
import ErrorModule from '../../components/ErrorModule';
import useMediaQuery from '@mui/material/useMediaQuery';
import BundleList from '../../components/BundlesList';
import PurchaseRequiredWidget from '../../components/PurchaseRequiredWidget';
import CouponBanner from '../../components/CouponBanner';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';  // CSS import once

const ViewerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
}));
const MediaCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  width: '50%',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
  marginBottom: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflow: 'visible',
  border: 'none',
  background: 'none',
}));
const TransparentOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'transparent',
  zIndex: 10,
  pointerEvents: 'none',
}));

function formatTime(totalSec) {
  if (totalSec <= 0) return '00:00:00';
  const hours = Math.floor(totalSec / 3600);
  const mins = Math.floor((totalSec % 3600) / 60);
  const secs = totalSec % 60;
  return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
}

const languageCodes = ['en', 'ru', 'es', 'pt', 'de', 'ja', 'ko', 'zh', 'hi'];
const trnsl = {
  errors: {
    auth: {
      en: "Authentication failed",
      ru: "Ошибка авторизации",
      es: "Autenticación fallida",
      pt: "Falha na autenticação",
      de: "Authentifizierung fehlgeschlagen",
      ja: "認証に失敗しました",
      ko: "인증 실패",
      zh: "身份验证失败",
      hi: "प्रमाणीकरण विफल"
    },
    downloadCredit: {
      title: {
        en: "Get Credits to Download",
        ru: "Получите кредиты для загрузки",
        es: "Obtén créditos para descargar",
        pt: "Obtenha créditos para baixar",
        de: "Credits zum Herunterladen erhalten",
        ja: "ダウンロードするにはクレジットを取得",
        ko: "다운로드를 위해 크레딧 얻기",
        zh: "获取积分以下载",
        hi: "डाउनलोड करने के लिए क्रेडिट प्राप्त करें"
      },
      message: {
        en: "You should have at least 1 credit to download this media. Purchasing credits will also unlock blurred images",
        ru: "Вам нужен хотя бы 1 кредит для загрузки этого медиа. Покупка кредитов также разблокирует размытые изображения",
        es: "Debes tener al menos 1 crédito para descargar este contenido. Comprar créditos también desbloqueará imágenes borrosas",
        pt: "Você deve ter pelo menos 1 crédito para baixar esta mídia. Comprar créditos também desbloqueará imagens desfocadas",
        de: "Sie benötigen mindestens 1 Credit, um dieses Medium herunterzuladen. Der Kauf von Credits wird auch verschwommene Bilder entsperren",
        ja: "このメディアをダウンロードするには、少なくとも1クレジットが必要です。クレジットの購入はぼかし画像もアンロックします",
        ko: "이 미디어를 다운로드하려면 최소 1크레딧이 필요합니다. 크레딧을 구매하면 흐릿한 이미지도 잠금 해제됩니다",
        zh: "下载此媒体需要至少1积分。购买积分还将解锁模糊图像",
        hi: "इस मीडिया को डाउनलोड करने के लिए आपके पास कम से कम 1 क्रेडिट होना चाहिए। क्रेडिट खरीदने से धुंधली छवियां भी अनलॉक हो जाएंगी"
      }
    },
    not_found: {
      en: "Media not found or you do not have permission to view it.",
      ru: "Медиа не найдено или у вас нет разрешения на его просмотр.",
      es: "Medios no encontrados o no tienes permiso para verlo.",
      pt: "Mídia não encontrada ou você não tem permissão para visualizá-la.",
      de: "Medium nicht gefunden oder Sie haben keine Berechtigung, es anzuzeigen.",
      ja: "メディアが見つからないか、表示する権限がありません。",
      ko: "미디어를 찾을 수 없거나 이를 볼 수 있는 권한이 없습니다.",
      zh: "未找到媒体或您没有权限查看。",
      hi: "मीडिया नहीं मिला या इसे देखने की अनुमति नहीं है।"
    }
  },
  delete_job: {
    title: {
      en: "Delete Job?",
      ru: "Удалить задание?",
      es: "¿Eliminar trabajo?",
      pt: "Excluir trabalho?",
      de: "Auftrag löschen?",
      ja: "ジョブを削除しますか？",
      ko: "작업 삭제?",
      zh: "删除任务？",
      hi: "कार्य हटाएं?"
    },
    message: {
      en: "Are you sure you want to delete this job? This action cannot be undone.",
      ru: "Вы уверены, что хотите удалить это задание? Это действие нельзя отменить.",
      es: "¿Estás seguro de que deseas eliminar este trabajo? Esta acción no se puede deshacer.",
      pt: "Tem certeza de que deseja excluir este trabalho? Esta ação não pode ser desfeita.",
      de: "Möchten Sie diesen Auftrag wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
      ja: "このジョブを削除してもよろしいですか？この操作は取り消せません。",
      ko: "이 작업을 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다.",
      zh: "确定要删除此任务吗？此操作无法撤销。",
      hi: "क्या आप वाकई इस कार्य को हटाना चाहते हैं? इस क्रिया को पूर्ववत नहीं किया जा सकता।"
    }
  },
  swipe_tip: {
    en: "Swipe left or right to view more images.",
    ru: "Проведите влево или вправо, чтобы увидеть больше изображений.",
    es: "Desliza hacia la izquierda o derecha para ver más imágenes.",
    pt: "Deslize para a esquerda ou direita para ver mais imagens.",
    de: "Wischen Sie nach links oder rechts, um weitere Bilder anzuzeigen.",
    ja: "左または右にスワイプして、さらに画像を表示します。",
    ko: "왼쪽 또는 오른쪽으로 스와이프하여 더 많은 이미지를 봅니다.",
    zh: "向左或向右滑动以查看更多图片。",
    hi: "अधिक चित्र देखने के लिए बाएँ या दाएँ स्वाइप करें।"
  },
  swipe_header: {
    en: "Swipe to View More Images",
    ru: "Свайпните, чтобы увидеть больше изображений",
    es: "Desliza para ver más imágenes",
    pt: "Deslize para ver mais imagens",
    de: "Wischen, um weitere Bilder anzuzeigen",
    ja: "スワイプして画像をもっと見る",
    ko: "스와이프하여 더 많은 이미지 보기",
    zh: "滑动查看更多图片",
    hi: "और चित्र देखने के लिए स्वाइप करें"
  },
  status: {
    queue: {
      en: "In queue",
      ru: "В очереди",
      es: "En cola",
      pt: "Na fila",
      de: "In Warteschlange",
      ja: "キューに入っています",
      ko: "대기 중",
      zh: "排队中",
      hi: "पंक्ति में"
    },
    processing: {
      en: "Processing",
      ru: "Обработка",
      es: "Procesando",
      pt: "Processando",
      de: "Wird verarbeitet",
      ja: "処理中",
      ko: "처리 중",
      zh: "处理中",
      hi: "प्रसंस्करण"
    }
  },
  imagePlaceHolder: {
    title: {
      processing: {
        en: "Queue passed, processing now...",
        ru: "Очередь пройдена, сейчас обрабатывается...",
        es: "Cola pasada, procesando ahora...",
        pt: "Fila passada, processando agora...",
        de: "Warteschlange übersprungen, wird jetzt verarbeitet...",
        ja: "キューを通過し、現在処理中...",
        ko: "대기열이 지나갔고, 현재 처리 중...",
        zh: "队列已通过，现在正在处理...",
        hi: "पंक्ति पार कर ली गई है, अब प्रक्रिया हो रही है..."
      },
      queue: {
        en: "In queue, waiting for the server to become available...",
        ru: "В очереди, ожидание освобождения сервера...",
        es: "En cola, esperando que el servidor esté disponible...",
        pt: "Na fila, aguardando o servidor ficar disponível...",
        de: "In der Warteschlange, warte auf Serververfügbarkeit...",
        ja: "キューに登録され、サーバーが利用可能になるのを待っています...",
        ko: "대기열에 있으며, 서버가 사용 가능해지기를 기다리는 중...",
        zh: "排队中，等待服务器可用...",
        hi: "पंक्ति में है, सर्वर के उपलब्ध होने की प्रतीक्षा कर रहा है..."
      },
      error: {
        en: "Job Failed. Credits for this job were returned",
        es: "El trabajo falló. Los créditos para este trabajo fueron devueltos",
        pt: "Trabalho falhou. Créditos para este trabalho foram devolvidos",
        de: "Auftrag fehlgeschlagen. Credits für diesen Auftrag wurden zurückerstattet",
        ja: "ジョブに失敗しました。このジョブのクレジットが返却されました",
        ko: "작업 실패. 이 작업에 대한 크레딧이 반환되었습니다",
        zh: "任务失败。此任务的积分已退还",
        ru: "Задача не выполнена. Кредиты за эту задачу были возвращены",
        hi: "कार्य विफल। इस कार्य के लिए क्रेडिट वापस कर दिए गए हैं"
      },
    },
    waitTime: {
      en: "Estimated wait time",
      ru: "Примерное время ожидания",
      es: "Tiempo de espera estimado",
      pt: "Tempo de espera estimado",
      de: "Geschätzte Wartezeit",
      ja: "推定待ち時間",
      ko: "예상 대기 시간",
      zh: "预计等待时间",
      hi: "अनुमानित प्रतीक्षा समय"
    },
    skipQueue: {
      en: "Your job is in low priority, purchase credits and get media as fast as possible",
      ru: "Ваше задание с низким приоритетом, купите кредиты, чтобы получить медиа как можно быстрее",
      es: "Tu trabajo tiene baja prioridad, compra créditos y obtén el contenido lo más rápido posible",
      pt: "Seu trabalho está com baixa prioridade, compre créditos e obtenha mídia o mais rápido possível",
      de: "Ihr Auftrag hat niedrige Priorität, kaufen Sie Credits, um Medien so schnell wie möglich zu erhalten",
      ja: "あなたのジョブは低優先度です。クレジットを購入して、できるだけ早くメディアを取得してください",
      ko: "작업 우선 순위가 낮습니다. 크레딧을 구매하여 가능한 빨리 미디어를 받으세요",
      zh: "您的任务优先级较低，购买积分以尽快获取媒体",
      hi: "आपका कार्य कम प्राथमिकता में है, क्रेडिट खरीदें और जितनी जल्दी हो सके मीडिया प्राप्त करें"
    }
  },
  use: {
    en: "Use",
    ru: "Использовать",
    es: "Usar",
    pt: "Usar",
    de: "Verwenden",
    ja: "使用する",
    ko: "사용",
    zh: "使用",
    hi: "उपयोग करें"
  },
  use_prompt: {
    en: "Use Prompt",
    ru: "Использовать",
    es: "Usar",
    pt: "Usar",
    de: "Eingabe",
    ja: "プロンプトを使用",
    ko: "프롬프트 사용",
    zh: "使用提示",
    hi: "प्रॉम्प्ट का उपयोग करें"
  },
  copy: {
    en: "Copy",
    ru: "Копировать",
    es: "Copiar",
    pt: "Copiar",
    de: "Kopieren",
    ja: "コピー",
    ko: "복사",
    zh: "复制",
    hi: "कॉपी करें"
  },
  delete: {
    en: "Delete",
    ru: "Удалить",
    es: "Eliminar",
    pt: "Excluir",
    de: "Löschen",
    ja: "削除",
    ko: "삭제",
    zh: "删除",
    hi: "हटाएं"
  },
  with_bundles: {
    en: "Created with Bundles",
    ru: "Созданный фотосет",
    es: "Creado con paquetes",
    pt: "Criado com pacotes",
    de: "Erstellt mit Bundles",
    ja: "バンドルで作成",
    ko: "번들로 생성됨",
    zh: "使用捆绑包创建",
    hi: "बंडलों के साथ बनाया गया"
  },
  buttons: {
    processing: {
      en: "Processing...",
      ru: "Обработка...",
      es: "Procesando...",
      pt: "Processando...",
      de: "Wird verarbeitet...",
      ja: "処理中...",
      ko: "처리 중...",
      zh: "处理中...",
      hi: "प्रसंस्करण..."
    },
    try_bundle: {
      en: "Try Bundle ✨",
      ru: "Скопировать ✨",
      es: "Prueba paquete ✨",
      pt: "Experimente o pacote ✨",
      de: "Paket ausprobieren ✨",
      ja: "バンドルを試す ✨",
      ko: "번들 시도 ✨",
      zh: "尝试捆绑 ✨",
      hi: "पैकेज आज़माएं ✨"
    },
    use_prompt: {
      en: "Use Prompt ✨",
      ru: "Используйте prompt ✨",
      es: "Usar prompt ✨",
      pt: "Usar prompt ✨",
      de: "Eingabe prompt ✨",
      ja: "プロンプトを使用 ✨",
      ko: "프롬프트 사용 ✨",
      zh: "使用提示 ✨",
      hi: "प्रॉम्प्ट का उपयोग करें ✨"
    },
    download: {
      en: "Download 📥",
      ru: "Скачать 📥",
      es: "Descargar 📥",
      pt: "Baixar 📥",
      de: "Herunterladen 📥",
      ja: "ダウンロード 📥",
      ko: "다운로드 📥",
      zh: "下载 📥",
      hi: "डाउनलोड करें 📥"
    }
  }
};

export default function MediaViewer() {
  const [mode, setMode] = useState('dark');
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const defaultTheme = createTheme({ palette: { mode } });
  const galleryTheme = createTheme(getSignUpTheme(mode));
  const [mediaData, setMediaData] = useState(null);
  const [modelData, setModelData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [isPaidUser, setPaidUser] = useState(false);
  const [credits, setCredits] = useState(0);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams(); 
  const location = useLocation(); 
  const isJob = location.pathname.includes('/gallery');
  const [isTelegramWebApp, setIsTelegramWebApp] = useState(false);
  const [showAdultContent, setShowAdultContent] = useState(null);
  const [bundles, setBundles] = useState([]);
  const [language, setLanguage] = useState(null);
  const [hasCoupon, setHasCoupon] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [touchStartX, setTouchStartX] = useState(null);
  const [firstTimeDialogOpen, setFirstTimeDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const activeStepRef = useRef(activeStep);

  const [imageLoaded, setImageLoaded] = useState(false);
  const [submitError, setSubmitError] = React.useState(null);

  const [estimateCountdownVal, setEstimateCountdownVal] = useState(0);

  useEffect(() => {
    activeStepRef.current = activeStep;
  }, [activeStep]);

  useEffect(() => {
    if (submitError) {
      if (isTelegramWebApp) {
        window.Telegram.WebApp.showAlert(submitError);
      }
    }
  }, [submitError, isTelegramWebApp]);

  useEffect(() => {
    if (mediaData && mediaData.expiresAt) {
      const calculateTimeLeft = () => {
        const currentTimestamp = Math.floor(Date.now() / 1000);
        const timeRemaining = mediaData.expiresAt - currentTimestamp;
        if (timeRemaining > 0) {
          setTimeLeft(timeRemaining);
        } else {
          setTimeLeft(0);
        }
      };
      calculateTimeLeft();
      const interval = setInterval(calculateTimeLeft, 1000);
      return () => clearInterval(interval);
    }
  }, [mediaData]);

  useEffect(() => {
    if (mediaData?.estimateWaitTime) {
      // Convert that to a timestamp in ms
      const targetMillis = mediaData.estimateWaitTime * 60_000;
      function tick() {
        const now = Date.now();
        const leftSec = Math.floor((targetMillis - now) / 1000);
        setEstimateCountdownVal(Math.max(leftSec, 0));
      }
      tick();
      const intervalId = setInterval(tick, 1000);
      return () => clearInterval(intervalId);
    }
  }, [mediaData]);


  useEffect(() => {
    const savedMode = localStorage.getItem('themeMode');
    if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initData) {
      setIsTelegramWebApp(true);
      const telegramColorScheme = window.Telegram.WebApp.colorScheme;
      setMode(telegramColorScheme === 'dark' ? 'dark' : 'light');
      if (!window.Telegram.WebApp.isExpanded) {
        window.Telegram.WebApp.expand();
      }
      window.Telegram.WebApp.disableVerticalSwipes();
      window.Telegram.WebApp.ready();
    } else {
      setMode(savedMode ? savedMode : 'light');
    }

    if (!language && localStorage.getItem("language")) {
      setLanguage(localStorage.getItem("language"))
    }

    /* if (!hasCoupon && localStorage.getItem("coupon") && localStorage.getItem("coupon_expiresAt")) {
      const expiresAt = parseInt(localStorage.getItem("coupon_expiresAt"));
      if (Date.now() < expiresAt) {
        setHasCoupon(expiresAt - Date.now());
      }
      else {
        setHasCoupon(null);
      }
    } */

    const token = localStorage.getItem('userToken');
    if (token) {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          setUser(user);
          const db = getDatabase();
          const userRef = ref(db, `users/${user.uid}`);
          const snapshot = await get(userRef);

          if (snapshot.exists()) {
            const userData = snapshot.val();

            if (!hasCoupon && userData.coupon) {
              if (localStorage.getItem("coupon") && localStorage.getItem("coupon_expiresAt") && localStorage.getItem("coupon") === userData.coupon) {
                const localExpiresAt = parseInt(localStorage.getItem("coupon_expiresAt"));
                if (Date.now() < localExpiresAt) {
                  setHasCoupon(localExpiresAt - Date.now());
                }
                else {
                  await set(ref(db, `users/${user.uid}/coupon`), null);
                  localStorage.removeItem("coupon")
                  localStorage.removeItem("coupon_expiresAt")
                  setHasCoupon(null);
                }
              }
              else {
                setHasCoupon((60 * 60 * 1000));
                localStorage.setItem("coupon", userData.coupon);
                localStorage.setItem("coupon_expiresAt", Date.now() + (60 * 60 * 1000))
                await set(ref(db, `users/${user.uid}/coupon`), null);
              }
            }

            if (!localStorage.getItem('language') && userData?.analytics?.lang && languageCodes.includes(userData?.analytics?.lang)) {
              setLanguage(userData?.analytics?.lang)
            }

            if (userData.payments && userData?.credits > 0) setPaidUser(true);

            if (userData?.credits > 0) {
              setCredits(userData.credits);
            }
            if (userData.adult) {
              setShowAdultContent(true);
            } else setShowAdultContent(false);
          }
        } else {
          navigate('/signup');
        }
      });
    } else {
      navigate('/signup');
    }
  }, [navigate, id]);

  useEffect(() => {
    if (showAdultContent !== null) {
      loadFeedData();
      loadMediaData(user);
    }
  }, [showAdultContent, user]); 

  // Telegram authentication
  useEffect(() => {
    const authenticateTelegramUser = async () => {
      if (isTelegramWebApp && !user) {
        const initData = window.Telegram.WebApp.initData;
        if (initData) {
          const formBody = `initData=${encodeURIComponent(initData)}`;
          try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/verify-telegram-webapp`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
              body: formBody,
            });
            const result = await response.json();
            if (result.success && result.customToken) {
              await signInWithCustomToken(auth, result.customToken);
              const idToken = await auth.currentUser.getIdToken();
              localStorage.setItem('userToken', idToken);
              setUser(auth.currentUser);
              const db = getDatabase();
              const userRef = ref(db, `users/${auth.currentUser.uid}`);
              const snapshot = await get(userRef);
              if (snapshot.exists()) {
                const userData = snapshot.val();
                if (!localStorage.getItem('language') && userData?.analytics?.lang && languageCodes.includes(userData?.analytics?.lang)) {
                  setLanguage(userData?.analytics?.lang)
                }

                if (userData.payments && userData?.credits > 0) setPaidUser(true);
                if (userData.adult) {
                  setShowAdultContent(true);
                } else {
                  setShowAdultContent(false);
                }

                if (!hasCoupon && userData.coupon) {
                  if (localStorage.getItem("coupon") && localStorage.getItem("coupon_expiresAt") && localStorage.getItem("coupon") === userData.coupon) {
                    const localExpiresAt = parseInt(localStorage.getItem("coupon_expiresAt"));
                    if (Date.now() < localExpiresAt) {
                      setHasCoupon(localExpiresAt - Date.now());
                    }
                    else {
                      localStorage.removeItem("coupon")
                      localStorage.removeItem("coupon_expiresAt")
                      setHasCoupon(null);
                    }
                  }
                  else {
                    setHasCoupon((60 * 60 * 1000));
                    localStorage.setItem("coupon", userData.coupon);
                    localStorage.setItem("coupon_expiresAt", Date.now() + (60 * 60 * 1000))
                  }
                  await set(ref(db, `users/${user.uid}/coupon`), null);
                }
              }
            } else {
              console.error('Telegram authentication failed:', result.error);
              setSubmitError(result.error || (trnsl.errors.auth?.[language] || trnsl.errors.auth.en));
              setShowAdultContent(false);
            }
          } catch (error) {
            console.error('Error during Telegram authentication:', error);
            setSubmitError(trnsl.errors.auth?.[language] || trnsl.errors.auth.en);
            setShowAdultContent(false);
          }
        }
      }
    };
    authenticateTelegramUser();
  }, [isTelegramWebApp, user]);

  // Cleanup and set defaults for Telegram Buttons
  const cleanupTelegramButtons = useCallback(() => {
    if (isTelegramWebApp && window.Telegram && window.Telegram.WebApp) {
      const MainButton = window.Telegram.WebApp.MainButton;
      const SecondButton = window.Telegram.WebApp.SecondaryButton;

      // Reset main button
      MainButton.offClick();
      MainButton.hide();
      MainButton.setParams({
        is_visible: false,
        is_active: false,
        has_shine_effect: false,
      });

      // Reset secondary button
      SecondButton.offClick();
      SecondButton.hide();
      SecondButton.setParams({
        is_visible: false,
        is_active: false,
        has_shine_effect: false,
      });
    }
  }, [isTelegramWebApp]);

  // Handle use prompt click
  const handleUsePromptClick = useCallback(() => {
    if (mediaData) {
      if (mediaData.bundle_id) {
        navigate(`/bundle?bundle_id=${mediaData.bundle_id}`);
      }
      else {
        const queryParams = new URLSearchParams();
        if (mediaData.custom_instructions && mediaData.custom_instructions.length > 0) {
          const prompt = mediaData.custom_instructions.join(', ');
          queryParams.append('prompt', prompt);
        }
        if (mediaData.selections) {
          Object.entries(mediaData.selections).forEach(([key, value]) => {
            queryParams.append(key, value);
          });
        }
        if (mediaData.pulid) {
          queryParams.append('pulid', 'true');
        }
        if (!isJob && mediaData.model_id) {
          queryParams.append('model_id', mediaData.model_id);
        }
  
        console.log(`/create?${queryParams.toString()}`)
        navigate(`/create?${queryParams.toString()}`);
      }
    }
  }, [mediaData, navigate, isJob]);

  // Handle download in Telegram
  const handleDownloadClickTelegram = useCallback(() => {
    if (mediaData.price === 0 && credits === 0) {
      if (!window.popupIsOpen) {
        const MainButton = window.Telegram.WebApp.MainButton;
        const SecondButton = window.Telegram.WebApp.SecondaryButton;

        window.popupIsOpen = true;
        window.Telegram.WebApp.showPopup(
          {
            title: (trnsl.errors.downloadCredit.title?.[language] || trnsl.errors.downloadCredit.title.en),
            message: (trnsl.errors.downloadCredit.message?.[language] || trnsl.errors.downloadCredit.message.en),
            buttons: [
              { id: 'shop', type: 'ok', text: "Shop" },
              { id: 'cancel', type: 'cancel', text: "Cancel" },
            ],
          },
          (buttonId) => {
            window.popupIsOpen = false;
            if (buttonId === 'shop') {
              cleanupTelegramButtons();
              navigate('/shop');
            }
          }
        );
      }
    } else {
      let imageUrl = '';
      if (mediaData.output && mediaData.output.length > 0) {
        imageUrl = mediaData.output[activeStepRef.current];
      } else if (mediaData.link) {
        imageUrl = mediaData.link;
      }
      if (imageUrl) {
        window.Telegram.WebApp.downloadFile(
          {
            url: imageUrl,
            file_name: `image_${id}_${activeStepRef.current}.jpg`,
          },
          (accepted) => {
            if (accepted) {
              console.log('Download accepted');
            } else {
              console.log('Download canceled');
            }
          }
        );
      }
    }
  }, [mediaData, credits, id, navigate, cleanupTelegramButtons]);

  // Handle pay logic depending on status
  useEffect(() => {
    if (isTelegramWebApp && mediaData) {
      // Clean up first
      cleanupTelegramButtons();

      const MainButton = window.Telegram.WebApp.MainButton;
      const SecondButton = window.Telegram.WebApp.SecondaryButton;

      if (mediaData.status === 'queue' || mediaData.status === 'processing') {
        // Processing state: Show main button disabled
        MainButton.setText(trnsl.buttons.processing?.[language] || trnsl.buttons.processing.en);
        MainButton.setParams({
          is_visible: true,
          is_active: false,
        });
        MainButton.show();
      } else {
        // Media completed or available
        const onMainButtonClick = () => {
          handleUsePromptClick();
        };

        MainButton.onClick(onMainButtonClick);

        if (mediaData.bundle_id) {
          MainButton.setText(trnsl.buttons.try_bundle?.[language] || trnsl.buttons.try_bundle.en);
        }
        else {
          MainButton.setText(trnsl.buttons.use_prompt?.[language] || trnsl.buttons.use_prompt.en);
        }

        MainButton.setParams({
          is_visible: true,
          is_active: true,
          text_color: '#FFFFFF',
          color: '#5288c1',
          position: 'bottom',
        });
        MainButton.show();
        MainButton.enable();

        // If completed, show secondary download button
        if (mediaData.status === 'completed') {
          const onSecondButtonClick = () => {
            handleDownloadClickTelegram();
          };
          SecondButton.onClick(onSecondButtonClick);
          SecondButton.setText(trnsl.buttons.download?.[language] || trnsl.buttons.download.en);
          SecondButton.setParams({
            is_visible: true,
            is_active: true,
            text_color: '#FFFFFF',
            position: 'right',
            color: '#7A4FFF',
            has_shine_effect: true,
          });
          SecondButton.show();

          // Cleanup
          return () => {
            MainButton.offClick(onMainButtonClick);
            MainButton.hide();
            SecondButton.offClick(onSecondButtonClick);
            SecondButton.hide();
          };
        } else {
          // Cleanup if not completed
          return () => {
            MainButton.offClick(onMainButtonClick);
            MainButton.hide();
            SecondButton.offClick();
            SecondButton.hide();
          };
        }
      }
    }
  }, [isTelegramWebApp, mediaData, handleUsePromptClick, handleDownloadClickTelegram, cleanupTelegramButtons]);

  useEffect(() => {
    let pollIntervalId;
    
    async function pollActiveJob() {
      if (!user || !isJob || !mediaData) return;
      
      // If job status is not queue/processing => no need to poll
      if (mediaData.status !== 'queue' && mediaData.status !== 'processing') return;
  
      try {
        // 1) Check if job is still in active
        const activeRef = ref(database, `jobs/active/${id}`);
        const activeSnap = await get(activeRef);
  
        if (!activeSnap.exists()) {
          // => job is no longer in active, so it might be in finished
          console.log("Job not in active; possibly finished. Let's load from /finished...");
          const finishedRef = ref(database, `jobs/finished/${id}`);
          const finishedSnap = await get(finishedRef);
          if (finishedSnap.exists()) {
            const finishedData = finishedSnap.val();
            // Update your UI
            setMediaData(finishedData);
          } else {
            // If not in finished either => maybe user deleted it or some error
            console.log("Job not in active or finished => notFound or something else");
            setError('notFound');
          }
          // Stop polling once it's no longer in active
          clearInterval(pollIntervalId);
          return;
        }
  
        // If still in active => see if there's new images added
        const activeData = activeSnap.val();
        
        // Compare .output array length
        const oldOutput = mediaData?.output || [];
        const newOutput = activeData?.output || [];
        const oldLength = oldOutput.length;
        const newLength = newOutput.length;
        
        // If new images exist => update local mediaData 
        if (newLength > oldLength) {
          console.log("New images found in active job => updating UI");
          setMediaData((prev) => ({ ...prev, output: newOutput }));
        }
        
        // If status changed from queue => processing => or vice versa => update
        if (activeData.status !== mediaData.status) {
          setMediaData((prev) => ({ ...prev, status: activeData.status }));
        }
        
        // Possibly check for estimateWaitTime changes
        if (activeData.estimateWaitTime !== mediaData.estimateWaitTime) {
          setMediaData((prev) => ({
            ...prev,
            estimateWaitTime: activeData.estimateWaitTime
          }));
        }
  
      } catch (err) {
        console.error("Poll job error:", err);
      }
    }
  
    // 2) If the job is queue/processing => start polling
    if (mediaData && user && isJob && 
        (mediaData.status === 'queue' || mediaData.status === 'processing')) {
      pollIntervalId = setInterval(pollActiveJob, 10000);
    }
  
    return () => {
      if (pollIntervalId) clearInterval(pollIntervalId);
    };
  }, [mediaData, user, isJob, id]);

  const loadFeedData = async () => {
    try {
      let cachedData = localStorage.getItem('feedData');
      let cachedTimestamp = localStorage.getItem('feedDataTimestamp');
      const now = Math.floor(Date.now() / 1000);
      const FEED_DATA_CACHE_DURATION = 10 * 60;

      if (cachedData && cachedTimestamp && now - parseInt(cachedTimestamp) < FEED_DATA_CACHE_DURATION) {
        const feedData = JSON.parse(cachedData);
        if (feedData.feed?.length === 0) cachedTimestamp = 0;
        setBundles(feedData.bundles || []);
      } else {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/feed`, {
          method: 'GET',
          headers: {
            showadultcontent: showAdultContent ? 'true' : 'false',
          },
        });

        if (response.ok) {
          const result = await response.json();
          setBundles(result.bundles || []);
          const feedDataToCache = {
            feed: result.feed || [],
            models: result.models || [],
            bundles: result.bundles || [],
          };
          localStorage.setItem('feedData', JSON.stringify(feedDataToCache));
          localStorage.setItem('feedDataTimestamp', now.toString());
        } else {
          console.error('Error fetching feed data:', response.statusText);
        }
      }
    } catch (error) {
      console.error('Error loading feed data:', error);
    }
  };

  const loadMediaData = async (currentUser) => {
    const db = database;
    try {
      setLoading(true);
      let mediaSnapshot;
      let isEmpty = true;
      localStorage.removeItem(`job_${id}`)
      if (localStorage.getItem(`${isJob ? "job_" : "feed_"}${id}`)) {
        console.log('got item from localStorage')
        mediaSnapshot = JSON.parse(localStorage.getItem(`job_${id}`))
        isEmpty = false;
      }
      else {
        let mediaRef = ref(db, `${isJob ? 'jobs' : `feed/${showAdultContent ? 'adult' : 'default'}`}/${isJob ? 'finished/' : ''}${id}`);
        mediaSnapshot = await get(mediaRef);
        console.log('got item from database. Exists?', mediaSnapshot.exists())
        if (mediaSnapshot.exists()) {
          isEmpty = false;
          mediaSnapshot = mediaSnapshot.val();
          console.log(mediaSnapshot)
          if (mediaSnapshot.status === 'finished') {
            console.log('and it is finished')
            localStorage.setItem(`${isJob ? "job_" : "feed_"}${id}`, JSON.stringify(mediaSnapshot))
          }
        }
      }

      if (isJob && isEmpty) {
        mediaSnapshot = await get(ref(db, `jobs/active/${id}`));
        console.log('got active item')
        if (mediaSnapshot.exists()) {
          isEmpty = false;
          mediaSnapshot = mediaSnapshot.val();
        }
      }
      
      if (!isEmpty) {
        console.log('not empty. Is job?', isJob)
        let media = mediaSnapshot;
        if (isJob) {
          if (media.userId !== currentUser.uid) {
            setError('forbidden');
            return;
          }
        }

        if (media.blurred && media.blur_after !== undefined && credits === 0) {
          if (media.output && media.blurred.length >= media.output.length - media.blur_after) {
            media.output = [
              ...media.output.slice(0, media.blur_after),
              ...media.blurred.slice(0, media.output.length - media.blur_after),
            ];
          }
        }

        setMediaData(media);

        if (media.output && media.output.length > 0) {
          media.output.forEach((url) => {
            const img = new Image();
            img.src = url;
          });
        }

        if (media.model_id) {
          const modelRef = ref(db, `models/${media.model_id}`);
          const modelSnapshot = await get(modelRef);
          if (modelSnapshot.exists()) {
            setModelData(modelSnapshot.val());
          } else {
            console.error('Model data not found');
          }
        }
      } else {
        console.error('Error fetching media data: Not found');
        setError('notFound');
      }
    } catch (error) {
      console.error('Error fetching media data:', error);
      setError('unknownError');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = () => {
    if (isTelegramWebApp) {
      if (!window.popupIsOpen) {
        window.popupIsOpen = true;
        window.Telegram.WebApp.showPopup(
          {
            title: trnsl.delete_job.title?.[language] || trnsl.delete_job.title.en,
            message: trnsl.delete_job.message?.[language] || trnsl.delete_job.message.en,
            buttons: [
              { id: 'confirm', type: 'destructive', text: 'Delete' },
              { id: 'cancel', type: 'cancel', text: 'Cancel' },
            ],
          },
          (buttonId) => {
            window.popupIsOpen = false;
            if (buttonId === 'confirm') {
              deleteJob();
            }
          }
        );
      }
    } else {
      setDeleteDialogOpen(true);
    }
  };

  const deleteJob = async () => {
    if (user) {
      const db = database;
      const storage = getStorage();
      try {
        const userJobRef = ref(db, `users/${user.uid}/jobs`);
        const userJobsSnapshot = await get(userJobRef);
        if (userJobsSnapshot.exists()) {
          const jobIds = userJobsSnapshot.val();
          const jobIndex = jobIds.indexOf(id);
          if (jobIndex > -1) {
            jobIds.splice(jobIndex, 1);
            await set(userJobRef, jobIds);
          }
        }

        if (mediaData && mediaData.output && mediaData.output.length > 0) {
          for (let i = 0; i < mediaData.output.length; i++) {
            try {
              const fileUrl = mediaData.output[i];
              const decodedUrl = decodeURIComponent(fileUrl);
              const pathStartIndex = decodedUrl.indexOf('/o/') + 3;
              const pathEndIndex = decodedUrl.indexOf('?');
              const fullPath = decodedUrl.substring(pathStartIndex, pathEndIndex);
              const storagePath = fullPath.replace(/%2F/g, '/');
              const fileRef = storageRef(storage, storagePath);
              await deleteObject(fileRef);
            } catch (error) {
              console.error(`Error deleting file from storage: ${error}`);
            }
          }
        }

        if (isJob) navigate('/gallery');
        else navigate('/feed');
      } catch (error) {
        console.error('Error deleting job:', error);
      }
    }
  };

  const handleDownloadClick = () => {
    let imageUrl = '';
    if (mediaData.output && mediaData.output.length > 0) {
      imageUrl = mediaData.output[activeStep];
    } else if (mediaData.link) {
      imageUrl = mediaData.link;
    }
    if (imageUrl) {
      window.open(imageUrl, '_blank');
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      const newStep = prevActiveStep + 1;
      return newStep >= mediaData.output.length ? prevActiveStep : newStep;
    });
    setImageLoaded(false);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      const newStep = prevActiveStep - 1;
      return newStep < 0 ? prevActiveStep : newStep;
    });
    setImageLoaded(false);
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (!touchStartX) return;
    const touchEndX = e.touches[0].clientX;
    const distance = touchStartX - touchEndX;
    if (distance > 50 && activeStep < mediaData.output.length - 1) {
      handleNext();
      setTouchStartX(null);
    } else if (distance < -50 && activeStep > 0) {
      handleBack();
      setTouchStartX(null);
    }
  };

  const handleTouchEnd = () => {
    setTouchStartX(null);
  };

  // Show first-time dialog
  useEffect(() => {
    if (!loading && mediaData && mediaData.output && mediaData.output.length > 1) {
      const hasSeenMediaViewer = localStorage.getItem('mediaViewerFirstTime');
      if (!hasSeenMediaViewer) {
        if (isTelegramWebApp) {
          window.Telegram.WebApp.showAlert(trnsl.swipe_tip?.[language] || trnsl.swipe_tip.en);
        } else {
          setFirstTimeDialogOpen(true);
        }
        localStorage.setItem('mediaViewerFirstTime', 'true');
      }
    }
  }, [loading, mediaData, isTelegramWebApp]);

  if (error) {
    console.log(error);
    return <ErrorModule type={error} />;
  }

  return (
    <TemplateFrame
      toggleCustomTheme={() => setShowCustomTheme((prev) => !prev)}
      showCustomTheme={showCustomTheme}
      mode={mode}
      credits={credits}
      toggleColorMode={() => {
        const newMode = mode === 'dark' ? 'light' : 'dark';
        setMode(newMode);
        if (!isTelegramWebApp) {
          localStorage.setItem('themeMode', newMode);
        }
      }}
    >
      <ThemeProvider theme={showCustomTheme ? galleryTheme : defaultTheme}>
        <CssBaseline enableColorScheme />
        <ViewerContainer sx={{ marginBottom: 10 }}>
          <Button
            startIcon={<ArrowBackIcon />}
            size="large"
            onClick={() => navigate('/gallery')}
            sx={{ alignSelf: 'flex-start', margin: 2 }}
          >
            Back
          </Button>
          {loading ? (
            <CircularProgress />
          ) : mediaData ? (
            <MediaCard>
              {hasCoupon && !isPaidUser && (
               <CouponBanner timeRemaining={hasCoupon || null} language={language}/>
              )}
              <CardActionArea sx={{ position: 'relative', width: '100%', marginBottom: 2 }}>
                {mediaData.output && mediaData.output.length > 0 ? (
                  <Box
                    sx={{ position: 'relative' }}
                    onTouchStart={isMobile ? handleTouchStart : null}
                    onTouchMove={isMobile ? handleTouchMove : null}
                    onTouchEnd={isMobile ? handleTouchEnd : null}
                  >
                    <Zoom canSwipeToUnzoom={false}>
                      <CardMedia
                        component="img"
                        image={mediaData.output[activeStep]}
                        alt={`Media ${id} - ${activeStep}`}
                        onLoad={() => setImageLoaded(true)}
                        onError={() => setImageLoaded(true)}
                        sx={{
                          width: '100%',
                          height: 'auto',
                          objectFit: 'cover',
                          display: imageLoaded ? 'block' : 'none',
                          borderRadius: 1,
                        }}
                      />
                    </Zoom>
                    {!imageLoaded && (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    )}
                    {mediaData.output.length > 1 && (
                      <>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleBack();
                          }}
                          disabled={activeStep === 0}
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '2%',
                            transform: 'translate(0, -50%)',
                            backgroundColor: "transparent",
                            borderColor: "transparent",
                            color: 'white',
                          }}
                        >
                          <KeyboardArrowLeft />
                        </IconButton>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleNext();
                          }}
                          disabled={activeStep === mediaData.output.length - 1}
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            right: '2%',
                            transform: 'translate(0, -50%)',
                            backgroundColor: "transparent",
                            borderColor: "transparent",
                            color: 'white',
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            },
                          }}
                        >
                          <KeyboardArrowRight />
                        </IconButton>
                      </>
                    )}
                  </Box>
                ): 
                (
                  <Card sx={{ position: 'relative', width: '100%', textAlign: 'center'}}>
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/icons/webp/${mediaData.status === 'queue' ? "expires" : mediaData.status === 'processing' ? "magic" : "error"}.webp`}
                        alt="Processing"
                        style={{ width: '50%', maxWidth: '200px', marginTop: 12}}
                    />
                    <Typography variant="h6" sx={{marginTop: 2}}>
                      {mediaData.status === 'queue' && (trnsl.imagePlaceHolder.title.queue?.[language] || trnsl.imagePlaceHolder.title.queue.en)}
                      {mediaData.status === 'processing' && (trnsl.imagePlaceHolder.title.processing?.[language] || trnsl.imagePlaceHolder.title.processing.en)}
                      {mediaData.status === 'failed' && (trnsl.imagePlaceHolder.title.error?.[language] || trnsl.imagePlaceHolder.title.error.en)}
                    </Typography>
                    { mediaData.status === 'queue' && mediaData.estimateWaitTime && ((mediaData.estimateWaitTime - Math.floor(Date.now() / 1000)) > 60) && (
                      <>
                        <Typography variant="subtitle2" >
                          {trnsl.imagePlaceHolder.waitTime?.[language] || trnsl.imagePlaceHolder.waitTime.en}{" "}{formatTime(mediaData.estimateWaitTime - Math.floor(Date.now() / 1000))}
                        </Typography>
                        { !isPaidUser && (
                          <Box sx={{  maxWidth: '100%', marginTop: 2 }}>
                            <PurchaseRequiredWidget source={'speed'} language={language}/>
                          </Box>
                        )}
                      </>
                    )}
                  </Card>
                )}

                {!imageLoaded && mediaData.status === 'success' && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}

                <TransparentOverlay />

                {(mediaData.status === 'queue' || mediaData.status === 'processing') && (mediaData.output && mediaData.output.length > 0) ? (
                  <Box sx={{ position: 'absolute', bottom: 16, left: 16, right: 16, zIndex: 11, maxWidth: '100%' }}>
                    <PurchaseRequiredWidget source={'waiting'} language={language} expiresIn={mediaData.estimateWaitTime ? mediaData.estimateWaitTime - Math.floor(Date.now() / 1000) : null} mediaLeft={(mediaData.bundle ? mediaData.bundle.length : mediaData.num_outputs) - mediaData.output.length}/>
                  </Box>
                ) : (
                  <>
                    {!mediaData.blurred && !mediaData.expiresAt && modelData && (
                      <Box sx={{ position: 'absolute', bottom: 16, left: 16, zIndex: 11 }}>
                        <ModelWidget
                          name={modelData.name}
                          imageUrl={modelData.avatar}
                          mediaAmount={modelData.media.length || '10+'}
                          id={mediaData.model_id}
                        />
                      </Box>
                    )}
                    {mediaData.blurred && !isPaidUser && (
                      <Box sx={{ position: 'absolute', bottom: 16, left: 16, right: 16, zIndex: 11, maxWidth: '100%' }}>
                        <PurchaseRequiredWidget source={'credits'} language={language} />
                      </Box>
                    )}
                    {!isPaidUser && mediaData.expiresAt && !mediaData.blurred && timeLeft !== null && (
                      <Box sx={{ position: 'absolute', bottom: 16, left: 16, right: 16, zIndex: 11, maxWidth: '100%' }}>
                        <PurchaseRequiredWidget source={'expires'} expiresIn={timeLeft} language={language} />
                      </Box>
                    )}
                  </>
                )}
              </CardActionArea>
              
              {mediaData.output && mediaData.output.length > 1 && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 1,
                  }}
                >
                  <Typography variant="body2">
                    {activeStep + 1}/{mediaData.output.length}
                  </Typography>
                </Box>
              )}

              {!mediaData.bundle_id && (
                mediaData.custom_instructions && mediaData.custom_instructions.length > 0 ? (
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 2 }}>
                    <TextField
                      value={mediaData.custom_instructions.join(', ')}
                      readOnly
                      fullWidth
                      sx={{
                        flexGrow: 1,
                        mr: 1,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                          },
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <>
                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                            <Button
                              startIcon={<AutoAwesomeIcon />}
                              onClick={() => {
                                navigator.clipboard.writeText(mediaData.custom_instructions.join(', '));
                                handleUsePromptClick();
                              }}
                            >
                              {trnsl.use?.[language] || trnsl.use.en}
                            </Button>
                          </>
                        ),
                      }}
                    />
                  </Box>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    startIcon={<AutoAwesomeIcon />}
                    onClick={handleUsePromptClick}
                    sx={{ mt: 2 }}
                  >
                    {trnsl.use_prompt?.[language] || trnsl.use_prompt.en}
                  </Button>
                )
              )}

              {mediaData.bundle_id && (
                <Container maxWidth="xl">
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginBottom: 2,
                      width: '100%',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <WebStoriesIcon sx={{ fontSize: 24 }} />
                      <Typography variant="h6">{trnsl.with_bundles?.[language] || trnsl.with_bundles.en}</Typography>
                    </Box>
                  </Box>
                  <BundleList bundles={[bundles.find((bundle) => bundle.bundle_id === mediaData.bundle_id)]} language={language} isPaid={isPaidUser} isShowAdultContent={showAdultContent} />
                </Container>
              )}

              {mediaData.selections && (
                <Container maxWidth="md">
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 1,
                      mt: 2,
                      justifyContent: 'center',
                    }}
                  >
                    {Object.values(mediaData.selections).map((value, index) => (
                      <Chip key={index} label={value} />
                    ))}
                  </Box>
                </Container>
              )}

              {isJob && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 2 }}>
                  <TextField
                    label="Job ID"
                    value={id}
                    readOnly
                    fullWidth
                    sx={{
                      flexGrow: 1,
                      mr: 1,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                        },
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <>
                          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                          <Button
                            onClick={() => {
                              navigator.clipboard.writeText(id);
                            }}
                          >
                            {trnsl.copy?.[language] || trnsl.copy.en}
                          </Button>
                        </>
                      ),
                    }}
                  />
                </Box>
              )}

              {isJob && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 2 }}>
                  {mediaData.status === 'success' && !isTelegramWebApp && (
                    <Button
                      variant="contained"
                      color="inherit"
                      fullWidth
                      startIcon={<FileDownloadIcon />}
                      onClick={handleDownloadClick}
                      sx={{ mr: 1 }}
                    >
                      {trnsl.buttons.download?.[language] || trnsl.buttons.download.en}
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="error"
                    fullWidth
                    startIcon={<DeleteIcon />}
                    onClick={handleDeleteClick}
                  >
                    {trnsl.delete?.[language] || trnsl.delete.en}
                  </Button>
                </Box>
              )}
            </MediaCard>
          ) : (
            <Typography variant="h6" color="textSecondary">
              {trnsl.errors.not_found?.[language] || trnsl.errors.not_found.en}
            </Typography>
          )}
        </ViewerContainer>

        {!isTelegramWebApp && (
          <Dialog open={firstTimeDialogOpen} onClose={() => setFirstTimeDialogOpen(false)}>
            <DialogTitle
              sx={{
                backgroundColor: galleryTheme.palette.background.paper
              }}
            >
              <Box sx={{ textAlign: 'center', mb: 2 }}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/webp/swipe.webp`}
                  alt="Swipe Hint"
                  style={{ width: '50%', maxWidth: '200px', margin: '0 auto' }}
                />
              </Box>
              {trnsl.swipe_header?.[language] || trnsl.swipe_header.en}
            </DialogTitle>
            <DialogContent
              sx={{
                backgroundColor: galleryTheme.palette.background.paper
              }}
            >
              <Typography variant="body2" color="textSecondary">
                {trnsl.swipe_tip?.[language] || trnsl.swipe_tip.en}
              </Typography>
            </DialogContent>
            <DialogActions
              sx={{
                backgroundColor: galleryTheme.palette.background.paper
              }}
            >
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => setFirstTimeDialogOpen(false)}
              >
                Got It!
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {!isTelegramWebApp && (
          <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
            <DialogTitle
              sx={{
                backgroundColor: galleryTheme.palette.background.paper
              }}
            >
              <Box sx={{ textAlign: 'center', mb: 2 }}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/webp/bomb.webp`}
                  alt="Delete Confirmation"
                  style={{ width: '50%', maxWidth: '200px', margin: '0 auto' }}
                />
              </Box>
              {trnsl.delete_job.title?.[language] || trnsl.delete_job.title.en}
            </DialogTitle>
            <DialogContent
              sx={{
                backgroundColor: galleryTheme.palette.background.paper
              }}
            >
              <Typography variant="body2" color="textSecondary">
              {trnsl.delete_job.message?.[language] || trnsl.delete_job.message.en}
              </Typography>
            </DialogContent>
            <DialogActions
              sx={{
                backgroundColor: galleryTheme.palette.background.paper
              }}
            >
              <Button
                variant="contained"
                color="error"
                fullWidth
                onClick={() => {
                  deleteJob();
                  setDeleteDialogOpen(false);
                }}
              >
                {trnsl.delete?.[language] || trnsl.delete.en}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => setDeleteDialogOpen(false)}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </ThemeProvider>
    </TemplateFrame>
  );
}
