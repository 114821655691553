import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  CssBaseline,
  Button,
} from '@mui/material';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TemplateFrame from '../pages/theme/TemplateFrame';
import getSignUpTheme from '../pages/theme/theme/getSignUpTheme';

const PlaceholderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '80vh',
  padding: theme.spacing(4),
}));

const AnimationContainer = styled(Box)(({ theme }) => ({
  width: '50%',
  maxWidth: '400px',
  marginBottom: theme.spacing(4),
}));

export default function ErrorModule({ type, custom_description, custom_navigate, button_name }) {
  const [mode, setMode] = useState('dark');
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const [isTelegramWebApp, setIsTelegramWebApp] = useState(false);

  const defaultTheme = createTheme({ palette: { mode } });
  const placeholderPageTheme = createTheme(getSignUpTheme(mode));
  const navigate = useNavigate();

  useEffect(() => {
    // Check theme mode from localStorage
    const savedMode = localStorage.getItem('themeMode');
    setMode(savedMode ? savedMode : 'dark');

    // Detect if running inside Telegram Web App
    if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initData) {
      setIsTelegramWebApp(true);

      // Set up the Telegram main button for navigation
      const MainButton = window.Telegram.WebApp.MainButton;
      MainButton.setParams({
        text: 'Back',
        color: '#5288c1',
        textColor: '#ffffff',
        is_visible: true,
      });
      MainButton.onClick(() => navigate('/create'));

      return () => {
        MainButton.offClick();
        MainButton.hide();
      };
    }
  }, [navigate]);

  const errorDetails = {
    forbidden: {
      title: 'Forbidden',
      description: 'You do not have permission to access this page. Please contact the administrator or check your permissions.',
      animationSrc: `${process.env.PUBLIC_URL}/assets/icons/webp/legal.webp`,
    },
    notFound: {
      title: '404 Not Found',
      description: 'The page you are looking for does not exist. It may have been moved or deleted.',
      animationSrc: `${process.env.PUBLIC_URL}/assets/icons/webp/find.webp`,
    },
    unknownError: {
      title: 'Unknown Error',
      description: 'An unexpected error has occurred. Please try again later or contact support if the issue persists.',
      animationSrc: `${process.env.PUBLIC_URL}/assets/icons/webp/confused.webp`,
    },
    empty: {
      title: "Empty",
      description: "There is no content here",
      animationSrc: `${process.env.PUBLIC_URL}/assets/icons/webp/notfound.webp`,
    }
  };

  const errorType = errorDetails[type] ? type : 'unknownError';
  const { title, description, animationSrc } = errorDetails[errorType];

  const handleNavigate = () => {
    navigate('/create');
  };

  return (
    <ThemeProvider theme={showCustomTheme ? placeholderPageTheme : defaultTheme}>
      <CssBaseline />
      <PlaceholderContainer>
        <AnimationContainer>
          <img src={animationSrc} alt={title} style={{ width: '100%' }} />
        </AnimationContainer>

        <Typography variant="h3" align="center" gutterBottom>
          {title}
        </Typography>

        <Typography variant="body1" color="textSecondary" align="center">
          {custom_description || description}
        </Typography>

        {!isTelegramWebApp && (
          <Button
            variant="contained"
            sx={{ marginTop: 2 }}
            size="large"
            startIcon={<ArrowBackIcon />}
            onClick={handleNavigate}
          >
            Back
          </Button>
        )}
      </PlaceholderContainer>
    </ThemeProvider>
  );
}
