import React, { useEffect, useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const SelectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  alignItems: 'center',
  marginBottom: theme.spacing(4),
  width: '100%',
}));

const translations = {
  en: { selectLanguage: 'Select Language' },
  es: { selectLanguage: 'Selecciona un idioma' },
  pt: { selectLanguage: 'Selecionar idioma' },
  de: { selectLanguage: 'Sprache auswählen' },
  ja: { selectLanguage: '言語を選択してください' },
  ko: { selectLanguage: '언어 선택' },
  zh: { selectLanguage: '选择语言' },
  hi: { selectLanguage: 'भाषा चुनें' },
  ru: { selectLanguage: 'Выберите Язык'}
};

const languageOptions = [
  { tag: 'en', label: 'English 🇬🇧' },
  { tag: 'es', label: 'Spanish 🇪🇸' },
  { tag: 'ru', label: "Russian 🇷🇺"}, 
  { tag: 'pt', label: 'Portuguese 🇵🇹' },
  { tag: 'zh', label: 'Chinese 🇨🇳' },
  { tag: 'de', label: 'German 🇩🇪' },
  { tag: 'ja', label: 'Japanese 🇯🇵' },
  { tag: 'ko', label: 'Korean 🇰🇷' },
  { tag: 'hi', label: 'Hindi 🇮🇳' },
];

const LanguageSelection = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('language') || 'en'
  );

  const handleLanguageChange = (event) => {
    const value = event.target.value;
    setSelectedLanguage(value);
    localStorage.setItem('language', value); // Save as IETF language tag
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setSelectedLanguage(savedLanguage);
    }
  }, []);

  return (
    <SelectionContainer>
      <Typography variant="h6">{translations[selectedLanguage]?.selectLanguage}</Typography>
      <FormControl fullWidth>
        <InputLabel id="language-select-label">Language</InputLabel>
        <Select
          labelId="language-select-label"
          value={selectedLanguage}
          onChange={handleLanguageChange}
        >
          {languageOptions.map((language) => (
            <MenuItem key={language.tag} value={language.tag}>
              {language.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </SelectionContainer>
  );
};

export default LanguageSelection;
