// MasonryItem.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  Button,
  Card,
  CardMedia,
  CardActions,
  CardActionArea,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  CircularProgress,
  Skeleton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useNavigate } from 'react-router-dom';

const Item = styled(Card)(({ theme, jobStatus }) => ({
  position: 'relative',
  width: '90%',
  overflow: 'hidden',
  boxShadow: 'none',
  border: 'none',
  borderRadius: 0,
  backgroundColor: "transparent",
  borderBottom:
    jobStatus === 'queue' || jobStatus === 'processing'
      ? `4px solid ${theme.palette.warning.main}`
      : '',
}));

const ExpiryChip = styled(Chip)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  left: theme.spacing(1),
}));

const TransparentOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'transparent',
  zIndex: 10,
  cursor: 'not-allowed',
}));

const trnsl = {
  failed_label: {
    en: "Failed",
    ru: "Не удалось",
    es: "Fallido",
    pt: "Falhou",
    de: "Fehlgeschlagen",
    ja: "失敗しました",
    ko: "실패",
    zh: "失败",
    hi: "असफल"
  },
  failed_message: {
    en: "credit(-s) for this job were returned",
    ru: "Кредит(ов) за эту задачу были возвращены",
    es: "los créditos para este trabajo fueron devueltos",
    pt: "créditos para este trabalho foram devolvidos",
    de: "guthaben für diesen Auftrag wurden zurückgegeben",
    ja: "このジョブのクレジットが返されました",
    ko: "이 작업에 대한 크레딧이 반환되었습니다",
    zh: "此任务的积分已退还",
    hi: "इस कार्य के लिए क्रेडिट वापस कर दिए गए"
  },
  processing: {
    en: "Processing...",
    ru: "Обработка...",
    es: "Procesando...",
    pt: "Processando...",
    de: "Wird verarbeitet...",
    ja: "処理中...",
    ko: "처리 중...",
    zh: "处理中...",
    hi: "प्रक्रिया जारी है..."
  },
  queue: {
    en: "In queue...",
    ru: "В очереди...",
    es: "En cola...",
    pt: "Na fila...",
    de: "In Warteschlange...",
    ja: "キューに入っています...",
    ko: "대기 중...",
    zh: "排队中...",
    hi: "पंक्ति में..."
  }
};


export default function MasonryItem({ job, onDeleteJob, onNavigateToJob }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingImage, setLoadingImage] = useState(true);
  const [language, setLanguage] = useState('en');
  const navigate = useNavigate();

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    onDeleteJob(job.jobId);
  };

  const handleNavigateClick = () => {
    onNavigateToJob(job.jobId);
  };

  const handleDownloadClick = async (event) => {
    event.stopPropagation();
    setLoadingDownload(true);
    try {
      const url = job.link || (job.output && job.output[0]);

      // Open the download URL in a new tab
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error downloading the file:', error);
    } finally {
      setLoadingDownload(false);
    }
  };

  const handleChipClick = (event) => {
    event.stopPropagation();
    setOpenDialog(true);
  };

  useEffect(() => {
    if (localStorage.getItem("language")) {
      setLanguage(localStorage.getItem("language"))
    }

    if (job.expiresAt) {
      const calculateTimeLeft = () => {
        const difference = job.expiresAt - Math.floor(Date.now() / 1000);
        if (difference <= 0) return 'Expired';

        const days = Math.floor(difference / (60 * 60 * 24));
        const hours = Math.floor((difference % (60 * 60 * 24)) / (60 * 60));
        const minutes = Math.floor((difference % (60 * 60)) / 60);
        const seconds = Math.floor(difference % 60);

        if (days > 0) {
          return `${days}:${hours}`;
        } else if (hours > 0) {
          return `${hours}:${minutes}:${seconds}`;
        } else if (minutes > 0) {
          return `00:${minutes}:${seconds}`;
        } else {
          return `${seconds} seconds`;
        }
      };

      const updateCountdown = () => {
        setCountdown(calculateTimeLeft());
      };

      updateCountdown();
      const timer = setInterval(updateCountdown, 1000);

      return () => clearInterval(timer);
    }
  }, [job.expiresAt]);

  return (
    <>
      <Item jobStatus={job.status} onClick={handleNavigateClick} onContextMenu={(e) => e.preventDefault()}>
        {loadingImage && (
          <Box sx={{ position: 'relative', width: '100%' }}>
            <Skeleton variant="rectangular" width="100%" height={job.status === 'failed' ? 150 : 200} />
            {/* Add Processing Label on top of Skeleton if the job is not successful */}
            {job.status !== 'completed' && (
              <>
                <Typography
                  variant="h6"
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    color: 'white',
                    padding: '8px',
                    borderRadius: '4px',
                  }}
                >
                  {job.status === 'processing' ? (trnsl.processing?.[language] || trnsl.processing.en) : job.status === 'failed' ? ((job.price ? `${job.price} ` : "" ) + (trnsl.failed_message?.[language] || trnsl.failed_message.en)) : (trnsl?.queue?.[language] || trnsl.queue.en)}
                </Typography>
              </>
            )}
          </Box>
        )}
        <CardActionArea sx={{ display: loadingImage ? 'none' : 'block' }}>
          <CardMedia
            component="img"
            image={job.link || (job.output && job.output[0])}
            alt={`Job ${job.jobId}`}
            onLoad={() => setLoadingImage(false)}
            sx={{ cursor: 'pointer', borderRadius: 1, width: '100%' }}
          />
          <TransparentOverlay />
        </CardActionArea>

        {job.expiresAt && (
          <ExpiryChip
            label={countdown}
            onClick={handleChipClick}
            icon={<AccessTimeIcon />}
            variant="filled"
            size="medium"
          />
        )}

        {/* Display action buttons only for jobs with 'success' status */}
        {job.status === 'success' && (
          <CardActions sx={{ marginTop: 2 }}>
            <Button
              variant="contained"
              color="inherit"
              fullWidth
              startIcon={loadingDownload ? <CircularProgress size={20} /> : <FileDownloadIcon />}
              onClick={job.expiresAt && job.noDownload ? handleChipClick : handleDownloadClick}
              disabled={loadingDownload}
            >
              {loadingDownload ? 'Downloading...' : 'Download'}
            </Button>
            <Button
              variant="contained"
              color="error"
              fullWidth
              startIcon={<DeleteIcon />}
              onClick={handleDeleteClick}
            >
              Delete
            </Button>
          </CardActions>
        )}
      </Item>

      {/* Expiry Dialog */}
      {job.expiresAt && (
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle sx={{ textAlign: 'center' }}>
            <Box sx={{ textAlign: 'center', mb: 2 }}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/icons/webp/expires.webp`}
                alt="Expires Soon"
                style={{ width: '50%', maxWidth: '200px', margin: '0 auto' }}
              />
            </Box>
            <Typography variant="h4" align="center">
              File will be deleted in
            </Typography>
          </DialogTitle>
          <DialogContent sx={{ textAlign: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
              <AccessTimeIcon sx={{ mr: 1 }} />
              <Typography variant="h5">{countdown}</Typography>
            </Box>
            <Typography variant="body2" color="textSecondary">
              Only paid users can download media from gallery. Purchase any amount of credits to proceed.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              startIcon={<span role="img" aria-label="gem">💎</span>}
              onClick={() => navigate('/shop')}
            >
              Shop
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
