import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // or other effect CSS
import { Box, Chip } from '@mui/material';

export default function OutputCycle({ afterImages = [] }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  // cycle index every 5s, if more than 1 image
  useEffect(() => {
    if (afterImages.length > 1) {
      const timer = setInterval(() => {
        setCurrentIndex((prev) => (prev + 1) % afterImages.length);
      }, 5000);
      return () => clearInterval(timer);
    }
  }, [afterImages]);

  const url = afterImages[currentIndex] || '';

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      {/* 
         LazyLoadImage supports props like:
         - placeholderSrc: to show a custom placeholder
         - effect: 'blur', 'opacity', etc.
         - height / width to maintain layout
      */}
      <LazyLoadImage
        src={url}
        alt={`After ${currentIndex}`}
        effect="blur"  // or "opacity"
        placeholderSrc="https://via.placeholder.com/600x400?text=Skeleton"
        width="100%"
        // if you know your aspect ratio, you can set a height to keep layout stable:
        height="auto"
        style={{
          objectFit: 'cover',
          borderRadius: '8px',
        }}
      />

      <Chip
        label="OUTPUT"
        color="default"
        size="medium"
        sx={{
          position: 'absolute',
          top: 16,
          left: 16,
          fontSize: '1rem',
          pointerEvents: 'none',
        }}
      />
    </Box>
  );
}
